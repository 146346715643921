import { Match, MatchComment, MatchEvent } from "../../api/schema"
import LiveFeed, { groupedComments } from "./LiveFeed";
import styles from './LiveFeedAroundGame.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentLines } from "@fortawesome/pro-regular-svg-icons";
import CommentAroundGame from "./CommentAroundGame";

type props = {
  events: MatchComment[],
  game: Match<typeof LiveFeed.fragment>,
  title: string,
  live?: boolean,
  emojiFlagsOn: boolean,
}


export default function LiveFeedAroundGame ({emojiFlagsOn, events, game, title, live} : props) {

  return <div className={styles.wrapper} style={{background: `url(https://static.cupmanager.net/images/talk-bg.png)`}}>
    {(live || live === undefined) && <h2 className="custom-font">
      <FontAwesomeIcon icon={faCommentLines}/> 
      <span>{title}</span>
      <FontAwesomeIcon icon={faCommentLines} style={{transform: 'scaleX(-1)'}} /> 
    </h2>}
    {events.map(event => {
      const commentKey = `${event.side}_${event.comment}`;

      return groupedComments(events)[commentKey][0].id === event.id && <CommentAroundGame 
        key={event.id}
        events={groupedComments(events)[commentKey]}
        colors={
          {
            primary: game[event.side].team?.shirt?.color1 || 'rgb(var(--clr-gray-700))',
            secondary: game[event.side].team?.shirt?.anti || 'white'
          }
        }
        emojiFlagsOn={emojiFlagsOn}
        nationCode={game[event.side].team?.club.nation.code}
      />
    }
    )}
  </div>
}