
import { faArrowRight, faGenderless, faMap, faMars, faTrophy, faUsers, faVenus, faVenusMars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import React from "react";
import { useCurrentTournament } from "../../App";
import LinkButton from "../../components/Button/LinkButton";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import WinnersAwardContainer from "../../components/WinnersAwardContainer/WinnersAwardContainer";
import styles from './WinnersPage.module.scss';
import { groupBy } from "../../utils";
import PageHeader from "../../components/PageHeader/PageHeader";
import classNames from "classnames";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";



export default function WinnersPage() {

  const T = useTranslations('cmresults');

  const t = useLink(useCurrentTournament(), {
    finals: [],
    lotCategories: [
      {
        stages: [
          {
            rankings: [
              WinnersAwardContainer.Fragment
            ]
          }
        ]
      }
    ]
  });


  const categoriesSortedFiltered = t.lotCategories.sort((a, b) => {
    if(b.gender.length === a.gender.length) {
      return a.age - b.age;
    } else {
      return b.gender.length - a.gender.length
    }
  }).map(category => {
    return {
      ...category,
      stages: category.stages.filter(stage => stage.rankings.length > 0)
    }
  }).filter(category => category.stages.length > 0);

  const onlyGoldOrCategory = categoriesSortedFiltered.map(category => {
      return {
        ...category,
        stages: category.stages.map(stage => {
          return {
            ...stage,
            rankings: stage.rankings.filter(ranking => ranking.rank === 1)
          }
        })
      }
    })

  const categoriesGrouped = groupBy(onlyGoldOrCategory, (cat) => cat.gender);

  const admin = useAdmin();

  const schedulePublished = t.schedulePublished || admin.overridePublished;

  return <>
    <PageHeader 
        title={T('Winners')}  
        crumbs={[{
            title: T('Results'),
            url: '/'
          }, {
            title: T('Statistics'),
            url: 'statistics/'
          }]
        }
      >
        {t.hasStatistics ? <Shortcuts links={[
        {
          to: '/statistics',
          title: T('Overview'),
          icon: faMap
        }, schedulePublished && {
          to: '/statistics/winners',
          title: T('Winners'),
          icon: faTrophy
        }, t.hasPlayerStatistics && {
            to: '/statistics/players',
            title: T('Players'),
            icon: faUsers
        }, t.schedulePublished && t.finals.length > 0 && {
          to: '/statistics/finals',
          title: T('Finals'),
          icon: faTrophy
        }/*, schedulePublished && {
          to: '/statistics/matches',
          title: T('Games'),
          icon: faTrophy
        }*/
      ]} />: undefined}
      </PageHeader>
    <div className={classNames("max-75", styles.wrapper)}>
      {['women','mixed','men', 'none'].filter(gender => gender in categoriesGrouped).map((gender, i) => {

        const categories = categoriesGrouped[gender];

        return <ContentContainer visibilityDesktop key={i}>
          <div className={styles.gender_header}>
            <h2 className={classNames(styles.content_header,"custom-font")}>
              {gender === 'men' ? <FontAwesomeIcon icon={faMars} />
              : gender === 'women' ? <FontAwesomeIcon icon={faVenus} />
              : gender === 'mixed' ? <FontAwesomeIcon icon={faVenusMars} />
              : <FontAwesomeIcon icon={faGenderless} />
              } {gender === 'women' ? T('Women')
                : gender === 'mixed' ? T('Mixed')
                : gender === 'men' ? T('Men')
                : T('Categories')
              }
            </h2>
          </div>

          {categories.map(cat => {

          return <React.Fragment key={cat.id}>
            <h3 className={classNames(styles.h3,"custom-font")}>
              {cat.name}
            </h3>

            {<div>
              <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load winners')} />}>
                <ContentContainer dark noPadding>
                  {cat.stages.map(stage => {
                    
                    return <React.Fragment key={stage.id}>
                      {stage.rankings.map((ranking, i) => {

                        if('match' in ranking) {
                          return <WinnersAwardContainer ranking={ranking} stageName={stage.name} key={i} />
                        }
          
                        if ('conference' in ranking) {
                          return <WinnersAwardContainer ranking={ranking} stageName={stage.name} key={i} />
                        }
          
                        return false;
                      })}
                    </React.Fragment>
                  })}
                </ContentContainer>
              </ErrorBoundary>

              <div className={styles.btn_wrapper}>
                
                <LinkButton inverted url={`/statistics/winners/${cat.id}`} size="sm" className={styles.btn_category}>
                  <span>
                    {T('All ranking for X', cat.name)}&lrm;
                  </span> <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
                </LinkButton>
              </div>
            </div>}

          </React.Fragment>}
          )}
        </ContentContainer>
        
      })}
      
      
    </div>
  </>
}