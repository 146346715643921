import { useTranslations } from "cupman-utils"
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars"

type Props = {
  statistics: {home: {onePointers: number}, away: {onePointers: number}},
  statisticsTotal: {home: {onePointers: number, threePointers: number}, away: {onePointers: number, threePointers: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
}

export default function ScoredFreeThrows ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const sumScoredFreeThrows = statisticsTotal.away.onePointers + statisticsTotal.home.onePointers;
  const sumScoredThreePointers = statisticsTotal.away.threePointers + statisticsTotal.home.threePointers;
  const scale = Math.max(sumScoredFreeThrows, sumScoredThreePointers)

  return <LabeledOpposingHorizontalBars
    label={T('Satta straffkast')}
    left={{
      value: statistics.home.onePointers / scale,
      color: teamColors.home,
      label: statistics.home.onePointers
    }}
    right={{
      value: statistics.away.onePointers / scale,
      color: teamColors.away,
      label: statistics.away.onePointers
    }}
  />
}