import { faCalendar, faCalendarDay, faGenderless, faMap, faMars, faTrophy, faUser, faUsers, faVenus, faVenusMars, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import React from "react";
import { Category, Category$CategoryMatchStatistics } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Flag from "../../components/Flag/Flag";
import PageHeader from "../../components/PageHeader/PageHeader"
import styles from './StatisticsPage.module.scss';
import tableStyles from './TableStyles.module.scss';
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import { useFormatDate } from "cupman-utils/lib/LangContext";

export default function StatisticsPage () {

  const T = useTranslations('cmresults');

  const t = useLink(useCurrentTournament(), {
    lotCategories: [{
      statistics: {
        nations: [{}]
      }
    }],
    nations: [{
      nation: {},
      stats: {}
    }],
    finals: [],
    cup: {},
    days: [{}]
  });

  const admin = useAdmin();

  const lotPublished = t.lotPublished || admin.overridePublished;
  const schedulePublished = t.schedulePublished || admin.overridePublished;

  // COUNTRIES

  const sortedNations = t.nations;

  const countriesHeaders = [
    T('Nation'), 
    T('Games *'), 
    T('Played *'), 
    T('Won'), 
    T('Tied'),
    T('Lost'),
    T('Made goals'),
    T('Lost goals')
  ]

  const getFinishedGames = (_matches : Record<number, Category$CategoryMatchStatistics<{}>>) => {
    const matches = Object.values(_matches);

    return matches.reduce((prev, curr) => {
      return prev + curr.finished
    }, 0)
  }

  const getUnfinishedGames = (_matches : Record<number, Category$CategoryMatchStatistics<{}>>) => {
    const matches = Object.values(_matches);

    return matches.reduce((prev, curr) => {
      return prev + (curr.count - curr.finished)
    }, 0)
  }


  const getGoalsPerMatch = (goals : number, finishedGames : Record<number, Category$CategoryMatchStatistics<{}>>) => {
    if (goals && getFinishedGames(finishedGames)) {
      return Math.round(Math.max(1, goals) / getFinishedGames(finishedGames) * 10) / 10
    }
    return '-'
  }

  const categoriesHeaders = [
    T('Category'), 
    T('Team'), 
    T('Players'), 
    T('Played games'), 
    T('Games left'),
    T('Goals'),
    T('Goals/game') 
  ]

  if( t.international ) {
    categoriesHeaders.push(T('Nations'));
  }



  const matchesPerDay = t.lotCategories.reduce((sum, cat) => {
    
    const entries  = [
      ...Object.entries(sum), 
      ...Object.entries(cat.statistics?.matches || {})
    ];


    return entries.reduce((sum2, entry) => {
        const count = (sum2[entry[0]]?.count || 0) + entry[1].count;
        const finished = (sum2[entry[0]]?.finished || 0) + entry[1].finished;
        return {
        ...sum2, 
        [entry[0]]: {
          count: count,
          finished: finished,
          unFinished: count - finished
        }
      }
    }, {} as Record<string, {count: number, finished: number, unFinished: number}>);
  }, {} as Record<string, {count: number, finished: number, unFinished: number}>)



  const gamesPerDay = t.days.map(day => {
    return (
        {
          date: day.date,
          matches: matchesPerDay[day.id].count,
          finished: matchesPerDay[day.id].finished,
          unFinished: matchesPerDay[day.id].unFinished
        }
      )
    }
  )

  const gamesTotal = {
      matches: gamesPerDay.reduce((sum, perDay) => {
        return sum + perDay.matches
      }, 0),
      finished: gamesPerDay.reduce((sum, perDay) => {
        return sum + perDay.finished
      }, 0),
      unFinished: gamesPerDay.reduce((sum, perDay) => {
        return sum + perDay.unFinished
      }, 0)
    }


  // CATEGORIES

  const sortedCategories = t.lotCategories.filter(c => c.lotPublished || admin.overridePublished).sort((a,b) => {
    return b.gender.length - a.gender.length
  });
  const onlyMale = sortedCategories.filter(cat => cat.gender === 'men');
  const onlyFemale = sortedCategories.filter(cat => cat.gender === "women");
  const onlyMixed = sortedCategories.filter(cat => cat.gender === "mixed");
  const onlyNone = sortedCategories.filter(cat => cat.gender === "none");


  return <>
    <PageHeader title={T('Statistics')}
      crumbs={[
        {
          title: T('Results'),
          url: ''
        }
      ]}
    >
      {t.hasStatistics ? <Shortcuts links={[
        {
          to: '/statistics',
          title: T('Overview'),
          icon: faMap
        }, schedulePublished && {
          to: '/statistics/winners',
          title: T('Winners'),
          icon: faTrophy
        }, t.hasPlayerStatistics && {
            to: '/statistics/players',
            title: T('Players'),
            icon: faUsers
        }, t.schedulePublished && t.finals.length > 0 && {
          to: '/statistics/finals',
          title: T('Finals'),
          icon: faTrophy
        }/*, schedulePublished && {
          to: '/statistics/matches',
          title: T('Games'),
          icon: faTrophy
        }*/
      ]} />: undefined}
    </PageHeader>

    <div className={classNames("max-75", styles.content_wrapper)}>
      
      {lotPublished && <ContentContainer visibilityDesktop>
        <h2 className={classNames(styles.content_header,"custom-font")}>
          {T('Categories')}
        </h2>

        <div className={tableStyles.mobile_wrapper}>
          { // FEMALE MOBILE
          onlyFemale.length > 0 && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The statistics for the female teams could not be loaded')}/>}>
            <StatisticsCategoryTableMobile 
              categoryStats={onlyFemale} 
              categoriesHeaders={categoriesHeaders}
              getFinishedGames={getFinishedGames}
              getUnfinishedGames={getUnfinishedGames}
              getGoalsPerMatch={getGoalsPerMatch}
              international={t.international}
              header={T('Women')}
              icon={faVenus}
            />
          </ErrorBoundary>}

          { // MALE MOBILE
          onlyMale.length > 0 && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The statistics for the male teams could not be loaded')}/>}>
            <StatisticsCategoryTableMobile 
              categoryStats={onlyMale}
              categoriesHeaders={categoriesHeaders}
              getFinishedGames={getFinishedGames}
              getUnfinishedGames={getUnfinishedGames}
              getGoalsPerMatch={getGoalsPerMatch}
              international={t.international}
              header={T('Men')}
              icon={faMars}
            />
          </ErrorBoundary>}

          { // MIXED MOBILE
          onlyMixed.length > 1 && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The statistics for the mixed teams could not be loaded')}/>}>
            <StatisticsCategoryTableMobile 
              categoryStats={onlyMixed}
              categoriesHeaders={categoriesHeaders}
              getFinishedGames={getFinishedGames}
              getUnfinishedGames={getUnfinishedGames}
              getGoalsPerMatch={getGoalsPerMatch}
              international={t.international}
              header={T('Mixed')}
              icon={faVenusMars}
            />
          </ErrorBoundary>}

          { // GENDER NONE MOBILE
          onlyNone.length > 1 && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The statistics for the teams could not be loaded')}/>}>
            <StatisticsCategoryTableMobile 
              categoryStats={onlyNone}
              categoriesHeaders={categoriesHeaders}
              getFinishedGames={getFinishedGames}
              getUnfinishedGames={getUnfinishedGames}
              getGoalsPerMatch={getGoalsPerMatch}
              international={t.international}
              header={T('Categories')}
              icon={faGenderless}
            />
          </ErrorBoundary>}
      </div>

        {
        // FEMALE DESKTOP
        onlyFemale.length > 0 && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The statistics for the female teams could not be loaded')}/>}>
          <div className={tableStyles.desktop_wrapper}>
          
          <StatisticsCategoryTableDesktop 
            categoryStats={onlyFemale} 
            categoriesHeaders={categoriesHeaders}
            getFinishedGames={getFinishedGames}
            getUnfinishedGames={getUnfinishedGames}
            getGoalsPerMatch={getGoalsPerMatch}
            international={t.international}
            header={T('Women')}
            icon={faVenus}
          />
          
        </div>
      </ErrorBoundary>}

        {
        // MALE DESKTOP
        onlyMale.length > 0  && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The statistics for the male teams could not be loaded')}/>}>
          <div className={tableStyles.desktop_wrapper}>
            <StatisticsCategoryTableDesktop 
              categoryStats={onlyMale} 
              categoriesHeaders={categoriesHeaders}
              getFinishedGames={getFinishedGames}
              getUnfinishedGames={getUnfinishedGames}
              getGoalsPerMatch={getGoalsPerMatch}
              international={t.international}
              header={T('Male')}
              icon={faMars}
            />
          </div>
        </ErrorBoundary>}

        {// MIXED DESKTOP
        onlyMixed.length > 0  && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The statistics for the mixed teams could not be loaded')}/>}>
          <div className={tableStyles.desktop_wrapper}>
            <StatisticsCategoryTableDesktop 
              categoryStats={onlyMixed} 
              categoriesHeaders={categoriesHeaders}
              getFinishedGames={getFinishedGames}
              getUnfinishedGames={getUnfinishedGames}
              getGoalsPerMatch={getGoalsPerMatch}
              international={t.international}
              header={T('Mixed')}
              icon={faVenusMars}
            />
          </div>
        </ErrorBoundary>}

        {// GENDER NONE DESKTOP
        onlyNone.length > 0  && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The statistics for the teams could not be loaded')}/>}>
          <div className={tableStyles.desktop_wrapper}>
            <StatisticsCategoryTableDesktop 
              categoryStats={onlyNone} 
              categoriesHeaders={categoriesHeaders}
              getFinishedGames={getFinishedGames}
              getUnfinishedGames={getUnfinishedGames}
              getGoalsPerMatch={getGoalsPerMatch}
              international={t.international}
              header={T('Categories')}
              icon={faGenderless}
            />
          </div>
        </ErrorBoundary>}
      </ContentContainer>}



      {// MATCHES
      gamesPerDay.length > 0 && <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The match statistics for could not be loaded')}/>}>
        <ContentContainer visibilityDesktop>
          <div className={tableStyles.desktop_wrapper}>
            <h2 className={classNames(styles.content_header,"custom-font")}>{T('Matches')}</h2>
            <StatisticsMatchesPerDayTable gamesPerDay={gamesPerDay} gamesTotal={gamesTotal} />
          </div>
          <div className={tableStyles.mobile_wrapper}>
            <StatisticsMobileMatchesPerDayTable gamesPerDay={gamesPerDay} gamesTotal={gamesTotal} />
          </div>
        </ContentContainer>
      </ErrorBoundary>}

      {// NATIONS DESKTOP
      t.international && lotPublished && <ContentContainer visibilityDesktop>
        
        <h2 className={classNames(styles.content_header,"custom-font")}>{T('Nations')}</h2>
          
        <div className={tableStyles.desktop_wrapper}>
          <table className={tableStyles.desktop_table}>
            <thead>
              <tr className={tableStyles.inner_header}>
                {countriesHeaders.map(header => <Th value={header} />)}
              </tr>
            </thead>
            <tbody>

              {sortedNations.map(nation => <>
                <tr className={tableStyles.data_row}>
                  <Td className={tableStyles.nations} value={<>
                    <Flag code={nation.nation.code} alt={T.select(nation.nation.name)} /> {T.select(nation.nation.name)}
                  </>} />
                  <Td value={nation.stats.matchCount} />
                  <Td value={nation.stats.played} />
                  <Td value={nation.stats.won} />
                  <Td value={nation.stats.tied} />
                  <Td value={nation.stats.lost} />
                  <Td value={nation.stats.goalsWon} />
                  <Td value={nation.stats.goalsLost} />
                </tr>
                <TdHr colSpan={categoriesHeaders.length} />
              </>)}
            </tbody>
          </table>
        </div>

        
        <div className={tableStyles.mobile_wrapper}>
          {sortedNations.map(_nation => {

            const nation = _nation.nation;
            const stats = _nation.stats;
            

            return <React.Fragment key={nation.id}>
              <table className={tableStyles.mobile_table}>
                <thead>
                  <tr className={tableStyles.inner_header}>
                    <Th colSpan={countriesHeaders.length} value={<>
                      <Flag code={nation.code} key={nation.id} alt={T.select(nation.name)} /> {T.select(nation.name)}
                    </>} />
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <Th value={countriesHeaders[1]}/>
                    <Td value={stats.matchCount} />
                  </tr>
                  <tr>
                    <Th value={countriesHeaders[2]}/>
                    <Td value={stats.played} />
                  </tr>
                  <tr>
                    <Th value={countriesHeaders[3]}/>
                    <Td value={stats.won} />
                  </tr>
                  <tr>
                    <Th value={countriesHeaders[4]}/>
                    <Td value={stats.tied} />
                  </tr>
                  <tr>
                    <Th value={countriesHeaders[5]}/>
                    <Td value={stats.lost} />
                  </tr>
                  <tr>
                    <Th value={countriesHeaders[6]}/>
                    <Td value={stats.goalsWon} />
                  </tr>
                  <tr>
                    <Th value={countriesHeaders[7]}/>
                    <Td value={stats.goalsLost} />
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
          })}
        </div>

        <p className={tableStyles.legend}>
          <strong>*</strong> {T('Please note that since each game has two teams, each game will be counted twice in the table')}
        </p>
      </ContentContainer>}
    </div>
  </>
}






type tdProps = {
  value: any,
  colSpan?: number,
  className?: string,
  spanClassName?: string
}

function Td ({value, colSpan, className, spanClassName} : tdProps) {
  return <td colSpan={colSpan} className={className}>
    <span className={spanClassName}>{value}</span>
  </td>
}



type thProps = {
  value: any,
  colSpan?: number
}

function Th ({colSpan, value} : thProps) {
  return <th colSpan={colSpan}>
    <span>{value}</span>
  </th>
}



type tdHrProps = {
  colSpan?: number
}

function TdHr ({colSpan} : tdHrProps) {
  return <tr className={tableStyles.hr}>
  <td colSpan={colSpan}>
    <hr className={tableStyles.hr_styled} />
  </td>
</tr>
}


function StatisticsMobileMatchesPerDayTable ({gamesPerDay, gamesTotal} : {
  gamesPerDay: {
    date: number;
    matches: number;
    finished: number;
    unFinished: number;
  }[],
  gamesTotal: {
    matches: number;
    finished: number;
    unFinished: number;
  }
}
) {

  const T = useTranslations('cmresults');

  return <>
    <h2 className={classNames(styles.content_header,"custom-font")}>{T('Matches')}</h2>
    <table className={tableStyles.mobile_table}>
      <thead>
        <tr className={tableStyles.inner_header}>
          <th colSpan={3}>
            <span>
            <FontAwesomeIcon icon={faCalendar}/> {T('All days')}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
      <tr>
          <Th value={T('Total')} />
          <Td value={gamesTotal.matches}></Td>
        </tr>
        <tr>
          <Th value={T('Finished')} />
          <Td value={gamesTotal.finished}></Td>
        </tr>
        <tr>
          <Th value={T('Unfinished')} />
          <Td value={gamesTotal.unFinished}></Td>
        </tr>
      </tbody>
    </table>

    {gamesPerDay.map(day => <table className={tableStyles.mobile_table}>
      <thead>
        <tr className={tableStyles.inner_header}>
          <th colSpan={3}>
            <span>
              <MatchesPerDayHeader date={day.date} />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
      <tr>
          <Th value={T('Total')} />
          <Td value={day.matches}></Td>
        </tr>
        <tr>
          <Th value={T('Finished')} />
          <Td value={day.finished}></Td>
        </tr>
        <tr>
          <Th value={T('Unfinished')} />
          <Td value={day.unFinished}></Td>
        </tr>
      </tbody>
    </table>)}
  </>
}

function StatisticsCategoryTableMobile ({categoryStats, categoriesHeaders, getFinishedGames, getUnfinishedGames, getGoalsPerMatch, international, icon, header} : {
  categoryStats: Category<{
    statistics: {
        nations: [{}];
    };
  }>[],
  categoriesHeaders: string[],
  getFinishedGames: (_matches: Record<number, Category$CategoryMatchStatistics<{}>>) => number,
  getUnfinishedGames: (_matches: Record<number, Category$CategoryMatchStatistics<{}>>) => number,
  getGoalsPerMatch: (goals: number, finishedGames: Record<number, Category$CategoryMatchStatistics<{}>>) => number | "-",
  international: boolean,
  header: string,
  icon: IconDefinition
}) {

  const T = useTranslations('cmresults');

  return <>
    <h3 className={classNames(styles.h3, 'custom-font')}>
      <FontAwesomeIcon icon={icon} /> {header}
    </h3>
    {categoryStats.map(cat => <React.Fragment key={cat.id}>
      <table className={tableStyles.mobile_table}>
        <thead>
          <tr className={tableStyles.inner_header}>
            <th colSpan={categoriesHeaders.length}>
              <span>
                {cat.name}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Th value={T('Teams')} />
            <Td value={cat.statistics.teamCount 
              ? cat.statistics.teamCount 
              : '0'} 
            />
          </tr>
          <TdHr colSpan={categoriesHeaders.length} />
          <tr>
            <Th value={T('Players')} />
            <Td value={cat.statistics.playerCount} />
          </tr>
          <TdHr colSpan={categoriesHeaders.length} />
          <tr>
            <Th value={T('Played games')} />
            <Td value={getFinishedGames(cat.statistics.matches)} />
          </tr>
          <TdHr colSpan={categoriesHeaders.length} />
          <tr>
            <Th value={T('Games left')} />
            <Td value={getUnfinishedGames(cat.statistics.matches)} />
          </tr>
          <TdHr colSpan={categoriesHeaders.length} />
          <tr>
            <Th value={T('Goals')} />
            <Td value={cat.statistics.goals} />
          </tr>
          <TdHr colSpan={categoriesHeaders.length} />
          <tr>
            <Th value={T('Goals/game')} />
            <Td value={getGoalsPerMatch(cat.statistics.goals, cat.statistics.matches)} />
          </tr>
          <TdHr colSpan={categoriesHeaders.length} />
          <tr>
            <Th value={T('Nations')} />
            <Td className={tableStyles.nations} value={cat.statistics.nations.map(nation => <Flag code={nation.code} key={nation.id} alt={T.select(nation.name)} /> )} />
          </tr>
        </tbody>
      </table>
    </React.Fragment>)}
  </>
}

function StatisticsCategoryTableDesktop ({categoryStats, categoriesHeaders, getFinishedGames, getUnfinishedGames, getGoalsPerMatch, international, header, icon} : {
  categoryStats: Category<{
    statistics: {
        nations: [{}];
    };
  }>[],
  categoriesHeaders: string[],
  getFinishedGames: (_matches: Record<number, Category$CategoryMatchStatistics<{}>>) => number,
  getUnfinishedGames: (_matches: Record<number, Category$CategoryMatchStatistics<{}>>) => number,
  getGoalsPerMatch: (goals: number, finishedGames: Record<number, Category$CategoryMatchStatistics<{}>>) => number | "-",
  international: boolean,
  header: string,
  icon: IconDefinition
}) {

  const T = useTranslations('cmresults');

  return <>
    <h3 className={classNames(styles.h3, 'custom-font')}>
      <FontAwesomeIcon icon={icon} /> {header}
    </h3>
    <table className={tableStyles.desktop_table}>
      <thead>
        <tr className={tableStyles.inner_header}>
          {categoriesHeaders.map(header => <th>
            <span>{header}</span>
          </th>)}
        </tr>
      </thead>
      <tbody>
        {categoryStats.map(cat => {

        return <React.Fragment key={cat.id}>
          <tr className={tableStyles.data_row}>
            <Td value={cat.name} />
            <Td value={cat.statistics.teamCount ? cat.statistics.teamCount : '-'} />
            <Td value={cat.statistics.playerCount ? cat.statistics.playerCount : '-'} />
            <Td value={getFinishedGames(cat.statistics.matches)} />
            <Td value={getUnfinishedGames(cat.statistics.matches)} />
            <Td value={cat.statistics.goals ? cat.statistics.goals : '-'} />
            <Td value={getGoalsPerMatch(cat.statistics.goals, cat.statistics.matches)} />
            {international && <Td value={cat.statistics.nations.map(nation => <Flag code={nation.code} key={nation.id} alt={T.select(nation.name)} /> )} className={tableStyles.nations} />}
          </tr>
          <TdHr colSpan={categoriesHeaders.length} />
        </React.Fragment>
      })}
      </tbody>
    </table>
  </>
}


function StatisticsMatchesPerDayTable ({gamesPerDay, gamesTotal} : {
    gamesPerDay: {
      date: number;
      matches: number;
      finished: number;
      unFinished: number;
    }[],
    gamesTotal: {
      matches: number;
      finished: number;
      unFinished: number;
    }
  }
) {
  
  const T = useTranslations('cmresults')

  return <>
    <div className={tableStyles.desktop_wrapper}>
    <h3 className={classNames(styles.h3, styles.align_middle, 'custom-font')}>
      <FontAwesomeIcon icon={faCalendar}/> {T('All days')}
    </h3>
    
      <table className={tableStyles.desktop_table}>
        <thead>
          <tr className={tableStyles.inner_header}>
            <th>
              <span>{T('Total')}</span>
            </th>
            <th>
              <span>{T('Finished')}</span>
            </th>
            <th>
              <span>{T('Unfinished')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={tableStyles.data_row}>
            <Td value={gamesTotal.matches} />
            <Td value={gamesTotal.finished} />
            <Td value={gamesTotal.unFinished} />
          </tr>
          <TdHr colSpan={Object.values(gamesTotal).length} />
        </tbody>

      </table>


      {gamesPerDay.map(day => <React.Fragment key={day.date}>

        <h3 className={classNames(styles.h3, styles.align_middle, 'custom-font')}>
          <MatchesPerDayHeader date={day.date} />
        </h3>

        <table className={tableStyles.desktop_table}>
          <thead>
            <tr className={tableStyles.inner_header}>
              <th>
                <span>{T('Total')}</span>
              </th>
              <th>
                <span>{T('Finished')}</span>
              </th>
              <th>
                <span>{T('Unfinished')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={tableStyles.data_row}>
              <Td value={day.matches} />
              <Td value={day.finished} />
              <Td value={day.unFinished} />
            </tr>
            <TdHr colSpan={Object.values(gamesTotal).length} />
          </tbody>
          
        </table>
      </React.Fragment>)}
    </div>
  </>
}



function MatchesPerDayHeader ({date} : {date: number}) {
  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);

  return <>
    <FontAwesomeIcon icon={faCalendarDay}/> {formatDate(date, 'EEEE - dd/MM')}
  </>
}