import classNames from 'classnames';
import styles from './SingleValue.module.scss';

type Props = {
  value: number | string,
  color?: string,
  backgroundColor?: string,
  label: string,
  className?: string,
}

export default function SingleValue ({value, color: _color, backgroundColor: _backgroundColor, label, className} : Props) {

  const color = _color ? _color : '#FFFFFF';
  const backgroundColor = _backgroundColor ? _backgroundColor : '#000000'

  return <div className={classNames(styles.value_container,className)} style={{'--color' : color, '--backgroundColor' : backgroundColor} as any}>
    <h5 className={classNames(styles.label,"custom-font")}>{label}</h5>
    <span className={classNames(styles.value, 'custom-font')}>{value}</span>
  </div>
}

