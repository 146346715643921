import classNames from "classnames";
import { Suspense, useEffect } from "react";
import { MapNameClub } from "../api/schema";
import { header, list, LoadingContent, paragrapgh } from "../Loading/LoadingContent";
import { setModalNoScroll } from "../utils";
import styles from './MapOverlayClub.module.scss';
import MapOverlayInner from "./MapOverlayClubInner";

type props = {
  selectedClub: MapNameClub
  selectClub: (clubId: number) => void,
  selectCategory: (categoryId: number|'all') => void,
  setScrolled: (scrolled: boolean) => void, 
  scrolled?: boolean,
}

export default function MapOverlayClub ({selectedClub, selectClub, selectCategory, scrolled, setScrolled} : props) {

  useEffect(() => {
    setModalNoScroll(true);
    return () => setModalNoScroll(false);
  })
  return (
    <div className={classNames(styles.club_overlay)}>
      <Suspense fallback={
        <LoadingContent className="max-50" margin={20} components={
          [header(17, 24), paragrapgh(286, 27), list(37,0, 10)]
        }/>
      }>
        <MapOverlayInner club={selectedClub} selectClub={selectClub} setScrolled={setScrolled} scrolled={scrolled} selectCategory={selectCategory}/>
      </Suspense>
    </div>
  )
}