import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {goals: number}, away: {goals: number}},
  statisticsTotal: {home: {goals: number, lost: number}, away: {goals: number, lost: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
}

export default function PointsCount ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const totalPoints = statisticsTotal.home.goals + statisticsTotal.away.goals;
  const totalConceded = statisticsTotal.home.lost + statisticsTotal.away.lost;
  const scale = Math.max(totalPoints, totalConceded)


  return <LabeledOpposingHorizontalBars 
    label={T('Poäng')}
    left={{
      value: statistics.home.goals / scale,
      color: teamColors.home,
      label: statistics.home.goals,
    }}
    right={{
      value: statistics.away.goals / scale,
      color: teamColors.away,
      label: statistics.away.goals
    }}
  />
}