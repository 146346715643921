import { CSSTransition } from "react-transition-group"
// import Map, { MapMarker, getMapStyles } from "./Map";
import styles from './MapWithOverlay.module.scss';
import { useMemo, useState } from "react";
import classNames from "classnames";
import { MapLocation } from "../api/schema";
import { CupColorsSpec, useColors } from "../colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { MapMarker } from "./MapTypes";
import { Link } from "react-router-dom";
import MiddleEllipsisSpan from "../components/MiddleEllipsisSpan/MIddleEllipsisSpan";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { useTranslations } from "cupman-utils";
import { useAdmin } from "../components/AdminBanner/AdminBanner";
import { useCurrentTournament } from "../App";
import MapLibre from "./MapLibre";

const Map = React.lazy(() => import("./Map"));

type props<MarkerType> = {
  controls?: JSX.Element,
  visible: boolean
  selectedTitle?: JSX.Element | undefined,
  selectedOverlay?: JSX.Element | undefined,
  minInitWidth: 'registrations' | 'tournament' | 'mixed',
  markers: MarkerType[],
  selectedId: number|undefined,
  onChange: (p:MarkerType|undefined) => void,
  zoomKey?: any,
  pointsOfInterest?: (MapLocation & {character: string})[]
  scrolling?: boolean,
  clusterLabel: (markers:MarkerType[]) => string,
  setMap: (map:any) => void,
  hideMap?: boolean,
  noMap?: boolean,

}

const animationTimeOut = 500;


export const getMapStyles = (colors:CupColorsSpec) => ([
  {
    map: 'https://api.protomaps.com/tiles/v3.json?key=166f8aeacbcb9884',//mapStyle(getHSL(colors.primary)),
    img: "https://static.cupmanager.net/images/map-layer-mode/map_layer_satellit.png"

  },
  {
    map: 'https://api.protomaps.com/tiles/v3.json?key=166f8aeacbcb9884',
    img: "https://static.cupmanager.net/images/map-layer-mode/map_layer_vector.png"
  }
]);


export default function MapWithOverlay<MarkerType extends MapMarker> ({setMap, markers, onChange, zoomKey, minInitWidth, controls, visible, clusterLabel, selectedTitle, selectedOverlay, scrolling, selectedId, pointsOfInterest,hideMap,noMap} : props<MarkerType>) {

  const mapHeight = noMap ? '0px' : hideMap ? '55px' : selectedId ? 'var(--map-height-selected)': scrolling ?  'var(--map-height-scrolling)' : 'var(--map-height)';
  
  const colors = useColors();
  const mapStyles = useMemo(() => getMapStyles(colors), [colors]);
  const [zoomState,setZoomState] = useState(0);
  const T = useTranslations("cmresults");

  const [selectedMapStyle, setSelectedMapStyle] = useState(mapStyles[0]);

  const admin = useAdmin();
  const t = useCurrentTournament();
  //

  
  return <div style={{'--map-timeout' : `${animationTimeOut}ms`} as any}>
    {selectedTitle && <CSSTransition
      in={visible}
      timeout={animationTimeOut}
      unmountOnExit
      classNames={{
        enter: styles.titleEnter,
        enterActive: styles.titleEnterActive,
        exit: styles.titleExit,
        exitActive: styles.titleExitActive
      }}
    >
      <div className={classNames(styles.overlay_title,hideMap && styles.hidden)}>
        {selectedTitle}
      </div>
    </CSSTransition>}

    <CSSTransition
      in={visible}
      timeout={animationTimeOut}
      unmountOnExit
      classNames={{
        enter: styles.overlayEnter,
        enterActive: styles.overlayEnterActive,
        exit: styles.overlayExit,
        exitActive: styles.overlayExitActive
      }}
    >
      <div className={classNames(styles.overlay, (hideMap || noMap) && styles.hidden, hideMap === false && styles.wasHidden)}>
      {selectedOverlay}
      </div>
    </CSSTransition>

    {<CSSTransition
      in={!selectedId && !scrolling && !noMap}
      timeout={300}
      unmountOnExit
      classNames={{
        enter: styles.controlsEnter,
        enterActive: styles.controlsEnterActive,
        exit:  styles.controlsExit,
        exitDone:  styles.controlsExitDone
      }}
    >
      <div className={classNames(styles.controls, "left-75")}>
        <div className={styles.crumbs}><Link to={'/'} className={styles.breadcrumb_link}>
                    <MiddleEllipsisSpan text={T('Results')}/>
                    {/*<span>{crumb.title}</span>*/}
                  </Link>
                  <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} />
        </div>
        
        {/*<button onClick={
          () => selectedMapStyle.img === mapStyles[0].img 
            ? setSelectedMapStyle(mapStyles[1]) 
            : setSelectedMapStyle(mapStyles[0])
          } 
          className={styles.layer_button} 
          aria-label={`Växla till ${selectedMapStyle.img === mapStyles[0].img ? 'satellit' : 'karta'}`}
          tabIndex={-1}>
          <img src={selectedMapStyle.img} alt='karta' />
        </button>*/}
        {controls}
        <button onClick={()=>setZoomState(zoomState+1)} 
          className={styles.fitbounds_button} 
          tabIndex={-1}>
          <div><FontAwesomeIcon icon={faExpand} /></div>
        </button>
      </div>
    </CSSTransition>}

    <MapLibre 
      setMap={setMap}
      markers={markers} 
      pointsOfInterest={pointsOfInterest}
      clusterLabel={clusterLabel as any}
      minInitWidth={minInitWidth} 
      selectedId={selectedId} 
      onChange={onChange as any} 
      zoomKey={(zoomKey || 0) + zoomState}
      mapHeight={mapHeight}
      mapStyle={selectedMapStyle}
    />
    
  </div>
}