import { faAngleRight, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { roundToNearestMinutes } from 'date-fns';
import React, { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Match } from '../../api/schema';
import { useCurrentTournament } from '../../App';
import { videoThumbnail } from '../../utils';
import useScrolledFromTop from '../../utils/HasScrolledFromTop';
import styles from './PageHeaderVideo.module.scss';
import { CSSTransition } from "react-transition-group"

PageHeaderVideo.fragment = Match.fragment({
  home: {
    team: {
      club: {
        nation: {}
      },
      shirt: {}
    }
  },
  away: {
    team: {
      club: {
        nation: {}
      },
      shirt: {}
    }
  },
  division: {
    category: {},
    stage: {}
  },
  video: {},
  roundName: {}
})

type props = {
  extraPadding? : number,
  scrolledTitle? : string,
  subtitle?: string,
  visuallyHidden?: boolean,
  noExpand?: boolean,
  game: Match<typeof PageHeaderVideo.fragment>
}

export default function PageHeaderVideo ({
  visuallyHidden, 
  scrolledTitle, 
  subtitle, 
  noExpand,
  game
} : props) {

  const ref = useRef<HTMLDivElement>(null);

  const t = useCurrentTournament();
  const T = useTranslations('cmresults');
  const sport = t.subcup.sport.type;

  const crumbsWidth = ref && ref.current
    ? ref.current.clientWidth
    : 0
  ;
  
  const scrolled = (useScrolledFromTop(1))
    || noExpand;

  const crumbs = [
    {
      title: T.select(game.division.category.name),
      url: `categories/${game.division.category.id}`,
    }
  ]

  
  const bgImage = `url(${videoThumbnail(game.video?.thumbnail, sport, 1200)}`;
  const title = game.roundName
    ? T.select(game.division.name) + ` - ${T.select(game.roundName.name)}`
    : T.select(game.division.name);

  const nodeRef = useRef<any>(null);
  const animationTimeOut = 300;

  const provider = t.streamingInfo?.provider.toLocaleLowerCase();
  const solidSport = provider === 'solidsport' || provider === 'solidtango';

  return visuallyHidden 
    ? <h1 className="visually_hidden">
        {title}
      </h1>
    : <div 
      className={classNames(styles.wrapper, scrolled 
        ? styles.scrolled 
        : (scrolled === undefined
          ? ''
          : styles.unscrolled ))} 
      style={{'--crumbs-width' : crumbsWidth+'px'} as any}>

        <div className={
          classNames(styles.header_container,
          t.cup.imageFilter && `image-filter-${t.cup.imageFilter}`,
          t.cup.imageFilter && 'image-filter')
        } style={{
          background: bgImage
        } as any}>

          <div className={styles.video_sides}></div>
          <div className={classNames(styles.inner_container)}>

            <div className={styles.position_container}>
              <div ref={ref} className={styles.crumbs}>
                {crumbs && crumbs.map((crumb, i) => <React.Fragment key={i}>
                  <Link to={`/${crumb.url}`} className={styles.breadcrumb_link}>
                    <span>{crumb.title}</span>
                  </Link>
                  <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} />
                </React.Fragment>)}
                
                <h1 className='custom-font' style={{'--btn-timeout' : `${animationTimeOut}ms`} as any}>
                  <span>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.scrolled_title}>{scrolledTitle || title}</span>
                  </span>
                  {subtitle && <small>{subtitle}</small>}

                  {game.video && 
                  <CSSTransition
                    in={!scrolled}
                    timeout={animationTimeOut}
                    nodeRef={nodeRef}
                    classNames={{
                      enter: styles.btnEnter,
                      enterActive: styles.btnEnterActive,
                      enterDone: styles.btnEnterDone,
                      exit: styles.btnExit,
                      exitActive: styles.btnExitActive,
                      exitDone: styles.btnExitDone
                    }}
                  >
                    <a 
                      href={game.video.externalLink} 
                      className={styles.video_link} 
                      target="_blank" 
                      rel="noreferrer"
                      ref={nodeRef}
                    >
                      <span className={classNames(styles.video_link_inner, solidSport && styles.solidSport_red)}>

                        <FontAwesomeIcon 
                          icon={faArrowUpRightFromSquare} 
                          className={styles.external_link} 
                        /> 
                        <span className={styles.btn_txt}>
                          {game.live && !game.finished ? T('Köp matchen nu') : T('Se matchen nu')} {game.live && game.finished && <FontAwesomeIcon icon={faCirclePlay}/>}
                        </span>

                      </span>
                    </a>
                  </CSSTransition>}
                </h1>
              </div>
            </div>
            </div>
          <div className={styles.video_sides}></div>



          <div className={classNames(styles.solidSport, scrolled ? styles.scrolled : undefined)}>
            <span>{T('Matchen sänds live av')}</span> {
              solidSport
                ? <img src='https://static.cupmanager.net/images/cm-results/solid-sport-white.svg' alt={T('Solid Sport')}/>
                : <span>{t.streamingInfo?.provider}</span>
              }
          </div>
        </div>
        <div className={styles.underlay}></div>
      </div>
}