import { MutationSpec } from 'granular'

export function Me$setInteractionName_Optimization(params:{optionalCupId?:number|undefined}, payload:string, query:never) : Partial<MutationSpec<string,never,any>> {
	return {
		fetchRefsIfInvalidatedByMutation: [],
		optimistics: {
			// Add your optimistics here like [Ref({paramId:123})]: (data:Ref) => { return data; }
		}
	}
}
