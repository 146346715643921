import { useEffect } from "react";
import { scrollbarWidth } from "./detectScrollbarWidth";

let hideCounter = 0;
let lockCounter = 0;

export default function useBodyScrollLock({hide, lock} : {hide?:boolean, lock?:boolean}) {
    useEffect(() => {
        if (hide) {
            hideCounter++;
            refresh();
            return () => {
                hideCounter--;
                refresh();
            };
        }
    }, [hide]);

    useEffect(() => {
        if (lock) {
            lockCounter++;
            refresh();
            return () => {
                lockCounter--;
                refresh();
            };
        }
    }, [lock]);

}


function refresh() {
    if (hideCounter > 0) {
        document.body.classList.add("no-scrollbar");
        document.body.style.setProperty('--scrollbar-width', scrollbarWidth+"px");
    } else {
        document.body.classList.remove("no-scrollbar");
    }

    if (lockCounter > 0) {
        document.body.classList.add("overflow-hidden");
    } else {
        document.body.classList.remove("overflow-hidden");
    }
}