import { ClubUtils$ShirtTypes } from '../../api/schema';
import styles from './Dress.module.scss';
import { v4 } from "uuid";
var cn = require('classnames');

type props = {
  primary?: string,
  secondary?: string,
  tertiary?: string,
  code?: string,
  type?: ClubUtils$ShirtTypes,
  className?: string,
  side?: 'home' | 'away' | undefined
}


export default function DressHockey ({primary, secondary, tertiary, type, code, className, side} : props) {

  const shortsInverted = false;
  const uuid = v4();

  // If tertiary color exists end if shorts should be inverted.
  const shortsColors = shortsInverted
    ? {
      primary: secondary,
      secondary: tertiary 
        ? tertiary
        : primary
    }
    : {
      primary: primary,
      secondary: tertiary 
        ? tertiary
        : secondary
    }



    let gloveColor = secondary;
    if(type !== 'horizontal' && gloveColor === secondary){
      gloveColor = primary;
    }


    const shadowColor = '#000000';
    const lightColor = '#FFFFFF';
    const opacityShadowValue = 0.2;
    const opacityLightValue = 0.3;

    const flagUrl = code && 'https://static.cupmanager.net/images/flags_svg/4x3/'+ code.toLowerCase() +'.svg';

  return (
    <div className={cn(styles.dress, className)}>

      {code && <div className={styles.flag_container_hockey}>
        <div className={styles.flag}>
          <img src={flagUrl} alt="" />
        </div>
      </div>}

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 2149.5 2383.9">
        
        <g id="dress">
          <path id="Fill_primary" fill={primary} d="M1742,725.3c-44.1-149.8-127.7-387.9-174.2-472.6c-58.1-82.1-185.4-100.6-217.2-98.3
            s-60.2-3.2-60.2-3.2c-16.7-1.3-42.9-20.5-69.1-38c-24.5-16.4-39.9-7.6-55.2-3.2c-15.2,4.4-32.2,8.2-91.4,8.2
            c-59.2,0-76.3-3.8-91.4-8.2c-15.2-4.4-30.6-13.2-55.2,3.2c-26.2,17.5-52.4,36.8-69.1,38c0,0-28.5,5.5-60.2,3.2
            s-159.1,16.3-217.2,98.3c-46.5,84.8-130.1,322.8-174.2,472.6c-44.1,149.8-111.1,617-111.1,617c82.5,49.9,184.2,38.2,184.2,38.2
            c89.2-169.9,189.2-493.6,205.6-547.6c2.6,119.7,1.8,230.5-2.6,323.5c-7.8,164.6-11.3,292.6-11.3,292.6s5,1.5,14.9,4
            c-67.3,202.9-80.3,496.1-82,533.4c-1.7,37.5-2.4,72.1,27,75.8c131.2,16.5,210.8,21.2,334.5,17.4c52.3-1.6,53.1-34.8,55.7-62.7
            c2.6-27.9,17.4-228.2,22.6-319.6c4.8-83.8,26.2-90.9,29.7-92.8c3.5,1.9,24.9,9,29.7,92.8c5.2,91.5,20,291.8,22.6,319.6
            c2.6,27.9,3.4,61.1,55.7,62.7c123.7,3.8,203.2-0.9,334.5-17.4c29.4-3.7,28.7-38.3,27-75.8c-1.7-37.3-14.7-330.5-82-533.4
            c9.9-2.5,14.9-4,14.9-4s-3.5-128-11.3-292.6c-4.4-93-5.2-203.9-2.6-323.5c16.4,54,116.4,377.6,205.6,547.6
            c0,0,101.7,11.8,184.2-38.2C1853.1,1342.4,1786.2,875.1,1742,725.3z"/>

            <g id="fill_secondary_shorts_00000001639383879750429610000015416543131396406709_">
              <path fill={shortsColors.secondary} d="M687.2,1453.1c-23,69.2-39.6,149-51.6,225.5c-0.3,1.8-0.6,3.5-0.8,5.3c-3,19.3-5.7,38.4-8.1,57
                c-0.2,1.7-0.4,3.4-0.7,5.1c-1.7,13.5-3.3,26.7-4.8,39.6c-0.2,1.6-0.4,3.2-0.5,4.8c-0.5,4.8-1.1,9.6-1.6,14.3
                c-0.3,3.1-0.7,6.2-1,9.3c-0.3,3.1-0.6,6.2-0.9,9.2c-0.3,3-0.6,6-0.9,9c-0.4,4.5-0.9,8.9-1.3,13.2c-0.1,1.4-0.3,2.9-0.4,4.3
                c-0.3,2.9-0.5,5.7-0.8,8.5s-0.5,5.6-0.7,8.3c-0.2,2.7-0.5,5.4-0.7,8.1c-0.2,2.7-0.4,5.3-0.6,7.9c-0.2,2.6-0.4,5.2-0.6,7.7
                c-0.1,1.3-0.2,2.5-0.3,3.8c-0.2,2.5-0.4,5-0.6,7.4c-0.1,1.2-0.2,2.4-0.3,3.6c-0.2,2.4-0.3,4.7-0.5,7c-0.1,1.1-0.2,2.3-0.2,3.4
                c-0.2,2.3-0.3,4.5-0.5,6.6c-0.1,1.1-0.1,2.2-0.2,3.2c-0.1,2.1-0.3,4.2-0.4,6.3c-1.8,27.6-2.7,46.9-3,54.8c-0.7,14-1.2,27.7,0,39.5
                c0.2,2,0.4,3.9,0.7,5.7c0.4,2.8,1,5.4,1.7,7.9c3.6,12.4,10.7,21,24.5,22.7h0c19.9,2.5,48.2,5.7,66.1,7.7
                c1.6-85.8-6.6-361.9,59.4-602.7C724.7,1461.8,701.1,1456.5,687.2,1453.1z"/>
              <path fill={shortsColors.secondary} d="M1462.3,1453.1c23,69.2,39.6,149,51.6,225.5c0.3,1.8,0.6,3.5,0.8,5.3c3,19.3,5.7,38.4,8.1,57
                c0.2,1.7,0.4,3.4,0.7,5.1c1.7,13.5,3.3,26.7,4.8,39.6c0.2,1.6,0.4,3.2,0.5,4.8c0.5,4.8,1.1,9.6,1.6,14.3c0.3,3.1,0.7,6.2,1,9.3
                c0.3,3.1,0.6,6.2,0.9,9.2c0.3,3,0.6,6,0.9,9c0.4,4.5,0.9,8.9,1.3,13.2c0.1,1.4,0.3,2.9,0.4,4.3c0.3,2.9,0.5,5.7,0.8,8.5
                s0.5,5.6,0.7,8.3s0.5,5.4,0.7,8.1c0.2,2.7,0.4,5.3,0.6,7.9c0.2,2.6,0.4,5.2,0.6,7.7c0.1,1.3,0.2,2.5,0.3,3.8
                c0.2,2.5,0.4,5,0.6,7.4c0.1,1.2,0.2,2.4,0.3,3.6c0.2,2.4,0.3,4.7,0.5,7c0.1,1.1,0.2,2.3,0.2,3.4c0.2,2.3,0.3,4.5,0.5,6.6
                c0.1,1.1,0.1,2.2,0.2,3.2c0.1,2.1,0.3,4.2,0.4,6.3c1.8,27.6,2.7,46.9,3,54.8c0.7,14,1.2,27.7,0,39.5c-0.2,2-0.4,3.9-0.7,5.7
                c-0.4,2.8-1,5.4-1.7,7.9c-3.6,12.4-10.7,21-24.5,22.7h0c-19.9,2.5-48.2,5.7-66.1,7.7c-1.6-85.8,6.6-361.9-59.4-602.7
                C1424.9,1461.8,1448.4,1456.5,1462.3,1453.1z"/>
            </g>
            
          {type === 'horizontal'
            ? <g id="lines">
              <g>
                <path fill={secondary} d="M1471.8,1290.8c-179.7,34.3-391.2,32-397,31.9v0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0v0
                  c-5.8,0.1-217.2,2.4-396.9-31.9c-0.9,23.4-1.7,44.8-2.4,63.9c72.3,11.6,218.3,30.7,399.3,30.7v0c0,0,0,0,0.1,0c0,0,0,0,0.1,0v0
                  c181.1,0,327.1-19.1,399.4-30.7C1473.5,1335.7,1472.7,1314.2,1471.8,1290.8z"/>
                <path fill={secondary} d="M1467.5,1190.2c-175.2,27.7-388.6,27.9-392.8,27.9c-4.2,0-217.4-0.2-392.7-27.9c-1,21.8-1.9,42.9-2.7,62.9
                  c73.6,10.8,217.6,27.8,395.3,27.8v0c0,0,0,0,0.1,0c0,0,0,0,0.1,0v0c177.9,0,321.9-17,395.5-27.8
                  C1469.4,1233.1,1468.5,1212,1467.5,1190.2z"/>
                <path fill={secondary} d="M327.3,1140.4c-3.5,21.8-6.8,42.7-9.8,62.2c27.1,16.9,105.1,57.6,221.4,50.8c8.1-19.5,16-39.5,23.9-59.7
                  C523.3,1194.4,411.6,1191.1,327.3,1140.4z"/>
                <path fill={secondary} d="M343.6,1040.6c-3.5,20.9-7,41.6-10.2,61.8c17.8,12.6,105.8,69,242.2,57.7c7.5-19.8,14.8-39.7,21.8-59.3
                  C569.9,1101.4,436.9,1100.8,343.6,1040.6z"/>
                <path fill={secondary} d="M1822.1,1140.4c3.5,21.8,6.8,42.7,9.8,62.2c-27.1,16.9-105.1,57.6-221.4,50.8c-8.1-19.5-16-39.5-23.9-59.7
                  C1626.1,1194.4,1737.8,1191.1,1822.1,1140.4z"/>
                <path fill={secondary} d="M1805.8,1040.6c3.5,20.9,7,41.6,10.2,61.8c-17.8,12.6-105.8,69-242.2,57.7c-7.5-19.8-14.8-39.7-21.8-59.3
                  C1579.5,1101.4,1712.5,1100.8,1805.8,1040.6z"/>
              </g>
              <g id="shoulders_00000134238910843587940870000018001672220695853203_">
                <path id="fill_secondary_shorts_00000121260333420580810450000000954288256071271821_" fill={secondary} d="M767.5,258.8
                  c92.6-12.8,227-12.8,227-12.8l-78-50.2c-34.5-27.1-57.5-44.6-57.5-44.6s-28.5,5.5-60.2,3.2s-159.1,16.3-217.2,98.3
                  c-19,35.2-47.2,103.4-47.2,103.4c35.6,46.5,113.8,27.9,113.8,27.9c-15.8-57.2-37-93.3-51.2-112.9c7.9,10.8,17.8,26.1,26.7,45.8
                  C623.8,316.9,674.9,271.6,767.5,258.8z"/>
                <path fill={secondary} d="M1382,258.8c-92.6-12.8-227-12.8-227-12.8l78-50.2c34.5-27.1,57.5-44.6,57.5-44.6s28.5,5.5,60.2,3.2
                  c31.7-2.3,159.1,16.3,217.2,98.3c19,35.2,47.2,103.4,47.2,103.4c-35.6,46.5-113.8,27.9-113.8,27.9c15.8-57.2,37-93.3,51.2-112.9
                  c-7.9,10.8-17.8,26.1-26.7,45.8C1525.7,316.9,1474.6,271.6,1382,258.8z"/>
              </g>
            </g>
            : <g id="no_lines">
              <g id="fill_no_lines">
                <path fill={secondary} d="M369.7,894.7c-36.8,193.5-73.3,447.7-73.3,447.7c82.5,49.9,184.2,38.2,184.2,38.2
                  c29.2-55.7,59.6-127.9,87.9-201.7C523.4,1152.2,401.1,1065.4,369.7,894.7z"/>
                <path fill={secondary} d="M1779.6,894.7c36.8,193.5,73.3,447.7,73.3,447.7c-82.5,49.9-184.2,38.2-184.2,38.2
                  c-29.2-55.7-59.6-127.9-87.9-201.7C1625.9,1152.2,1748.2,1065.4,1779.6,894.7z"/>
              </g>
              <g id="shoulders">
                <path id="fill_secondary_shorts_00000019668716689518064220000010662400288854520747_" fill={secondary} d="M767.5,258.8
                  c92.6-12.8,227-12.8,227-12.8l-78-50.2c-34.5-27.1-57.5-44.6-57.5-44.6s-28.5,5.5-60.2,3.2s-159.1,16.3-217.2,98.3
                  c-19,35.2-47.2,103.4-47.2,103.4c35.6,46.5,113.8,27.9,113.8,27.9c-15.8-57.2-37-93.3-51.2-112.9c7.9,10.8,17.8,26.1,26.7,45.8
                  C623.8,316.9,674.9,271.6,767.5,258.8z"/>
                <path fill={secondary} d="M1382,258.8c-92.6-12.8-227-12.8-227-12.8l78-50.2c34.5-27.1,57.5-44.6,57.5-44.6s28.5,5.5,60.2,3.2
                  c31.7-2.3,159.1,16.3,217.2,98.3c19,35.2,47.2,103.4,47.2,103.4c-35.6,46.5-113.8,27.9-113.8,27.9c15.8-57.2,37-93.3,51.2-112.9
                  c-7.9,10.8-17.8,26.1-26.7,45.8C1525.7,316.9,1474.6,271.6,1382,258.8z"/>
              </g>
            </g>
          }
          <g id="shadow_inner_shorts" style={{opacity: opacityShadowValue}}>
            <path fill={shadowColor} d="M1022.4,2017c2.6-27.9,17.4-228.2,22.6-319.6s30.2-93,30.2-93v0c-34.9,17.8-52.4,80.3-60.1,162.3
              c-13,139.1-29.3,247.4-37.1,285.7c-3.7,18.3-15.1,25.3-25.8,27.6c1.5,0,12.9-0.1,14.4-0.1C1019,2078.1,1019.8,2044.9,1022.4,2017z
              "/>
            <path fill={shadowColor} d="M1127.1,2017c-2.6-27.9-17.4-228.2-22.6-319.6s-30.2-93-30.2-93v0c34.9,17.8,52.4,80.3,60.1,162.3
              c13,139.1,29.3,247.4,37.1,285.7c3.7,18.3,15.1,25.3,25.8,27.6c-1.5,0-12.9-0.1-14.4-0.1C1130.6,2078.1,1129.7,2044.9,1127.1,2017
              z"/>
          </g>
          <path id="shadow_dress" fill={shadowColor} style={{opacity: opacityShadowValue}} d="M1677.1,1336.3c0,0-146-331.5-210-625.1c2.2-54.5,5-110.2,8.4-166.2
            c2.3-37.4,6.4-70.4,11.5-99.5c-66.6,279.6-46.6,961.9-46.6,961.9c-101.3,33.1-316.8,38.8-365.7,39.4
            c-48.9-0.6-264.4-6.3-365.7-39.4c0,0,20-682.3-46.6-961.9c5.2,29.1,9.2,62.1,11.5,99.5c3.4,56,6.3,111.7,8.4,166.2
            c-64,293.6-210,625.1-210,625.1l20.4,20.2c85.9-174.7,177.7-472,193.4-523.5c2.6,119.7,1.8,230.5-2.6,323.5
            c-7.8,164.6-11.3,292.6-11.3,292.6s5.2,1.6,15.6,4.1c-0.2,0-0.4-0.1-0.6-0.1l-0.1,0c-67.3,202.9-80.3,496.1-82,533.4
            c-1.7,37.5-2.4,72.1,27,75.8c6.2,0.8,12.3,1.5,18.2,2.3c-4.7-301.6,66-558.6,78.8-602.3c-1.4-0.3,1,0.2-0.4-0.1
            c62.2,12.2,175.4,28.5,345.4,28.5v0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0v0c167-0.1,282.9-16.4,345.9-28.6c-0.3,0.1-0.6,0.1-0.9,0.2
            c12.7,43.7,83.5,300.7,78.8,602.3c6-0.7,12-1.5,18.2-2.3c29.4-3.7,28.7-38.3,27-75.8c-1.7-37.3-14.7-330.5-82-533.4l-0.1,0
            c-0.2,0-0.4,0.1-0.6,0.1c10.4-2.6,15.6-4.1,15.6-4.1s-3.5-128-11.3-292.6c-4.4-93.1-5.2-203.8-2.6-323.5
            c15.7,51.5,107.5,348.8,193.4,523.5L1677.1,1336.3z"/>
          
          <g id="light_shorts" style={{opacity: opacityLightValue}}>
            <path fill={lightColor} d="M966.7,2079.7c52.3-1.6,53.1-34.8,55.7-62.7c0.1-1.2,0.2-2.7,0.4-4.5c-2.4,7.7-10.2,24.5-33,25.8
              c-171.6,10.1-337.9-16.5-362.3-20c-22.1-3.2-22.4-26.6-22.3-31.1c-1.7,37.2-2.2,71.4,27,75.1
              C763.5,2078.8,843,2083.5,966.7,2079.7z"/>
            <path fill={lightColor} d="M1182.9,2079.7c-52.3-1.6-53.1-34.8-55.7-62.7c-0.1-1.2-0.2-2.7-0.4-4.5c2.4,7.7,10.2,24.5,33,25.8
              c171.6,10.1,337.9-16.5,362.3-20c22.1-3.2,22.4-26.6,22.3-31.1c1.7,37.2,2.2,71.4-27,75.1
              C1386.1,2078.8,1306.5,2083.5,1182.9,2079.7z"/>
          </g>
        </g>

        <g id="socks">
          <g id="fill_gradient_primary">
            <linearGradient id={uuid} gradientUnits="userSpaceOnUse" x1="796.15" y1="2137.8367" x2="796.15" y2="2273.8982">
              <stop  offset="0" style={{stopColor: primary}} />
              <stop  offset="1" style={{stopColor: primary, stopOpacity: 0}}/>
            </linearGradient>
            <path style={{fill: `url(#${uuid})`}} d="M649.7,2064.4c-4.3,29.8-10.8,100.5-0.6,215.9h274.1c12.1-65.1,18-115.5,25.5-200.1
              C840.4,2082.8,764.5,2078.5,649.7,2064.4z"/>
            
            <linearGradient id={uuid+"A"} gradientUnits="userSpaceOnUse" x1="4184.6338" y1="2137.8367" x2="4184.6338" y2="2273.8982" gradientTransform="matrix(-1 0 0 1 5538.0278 0)">
              <stop  offset="0" style={{stopColor: primary}} />
              <stop  offset="1" style={{stopColor: primary, stopOpacity: 0}}/>
            </linearGradient>
            
            <path style={{fill: `url(#${uuid+"A"})`}} d="M1499.8,2064.4
              c4.3,29.8,10.8,100.5,0.6,215.9h-274.1c-12.1-65.1-18-115.5-25.5-200.1C1309.1,2082.8,1385.1,2078.5,1499.8,2064.4z"/>
          </g>

          <g id="shadow_gradient">
              <linearGradient id={"SVGID_00000009582305023526988700000003448765134070065590_"}gradientUnits="userSpaceOnUse" x1="666.6025" y1="2137.7065" x2="666.6025" y2="2273.5266">
              <stop  offset="0" style={{stopColor: shadowColor, stopOpacity: 0.2}} />
              <stop  offset="1" style={{stopColor: shadowColor, stopOpacity: 0}}/>
            </linearGradient>
            <path style={{fill: `url(#SVGID_00000009582305023526988700000003448765134070065590_`}} d="M689.5,2069.1c-12.8-1.4-26-3-39.8-4.6
              c-4.3,29.7-10.8,100.3-0.7,215.5l38.6-0.9C678.8,2184.9,685.7,2102.9,689.5,2069.1z"/>
            
              <linearGradient id={"SVGID_00000056416770656065200970000017195949098567242886_"}gradientUnits="userSpaceOnUse" x1="4055.0862" y1="2137.7065" x2="4055.0862" y2="2273.5266" gradientTransform="matrix(-1 0 0 1 5538.0278 0)">
              <stop  offset="0" style={{stopColor: shadowColor, stopOpacity: 0.2}} />
              <stop  offset="1" style={{stopColor: shadowColor, stopOpacity: 0}}/>
            </linearGradient>
            <path style={{fill: `url(#SVGID_00000056416770656065200970000017195949098567242886_})`}} d="M1460,2069.1c12.8-1.4,26-3,39.8-4.6
              c4.3,29.7,10.8,100.3,0.7,215.5l-38.6-0.9C1470.8,2184.9,1463.8,2102.9,1460,2069.1z"/>
          </g>
          <g id="light_gradient">
            <path style={{opacity:0.3, fill: lightColor}} d="M911,2080.8c-2.3,37.1-8.4,112-22.5,199.5h34.7c12.1-65.1,18-115.5,25.5-200.1
              C935.7,2080.5,923.1,2080.7,911,2080.8z"/>
            <path style={{opacity:0.3, fill: lightColor}} d="M1238.6,2080.8c2.3,37.1,8.4,112,22.5,199.5h-34.7c-12.1-65.1-18-115.5-25.5-200.1
              C1213.9,2080.5,1226.4,2080.7,1238.6,2080.8z"/>
          </g>
        </g>

        <g id="collar">
          <path id="big_shadow" fill={shadowColor} style={{opacity: opacityShadowValue + .1}} d="M1290.4,151.2c-16.7-1.3-42.9-20.5-69.1-38c-24.5-16.4-39.9-7.6-55.2-3.2
            c-15.2,4.4-32.2,8.2-91.4,8.2c-59.2,0-76.3-3.8-91.4-8.2c-15.2-4.4-30.6-13.2-55.2,3.2c-26.2,17.5-52.4,36.8-69.1,38
            c0,0,13.6,65.4,215.2,123.9v0.3c0.2-0.1,0.3-0.1,0.5-0.2c0.2,0.1,0.3,0.1,0.5,0.2v-0.3C1279.7,215.4,1290.4,151.2,1290.4,151.2z"/>
          <g id="fill_primary">
            <path fill={primary} d="M1074.3,275.5c0,0,161.4-86.2,167.2-149c0,0,25.4,18,49,24.7C1290.4,151.2,1277.1,216.5,1074.3,275.5z"/>
            <path fill={primary} d="M1075.3,275.5c0,0-161.4-86.2-167.2-149c0,0-25.4,18-49,24.7C859.1,151.2,872.4,216.5,1075.3,275.5z"/>
          </g>
          <path id="inner_shadow" fill={shadowColor} style={{opacity: opacityShadowValue}} d="M1221.3,113.2c-24.5-16.4-39.9-7.6-55.2-3.2c-15.2,4.4-32.2,8.2-91.4,8.2
            c-59.2,0-76.2-3.8-91.4-8.2c-15.2-4.4-30.6-13.2-55.2,3.2c-6.7,4.5-13.5,9.1-20.1,13.5c1,10.9,6.7,22.4,15.4,34.1
            c6.1-2.5,15.8-6.8,24.1-12.2c12.9-8.4,54.8,7.6,126.5,8.6v0c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.5,0v0c71.7-1,113.6-17.1,126.5-8.6
            c8.3,5.4,18,9.7,24.1,12.2c8.7-11.7,14.4-23.2,15.4-34.1C1234.8,122.3,1228,117.7,1221.3,113.2z"/>
          <g id="outer_shadow" fill={shadowColor} style={{opacity: opacityShadowValue - .1}}>
            <path  d="M1074.3,275.5c0,0,161.4-86.2,167.2-149c0,0,25.4,18,49,24.7C1290.4,151.2,1277.1,216.5,1074.3,275.5z"/>
            <path d="M1075.3,275.5c0,0-161.4-86.2-167.2-149c0,0-25.4,18-49,24.7C859.1,151.2,872.4,216.5,1075.3,275.5z"/>
          </g>
        </g>

        <g id="lights_dress" style={{opacity: opacityLightValue}}>
          <g fill={lightColor}>
            <path d="M300.7,1306.3c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.3,0.3-0.4
              c0.1-0.2,0.2-0.3,0.3-0.4c0,0,0,0,0-0.1c17-114.4,69-455.2,105.5-579c44.1-149.8,127.7-387.9,174.2-472.6
              c58.1-82.1,185.4-100.6,217.2-98.3c31.7,2.3,60.2-3.2,60.2-3.2h0c23.7-6.8,49-24.7,49-24.7c1.2,13.2,9.2,27.3,21.2,41.5
              c-14.7,23.5-40.2,24.1-40.2,24.1c-179.4,8.7-260.3,44.9-290.3,124.3c-193.4,510.4-243.9,908.7-243.9,908.7
              c-6.8,40.8-8.8,59.3-13.4,73.5L300.7,1306.3z"/>
            <path d="M1848.8,1306.3c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.5-0.7-0.6-0.9c0,0,0,0,0-0.1
              c-17-114.4-69-455.2-105.5-579c-44.1-149.8-127.7-387.9-174.2-472.6c-58.1-82.1-185.4-100.6-217.2-98.3
              c-31.7,2.3-60.2-3.2-60.2-3.2h0c-23.7-6.8-49-24.7-49-24.7c-1.2,13.2-9.2,27.3-21.2,41.5c14.7,23.5,40.2,24.1,40.2,24.1
              c179.4,8.7,260.3,44.9,290.3,124.3c193.4,510.4,243.9,908.7,243.9,908.7c6.8,40.8,8.8,59.3,13.4,73.5L1848.8,1306.3z"/>
          </g>
        </g>

        <g id="glowes">
          <g id="fill_secondary">
            <path fill={gloveColor} d="M1618.5,1382.3c0,0-25.9-48.6,32.6-77.6c0,0,77.1,24.3,132.5-2.6s80.2-37.3,113.9-3.6
              c33.6,33.6,8.8,52.8,8.8,52.8s14.5,7.8,5.7,35.2s-16.6,75-31.1,79.2c0,0,22.3,0,23.3,16c1,16,5.7,38.3-4.1,50.2
              c0,0,9.3,6.7,9.3,17.6c0,10.9,0.5,23.3,0,32.6c-0.5,9.3-6.2,12.9-6.2,12.9s6.7,11.4,5.7,22.8c-1,11.4-2.1,15-3.6,24.3
              s-10.4,17.1-10.4,17.1s0.5,9.3-3.6,23.8s-7.8,33.6-19.7,40.4c0,0,1.6,7.8-5.2,18.1s-22.8,33.6-29.5,40.4c-6.7,6.7-16,3.6-16,3.6
              s-25.9,27.4-35.2,36.2c-9.3,8.8-12.4,9.8-17.6,10.4c-5.2,0.5-9.8,0-16.6-4.7c0,0-9.8,8.3-22.8,15c-12.9,6.7-25.9,2.1-32.6-4.7
              s-7.8-10.4-7.8-17.6c0,0-3.6,2.1-9.3-3.6c-5.7-5.7-17.6-14-24.3-22.3s8.3-24.3,8.3-24.3s47.6-60,60-95.2c0,0-1.6-15.5-11.4-34.7
              c0,0-10.4,105.1-51.2,102c-40.9-3.1-48.6-20.7-50.2-40.4c-1.6-19.7-1-87.5,4.7-98.9c0,0-12.4-2.1-9.8-23.3s9.3-113.3,22.3-116.4
              c0,0-10.9-2.1-9.3-19.7C1619.6,1425.8,1608.7,1400.4,1618.5,1382.3z"/>
            <path fill={gloveColor} d="M531,1382.3c0,0,25.9-48.6-32.6-77.6c0,0-77.1,24.3-132.5-2.6s-80.2-37.3-113.9-3.6
              c-33.6,33.6-8.8,52.8-8.8,52.8s-14.5,7.8-5.7,35.2c8.8,27.4,16.6,75,31.1,79.2c0,0-22.3,0-23.3,16c-1,16-5.7,38.3,4.1,50.2
              c0,0-9.3,6.7-9.3,17.6c0,10.9-0.5,23.3,0,32.6s6.2,12.9,6.2,12.9s-6.7,11.4-5.7,22.8c1,11.4,2.1,15,3.6,24.3
              c1.6,9.3,10.4,17.1,10.4,17.1s-0.5,9.3,3.6,23.8s7.8,33.6,19.7,40.4c0,0-1.6,7.8,5.2,18.1s22.8,33.6,29.5,40.4s16,3.6,16,3.6
              s25.9,27.4,35.2,36.2c9.3,8.8,12.4,9.8,17.6,10.4c5.2,0.5,9.8,0,16.6-4.7c0,0,9.8,8.3,22.8,15c12.9,6.7,25.9,2.1,32.6-4.7
              c6.7-6.7,7.8-10.4,7.8-17.6c0,0,3.6,2.1,9.3-3.6c5.7-5.7,17.6-14,24.3-22.3s-8.3-24.3-8.3-24.3s-47.6-60-60-95.2
              c0,0,1.6-15.5,11.4-34.7c0,0,10.4,105.1,51.2,102c40.9-3.1,48.6-20.7,50.2-40.4c1.6-19.7,1-87.5-4.7-98.9c0,0,12.4-2.1,9.8-23.3
              s-9.3-113.3-22.3-116.4c0,0,10.9-2.1,9.3-19.7C530,1425.8,540.8,1400.4,531,1382.3z"/>
          </g>
          <g id="glove_lines" fill={shadowColor} style={{opacity: opacityShadowValue}}>
            <path d="M1909.4,1582.2c-21.6,26.3-40.4,41.3-51.7,46.9c1.5-14.3,1.9-28.3-0.4-49.2c12-7.3,25.3-22.4,39.4-37.6
              c2.4-2.6,4.6-5,6.6-7.3c-1.7-1.9-3.2-2.9-3.2-2.9c2.4-2.8,3.9-6.3,4.8-10.1c-9.1,11.2-30,35.2-51,48.2
              c-5.9-5.1-22-19.9-36.2-27.4c-1.5-33.6-5.6-67.7-6.4-78.4c31.3,3.6,53.1,6.1,82.6,4.1c-6.2-2.6-12.9-2.6-12.9-2.6
              c2.1-0.6,4.1-2.2,6-4.4c-26.1,0-46.8-6.2-76-9.6c-18.6-2.2-39.6-10.8-67.4-13c-61.6-4.8-105,11.9-122.4,20
              c2.7,3.8,6.2,4.5,6.2,4.5c-1.4,0.3-2.7,1.7-3.9,3.9c7.4-2.1,19.5-5,35.6-7.3c-3.1,16.9-12,70.3-13.9,137.7c-2.9-0.2-6-0.4-9-0.4
              c-11.5-0.2-21.3,0.9-27.4,1.8c2.9,3.5,6.2,4.1,6.2,4.1c-0.3,0.6-0.5,1.3-0.8,2.1c13.9-2,39.3-3.3,61.3,7.9
              c-7.6,3.3-17.2,13-22.5,36.3c-6.4,27.8-9.8,57.6-10.8,90.1c2.2,0.6,4.6,1.1,7.2,1.5c1.4-32.4,7.7-62.1,14.1-90
              c7-30.3,24.5-31.9,25.2-31.9l0-0.4c1,0.7,2.1,1.5,3.1,2.3c9.1,7.4,15.4,17.3,18.7,29.6c0.9-6.3,1.3-10.3,1.3-10.3
              c3.8,7.5,6.4,14.4,8.1,20c-1.2-14-4.3-24.5-10.6-37.4c-3.1-66,4.4-151.7,6.7-166.6c8.6,0.1,17.6,0.5,27.1,1.2
              c24.7,1.9,44,4.1,61.1,6c0.7,9,3.3,45.9,4.8,81.6c1.4,32.8,1.5,55.4,0.2,67.3c-2.9,28.1-10.9,50.1-13.7,57.1
              c-29.9-4.6-54.2-19.9-54.7-20l-1.7-0.5l-16.9,20c0.8,3.7,1,5.9,1,5.9c-0.5,1.4-1,2.8-1.6,4.2l18.9-14.3
              c5.5,1.6,23.1,6.4,44.9,10.3c-8.2,20.7-48.4,106.7-102.9,144.2c3.8,2.3,6,1,6,1c0,3.6,0.3,6.3,1.4,9
              c20.8-13.1,37.9-34.2,58.9-66.8c6.1,5.5,22.9,13.2,47.9,17.6c-21.9,23.6-46.1,45-67.3,62.2c12.7-6.7,22.3-14.8,22.3-14.8
              c1.3,0.9,2.6,1.7,3.7,2.3c22.3-19.4,45.6-42.2,65.6-67.3c2.1,1.4,7,1.1,11.2,1.9c-9.4,13.9-20.7,29.1-34.3,45.4
              c10.7-11,23.1-24.1,23.1-24.1s1.8,0.6,4.4,0.7c15.7-21.3,27.2-40.1,35.5-55.4l0,0.2c0.3,0,4.3-0.7,10.8-3c0.3-2.9,0-4.6,0-4.6
              c2.5-1.4,4.5-3.3,6.4-5.6c-5.3,2.4-9.7,3.9-12.9,4.8c0.3-0.6,0.6-1.1,0.8-1.6c14.7-29.2,21.5-46.8,21.7-47.5l-0.1,0
              c2.9-1.1,2.8-2.4,5.3-3.8c0.5-0.3,1-0.6,1.4-0.9c0.6-4.8,0.7-8,0.6-9.1c-1.7,1.3-3.5,2.6-5.6,3.8c-11.3,6.3-26.4,9.1-42.7,9.6
              c2.6-5.7,7.2-14,9.9-32.1c6.8-3,26.1-17.6,48.3-42.6c-1-2.1-1.7-3.4-1.7-3.4S1908.9,1591.4,1909.4,1582.2z M1694.7,1611
              c-0.1-0.1-0.2-0.2-0.3-0.2c-10.5-5.2-16.2-6.9-27.8-10c-4.5-1.2-4.9-2.1-9.9-2.8c2-70.6,7.2-125.9,9.8-139.4
              c12.2-1.5,26.4-2.5,42.2-2.6C1706,1473.2,1692.8,1547,1694.7,1611z M1876.8,1676.1c-2,6.8-7.1,21.6-17.4,42
              c-5.5,10.8-13,24.2-22.9,39.5c-4.3-0.2-7.4-1.5-9.4-2.7c5.4-7.2,10.5-14.4,15.1-21.8c18.4-29.3,25.8-46,25.9-46.7l-2.5-1.6
              l-7.5-5.4C1864.9,1678.8,1871.2,1677.7,1876.8,1676.1z M1849.1,1680.2l6.3,7c-0.9,4.6-4.9,19.6-19.7,42.9
              c-9.5,15-21,29.7-33.5,43.7l0-0.1c-17.4-2.3-29.9-8.3-37.4-13c-5.2-3.2-8.7-6.3-10.6-8c2-3.3,4-6.6,6-10.1
              c17.3-29.7,28.5-57.2,32.1-66.4c1.7,0.3,3.4,0.5,5.1,0.8C1817,1679.8,1834.3,1680.9,1849.1,1680.2z M1838.7,1673.2
              c-12-0.1-24.3-1.2-36.1-2.8c3.4-8.7,14.3-30,17.1-57.4c1.2-11.4-0.6-32.3-1.7-62.1c13.6,8,27.9,25.2,32.1,28.8
              C1856.9,1641.5,1840.3,1670.2,1838.7,1673.2z"/>
            <path d="M1906.3,1351.3c0,0,0.5-0.4,1.3-1.2c-12.5-10.2-27.9-22-44.5-30.7c-24.9-13-46.9-20-65.3-12.1
              c-42.1,18-71.6,23.6-106.9,20c-28.1-2.8-59.9,31.5-74.6,49.5c1.2,3.5,2.3,5.5,2.3,5.5c-1.2,2.2-2,4.4-2.7,6.8
              c9.3-10.8,45.3-49.6,74.3-46.7c36.9,3.7,67.3-5.6,110.5-24.1c16.6-7.1,35.9-4.7,59.2,7.5c19.9,10.4,38.4,25.7,51.8,36.9
              c1,0.8,1.9,1.6,2.8,2.4C1912.7,1354.7,1906.3,1351.3,1906.3,1351.3z"/>
            <path d="M246.3,1595c0,0-0.7,1.3-1.7,3.4c22.3,25,41.5,39.6,48.3,42.6c2.7,18.1,7.3,26.4,9.9,32.1c-16.3-0.6-31.4-3.3-42.7-9.6
              c-2.1-1.2-3.9-2.4-5.6-3.8c0,1.2,0,4.4,0.6,9.1c0.5,0.3,0.9,0.6,1.4,0.9c2.5,1.4,2.5,2.6,5.3,3.8l-0.1,0
              c0.2,0.7,7,18.4,21.7,47.5c0.3,0.5,0.6,1.1,0.8,1.6c-3.2-0.9-7.6-2.5-12.9-4.8c1.8,2.3,3.9,4.2,6.4,5.6c0,0-0.3,1.7,0,4.6
              c6.5,2.3,10.5,3,10.8,3l0-0.2c8.3,15.2,19.8,34.1,35.5,55.4c2.6-0.1,4.4-0.7,4.4-0.7s12.4,13.1,23.1,24.1
              c-13.6-16.2-24.9-31.5-34.3-45.4c4.1-0.8,9-0.5,11.2-1.9c20,25.2,43.3,47.9,65.6,67.3c1.2-0.6,2.4-1.4,3.7-2.3
              c0,0,9.6,8.1,22.3,14.8c-21.2-17.2-45.4-38.5-67.3-62.2c25-4.4,41.9-12.1,47.9-17.6c20.9,32.6,38.1,53.7,58.9,66.8
              c1.1-2.7,1.4-5.4,1.4-9c0,0,2.3,1.3,6-1c-54.5-37.5-94.6-123.5-102.9-144.2c21.8-3.9,39.4-8.7,44.9-10.3l18.9,14.3
              c-0.6-1.4-1.1-2.8-1.6-4.2c0,0,0.2-2.2,1-5.9l-16.9-20l-1.7,0.5c-0.5,0.2-24.7,15.4-54.7,20c-2.8-7-10.8-29-13.7-57.1
              c-1.2-11.9-1.1-34.5,0.2-67.3c1.5-35.7,4.1-72.6,4.8-81.6c17-2,36.4-4.1,61.1-6c9.5-0.7,18.5-1.1,27.1-1.2
              c2.3,14.9,9.8,100.6,6.7,166.6c-6.3,12.9-9.4,23.4-10.6,37.4c1.7-5.7,4.3-12.6,8.1-20c0,0,0.4,3.9,1.3,10.3
              c3.4-12.3,9.6-22.2,18.7-29.6c1-0.8,2-1.6,3.1-2.3l0,0.4c0.7,0.1,18.2,1.6,25.2,31.9c6.4,27.8,12.7,57.6,14.1,90
              c2.6-0.4,4.9-0.9,7.2-1.5c-1-32.5-4.4-62.2-10.8-90.1c-5.4-23.3-14.9-33-22.5-36.3c22-11.2,47.4-9.9,61.3-7.9
              c-0.3-0.8-0.5-1.5-0.8-2.1c0,0,3.4-0.6,6.2-4.1c-6.1-0.9-15.9-2-27.4-1.8c-3.1,0-6.1,0.2-9,0.4c-1.9-67.4-10.8-120.8-13.9-137.7
              c16.1,2.3,28.2,5.2,35.6,7.3c-1.2-2.2-2.6-3.5-3.9-3.9c0,0,3.5-0.7,6.2-4.5c-17.5-8.1-60.9-24.8-122.4-20
              c-27.8,2.2-48.8,10.8-67.4,13c-29.2,3.4-49.9,9.6-76,9.6c1.9,2.3,3.8,3.8,6,4.4c0,0-6.7,0-12.9,2.6c29.4,2,51.3-0.5,82.6-4.1
              c-0.8,10.7-4.9,44.8-6.4,78.4c-14.2,7.5-30.3,22.3-36.2,27.4c-21-12.9-41.9-37-51-48.2c0.9,3.8,2.5,7.3,4.8,10.1
              c0,0-1.4,1-3.2,2.9c2,2.3,4.2,4.7,6.6,7.3c14.1,15.3,27.4,30.3,39.4,37.6c-2.2,20.9-1.9,34.9-0.4,49.2
              c-11.3-5.6-30.1-20.5-51.7-46.9C240.7,1591.4,246.3,1595,246.3,1595z M440.9,1456.1c15.9,0.1,30,1.2,42.2,2.6
              c2.6,13.5,7.8,68.7,9.8,139.4c-5,0.6-5.4,1.6-9.9,2.8c-11.6,3.1-17.3,4.8-27.8,10c-0.1,0-0.2,0.1-0.3,0.2
              C456.8,1547,443.5,1473.2,440.9,1456.1z M291.5,1679.5l-7.5,5.4l-2.5,1.6c0.1,0.7,7.5,17.3,25.9,46.7c4.6,7.3,9.7,14.6,15.1,21.8
              c-2,1.2-5.1,2.4-9.4,2.7c-10-15.3-17.4-28.7-22.9-39.5c-10.3-20.4-15.3-35.2-17.4-42C278.4,1677.7,284.6,1678.8,291.5,1679.5z
              M352,1676.9c1.7-0.2,3.4-0.5,5.1-0.8c3.6,9.2,14.9,36.7,32.1,66.4c2,3.5,4,6.8,6,10.1c-1.9,1.8-5.4,4.8-10.6,8
              c-7.5,4.6-20,10.7-37.4,13l0,0.1c-12.5-14-24.1-28.7-33.5-43.7c-14.7-23.3-18.7-38.3-19.7-42.9l6.3-7
              C315.3,1680.9,332.5,1679.8,352,1676.9z M299.4,1579.7c4.2-3.6,18.6-20.8,32.1-28.8c-1.1,29.8-2.9,50.6-1.7,62.1
              c2.8,27.4,13.7,48.7,17.1,57.4c-11.7,1.6-24.1,2.7-36.1,2.8C309.2,1670.2,292.6,1641.5,299.4,1579.7z"/>
            <path d="M243.2,1351.3c0,0-0.5-0.4-1.3-1.2c12.5-10.2,27.9-22,44.5-30.7c24.9-13,46.9-20,65.3-12.1c42.1,18,71.6,23.6,106.9,20
              c28.1-2.8,59.9,31.5,74.6,49.5c-1.2,3.5-2.3,5.5-2.3,5.5c1.2,2.2,2,4.4,2.7,6.8c-9.3-10.8-45.3-49.6-74.3-46.7
              c-36.9,3.7-67.3-5.6-110.5-24.1c-16.6-7.1-35.9-4.7-59.2,7.5c-19.9,10.4-38.4,25.7-51.8,36.9c-1,0.8-1.9,1.6-2.8,2.4
              C236.9,1354.7,243.2,1351.3,243.2,1351.3z"/>
          </g>
          <g id="shadow" fill={shadowColor} style={{opacity: opacityShadowValue}}>
            <path d="M1642.1,1739.2c-23.8-29.1,1.9-137.9,1.9-137.9c-23.6-4.3-9.5-88-5.2-109.3s15.3-36.9,15.3-36.9
              c-18.6-2.7-11.8-28.2-18.2-50.3c-6.2-21.3,7-47.3,7-47.3c-13.1-18.9-1.3-42.4,8.3-52.7c-58.8,28-32.6,77.6-32.6,77.6
              c-9.8,18.1,1,43.5-0.5,61.1c-1.6,17.6,9.3,19.7,9.3,19.7c-12.9,3.1-19.7,95.2-22.3,116.4c-2.6,21.2,9.8,23.3,9.8,23.3
              c-5.7,11.4-6.2,79.2-4.7,98.9C1611.5,1717.9,1617.1,1732.8,1642.1,1739.2L1642.1,1739.2z"/>
            <path d="M507.4,1739.2c23.8-29.1-1.9-137.9-1.9-137.9c23.6-4.3,9.5-88,5.2-109.3c-4.3-21.3-15.3-36.9-15.3-36.9
              c18.6-2.7,11.8-28.2,18.2-50.3c6.2-21.3-7-47.3-7-47.3c13.1-18.9,1.3-42.4-8.3-52.7c58.8,28,32.6,77.6,32.6,77.6
              c9.8,18.1-1,43.5,0.5,61.1c1.6,17.6-9.3,19.7-9.3,19.7c12.9,3.1,19.7,95.2,22.3,116.4c2.6,21.2-9.8,23.3-9.8,23.3
              c5.7,11.4,6.2,79.2,4.7,98.9C538,1717.9,532.4,1732.8,507.4,1739.2L507.4,1739.2z"/>
          </g>
          <g id="light" fill={lightColor} style={{opacity: opacityLightValue}}>
            <path d="M1856.5,1306.4c10.2,0.7,25.4,14.8,13.8,22.9c0,0,19.7,25.5,5.2,59.2c-14.5,33.7-18.6,71.8-38.9,72.9
              c0,0,35.2,12.6,36.3,30s15.5,53.4-3.6,72.5c0,0,12.9-1.7,12.9,11.1s1.6,19.7,0.4,30.2c-1.2,10.5-7,17.4-7,17.4s8.7,3.5,8.1,9.3
              c-0.6,5.8-2.4,36-11.7,40.6c0,0-5.8,40.6-18,49.9c0,0-11.6,29.6-23.8,44.7c-5.4,6.7-14.6,6.5-19.7,7c0,0-22.6,34-39.5,40.4
              c-16.8,6.4-23.8-5.5-23.8-5.5s-22.5,40.6-55.6,23.2l0,0.1c1.1,1.6,2.6,3.3,4.5,5.3c6.7,6.7,19.7,11.4,32.6,4.7
              c12.9-6.7,22.8-15,22.8-15c6.7,4.7,11.4,5.2,16.6,4.7c5.2-0.5,8.3-1.6,17.6-10.4c9.3-8.8,35.2-36.2,35.2-36.2s9.3,3.1,16-3.6
              c6.7-6.7,22.8-30,29.5-40.4c6.7-10.4,5.2-18.1,5.2-18.1c11.9-6.7,15.5-25.9,19.7-40.4s3.6-23.8,3.6-23.8s8.8-7.8,10.4-17.1
              c1.6-9.3,2.6-12.9,3.6-24.3c1-11.4-5.7-22.8-5.7-22.8s5.7-3.6,6.2-12.9c0.5-9.3,0-21.7,0-32.6c0-10.9-9.3-17.6-9.3-17.6
              c9.8-11.9,5.2-34.2,4.1-50.2c-1-16-23.3-16-23.3-16c14.5-4.1,22.3-51.8,31.1-79.2c8.8-27.4-5.7-35.2-5.7-35.2s24.8-19.1-8.8-52.8
              c-33.6-33.6-58.5-23.3-113.9,3.6c-55.4,26.9-132.5,2.6-132.5,2.6s18.4,25,63.7,30.2C1760.2,1340.1,1818.8,1303.9,1856.5,1306.4z"
              />
            <path d="M293,1306.4c-9.6,0.1-25.4,14.8-13.8,22.9c0,0-19.7,25.5-5.2,59.2c14.5,33.7,18.6,71.8,38.9,72.9
              c0,0-35.2,12.6-36.3,30c-1.2,17.4-15.5,53.4,3.6,72.5c0,0-12.9-1.7-12.9,11.1s-1.6,19.7-0.4,30.2c1.2,10.5,7,17.4,7,17.4
              s-8.7,3.5-8.1,9.3c0.6,5.8,2.4,36,11.7,40.6c0,0,5.8,40.6,18,49.9c0,0,11.6,29.6,23.8,44.7c5.4,6.7,14.6,6.5,19.7,7
              c0,0,22.6,34,39.5,40.4s23.8-5.5,23.8-5.5s22.5,40.6,55.6,23.2l0,0.1c-1.1,1.6-2.6,3.3-4.5,5.3c-6.7,6.7-19.7,11.4-32.6,4.7
              s-22.8-15-22.8-15c-6.7,4.7-11.4,5.2-16.6,4.7c-5.2-0.5-8.3-1.6-17.6-10.4c-9.3-8.8-35.2-36.2-35.2-36.2s-9.3,3.1-16-3.6
              c-6.7-6.7-22.8-30-29.5-40.4c-6.7-10.4-5.2-18.1-5.2-18.1c-11.9-6.7-15.5-25.9-19.7-40.4c-4.1-14.5-3.6-23.8-3.6-23.8
              s-8.8-7.8-10.4-17.1s-2.6-12.9-3.6-24.3s5.7-22.8,5.7-22.8s-5.7-3.6-6.2-12.9c-0.5-9.3,0-21.7,0-32.6c0-10.9,9.3-17.6,9.3-17.6
              c-9.8-11.9-5.2-34.2-4.1-50.2c1-16,23.3-16,23.3-16c-14.5-4.1-22.3-51.8-31.1-79.2c-8.8-27.4,5.7-35.2,5.7-35.2
              s-24.8-19.1,8.8-52.8c33.6-33.6,58.5-23.3,113.9,3.6c55.4,26.9,132.5,2.6,132.5,2.6s-18.4,25-63.7,30.2S330.8,1306.1,293,1306.4z"
              />
          </g>
        </g>

      </svg>

    </div>
  )
}