import { useTranslations } from "cupman-utils";
import { Sport$SportTypes, useMatch } from "../../api/schema";
import { CSSProperties } from "react";
import styles from './matchStatistics.module.scss';
import IcehockeyStatisticsTotal from "./IcehockeyStatisticsTotal";
import FootballStatisticsTotal from "./FootballStatisticsTotal";
import BasketballStatisticsTotal from "./BasketballSTatisticsTotal";
import OtherStatisticsTotal from "./OtherStatisticsTotal";
import { makeContrastColor } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/pro-light-svg-icons";
import MatchTeamsAwards from "../MatchTeamsAwards/MatchTeamsAwards";
import classNames from "classnames";

type Props = {
  sportType: Sport$SportTypes,
  matchId: number
}

const TeamStatisticsFragment = {
  team: {
    shirt: {},
    statistics: {}
  }
}

export default function TeamStatistics ({matchId, sportType} : Props) {

  const game = useMatch({id: matchId}, {
    home: TeamStatisticsFragment,
    away: TeamStatisticsFragment,
    division: {
      category: {}
    },
  });

  const T = useTranslations('cmresults');

  const home = game.home.team?.statistics;
  const away = game.away.team?.statistics;

  const showShotAndSaves = sportType === 'icehockey' && game.division.category.registerShots;
  
  const shirtPrimaryColors = {
    home: makeContrastColor(game.home.team?.shirt?.color1),
    away: makeContrastColor(game.away.team?.shirt?.color1)
  }


  return home && away 
  ? <div className="max-50">
    <h2 style={{textAlign: 'center', marginBottom: '.5rem', marginTop: '4rem', fontSize: 'var(--font-size-l)'}} className="custom-font">
      <FontAwesomeIcon icon={faUsers}/> {T('Lagstatistik')}
    </h2>

    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Awards')}
    </h3>
    <MatchTeamsAwards home={game.home.team} away={game.away.team}/>

    {sportType === 'icehockey' && <IcehockeyStatisticsTotal statistics={{home, away}} teamColors={shirtPrimaryColors} registerShots={showShotAndSaves}  />}

    {sportType === 'football' && <FootballStatisticsTotal 
      statistics={{home, away}} 
      teamColors={shirtPrimaryColors} 
      registerDisciplinaryCards={game.division.category.registerRedCards && game.division.category.registerYellowCards}
      registerGreenCards={game.division.category.registerGreenCards}
    />}
    

    {sportType === 'basketball' && <BasketballStatisticsTotal live={game.live} statistics={{home, away}} teamColors={shirtPrimaryColors} />}

    {(sportType !== 'football' && sportType !== 'basketball' && sportType !== 'icehockey') && <OtherStatisticsTotal
      showTied={game.division.category.matchesCanTie}
      statistics={{home, away}} 
      teamColors={shirtPrimaryColors}
    />}
  </div>
  : <></>
}