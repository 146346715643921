import { faClock, faStarShooting, faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { MatchStart, RuleTemplate$PeriodTypes, Sport$SportTypes } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import styles from './PeriodStart.module.scss';
import { useSportSpecificWords } from "../../utils/useSportSpecificWords";
import { capitalize } from "../../utils";
import HidePeriodButton from "./HidePeriodButton";
import PeriodScore from "./PeriodScore";


type props = {
  event: MatchStart,
  sport: Sport$SportTypes, 
  startTime:number, 
  audio: HTMLAudioElement,
  soundOn: boolean,
  eventsBefore?: boolean,
  ordinaryPeriodCount: number,
  periodTypes: RuleTemplate$PeriodTypes[],
  hiddenPeriods: Set<number>,
  setHiddenPeriods: (hiddenPeriods: Set<number>) => void
}


export default function PeriodStart ({eventsBefore, event, sport, startTime, audio, soundOn, ordinaryPeriodCount, periodTypes, hiddenPeriods, setHiddenPeriods} : props) {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const formatStartWord = useSportSpecificWords(sport, ordinaryPeriodCount);

  const startWord = formatStartWord('start', ordinaryPeriodCount) || T('Matchstart')

  if( event.winByPeriods && event.period > 0 ){
    return <>
    <div className={classNames(styles.period_start, styles.inverted)}>
      <div className={styles.game_start_info}>
        <span className={styles.period_arrow}><FontAwesomeIcon icon={faArrowUp} /></span>
        <span className={styles.period_start_label}>
          {event.period === 0 
            ? capitalize(startWord)
            : T('X börjar',formatStartWord(periodTypes[event.period], event.period))
          }
        </span>
      </div>
    </div>
    <div className={classNames(styles.period_start)}>
      <div className={styles.game_start_info}>
          <span>{formatStartWord(periodTypes[event.period-1], event.period-1)}</span>
          {event.period !== 0 && <PeriodScore homeScore={event.homeScore} awayScore={event.awayScore} winByPeriod={true}/>}
      </div>
    </div>
    <HidePeriodButton hiddenPeriods={hiddenPeriods} 
          setHiddenPeriods={setHiddenPeriods} 
          period={event.period-1}
          periodName={formatStartWord(periodTypes[event.period-1], event.period-1)}/>
    </>
  } else {
    return <><div className={classNames(styles.period_start, event.period === 0 && styles.match_start, eventsBefore && styles.events_before, 'both', 'period_start')} onClick={() => {soundOn && audio.play()}}>
      {event.period === 0 && <FontAwesomeIcon icon={faStarShooting} />}
      <div className={styles.game_start_info}>
        <span className={styles.period_arrow}><FontAwesomeIcon icon={faArrowUp} /></span>
        <span className={styles.period_start_label}>
          {event.period === 0 
            ? capitalize(startWord)
            : T('X börjar',formatStartWord(periodTypes[event.period], event.period))
          }
        </span>
        {event.period !== 0 && <PeriodScore homeScore={event.homeScore} awayScore={event.awayScore}/>}
        {event.period === 0 && <span>
          <FontAwesomeIcon icon={faClock}/> {T('X local time', formatDate(startTime, 'HH:mm'))} 
        </span>}
      </div>
      {event.period === 0 && <FontAwesomeIcon icon={faStarShooting} />}
    </div>
    {event.period > 0 && <HidePeriodButton hiddenPeriods={hiddenPeriods} 
          setHiddenPeriods={setHiddenPeriods} 
          period={event.period-1}
          periodName={formatStartWord(periodTypes[event.period-1], event.period-1)}/>}
    
    </>
  }
}