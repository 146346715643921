import styles from "./CountdownSection.module.scss";
import { Flippen } from "./Flippen";

export default function CountdownSection({title, value, color} : {title:string, value:string, color?:string}) {
    return <div className={styles.container}>
        <span className={styles.title}>{title}</span>
        <div className={styles.number_container} style={
          color && {'--clr-solidSport' : color
        } as any}>
            {Array.from(value).map((char, i) => <Flippen key={i} value={char}/>)}
        </div>
    </div>
}




