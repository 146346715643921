import { useTranslations } from 'cupman-utils';
import { MatchFeed$Comments } from '../../api/schema';

export function useCommentTexts(): Record<MatchFeed$Comments, string> {
  const T = useTranslations("cmresults");
  return {
    COME_ON: T("Kom igen! Ni kan ta igen detta!"),
    BELIEVE_IN_YOU: T("Vi tror på er! Gå för vinst!"),
    TRUST_YOU_FIX: T("Är säker på att ni fixar det!"),

    PLAY_GOOD: T("Vad ni spelar bra!"),
    KEEP_LEAD: T("Håll i ledningen nu!"),
    NICE_PLAY: T("Snyggt spel!"),

    GOOD_LUCK: T("Lycka till!"),
    LOOKING_FORWARD: T("Ser verkligen fram mot matchen!"),

    THANK_YOU: T("Tack för en bra match!"),
    NICE_GAME: T("Härligt spelat!"),
    THANKS_REFEREE: T("Tack domaren, bra dömt!")
  };
}
