import { MutationSpec } from 'granular'
import { MeReferee$RemoveRefereeExpensePayload } from '..'

export function MeReferee$removeRefereeExpense_Optimization(params:{id:number}, payload:MeReferee$RemoveRefereeExpensePayload, query:never) : Partial<MutationSpec<MeReferee$RemoveRefereeExpensePayload,never,any>> {
	return {
		fetchRefsIfInvalidatedByMutation: [],
		optimistics: {
			// Add your optimistics here like [Ref({paramId:123})]: (data:Ref) => { return data; }
		}
	}
}
