import { useTranslations } from "cupman-utils";
import LabeledHorizontalBarMultiple from "./LabeledHorizontalBarMultiple";
import { Team$statistics } from "../../../api/schema";
import { roundToOneDecimal } from "../../../utils";
import { getColorString } from "../../../colors";

export type RawRgb = {r:number, g:number, b:number}

type Props = {
  statistics: Team$statistics,
  colors: {
    positive: RawRgb,
    negative: RawRgb
  }
}

export default function PointsBar ({statistics, colors} : Props) {

  
  const T = useTranslations('cmresults');
  const totalPoints = statistics.lost + statistics.goals;

  const bars = [
    {
      label: T('Gjorda poäng'),
      value: statistics.goals / totalPoints,
      valueLabel: `${statistics.goals} P (${roundToOneDecimal(statistics.goals / totalPoints)}%)`,
      color: getColorString(colors.positive)
    },
    {
      label: T('Förlorade poäng'),
      value: statistics.lost / totalPoints,
      valueLabel: `${statistics.lost} P (${roundToOneDecimal(statistics.lost / totalPoints)}%)`,
      color: getColorString(colors.negative)
    }
  ]

  return <div>
    <LabeledHorizontalBarMultiple bars={bars} />
  </div>
}

