import { useEffect, useState } from "react";

export const useBodyScroll = () => {}

let scroll = 0;


export default function useCatchBodyScroll(offset:number =  2) {
    const [scrollLocked,setScrollLocked] = useState<{locked: boolean, scrolling:boolean}>({locked: true, scrolling:false});
    
    useEffect(() => {
        const listener = (event:Event) => {
            // if( scrollOffset < offset ){
            if( window.pageYOffset === 0 && !scrollLocked.locked){
                setScrollLocked({locked:true, scrolling:false});
                return;
            }
            
            if( scrollLocked.locked ){
                scroll += 1;     
            
                
                if( scroll === 1 ){
                    setScrollLocked({locked:true, scrolling:true});
                }

                if( scroll > offset  ){
                    scroll = 0;
                    setTimeout(()=>{
                        setScrollLocked({locked:false, scrolling:true});
                        window.scrollTo(0,Math.max(1,window.pageYOffset)); // Make sure it is possible to scroll up
                    },500);
                }
            }
        };
        
        window.addEventListener('scroll',listener);
        return () => {
            window.removeEventListener('scroll',listener);
        };
    }, [scrollLocked.locked]);

    return scrollLocked.scrolling
}
