import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ContentContainer.module.scss';


type props = {
  icon?: IconDefinition,
  header?: string,
  children: React.ReactNode,
  fullWidth?: boolean,
  paddingInside?: boolean,
  className?: string,
  path?: string,
  borderCss?: string,
  hideTitle?: boolean,
  noPadding?: boolean,
  dark?: boolean,
  visibilityDesktop?: boolean
}

export default function ContentContainer ({children, header, hideTitle, icon, fullWidth, paddingInside, className, path, borderCss, noPadding, dark, visibilityDesktop} : props) {

  const content = <div className={classNames(styles.content_container_wrapper, fullWidth && styles.full, hideTitle && styles.extra_margin_top)}>
    {!hideTitle && header && <h2 className={classNames('max-75', "custom-font", styles.header)}>{icon && <span className={styles.icon}>
      <FontAwesomeIcon icon={icon} />
    </span>} {header}</h2>}
    
    <div className={classNames(styles.content_container, fullWidth && styles.full, paddingInside && styles.padding_inside, noPadding && styles.no_padding, dark && styles.dark, visibilityDesktop && styles.visible_desktop)} style={{border: borderCss ? borderCss : ''}}>
      {children}
    </div>
  </div>

  return (
    path 
      ? <Link className={classNames(className, styles.link)} to={path}>
        {content}
      </Link>
      : <div className={className}>
          {content}
      </div>
  )
}