export default function PendingIcon ({color: _color, className} : {color?:string, className?: string}) {

  const color = _color ? _color : '#000';

  

  return <svg version="1.1" id="Lager_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 194.2 213.8" xmlSpace="preserve" className={className}>
    <path fill={color} d="M193,101.7c-5.2-33.4-27-61.3-58.2-74.5c-5.1-2.2-11,0.2-13.1,5.3c-2.2,5.1,0.2,11,5.3,13.1
  c24.8,10.5,42,32.6,46.2,59.1c6.6,42-22.3,81.5-64.3,88.1c-42,6.6-81.5-22.3-88.1-64.3c-3.2-20.3,1.8-40.7,13.9-57.3
  c7.9-10.8,18.2-19.1,30-24.6L54.6,66.7c-2.5,4.9-0.6,10.9,4.4,13.5c1.5,0.7,3,1.1,4.5,1.1c3.6,0,7.2-2,8.9-5.5l25.2-49.4L48.2,1.1
  c-4.9-2.5-10.9-0.6-13.5,4.4c-2.5,4.9-0.6,10.9,4.4,13.5l18.1,9.2C42,35,28.8,45.7,18.7,59.5C3.4,80.5-2.8,106.1,1.2,131.7
  c7.5,47.8,48.9,82.1,95.9,82.1c5,0,10-0.4,15.1-1.2C165,204.4,201.3,154.6,193,101.7z"/>
    <circle fill={color} cx="59.7" cy="117.3" r="12.8"/>
    <circle fill={color} cx="100.1" cy="117.3" r="12.8"/>
    <circle fill={color} cx="140.4" cy="117.3" r="12.8"/>
  </svg>
}