import './styles/App.scss';
import { Client, GranularProvider, HttpNetworkInterface, useLink, Msg_Clear, useNetworkParams, useWebSocket, useWebsocketHandler, WS_INTENTIONAL_CLOSE } from 'granular';
import * as schema from './api/schema';
import { ReactNode, Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { Me, Tournament, useTournament } from './api/schema';
import { unstable_HistoryRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { LangFallbackProvider, LangProvider } from 'cupman-utils/lib/LangContext';
import { ColorProvider } from './colors';
import { ApiExplorer } from 'granular';
import React from 'react';
import RegistrationsPage from './pages/RegistrationsPage/RegistrationsPage';
import MapPage from './pages/MapPage/MapPage';
import { TeamPage } from './pages/TeamPage/TeamPage';
import { MatchPage } from './pages/MatchPage/MatchPage';
import { RootPage } from './pages/HomePage/HomePage';
import DivisionPage from './pages/DivisionPage/DivisionPage';
import CategoriesPage from './pages/CategoriesPage/CategoriesPage';
import StreamingPage from './pages/StreamingPage/StreamingPage';
import LargeLoading from './Loading/PageLoading';
import LargeLoadingNoTranslation from './Loading/PageLoadingNoTranslation';
import { v4 } from 'uuid';
import PlacesPage from './pages/Places/PlacesPage';
import { createBrowserHistory } from 'history';
import styles from './App.module.scss';
import StatisticsPage from './pages/StatisticsPage/StatisticsPage';
import SearchPage from './pages/SearchPage/SearchPage';
import HistoryPage from './pages/HistoryPage/HistoryPage';
import NationsPage from './pages/NationsPage/NationsPage';
import RefereePage from './pages/RefereePage/RefereePage';
import { ErrorBoundary, ErrorType } from './Error/ErrorBoundary';
import WinnersPage from './pages/WinnersPage/WinnersPage';
import WinnersCategoryPage from './pages/WinnersPage/WinnersCategoryPage';
import StatisticsMatchesPage from './pages/StatisticsMatchesPage/StatisticsMatchesPage';
import StageTeamConferencePage from './pages/StageTeamConferencePage/StageTeamConferencePage';
import TopPlayersStatisticsPage from './pages/StatisticsPage/TopPlayersStatisticsPage';
import TopPlayersCategoryStatisticsPage from './pages/StatisticsPage/TopPlayersCategoryStatisticsPage';
import AdminBanner from './components/AdminBanner/AdminBanner';
import { ErrorBoundaryOuter } from './Error/ErrorBoundaryOuter';
import ErrorPage from './Error/ErrorPage';
import MyMatchesPage from './pages/MyMatchesPage/MyMatchesPage';
import { ClubPageAccommodation } from './pages/ClubPage/ClubPageAccommodation';
import { useNetworkMutationParams } from 'granular';
import { BlockingCacheWrapper, DefaultCache } from 'granular/lib/src/Cache';
import ErrorModal from './Error/ErrorMoal';
import DayMatchesPage from './pages/DayMatchesPage/DayMatchesPage';
import RefereesPage from './pages/RefereePage/RefereesPage';
import StatisticsFinalsPage from './pages/StatisticsFinalsPage/StatisticsFinalsPage';
import GamesPage from './pages/GamesPage/GamesPage';


// if (process.env.NODE_ENV === 'development') {
//   import('react-error-overlay' as any).then(m => {
//     m.stopReportingRuntimeErrors();
//   });
// }


declare global {
  interface Window {
    trigger_magicbutton_refresh: () => void,
    close_magicbutton: () => void,
    cmresults_navigate?: (path:string) => void,
    magicButton_handleWebsocketMessage?: (msg:any) => boolean,
    magicButtonProps?: {cupId:number},
    cmresults_client: Client,
    cm_results_props: {baseUrl: string, tournamentId: number, lang: string, cupId?: number}
  }
}


var network = new HttpNetworkInterface(window.location.origin + "/rest/results_api", {maxBatchSize:1});
var cache = new BlockingCacheWrapper(new DefaultCache({taint: ["general"]}));

/* LOCAL STORAGE TESTS */
(window as any).saveToLocalStorage = function() {
  const dc = (cache as any).cache as DefaultCache;
  const rc = (dc as any).responseCache;
  const value = JSON.stringify(rc, (key,value) => key==="pubsub" ? undefined : value);
  localStorage.setItem('cmresults_cache', value);
};

(window as any).clearLocalStorage = function() {
  localStorage.removeItem('cmresults_cache');
};

(window as any).loadFromLocalStorage = function() {
  const dc = (cache as any).cache as DefaultCache;
  const entries = JSON.parse(localStorage.getItem('cmresults_cache') || '{}') as Record<string,any>;
  Object.entries(entries).forEach(([href, re]) => {
    const _re = dc.init(href);
    if (re.response && re.response.entity) re.response.entity.href = href;
    _re.gotResponse(re.response);
  })
};

(window as any).loadFromLocalStorage();

/* // LOCAL STORAGE TESTS */




function onMutationError(err: Error) {
    alert("An error occurred. Please contact Cup Manager support if you need help.\n\n Details: " + err.message);
    console.error({err})
}
var client = new Client({network, cache, schema, onMutationError, refetchDelay: 5000});
console.log("setting cmresults_client")
window.cmresults_client = client;

const isMapPath = (path: string) => {
    return /(clubs|map|places)/.test(path);
}

const pathToKey = (path: string) => {
  if( isMapPath(path) ){
    return path.replace(/\/:.*$/g,"");
  } else if(path.indexOf("search") > -1) {
    return "search";
  } else {
    return path;
  }
}


export const LoadingPath = React.createContext(undefined);

function App({baseUrl, tournamentId, lang} : {baseUrl:string, tournamentId:number, lang:string}) {

  const [loadingPath, setLoadingPath] = useState(undefined);
  const [isPending, startTransition] = useTransition();
  const [onTransitionError, setOnTransitionError] = useState<(()=>void)|undefined>(undefined);
  const [navigationError, setNavigationError] = useState<{error:ErrorType, errorInfo:any} | undefined>(undefined);
  const currentPathName = useRef<string>("/");


  const history = useMemo(() => {
    let h = createBrowserHistory();
    const {push, go ,replace} = h;
    
    h.push = (...args:any) => {      
      if( isMapPath(args[0].pathname) && isMapPath(currentPathName.current) ){
        push.apply(h, args);
      } else {
        setLoadingPath(args[0].pathname);
        
        startTransition(() => {
          cache.clearAll(re => re.isError());
          setOnTransitionError(()=>()=>{
            // console.log("transitionError inner", loadingPath, currentPathName.current);
            setOnTransitionError(undefined);
            push.apply(h, [currentPathName.current, args[1]]);
          });
          setLoadingPath(undefined);
          push.apply(h, args);
        });
      }
    }


    h.go = (...args:any) => {
      setLoadingPath(args[0].pathname);
      startTransition(() => {
        cache.clearAll(re => re.isError());
        setOnTransitionError(()=>()=>{
          // console.log("transitionError inner", loadingPath, currentPathName.current);
          setOnTransitionError(undefined);
          push.apply(h, [currentPathName.current, undefined]);
        });
        setLoadingPath(undefined);
        go.apply(h, args);
      })
    }

    h.replace = (...args:any) => {
      setLoadingPath(args[0].pathname);
      startTransition(() => {
        cache.clearAll(re => re.isError());
        setOnTransitionError(()=>()=>{
          // console.log("transitionError inner", loadingPath, currentPathName.current);
          setOnTransitionError(undefined);
          push.apply(h, [currentPathName.current, undefined]);
        });
        setLoadingPath(undefined);
        replace.apply(h, args);
      })
    }

    return h;
  }, []);

  const {pathname} = history.location;
  // console.log({pathname, loadingPath, isPending, onTransitionError, navigationError, currentPathName});

  useEffect(()=>{
    /** Will run after a transition has finished and merged somehow */
    if( loadingPath === undefined ){
      // console.log("transitionError - done", {loadingPath, onTransitionError, pathname:history.location.pathname});
      setOnTransitionError(undefined);
      /** Save the current succesfully loaded page to be used on navigation errors */
      currentPathName.current = history.location.pathname;
    }
  },[loadingPath])

  const onNavigationError =  (error:ErrorType, errorInfo:any)  => {
    // console.log("transitionError", loadingPath, onTransitionError);
    if( onTransitionError ){
      setNavigationError({error,errorInfo});
      onTransitionError();
      return true;
    } else {
      return false;
    }
  }

  /*useEffect(() => {
    if(!isPending) {
      setLoadingPath(undefined)
    }
  }, [isPending])*/

  return (
    
    <ErrorBoundaryOuter fallback={({error, errorInfo}) => <ErrorPage error={error} errorInfo={errorInfo} />}>
      
      <Suspense fallback={<LargeLoadingNoTranslation />}>
        {isPending && <div className={styles.loading_layer}>
          <div>
            <span className="visually_hidden">Loading next page</span>
          </div>
        </div>}

        
        <LangProvider lang={lang}>
          <LangFallbackProvider fallback={"en"}>
            <GranularProvider client={client}>
              <NetworkParams tournamentId={tournamentId} lang={lang}>
                <Router history={history} basename={baseUrl}>
                 
                    <ScrollToTop />
                    <ErrorBoundary fallback={({error, errorInfo}) => <>
                      <ErrorPage error={error} errorInfo={errorInfo} />
                    </>}>
                      <>
                        <Routes>
                          <Route path="/explorer" element={<ApiExplorer baseUrl={network.url} title="CM Results" />} />
                          
                          <Route path="*" element={<LoadingPath.Provider value={loadingPath}>
                            <Suspense fallback={<LargeLoading />}>
                              <ErrorBoundary fallback={({error,errorInfo}) => <><ErrorPage error={error} errorInfo={errorInfo} /></>}>
                                <InnerApp 
                                tournamentId={tournamentId} 
                                onError={onNavigationError} navigationError={navigationError}  
                                setNavigationError={setNavigationError}
                                />
                              </ErrorBoundary>
                            </Suspense>
                          </LoadingPath.Provider>} />
                        </Routes>
                      </>
                    </ErrorBoundary>

                </Router>
              </NetworkParams>
            </GranularProvider>
          </LangFallbackProvider>
        </LangProvider>
      </Suspense>
    </ErrorBoundaryOuter>
  );
}

function NetworkParams ({children, lang, tournamentId} : {children: ReactNode, lang: string, tournamentId: number}) {

  useNetworkParams({lang, tournamentId});
  useNetworkMutationParams({clientId, tournamentId});

  return <>{children}</>
}



// https://v5.reactRouter.com/web/guides/scroll-restoration
function ScrollToTop() {
  
  const { pathname } = useLocation();

  useEffect(() => {
    const pathArr = pathname.split('/').filter(path => path.length > 0)

    // SPECIAL, DO NOT SCROLL WHEN PLACES SHOWS ARENA-INFO
    if(pathArr[0] !== 'places') {
      if( pathArr[1] && pathArr[1].length > 0) {
        window.scrollTo(0, 0);
      }
    }

    if(pathname.startsWith("/games")){
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}


const CurrentTournamentContext = React.createContext<Tournament<{subcup:{sport:{}}, edition:{}, cup:{}, days:[{}], streamingInfo: {}}>>(null as any);
export const useCurrentTournament = () => useContext(CurrentTournamentContext);


const clientId = v4();

function InnerApp({tournamentId, onError, navigationError, setNavigationError} : {tournamentId : number, onError:  (error:ErrorType, errorInfo:any) => boolean, 
navigationError?: {
  error: ErrorType;
  errorInfo: any;
}, 
setNavigationError: React.Dispatch<React.SetStateAction<{
  error: ErrorType;
  errorInfo: any;
} | undefined>>}) {
  const t = useTournament({id:tournamentId}, {
    cup: {},
    subcup: {sport:{}},
    edition: {},
    days: [{}],
    streamingInfo: {}
  });

  useEffect(() => {
    window.magicButtonProps = { 
      cupId: t.cup.id
    }

    var existing = document.querySelector("script[src='https://parts.cupmanager.net/magicbutton.js']");
    if (!existing) {
      const mb_script = document.createElement("script");
      mb_script.src = "https://parts.cupmanager.net/magicbutton.js";
      document.body.appendChild(mb_script);
    }
  }, []);

  const me = useLink(Me({optionalCupId: t.cup.id}), {cups:[{}]}, {suspense:false});
  if (me && !me.cups) {
    debugger;
  }
  const meCup = me?.cups.find(c => c.cupId === t.cup.id);



  /* Websocket stuff */
  const [websocketIsOpen, setWebsocketIsOpen] = useState(false);
  const webSocketParams = useMemo(() => ({
    tournamentId,
    clientId,
    appInstallUuid: me?.appInstallUUID // sent as cookie also
  }), [tournamentId, me?.appInstallUUID]);
  const ws = useWebSocket("resultsapi", webSocketParams);

  const wsCloseTimeoutHandle = useRef<number>();
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.hidden) {
        // Start queueing taints
        cache.block();
        // Tell ws to not reconnect, and after delay disconnect
        ws?.setIntentionalClose(true);
        wsCloseTimeoutHandle.current = window.setTimeout(() => {
          ws?.close(WS_INTENTIONAL_CLOSE, "Tab hidden");
          wsCloseTimeoutHandle.current = undefined;
        }, 30_000)
      } else {
        // Clear timeout handle
        if (wsCloseTimeoutHandle.current) {
          window.clearTimeout(wsCloseTimeoutHandle.current);
          wsCloseTimeoutHandle.current = undefined;
        }
        // Tell ws to reconnect. This will also set intentional close to false
        // console.log("ws isopen", ws?.isOpen());
        if (!ws?.isOpen()) {
          ws?.reconnect().then(() => {
            // Run all queued taints
            cache.unblock();
          });
        } else {
          // Run all queued taints
          cache.unblock();
        }
      }
    };
    document.addEventListener("visibilitychange", onVisibilityChange);    
    return () => document.removeEventListener("visibilitychange", onVisibilityChange);
  }, [ws]);

  useEffect(()=>{
    function line() {
      if (navigator.onLine) {
        ws?.reconnect();
      } else {
        ws?.close(WS_INTENTIONAL_CLOSE, "got onOffline event");
      }
    }
    window.addEventListener("offline", line);
    window.addEventListener("online", line);
    return () => {
      window.removeEventListener("offline", line);
      window.removeEventListener("online", line);
    }
  }, [ws]);
  
  const onMessage = useCallback((msg:any) => {
    let handled = false;
    if (window.magicButton_handleWebsocketMessage) {
      handled = window.magicButton_handleWebsocketMessage(msg);
    }
    if (msg.type === "clear") {
      var msg_clear = msg as Msg_Clear;
      if (msg_clear.key.taint.length > 0 && msg_clear.key.taint[0] !== "general") {
        client.cache.clear(msg_clear.key);
      }
      return handled || true;
    } else {
      return handled || false;
    }
  }, []);
  const onOpen = useCallback(() => {
    setWebsocketIsOpen(true);
    // console.log("ws open")
    client.setHooksShouldRefetchOnMount(false);
    client.doClearAll();
  }, []);
  const onClose = useCallback(() => {
    // console.log("ws close")
    setWebsocketIsOpen(false);
    client.setHooksShouldRefetchOnMount(true);
  }, []);
  useWebsocketHandler(ws, () => ({onMessage, onOpen, onClose}), [onMessage, onOpen, onClose]);


  const [selectedSort, setSelectedSort] = useState<'total'|'totalAssists'|'totalPoints'|'greenCards'>('total');
  
  const { pathname } = useLocation();

  const routes = {
        "/": <RootPage/>,
        "/latest": <RootPage/>,

        "/search": <SearchPage/>,
        "/search/:searchQuery": <SearchPage/>,
        
        "/categories": <CategoriesPage />,
        "/categories/:catId": <CategoriesPage />,
        "/categories/:catId/:stageId": <CategoriesPage/>,
        "/categories/:catId/:stageId/:rank": <StageTeamConferencePage/>,

        "/category/:catId": <CategoriesPage />,
        "/category/:catId/:stageId": <CategoriesPage/>,
        "/category/:catId/:stageId/:rank": <StageTeamConferencePage/>,

        "/nations": <NationsPage />,
        "/nations/:nationId": <NationsPage/>,
        "/nation/:nationId": <NationsPage/>,

        "/history": <HistoryPage />,

        "/clubs": <RegistrationsPage/>,
        "/clubs/:clubId": <RegistrationsPage />,
        
        "/club/:clubId": <RegistrationsPage/>,
        "/club/:clubId/accommodation": <ClubPageAccommodation/>,

        "/map": <MapPage/>,
        "/map/:locationId": <MapPage/>,
        "/map/:locationId/:arenaId": <MapPage/>,
          
        
        "/places": <PlacesPage/>,
        "/places/:arenaId": <PlacesPage/>,
        "/places/:arenaId/:dayId": <PlacesPage/>,

        /* "/ice": <DressHockey
          primary={'darkcyan'} 
          secondary={'tan'}
          code={'se'}
        />, */


        "/days/:id": <DayMatchesPage/>,
          
        "/arena/:arenaId": <PlacesPage/>,
        "/arena/:arenaId/:dayId": <PlacesPage/>,
        

        "/statistics": <StatisticsPage/>,
        
        "/statistics/winners": <WinnersPage/>,
        "/statistics/winners/:catId": <WinnersCategoryPage/>,
        "/statistics/finals": <StatisticsFinalsPage/>,

        "/statistics/matches/": <StatisticsMatchesPage/>,
        "/statistics/players": <TopPlayersStatisticsPage 
          selectedSort={selectedSort} 
          setSelectedSort={(val) => setSelectedSort(val)}
         />,
        "/statistics/players/:catId": <TopPlayersCategoryStatisticsPage
          selectedSort={selectedSort} 
          setSelectedSort={(val) => setSelectedSort(val)} 
        />,
        
        "/referees": <RefereesPage/>,
        "/referee/:id": <RefereePage/>,
        "/refereeMatches/:id": <RefereePage/>,
        "/division/:id": <DivisionPage />,
        "/match/:id": <MatchPage/>,
        "/live/:id": <MatchPage/>,
        "/team/:id": <TeamPage/>,
        "/team/:id/timeline": <TeamPage timeline/>,
        "/streams": <StreamingPage/>,
        "/my-matches": <MyMatchesPage/>,
        "/games": <GamesPage/>
    };
    {/* TODO: <  /day/123/>,  /history */}

 
  return <>
    <div id="async-data-messages-portal" aria-live="assertive" className="visually_hidden"></div>

    <CurrentTournamentContext.Provider value={t}>
      <ColorProvider primary={t.cup.primaryColor} secondary={t.cup.secondaryColor}>
        <ErrorBoundary fallback={({error, errorInfo}) => <ErrorPage error={error} errorInfo={errorInfo} />}>
          <AdminBanner meCup={meCup} 
          noWebSockets_Element={!websocketIsOpen ? <div style={{fontSize: "var(--font-size-small)", display: 'flex', gap: '1rem', alignItems: 'center'}}>No connection - no realtime updates. <button style={{background: 'black', color: 'yellow', borderRadius: '100px', padding: '.3rem .7rem'}} onClick={(e) => {
                ws?.reconnect()
                const el = e.currentTarget;
                el.disabled = true;
                setTimeout(()=>{
                  el.disabled = false;
                }, 2000);
              }}>Retry</button></div> : undefined}>
            <main style={{position:"sticky", marginBottom: '4rem'}}>
              {navigationError && <ErrorModal navigationError={navigationError} setNavigationError={setNavigationError} />}
              
              
              <Routes>
                {Object.entries(routes).map(([path, component]) => <Route key={pathToKey(path)} path={path} element={
                  <PageErrorBoundary path={path} onError={onError}>
                    {component}
                  </PageErrorBoundary>
                } />)}
              </Routes>
            </main>
            </AdminBanner>
          </ErrorBoundary>
      </ColorProvider>
    </CurrentTournamentContext.Provider>
    <div id="portal"></div>
  </>
  
}

function PageErrorBoundary({onError, children, path} : {path:string, children?: JSX.Element, onError:  (error:ErrorType, errorInfo:any) => boolean}) {
  return <ErrorBoundary key={pathToKey(path)} onError={onError}  fallback={({error, errorInfo}) => <ErrorPage error={error} errorInfo={errorInfo}/>} >
    {children}
  </ErrorBoundary>
}


export default App;