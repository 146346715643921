import { useTranslations } from "cupman-utils";
import { EventStatistics$PeriodStat, EventStatistics$TeamStats, Match, MatchFeed$statistics } from "../../api/schema";
import ContentGrayContainer from "../ContentGrayContainer";
import ShotEfficiency from "./ShotEfficiency";
import styles from './matchStatistics.module.scss';
import SavesPercentage from "./SavesPercentage";
import SavesCount from "./SavesCount";
import GoalsCount from "./GoalsCount";
import PenaltiesMinutes from "./PenaltiesMinutes";
import PenaltiesCount from "./PenaltiesCount";
import { CSSProperties } from "react";
import classNames from "classnames";

type Props = {
  statistics: MatchFeed$statistics | EventStatistics$PeriodStat,
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
  registerShots: boolean,
  statisticsOnlyTotal: {
    home: EventStatistics$TeamStats;
    away: EventStatistics$TeamStats;
  }
}

// import styles from './matchStatistics.module.scss';

export default function IcehockeyStatistics ({statistics, teamColors, registerShots, statisticsOnlyTotal} : Props) {

  const T = useTranslations('cmresults');

  const hasGoalkeepers = registerShots;

  return <>
    {hasGoalkeepers && <>
      <h3 className={classNames(styles.statistics_group_header, 'custom-font')}>
        {T('Skott & mål')}
      </h3>
      <ContentGrayContainer className={styles.gray_container}>
        {hasGoalkeepers && <ShotEfficiency statistics={statistics} teamColors={teamColors} />}
        <GoalsCount statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
      </ContentGrayContainer>
      {hasGoalkeepers && <>
        <h3 className={classNames(styles.statistics_group_header, 'custom-font')}>
          {T('räddningar')}
        </h3>
        <ContentGrayContainer className={styles.gray_container}>
          <SavesCount statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
          <SavesPercentage statistics={statistics} teamColors={teamColors} />
        </ContentGrayContainer>
      </>}
    </>}
    
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')}>
      {T('Utvisningar')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <PenaltiesMinutes statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
      <PenaltiesCount statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
    </ContentGrayContainer>
  </>
}