import { useTranslations } from "cupman-utils";
import { useDivision, Division } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import Games from "../../components/Game/Games";
import Conference from "../../components/Conference/Conference";
import Playoff from "../../components/Conference/Playoff";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader/PageHeader";
import styles from './DivisionPage.module.scss';
import TopScrollShadow from "../../components/TopScrollShadow/TopScrollShadow";
import classNames from "classnames";
import PrevNextStages from "../../components/PrevNextStages/PrevNextStages";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import PendingPage from "../../components/Pending/PendingPage";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import DivisionPageDivision from "./DivisionPageDivision";
import { useState } from "react";
import ConferenceRounds from "../../components/Conference/ConferenceRounds";


const mobile = window.matchMedia('(hover: none)').matches;

export default function DivisionPage () {

  const params = useParams<{id:string}>();
  const divisionId = parseInt(params.id || '0');

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();

  const admin = useAdmin();

  const division = useDivision({id:divisionId}, Division.fragment({
    ___on_Conference: {
      pseudoTeams: [{}],
      table: {
        rows: [{
          team: {
            club: {
              nation: {}
            }
          }
        }],
      },
      extraMatches: [{
        matches:[Games.GameFragment]
      }],
    },
    
    
    finished: {},
    stage: {
      nextStages: [{}],
      previousStages: [{}],
      stageTeamConferences:[{}]
    },
    category: {},
    matches: [Games.GameFragment]
  }, Playoff.fragment).___on_Division);

  const lotPublished = division.stage.lotPublished || admin.overridePublished;
  const schedulePublished = division.stage.schedulePublished || admin.overridePublished;

  const [selectedRoundHref, setSelectedRoundHref] = useState<string|undefined>(undefined);

  return <div className={styles.content_wrapper}>
    <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load page header')} square/>}>
      <PageHeader 
        noExpand={division.__typename=="Playoff" && mobile}
        title={division.__typename=="Playoff" ? T('Ladder for X', T.select(division.name)) : T.select(division.name) || 'Groups'} 
        crumbs={[{
          title: T('Results'),
          url: ''
        },
        {
          title: division.category.shortName,
          url: `categories/${division.category.id}`
        },
        {
          title: division.stage.name,
          url: `categories/${division.category.id}/${division.stage.id}`
        }
      ]}
      >
        {(lotPublished && (division.stage.nextStages.length > 0 || division.stage.previousStages.length > 0)) && <div className="max-75">
          <PrevNextStages 
            nextStages={division.stage.nextStages} 
            previousStages={division.stage.previousStages}
            stageTeamConferences={division.stage.stageTeamConferences} 
            catId={division.category.id}
          />
    </div> || undefined}
      </PageHeader>
    </ErrorBoundary>

    {lotPublished ? 
      <div className={styles.bottom_content}>
      <TopScrollShadow top={120}/>
      <DivisionPageDivision division={division}/>
      {t.league && division.__typename === "Conference" && <ConferenceRounds conference={division} selectedRoundHref={selectedRoundHref} setSelectedRoundHref={setSelectedRoundHref}/>}
      <ErrorBoundary fallback={({error, errorInfo}) => <div className='max-75' style={{marginTop: '3rem'}}>
        <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load the games')}/>
      </div>}>
        <Games published={schedulePublished} games={division.matches.filter(m => selectedRoundHref === undefined || m.round.href === selectedRoundHref)} title={T('Games')} order='asc' knownDivision />
        {"extraMatches" in division && <>
          {division.extraMatches.map((em) => <>
            <Games published={schedulePublished} games={em.matches} title={T.select(em.title)} order='asc' />
          </>)}
        </>}
      </ErrorBoundary>
      {(lotPublished && (division.stage.nextStages.length > 0 || division.stage.previousStages.length > 0)) && <div className="max-75">
          <PrevNextStages 
            nextStages={division.stage.nextStages} 
            previousStages={division.stage.previousStages}
            stageTeamConferences={division.stage.stageTeamConferences} 
            catId={division.category.id}
            inBody
          />
        </div> || undefined}
    </div> : <PendingPage text={T('The schedule for X in Y is not published yet', division.stage.name, division.category.name )} color="rgb(var(--clr-primary))"/>}
  </div>
}