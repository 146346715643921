import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { useState, ReactNode } from "react"
import ReactModal from "react-modal";
import styles from './ModalTrigger.module.scss';
import useBodyScrollLock from "./useBodyScrollLock";
import classNames from "classnames";

type props = {
  trigger: ReactNode | ( (onOpen: ()=>void) => ReactNode ),
  modal: ReactNode | ( (onClose: ()=>void) => ReactNode ),
  hideCloseBtn?: boolean,
  scrollYOnly?: boolean
}

export default function ModalTrigger ({trigger, modal, hideCloseBtn, scrollYOnly} : props) {
  const [open, setOpen] = useState(false);
  ReactModal.setAppElement('body');

  const onOpen = () => {
    setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
  }

  return <>
    {typeof trigger === "function" ? trigger(onOpen) : trigger}
    <Modal isOpen={open} onClose={onClose} hideCloseBtn={hideCloseBtn} scrollYOnly={scrollYOnly}>{modal}</Modal>
  </>
}

// ***************************************** MODAL

type modalProps = {
  children: ReactNode | ( (onClose:()=>void) => ReactNode ),
  isOpen: boolean,
  onClose: () => void, 
  hideCloseBtn?: boolean,
  scrollYOnly?: boolean
}

const Modal = ({children, isOpen, onClose, hideCloseBtn, scrollYOnly} : modalProps) => {

const _showCloseBtn = hideCloseBtn ? false : true;

useBodyScrollLock({hide: isOpen, lock: isOpen});

const T = useTranslations('cmresults')

  return <ReactModal
    isOpen={isOpen} 
    closeTimeoutMS={300}
    onRequestClose={onClose}
    className={classNames(styles.modal_content, scrollYOnly && styles.overflow_y_only)}
    overlayClassName={styles.modal_overlay}
    style={{
      overlay: {}, 
      content: {'--team-bg' : 'white', '--team-color' : 'black'} as any
    }}
  >
    {_showCloseBtn && <button 
      aria-label={T('Close modal')} 
      onClick={() => onClose()}
      className={styles.btn_close}
    >
      <FontAwesomeIcon icon={faTimes}/>
    </button>}
    {typeof children === "function" ? children(onClose) : children}
  </ReactModal>
}