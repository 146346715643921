import {createContext} from 'react'

export const FavoritesContext = createContext<{
  favorites:number[],
  setFavorite: (teamId:number) => void,
  removeAll?: () => void
}>({
  favorites:[], 
  setFavorite: (teamId:number) => {},
  removeAll: () => {}
})