import { useLink } from "granular"
import { Category, Sport$SportTypes, Team } from "../../../api/schema"
import BasketBallStatistics from "./BasketballStatistics"
import FootballStatistics from "./FootballStatistics"
import IcehockeyStatistics from "./IcehockeyStatistics"
import OtherStatisticsTeam from "./OtherStatisticsTeam"

type props = {
  team: Team,
  sport: Sport$SportTypes,
  category: Category
}

export default function TeamStatisticsTotalForCup ({team: _team, sport, category} : props) {

  const team = useLink(_team, {
    statistics: {},
    shirt: {}
  })

  const colors = team.shirt 
    ? team.shirt 
    : {
      color1: '#000',
      color2: '#BCBCBC',
      color3: '#515251',
      anti: '#fff'
    }

  return <>
    <div className="max-50" style={{textAlign: 'center'}}>
      {sport === 'basketball' && <BasketBallStatistics 
        statistics={team.statistics} 
        teamColors={colors}
        settings= {{
          assists: category.registerAssists,
          showResults: !category.hideMatchResults
        }}
      />}
      {sport === 'football' && <FootballStatistics 
        statistics={team.statistics} teamColors={colors} 
        settings= {{
          greenCards: category.registerGreenCards,
          yellowCards: category.registerYellowCards,
          redCards: category.registerRedCards,
          showResults: !category.hideMatchResults,
          assists: category.registerAssists
        }} 
      />}
      {sport === 'icehockey' && <IcehockeyStatistics 
        statistics={team.statistics} 
        teamColors={colors} 
        settings= {{
          assists: category.registerAssists,
          shots: category.registerShots,
          showResults: !category.hideMatchResults
        }}
      />}
      {sport !== 'icehockey' && sport !== 'basketball' && sport !== 'football' && <OtherStatisticsTeam      
        statistics={team.statistics} 
        teamColors={colors} 
        settings= {{
          greenCards: category.registerGreenCards,
          yellowCards: category.registerYellowCards,
          redCards: category.registerRedCards,
          assists: category.registerAssists,
          shots: category.registerShots,
          showResults: !category.hideMatchResults
        }} 
      />}
    </div>
  </>
}