import { useTranslations } from "cupman-utils";
import { useParams } from "react-router-dom"
import { useNation } from "../../api/schema";

export default function NationsPage () {

  const T = useTranslations('cmresults');
  const params = useParams();

  return <div className="max-75">
    {params.nationId && <Nation nationId={params.nationId} />}
    {!params.nationId && <>
    <h1>{T('Nations')}</h1>
    </>}
  </div>
}




function Nation ({nationId: _nationId} : {nationId: string}) {

  const T = useTranslations('cmresults');
  const id = parseInt(_nationId);
  const nation = useNation({id}, {})

  return <>
    <h1>{T.select(nation.name)}</h1>
  </>
}