
import { faCircleInfo, faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { faBug } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from './ErrorPage.module.scss';

type props = {
  error: Error & {retry?:()=>void},
  errorInfo:any
}

export default function ErrorPage ({error, errorInfo} : props) {

  return <div className={styles.wrapper}>
      <div className={classNames('max-50', styles.content_wrapper)}>
        <h1>Could not load the page. Try again.</h1>
        <div className={styles.tools}>
        {/*showSearch && <SearchBar up white noMutation />*/}

        <div className={styles.buttons}>
          <button onClick={() => window.location.href = window.location.href.split('#')[0]} className={styles.retry}><FontAwesomeIcon icon={faRotateRight} /> Reload</button>
          {error.retry && <button onClick={() => error.retry && error.retry()} className={styles.retry}><FontAwesomeIcon icon={faRotateRight} /> Retry</button>}
        </div>
      </div>
      <div className={classNames('max-50', styles.info_wrapper)}>
        <h2>
          <FontAwesomeIcon icon={faCircleInfo} /> <span>Error</span>
        </h2>
        {error.message}
      </div>
    </div>
  </div>
}