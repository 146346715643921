import { Team$statistics } from "../../api/schema"
import ContentGrayContainer from "../ContentGrayContainer"
import styles from './matchStatistics.module.scss';
import { useTranslations } from "cupman-utils"
import GoalsCount from "./GoalsCount";
import CardsCount from "./CardsCount";
import WonMatches from "./WonMatches";
import TiedMatches from "./TiedMatches";
import LostMatches from "./LostMatches";
import classNames from "classnames";
import ConcededGoals from "./ConcededGoals";

type Props = {
  statistics: {home: Team$statistics, away: Team$statistics},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
  registerGreenCards: boolean,
  registerDisciplinaryCards: boolean
}

export default function FootballStatisticsTotal ({statistics, teamColors, registerDisciplinaryCards, registerGreenCards} : Props) {

  const T = useTranslations('cmresults');
  
  return <>
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Matcher')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <WonMatches statistics={statistics} teamColors={teamColors} />
      <TiedMatches statistics={statistics} teamColors={teamColors} />
      <LostMatches statistics={statistics} teamColors={teamColors} />
    </ContentGrayContainer>
    
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Mål under cupen')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <GoalsCount statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
      <ConcededGoals statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
    </ContentGrayContainer>

    {(registerDisciplinaryCards || registerGreenCards) && <>
      <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
        {T('Kort under cupen')}
      </h3>
      <ContentGrayContainer className={styles.gray_container}>
        {registerGreenCards && <>
          <CardsCount statistics={statistics} teamColors={teamColors} color='green' />
        </>}
        {registerDisciplinaryCards && <>
          <CardsCount statistics={statistics} teamColors={teamColors} color='yellow' />
          <CardsCount statistics={statistics} teamColors={teamColors} color='red' />
        </>}
      </ContentGrayContainer>
    </>}
  </>
}