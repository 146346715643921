import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { type } from "os";
import ReactDOM from "react-dom";
import { ErrorType } from "./ErrorBoundary";
import styles from './ErrorModal.module.scss';

type props = {
  navigationError?: {
    error: ErrorType;
    errorInfo: any;
  }, 
  setNavigationError: React.Dispatch<React.SetStateAction<{
    error: ErrorType;
    errorInfo: any;
  } | undefined>>
}


export default function ErrorModal ({navigationError, setNavigationError} : props) {

  console.log({error: navigationError});

  const T = useTranslations('cmresults');

  const portal = document.getElementById('portal');

  if (!portal) return null;

  return ReactDOM.createPortal(
    
    <>
      <div className={styles.error_modal} style={{/*height*/}}>
        <FontAwesomeIcon icon={faExclamationCircle} className={styles.icon} />
        <p>
          {T('Could not load the page you wanted. Try again.')}
        </p>
        <button autoFocus className="btn" onClick={() => setNavigationError(undefined)}>{T('Close')}</button>
      </div>
    </>,
    portal)
}