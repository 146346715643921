import styles from './TeamPageStatistics.module.scss';
import HorizontalbarSingle from "./HorizontalbarSingle";
import classNames from 'classnames';

type Props = {
  color: string,
  label: string,
  value: number
  valueLabel: string,
  extraLabel?: string
}

export default function LabeledHorizontalBarsSingle ({color, value, label, valueLabel, extraLabel} : Props) {


  return <div>
    <h4 className={classNames(styles.single_bar_header, 'custom-font')}>
      <span>{label}</span>
      <span className={styles.value_label}>{valueLabel}</span>
      {extraLabel && <span className={styles.extra_label}>{extraLabel}</span>}
    </h4>
    <HorizontalbarSingle color={color} value={value} />
  </div>
}