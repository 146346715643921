import { useTranslations } from "cupman-utils";
import { Match } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCirclePlay, faClock } from "@fortawesome/pro-light-svg-icons";
import styles from './VideoListLink.module.scss';
import { format } from "date-fns";
import classNames from "classnames";
import ActiveCountdown from "../Countdown/ActiveCountdown";
import Flag from "../Flag/Flag";
import { useFormatDate } from "cupman-utils/lib/LangContext";

type Team = {
  team: {
    club: {
      nation: {}
    }
  }
}

type props = {
  game: Match<{
    video: {},
    home: Team,
    away: Team,
  }>,
  dark?: boolean
}

export default function VideoListLink({game, dark} : props) {
  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const sport = t.subcup.sport.type;
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const thumbUrl = 'https://static.cupmanager.net/images/generic-sport-images400/'+ sport.toLowerCase() +'.jpg';

  return <Link to={`/match/${game.id}`} className={styles.video_slider_container} key={game.id}>
  <div className={styles.player_outer_container}>
    <div className={styles.player}>
      <div className={styles.video_overlay}>
        {game.start < Date.now()
          ? <span className={styles.play}>
            <FontAwesomeIcon icon={faCirclePlay} className={styles.play_icon} />
            {T("Watch live!")}
          </span>
          : <span>
              <ActiveCountdown to={game.start} />
            </span>}
      </div>

      <img src={thumbUrl} alt={`${T.select(game.home.name)} vs ${T.select(game.away.name)}`} />

    </div>

    <div className={classNames(styles.video_info, dark && styles.dark)}>
      <div className={styles.team}>
        <Flag code={game.home.team?.club.nation.code} alt={T.select(game.home.team ? game.home.team.club.nation.code : '')} /> <span>{T.select(game.home.name)}</span>
      </div>
      <div className={styles.team}>
        <Flag code={game.away.team?.club.nation.code} alt={T.select(game.away.team ? game.away.team.club.nation.code : '')} /> <span>{T.select(game.away.name)}</span>
      </div>
      <div className={styles.video_meta}>
        <span>
          <FontAwesomeIcon icon={faCalendar} /> {formatDate(game.start, 'dd/MM - yy')} 
        </span>
        <span>
          <FontAwesomeIcon icon={faClock} /> {formatDate(game.start, 'HH:mm')}
        </span>
      </div>
    </div>
    </div>
  </Link>
}