import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Shortcuts.module.scss';

type props = {
  _width?: 'max-50' | 'max-75',
  links: ({
    to: string,
    title: string,
    icon?: IconDefinition
  }|boolean)[]
}

export default function Shortcuts ({_width, links} : props ) {

  const T = useTranslations('cmresults')

  const width = _width ? _width : 'max-50';
  
  return <div className={styles.outer_container}>
    <h2 className="visually_hidden">
      {T('Shortcuts')}
    </h2>
    <div className={width}>
      <div className={styles.shortcuts_container}>
        {links.map((link, i) => {
          if( typeof(link) === "boolean" ){
            return <React.Fragment key={i}></React.Fragment>
          }
          const extUrl = link.to.indexOf('http') > 0;

          const content = <span className={styles.content}>
            {link.icon && <FontAwesomeIcon icon={link.icon} className={styles.icon} />}
            <span className={styles.title}>{link.title}</span>
          </span>
          
          return <React.Fragment key={i}>

            {extUrl 
              ? <a href={link.to} className={styles.shortcut}>
              {content}
            </a>
              : <Link to={link.to} className={styles.shortcut}>
              {content}
            </Link>
            }

            </React.Fragment>
          })
        }
      </div>
    </div>
  </div>
}