import { faBurger, faHouse, faRoute } from "@fortawesome/pro-light-svg-icons";
import { faBurgerCheese, faCalendar, faClock, faEggFried, faForkKnife, faMenorah, faSandwich } from "@fortawesome/pro-regular-svg-icons";
import { faCircleInfo, faLocationDot, faMapMarked, faMarker } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { ContextType } from "react";
import { Team$accommodation, Team$TeamAccommodationMealSchedule, Tournament } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { getColorString, useColors } from "../../colors";
import { capitalize } from "../../utils";
import { AdminContext, useAdmin } from "../AdminBanner/AdminBanner";
import LinkButton from "../Button/LinkButton";
import styles from './TeamAccomodation.module.scss';

TeamAccommodation.fragment = {
  lodgings: [{location:{persons:[{}]}}]
};
TeamAccommodation.shouldShow = (accommodation: Team$accommodation<typeof TeamAccommodation.fragment>, t:Tournament, admin:ContextType<typeof AdminContext>) => (t.lodgingPublished||admin.overridePublished);

export default function TeamAccommodation({accommodation, timelineLinkId} : {accommodation:Team$accommodation<typeof TeamAccommodation.fragment>, timelineLinkId?: number}) {
  const T = useTranslations("cmresults");

  const t = useCurrentTournament();
  const admin = useAdmin();

  const accommodationPublished = t.foodPublished || admin.overridePublished;
  const dormsPublished = t.dormsPublished || admin.overridePublished;
  const mapPublished = t.mapPublished  || admin.overridePublished;
  

  return <>
    <div className={styles.stay_eat_title}>{accommodation.stayEatTitle}.</div>

    {accommodation.lodgings && accommodation.lodgings.filter(l => (dormsPublished && accommodation.assignments[l.id]) || l.location.persons.length > 0).map(l => {
      return <div className={styles.lodging_info}>
        <h4 className={styles.lodging_title}>{T('X information:',l.name)}</h4>
        {(dormsPublished && accommodation.assignments[l.id]) && <><div>{T('Rooms: ')}
          {accommodation.assignments[l.id].dorms.map(d => d.count + ' x ' + d.name + ' (' + d.roomNames + ')').join(',')}  
        </div></>}
        {l.location.persons.length > 0 && <div>
          <div className={styles.lodging_subtitle}>{T('Contacts:')}</div>
          {l.location.persons.map(p => {
            return <div>{p.title}: {p.name}, {p.phone}</div>
          })}
        </div>}
      </div>
    })}

    {/*dormsPublished && <div>
      {accommodation.lodgings.map(l => {
        return <>
        {accommodation.assignments[l.id] ? <div>{T('Rooms at X: ', l.name)}
          {accommodation.assignments[l.id].dorms.map(d => d.count + ' x ' + d.name + ' (' + d.roomNames + ')').join(',')}  
             
        </div>:<></>}
     </>})}

      </div>*/}

    {(mapPublished || timelineLinkId || true ) && <div className={classNames(styles.buttonbar_outer, "no-print")}>
        <div className={styles.buttonbar}>
        
            {timelineLinkId && <LinkButton size="lg" url={`/team/${timelineLinkId}/timeline`} >
                    <FontAwesomeIcon icon={faRoute} />
                    <span>{T('Show team timeline')}</span>
                  </LinkButton>}
           
            {accommodation.lodgings.map(l => {
              return <LinkButton size="lg" url={`/map/${l.locationId}`} >
                <FontAwesomeIcon icon={faHouse} />
                <span>{l.name}</span>
              
              </LinkButton>
            })}

            {accommodationPublished && accommodation.restaurants.map(r => {
              return <LinkButton size="lg" url={`/map/${r.locationId}`} >
                <FontAwesomeIcon icon={faForkKnife} />
                <span>{r.name}</span>
              </LinkButton>
            })}

          </div>
        </div>}
  
    
    {accommodationPublished && <div>
      {accommodation.mealSchedulesForLodging && <div>
        {accommodation.mealSchedulesForLodging.map(mealSchedule => <div>
          {accommodation.mealSchedulesForLodging.length > 1 && 
            <h4 className={styles.meal_schedule_title}>
              {mealSchedule.title === "Food" ? T('Meal schedule for extra food orders:')
                   :T('Meal schedule for people living at X:',mealSchedule.title)}
            </h4>}
          
          <MealSchedule oneSchedule={accommodation.mealSchedulesForLodging.length == 1} mealSchedule={mealSchedule} />
        </div>)}
      </div>}
    </div>}

  </>
}

type iconProps = {
  mobile: boolean
}



function MealSchedule({mealSchedule, oneSchedule} : {mealSchedule: Team$TeamAccommodationMealSchedule, oneSchedule: boolean}) {
  const T = useTranslations("cmresults");
  const t = useCurrentTournament();
  const D = useFormatDate(t.cup.timeZoneId);


  const onePlaceToEat = oneSchedule && [...new Set(mealSchedule.days.map((day) => day.mealTimes).flat().reduce((a, b) => {
    if(b) {
      return [
        ...a,
        b.onlyRestaurant.name
      ]
    }
    return [
      ...a
    ]
  }, [] as string[] || []))].length < 2;


  const colors = useColors();

  const getMealIcon = (i:number, settings?: iconProps) => {

    const indexOffset = mealSchedule.meals.length === 1
      ? i + 2
      : mealSchedule.meals.length === 2
        ? i + 1
        : i;

    const wrap = (icon: JSX.Element) => <div className={classNames(styles.icon_wrapper, i === 0 && styles.breakfast, i === 1 && styles.lunch, settings && settings.mobile && styles.mobile)}>
      {icon}
    </div>
  
    if(indexOffset === 0) {
      return wrap(<FontAwesomeIcon icon={faEggFried} />);
    }
    if (indexOffset === 1) {
      return wrap(<FontAwesomeIcon icon={faSandwich} />);
    }
  
    return wrap(<FontAwesomeIcon icon={faForkKnife} />);
  }
  
  const toTimeString = (d:number) => {
    return `${Math.floor(d/3600000)}`.padStart(2,'0') + ":" + `${Math.floor((d/60000) % 60)}`.padStart(2,'0')
  }

  return <>
  
  <div className={styles.meal_schedule_grid} style={{
      '--grid-length' : mealSchedule.meals.length + 1,
      '--clr-breakfast-light' : getColorString(colors.contrast.complements[2], .1),
      '--clr-lunch-light' : getColorString(colors.contrast.complements[1], .1),
      '--clr-dinner-light' : getColorString(colors.contrast.complements[3], .1),
      '--clr-breakfast' : getColorString(colors.contrast.complements[2]),
      '--clr-lunch' : getColorString(colors.contrast.complements[1]),
      '--clr-dinner' : getColorString(colors.contrast.complements[3])
    } as any}>
    <div className={classNames(styles.row_wrapper, styles.row_headers)}>
      <div></div>
      {mealSchedule.meals.map((meal, i) => <div className={styles.meal_name}>
        {getMealIcon(i)} <span>{T.select(meal.name)}</span>
      </div>)}
    </div>
    {mealSchedule.days.map((day, i) => <div className={styles.row_wrapper}>
      {i !== 0 && <div className={styles.desktop_separator}></div>}
      {i !== 0 && <div className={styles.separator}></div>}
      <div className={styles.event_wrapper}>
        <h3 className={styles.date}>
          <FontAwesomeIcon icon={faCalendar} /> {capitalize(D(day.date, 'EEE. dd/MM'))}
        </h3>
        <div className={styles.inner_grid}>
          {day.mealTimes.map((mt, i) => <div className={styles.meal_time}>
            {mt && <>
              <span className={styles.mobile_header}>
               <>{getMealIcon(i, {mobile: true})}</> <>{T.select(mealSchedule.meals[i].name)}{!onePlaceToEat && <>, {mt.onlyRestaurant.name}</>}</>
              </span> {toTimeString(mt.dinnerTime)} - {toTimeString(mt.dinnerEndTime)} <br />{!onePlaceToEat && <span className={styles.desktop_location}>{mt.onlyRestaurant.name}</span>}
            </>}
          </div>)}
        </div>
      </div>
    </div>)}
  </div>
  
  </>
}