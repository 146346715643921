import { useTranslations } from "cupman-utils";
import { roundToOneDecimal } from "../../../utils";
import { Team$statistics } from "../../../api/schema";
import { RawRgb } from "./PointsWonLostBar";
import { getColorString } from "../../../colors";
import LabeledHorizontalBarsSingle from "./LabeledHorizontalBarSingle";
type Props = {
  statistics: Team$statistics,
  colors: {
    positive: RawRgb,
    negative: RawRgb
  }
}

export default function SavesPercentage ({statistics, colors} : Props) {

  const T = useTranslations('cmresults');
  const savesAndLosts = statistics.lost + statistics.saves;

  return <div>
    <LabeledHorizontalBarsSingle value={statistics.saves / savesAndLosts} color={getColorString(colors.positive)} label={T('Räddnings %') } valueLabel={`${roundToOneDecimal(statistics.lost / savesAndLosts)}%`} extraLabel={`(${statistics.lost} / ${savesAndLosts})`} />
  </div>
}

