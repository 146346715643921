import CustomIconFa from "../CustomIconFa/CustomIconFa";

type props = {
  icon: string,
  fontWeight?: 'thin' | 'regular' | 'bold',
  className?: string
}

export default function GameFieldIcon ({icon:initIcon, fontWeight} : props) {

  var icon;
  

  if (
    initIcon === 'football' || 
    initIcon === 'floorball' || 
    initIcon === 'handball' || 
    initIcon === 'basketball' || 
    initIcon === 'icehockey' || 
    initIcon === 'volleyball' || 
    initIcon === 'tennis' || 
    initIcon === 'badminton'
  ) {
    icon = `field-${initIcon}`;
  } else {
    icon = 'field-other'
  }

  return (
    <>
    <CustomIconFa icon={icon} fontWeight={fontWeight} />
    </>
  )
}