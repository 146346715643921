import { faArrowRight, faMap, faMars, faTrophy, faUsers, faVenus, faVenusMars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular"
import React from "react";
import { useCurrentTournament } from "../../App"
import LinkButton from "../../components/Button/LinkButton";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import PageHeader from "../../components/PageHeader/PageHeader";
import TopGoalsPlayerAwardContainer from "../../components/WinnersAwardContainer/TopGoalsPlayerAwardContainer";
import styles from '../WinnersPage/WinnersPage.module.scss';
import { groupBy } from "../../utils";
import Select from "../../components/Select/Select";
import classNames from "classnames";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import { Player$PlayerStatistics } from "../../api/schema";

type props = {
  selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards',
  setSelectedSort: (val: 'total'|'totalAssists'|'totalPoints'|'greenCards') => void
}

export default function TopPlayersStatisticsPage ({selectedSort, setSelectedSort} : props) {

  const T = useTranslations('cmresults');

  const t = useLink(useCurrentTournament(), {
    lotCategories: [{
      topPlayers: [
        TopGoalsPlayerAwardContainer.Fragment
      ]
    }],
    finals: [],
    cup: {}
  })

  const shortedTopPlayersCategories = t.lotCategories.map(cat => {
    return {
      ...cat,
      topPlayers: cat.topPlayers.map(player => {
        return {
          ...player,
          stats: {
            ...player.stats,
            total: player.stats.totalPoints + player.stats.totalAssists
          }
        }
      }).sort((a,b) => b.stats[selectedSort] - a.stats[selectedSort]).slice(0, 1)
    }
  }).sort((a,b) => {
    if(a.gender === b.gender) {
      return a.age - b.age
    }
    return b.gender.localeCompare(a.gender)
  });

  const categoriesGrouped = groupBy(shortedTopPlayersCategories, (cat => cat.gender));

  const admin = useAdmin();
  const schedulePublished = t.schedulePublished || admin.overridePublished;

  const getGenderHeader = (gender: string) => {
    if(gender === 'men') {
      return <>
        <FontAwesomeIcon icon={faMars} /> {T('Men')}
      </>
    } else if(gender === 'women') {
      return <>
        <FontAwesomeIcon icon={faVenus} /> {T('Women')}
      </>
    } else if (gender === "mixed") {
      return <>
        <FontAwesomeIcon icon={faVenusMars} /> {T('Mixed')}
      </>
    } else if (gender === "none") {
      return <>
        <FontAwesomeIcon icon={faArrowRight} /> {T('Categories')}
      </>
    }
  }

  const points = (p:Player$PlayerStatistics,selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards'  ) => {
    if( selectedSort == "total" ){
      return [p.totalPoints + p.totalAssists, p.totalPoints];
    } else if( selectedSort == "totalAssists"){
      return [p.totalAssists, p.totalPoints];
    } else if( selectedSort == "greenCards"){
      return [p.greenCards, p.totalPoints];
    } else {
      return [p.totalPoints, p.totalAssists];
    }
  }

  const cmp = (s1:Player$PlayerStatistics,s2:Player$PlayerStatistics,selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards') => {
    const p1 = points(s1,selectedSort);
    const p2 = points(s2,selectedSort);

    if( p1[0] === p2[0] ){
      return p2[1] - p1[1];
    } else {
      return p2[0] - p1[0];
    }
  }

  const includePlayer = (p:Player$PlayerStatistics, selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards') => {
    if(selectedSort === 'total') {
      return p.totalPoints + p.totalAssists > 0
    } else if(selectedSort === 'totalAssists') {
      return p.totalAssists > 0
    } else if(selectedSort === 'greenCards') {
      return p.greenCards > 0
    } else {
      return p.totalPoints > 0
    }
  }


  return (t.cup.modules.playerStatistics || admin.overridePublished)
    ? <>
      <PageHeader
        title={T('Player statistics')}
        crumbs={[
          {
            title: T('Results'),
            url: ''
          }, {
            title: T('Statistics'),
            url: '/statistics'
          }
        ]}
      >
        {t.hasStatistics ? <Shortcuts links={[
        {
          to: '/statistics',
          title: T('Overview'),
          icon: faMap
        }, schedulePublished && {
          to: '/statistics/winners',
          title: T('Winners'),
          icon: faTrophy
        }, t.hasPlayerStatistics && {
            to: '/statistics/players',
            title: T('Players'),
            icon: faUsers
        }, t.schedulePublished && t.finals.length > 0 && {
          to: '/statistics/finals',
          title: T('Finals'),
          icon: faTrophy
        }/*, schedulePublished && {
          to: '/statistics/matches',
          title: T('Games'),
          icon: faTrophy
        }*/
      ]} />: undefined}
      </PageHeader>
      <div className={classNames("max-75", styles.wrapper)}>
        {['women','mixed','men', 'none'].filter(gender => gender in categoriesGrouped).map((gender, i) => {
          const categories = categoriesGrouped[gender];
          const hasGreenCards = categories.some(cat => cat.registerGreenCards);
          const registersAssists = categories.some(cat => cat.registerAssists);
          return <ContentContainer visibilityDesktop key={i}>
            <div className={styles.gender_header}>
              <h2 className={classNames(styles.content_header, "custom-font")}>
                {getGenderHeader(gender)}
              </h2>
              <Select 
                onChange={(e) => {
                  setSelectedSort(e as any)
                }} 
                options={[
                  {
                    name: registersAssists ? T('Total') : T("Goals"),
                    id: 'total'
                  },
                  ...(registersAssists ? [{
                    name: T('Goals'),
                    id: 'totalPoints'
                  },{
                    name: T('Assists'),
                    id: 'totalAssists'
                  }] : []),
                  ...(hasGreenCards ? [{
                    name: T('Green cards'),
                    id: 'greenCards'
                  }] : [])
                ]} 
                selectedId={selectedSort}
              />
            </div>
            <div className={styles.gender_wrapper}>
              {categories.map(cat => <React.Fragment key={cat.id}>
                <h3 className={classNames(styles.h3, "custom-font")}>
                  {cat.name}
                </h3>

                <ContentContainer dark noPadding>
                {cat.topPlayers.filter(p => includePlayer(p.stats, selectedSort)).sort((a,b) => cmp(a.stats,b.stats,selectedSort) ).map((player, i) => 
                  <TopGoalsPlayerAwardContainer player={player} rank={i + 1} key={i} selectedSort={selectedSort} registerAssists={registersAssists} />)}
                </ContentContainer>
                
                <div className={styles.btn_wrapper}>
                  <LinkButton inverted url={`/statistics/players/${cat.id}`} size="sm" className={styles.btn_category}>
                    {T('All scorers in X', cat.name)} <FontAwesomeIcon icon={faArrowRight} />
                  </LinkButton>
                </div>
              </React.Fragment>)}
            </div>
            </ ContentContainer>
        })}
      </div>
    </>
    : <></>
}