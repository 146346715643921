import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {
    home: {
      onePointers: number, 
      twoPointers: number, 
      threePointers: number
  }, away: {
    onePointers: number, 
    twoPointers: number, 
    threePointers: number
  }},
  statisticsTotal: {
    home: {
      onePointers: number, 
      twoPointers: number, 
      threePointers: number
  }, away: {
    onePointers: number, 
    twoPointers: number, 
    threePointers: number
  }},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
}

export default function ThreePointsCount ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const sumThreePointers = statisticsTotal.home.threePointers + statisticsTotal.away.threePointers;
  const sumOnePointers = statisticsTotal.home.onePointers + statisticsTotal.away.onePointers;
  const scale = Math.max(sumThreePointers, sumOnePointers)



  return <LabeledOpposingHorizontalBars
    label={T('3-poängare')}
    left={{
      value: statistics.home.threePointers / scale,
      color: teamColors.home,
      label: statistics.home.threePointers
    }}
    right={{
      value: statistics.away.threePointers / scale,
      color: teamColors.away,
      label: statistics.away.threePointers
    }}
  />
}