import { faHeart, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { Search$SearchClub, SearchClub$SearchTeam, useMe } from "../../api/schema";
import SearchGroupHeader from "./SearchGroupHeader";
import styles from './AutocompleteResults.module.scss';
import { useCurrentTournament } from "../../App";
import { useMutation } from "granular";
import HeartButton from "../ButtonHeart/HeartButton";

type clubProps = {
  club: Search$SearchClub<{
    nameClub: {},
    teams: [{
      team: {}
    }],
  }>,
  query?: string,
  setMomentQuery?: React.Dispatch<React.SetStateAction<string | undefined>>,
  searchRef?: React.RefObject<HTMLInputElement>,
  noMutation?: boolean
}

export function SearchClubResults ({club, setMomentQuery, query, searchRef, noMutation} : clubProps) {

  const teamsChoosen = club.teams.filter(team => team.chosen);
  const teamsOther = club.teams.filter(team => !team.chosen);
  
  const [clubExpanded, setClubExpanded] = useState(false);

  const T = useTranslations('cmresults');
  
  return <>
    <SearchGroupHeader type="clubs" title={club.name} />
    <div className={styles.content}>
      <ul>
        
        {teamsChoosen.map(team => {
          return <Suspense fallback={<>Laddar</>}>
            <AutoCompleteTeamRow team={team} searchRef={searchRef} noMutation={noMutation} />
          </Suspense>}
        )}
      </ul>

      <ul>
        {clubExpanded && teamsOther.length > 0 && <li className={styles.separator}></li>}

        {clubExpanded && teamsOther.map((team) => (
          <Suspense fallback={<>Laddar</>}>
            <AutoCompleteTeamRow team={team} searchRef={searchRef} noMutation={noMutation} />
          </Suspense>
        ))}
      </ul>

      {teamsOther.length > 0 && !clubExpanded && <button className={styles.show_more} onClick={() => {   
          setClubExpanded(true);
          setMomentQuery && setMomentQuery(query);
          searchRef && searchRef.current?.focus();
        }}>
          <span>
            <FontAwesomeIcon icon={faPlus} /> {T('Show all teams')}
          </span>
        </button>}
    </div>
  </>
}

function AutoCompleteTeamRow ({team, searchRef, noMutation} : {team: SearchClub$SearchTeam<{
    team: {};
  }>,
  searchRef?: React.RefObject<HTMLInputElement>,
  noMutation?: boolean
}) {


  const t = useCurrentTournament();
  const me = useMe({optionalCupId: t.cup.id},{
    teams: [{
      team: {}
    }]
  });
  const [addFavoriteTeam] = useMutation(me.addFavoriteTeam);
  const [removeFavoriteTeam] = useMutation(me.removeFavoriteTeam);
  const favoriteMarked = me.teams.some(t => t.team?.id === team.team.id);

  return <li key={team.team.id} className={styles.team_row}>
      {team.team.id && !noMutation && <button 
        className={styles.heart_wrapper}
        onClick={() => {
          /*console.log("Div clicked", {event})
          if (favoriteMarked) {
            team.team.id && removeFavoriteTeam(team.team.id).then(window.trigger_magicbutton_refresh);
          } else {
            team.team.id && addFavoriteTeam(team.team.id).then(window.trigger_magicbutton_refresh);
          }*/
        }} 
      >
          <HeartButton 
          size="sm" 
          //teamName={team.team.name.fullName}
          onChange={() => {
            //searchRef && searchRef.current?.focus();
            if (favoriteMarked) {
              team.team.id && removeFavoriteTeam(team.team.id).then(window.trigger_magicbutton_refresh);
            } else {
              team.team.id && addFavoriteTeam(team.team.id).then(window.trigger_magicbutton_refresh);
            }
          }} 
          likeUnlikeWhat={team.team.name.fullName}
          likeStatus={!!favoriteMarked}
          color='rgb(var(--clr-primary))' 
          className={styles.heart_button_inner} 
        />
      </button>}

      <Link to={`/team/${team.team.id}`} className={styles.link} onClick={() => document.body.classList.remove('search-focused')
      }>
        <span>{team.team.name.fullName}</span>
      </Link>
  </li>

}