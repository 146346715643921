import { useTranslations } from "cupman-utils";
import LabeledHorizontalBarMultiple from "./LabeledHorizontalBarMultiple";
import { Team$statistics } from "../../../api/schema";
import { roundToOneDecimal } from "../../../utils";
import { getColorString } from "../../../colors";
import { RawRgb } from "./PointsWonLostBar";

type Props = {
  statistics: Team$statistics,
  colors: {
    negative: RawRgb,
    positive: RawRgb
  }
}

export default function GoalsWonLostBar ({statistics, colors} : Props) {

  
  const T = useTranslations('cmresults');
const totalPoints = statistics.lost + statistics.goals;


  const bars = [
    {
      label: T('Gjorda'),
      value: statistics.goals / totalPoints,
      valueLabel: `${statistics.goals} (${roundToOneDecimal(statistics.goals / totalPoints)}%)`,
      color: getColorString(colors.positive)
    },
    {
      label: T('Insläppta'),
      value: statistics.lost / totalPoints,
      valueLabel: `${statistics.lost} (${roundToOneDecimal(statistics.lost / totalPoints)}%)`,
      color: getColorString(colors.negative)
    }
  ]

  return <div>
    <LabeledHorizontalBarMultiple bars={bars} />
  </div>
}