import { faNetworkWired } from "@fortawesome/pro-light-svg-icons"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { useTranslations } from "cupman-utils"
import { Link } from "react-router-dom"
import { Stage, StageTeamConference, useCategory } from "../../api/schema"
import { getColorString, useColors } from "../../colors"
import { useAdmin } from "../AdminBanner/AdminBanner"
import MiddleEllipsisSpan from "../MiddleEllipsisSpan/MIddleEllipsisSpan"
import styles from './PrevNextStages.module.scss';
import { useCurrentTournament } from "../../App"

type props = {
  nextStages?: Stage[],
  previousStages?: Stage[],
  stageTeamConferences?: StageTeamConference[]
  catId: number,
  inBody?: boolean,
}

export default function PrevNextStages ({nextStages, previousStages, stageTeamConferences, catId, inBody} : props) {
  const T = useTranslations('cmresults')

  const _colors = useColors();
  const colors = [_colors.contrast.primary, ..._colors.contrast.complements];

  const admin = useAdmin();

  const t = useCurrentTournament();
  const cat = useCategory({tournamentId: t.id, categoryId: catId}, {});

  const activeLists = {
    previous: previousStages && previousStages.length > 0,
    teamConferences: stageTeamConferences && !cat.hideConferenceResults && stageTeamConferences.length > 0,
    next: nextStages && nextStages.length > 0
  }

  
  return <div className={classNames(styles.stages,inBody && styles.in_body, "no-print")}>
    <h2>
      <FontAwesomeIcon icon={faNetworkWired}/> {previousStages && previousStages.length > 0 
        ? nextStages && nextStages.length > 0 ? 
          T('Previous and next stages')
        : T('Previous stages')
        : T('Next stages')
      }
    </h2>
    
    <div className={styles.stages_nav_wrapper}>
      {previousStages && previousStages.length > 0 && <>
        <div className={classNames(styles.previous_stages_container)}>
          {previousStages.map((stage,i) => 
            <Link 
              to={`/categories/${catId}/${stage.id}`} 
              key={stage.id} 
              style={
                {
                  '--clr-link-bg' : 'radial-gradient(rgb(var(--clr-primary-contrast)), rgb(var(--clr-gray-500)))'
                } as any
              } 
              className={classNames(styles.stage_link, !stage.lotPublished && !admin.overridePublished && styles.disabled)}
              
            >
              <FontAwesomeIcon icon={faArrowLeft} /> <MiddleEllipsisSpan inline text={stage.name} className={styles.stage_link_text}/>
            </Link>
          )}
        </div>
      </>
      }

      {activeLists.previous && activeLists.teamConferences && <div role="presentation"></div>}

      {stageTeamConferences && !cat.hideConferenceResults && stageTeamConferences.length > 0 && <div className={styles.team_conferences_container_outer}>
          <div className={classNames(styles.team_conferences_container)}>
          
          {stageTeamConferences.map((stc) => 
            <Link 
              to={`/categories/${catId}/${stc.stageId}/${stc.rank}`}
              key={stc.href} 
              style={
                {
                  '--clr-link-bg' : 'radial-gradient(rgb(var(--clr-primary-contrast)), rgb(var(--clr-gray-500)))'
                } as any
              } 
              className={styles.stage_link}
            >
              <MiddleEllipsisSpan inline text={T.select(stc.name)} className={styles.stage_link_text} />
            </Link>
          )}
        </div>
      </div>}

      {(activeLists.teamConferences || activeLists.previous) && activeLists.next && <div role="presentation"></div>}

      {nextStages && nextStages.length > 0 && <div className={classNames(styles.next_stages_container)}>
        {nextStages.map((stage,i) => 
          <Link 
            to={`/categories/${catId}/${stage.id}`}
            key={stage.id} 
            style={
              {
                '--clr-link-bg' : `radial-gradient(${getColorString(colors[i])}, rgb(var(--clr-gray-500)))`
              } as any
            } 
            className={classNames(styles.stage_link, !stage.lotPublished && !admin.overridePublished && styles.disabled)}
          >
            <MiddleEllipsisSpan inline text={stage.name} className={styles.stage_link_text} /> <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        )}
      </div>}


      {/*
      MOBILE VIEW
      */}

      {stageTeamConferences && !cat.hideConferenceResults && stageTeamConferences.length > 0 && <div className={styles.mobile_ranking_games}>
        <h3>{T('Ranking between groups')}</h3>
        <div className={classNames(styles.team_conferences_container)}>
          {stageTeamConferences.map((stc) => 
            <Link 
              to={`/categories/${catId}/${stc.stageId}/${stc.rank}`}
              key={stc.href} 
              style={
                {
                  '--clr-link-bg' : 'radial-gradient(rgb(var(--clr-primary-contrast)), rgb(var(--clr-gray-500)))'
                } as any
              } 
              className={styles.stage_link}
            >
              {T.select(stc.name)}
            </Link>
          )}
        </div>
      </div>}

      {/*
      / MOBILE VIEW
      */}

    </div>
  </div>
}