import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { MatchTimeout } from "../../api/schema";
import Flag from "../Flag/Flag";
import TimelineMinutes from "./TimelineMinutes";
import styles from './Timeout.module.scss';

export default function Timeout ({event, timeoutsAway, timeoutsHome, code, nationName} : {event: MatchTimeout, timeoutsAway: number[], timeoutsHome: number[], code?: string, nationName: Record<string, string> | undefined}) {

  const T = useTranslations('cmresults');
  const timeoutCount = event.side === 'home'
    ? timeoutsHome.indexOf(event.id) + 1
    : timeoutsAway.indexOf(event.id) + 1

  return <div className={classNames(styles.timeout_wrapper, event.side === 'away' && styles.away, 'both')}>
    <div className={styles.info}>
      <Flag code={code} alt={nationName ? T.select(nationName) : ''}borderColor="rgba(var(--clr-gray-400))" /> <span>{T('Timeout X', timeoutCount)}</span> 
    </div>
    <TimelineMinutes time={event.displayRelativeTime||event.relativeTime} side={event.side} type={event.__typename} />
    <div></div>
  </div>
}