import styles from './Loading.module.scss';

type props = {
  loadingThis?: string
}

export default function PageLoadingNoTranslation ({loadingThis} : props) {
  
  const loadingAnimation = 'https://static.cupmanager.net/images/loading.min.svg';

  return <div className={styles.large_loading_container}>

    <div className={styles.large_loading_inner_container}>
      <img src={loadingAnimation} alt="" className={styles.icon} />
      <span className={styles.info}>
        {loadingThis ? `Loading ${loadingThis}` : 'Loading'}
      </span>
    </div>

  </div>
}