import { useState, useEffect } from 'react';

export function useMediaQuery(query:string) {
  const media = window.matchMedia(query);
  const [matches, setMatches] = useState(media.matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    if (media.addEventListener) {
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    } else {
      media.addListener(listener);
      return () => media.removeListener(listener);
    }
  }, [matches, query]);

  return matches;
}

const breakpoints = {
  xs: '400px',
  xs2: '480px',
  sm: '576px',
  sm2: '640px', 
  md: '768px',
  lg: '992px',
  lg2: '1024px',
  xl: '1200px',
  xxl: '1400px'
}


export function useMinWidth(width:keyof typeof breakpoints){
  return useMediaQuery(`(min-width: ${breakpoints[width]})`);
}

