import React from "react";
import { Link } from "react-router-dom";
import { isTemplateSpan } from "typescript";
import { Me$MeTeam } from "./api/schema";
import { CupColorsSpec, getColorString } from "./colors";
import { MapMarker } from "./Map/MapTypes";
import { useTranslations } from "cupman-utils";

export function formatShirtNr (shirtNr : number) {
  return shirtNr < 10 
    ? '0' + shirtNr
    : shirtNr
}

export function truthy<T>(item: T|undefined): item is T {
  return !!item;
}

export const capitalize = (s:string|undefined) => {
  if(s) return s[0].toUpperCase() + s.slice(1)
  return s
};

export function groupBy<T>(items: T[], groupBy: (t:T) => string) {
  return items.reduce((map, t) => {
    const key = groupBy(t);
    const current = map[key] || [] as T[];
    return {
      ...map,
      [key] : [
        ...current,
        t
      ]
    }
  },{} as Record<string,T[]>);
}

export function unique<T>(items: T[], feature: (t:T) => any) {
  let indexed = {} as Record<string,T>;
  for (let item of items) {
    indexed[feature(item)] = item;
  }
  return Object.values(indexed);
}

export const setModalNoScroll = (open: boolean) => {
  if (open) document.body.classList.add('modal')
  else document.body.classList.remove('modal')
}

export const buttonStyle = (size:'sm' | 'lg' | 'md' | 'xl', inverted?: boolean) => {
  if(size) {
    if(size === 'sm') return inverted ? 'sm inverted' : 'sm'
    if(size === 'md') return inverted ? 'md inverted' : 'md'
    if(size === 'lg') return inverted ? 'lg inverted' : 'lg'
    if(size === 'xl') return inverted ? 'xl inverted' : 'xl'
  }
}

export const setFontStyleCharacterMap = (character?: number | string) => {
  const xsStyle = {
    fontSize: '.6rem'
  }
  const smStyle = {
    fontSize: '.8rem'
  }
  const mdStyle = {
    fontSize: '1rem'
  }

  if(typeof character === 'number') {
    if (character > 9 && character <= 99) return smStyle
    if (character > 99) return xsStyle
  }
    

  if(typeof character === 'string') {
    if (character.length > 1 && character.length < 3) return smStyle
    if (character.length > 2) return xsStyle
  }
  return mdStyle
}


export const renderLinks = (text:string|undefined, onClick?:(url:string)=>void) => {
  if(!text){
    return undefined;
  }
  
  const linkRegExp = new RegExp("\\[\\[(.+?)::(.+?)\\]\\]","g");
  const matches  = Array.from(text.matchAll(linkRegExp));
  const strings = text.split(linkRegExp);

  return <>
    {matches.map((m,i) => {
      const pre = strings[i*3];
      let url = m[1] as string;
      if (url.startsWith("/result")) {
        url = url.substring("/result".length);
      }
      const title = m[2];

      if(onClick) {
        return <React.Fragment key={i}>{pre}<a href="#" onClick={()=>onClick(url)}>{title}</a></React.Fragment>
      } else {
        const extUrl = url.indexOf('http') > -1;
        return <React.Fragment key={i}>{pre}{extUrl ? <a href={url}>{title}</a> : <Link to={url}>{title}</Link>}</React.Fragment>
      }
    })}
    {strings.slice(-1)}
  </>
}

export const getIcon = (category:string, color:string, ) => {
  if(category === 'arena') return <g>
    <g>
      <path style={{fill: color || 'white'}} d="M165.3,73.9c-8.1-9.6-28.6-2.8-42.9-1.3c-14.3,1.5-20.1,1.7-24.6,1.7c-4.5,0-10.3-0.2-24.6-1.7
        c-14.3-1.5-34.9-8.3-42.9,1.3c-6.8,8.1-1.6,17.8,10.2,24.2c3.6,7.1,9,20.8,9,38.9c0,9,19.3,12.5,33.5,13.8v-5.2
        c0-8.3,6.8-15.2,15.2-15.2h1.7c8.3,0,15.2,6.8,15.2,15.2v4.9c13.9-1.5,31-5,31-13.5c0-18.2,5.4-31.8,9-38.9
        C166.9,91.7,172.2,82.1,165.3,73.9z M54.2,80.8c1.3-7.1,13.7-5.1,24.8-4.3s18.8,0.6,18.8,0.6s7.7,0.3,18.8-0.6s23.5-2.8,24.8,4.3
        c1.3,7.1-12.8,16.6-43.6,16.6S52.9,87.9,54.2,80.8z"/>
    </g>
    <g>
      <path style={{fill: color || 'white'}} d="M166.8,62.9c-7.8,1.5-8.9,0.4-13.8,2.2c-0.6,0.2-1.3-0.2-1.3-0.9V49.8c0-0.5,0.4-1,0.9-0.9
        c2.5,0.1,5.5,0.8,9,2.5c8.2,4,13,3.7,14.7,3.3c1-0.2,1.8,1.1,1.1,1.9C172.9,61.6,167,62.8,166.8,62.9z"/>
    </g>
    <g>
      <path style={{fill: color || 'white'}} d="M20.2,56.5c-0.7-0.8,0-2.1,1.1-1.9C23,55,27.8,55.3,36,51.3c3.5-1.7,6.5-2.3,9-2.5c0.5,0,0.9,0.4,0.9,0.9
        v14.4c0,0.7-0.7,1.1-1.3,0.9c-4.9-1.7-5.9-0.7-13.8-2.2C30.7,62.8,24.8,61.6,20.2,56.5z"/>
    </g>
  </g>;

  else if(category === 'restaurant') return <g>
    <path style={{fill: color || 'white'}} d="M97.7,92.6c6.9-6.1,4.3-3.8,11.6-10.3c-5.1-8.7-4.1-18,3.9-26c5.2-5.2,22.7-17.6,23.7-18.3
        c3.9-2.8,9,2.8,5.9,6.3l-19.5,22.5c-0.3,0.4,0.7,1.4,1.1,1.1L147.7,49c3.6-2.9,9.1,2.4,6.1,6.1l-18.9,23.2
        c-0.3,0.4,0.7,1.4,1.1,1.1L158.5,60c3.5-3,9.1,2.1,6.3,6c-0.7,1-13.1,18.4-18.3,23.6c-8.2,8.2-17.6,8.8-26,3.9
        c-6.4,7.2-3.5,3.9-9.8,11.1l37.1,34.4c3.2,2.9,3.3,8,0.2,11.1l-7.7,7.7c-3.1,3.1-8.1,3-11-0.2c-4.6-5.4-48.1-56.7-50-58.9
        c-38.4,0-51.8-21.6-51.8-53.7c0-6.7,8-10.2,12.9-5.6L97.7,92.6z M45.4,138.9l33.1-29.3l13.6,16l-28.2,31.8
        c-2.9,3.3-8.1,3.5-11.2,0.3l-7.7-7.7C41.9,147,42.1,141.8,45.4,138.9z"/>
  </g>;

  else if(category === 'club') return <g>
    <path style={{fill: color || 'white'}} d="M164.5,60.3c0,62.7-38.5,97.6-62.8,107.8c-3.3,1.4-7.1,1.4-10.5,0C61,155.4,28.5,116.5,28.5,60.3
  c0-5.5,3.3-10.5,8.4-12.6L91.3,25c1.6-0.7,3.4-1,5.2-1c1.8,0,3.6,0.4,5.2,1l54.4,22.7C161.2,49.8,164.5,54.8,164.5,60.3z
    M150.9,60.3L96.5,37.6L42.1,60.3c0,48,26.8,83.7,54.4,95.2C123,144.4,150.9,109.5,150.9,60.3z M56,69.2l40.5-16.9V140
  C74.5,126.6,58.4,102.1,56,69.2z"/>
  </g>

  else if(category === 'lodging') return <g>
    <path style={{fill: color || 'white'}} d="M176.6,88.4c-0.1,1.1-0.5,2.1-1.1,2.9l-3,3.3c-0.8,0.9-2,1.5-3.3,1.5c-1.1,0-2.2-0.4-3-1.1l-7.4-6.7v61.2
  c0,6.1-5,11.1-11.1,11.1H45.4c-6.1,0-11.1-5-11.1-11.1V88.4l-7.4,6.7c-0.8,0.6-1.8,1.1-3,1.1c-1.3-0.1-2.5-0.6-3.3-1.5l-3-3.3
  c-0.6-0.8-1.1-1.8-1.1-2.9c0.1-1.3,0.6-2.5,1.5-3.3l71.2-63.9c1.7-1.5,5-2.8,7.4-2.8c2.3,0,5.6,1.3,7.4,2.8l14.9,13.3v-0.6
  c0-3.7,3-6.7,6.7-6.7h26.7c3.7,0,6.7,3,6.7,6.7v36.5l16.3,14.6C175.9,85.9,176.5,87.1,176.6,88.4z M145.4,76.4L96.5,32.5L47.6,76.4
  v70.9H61v-4.4c0-14.7,12-26.7,26.7-26.7h17.8c14.7,0,26.7,12,26.7,26.7v4.4h13.3V76.4z M96.5,71.7c9.8,0,17.8,8,17.8,17.8
  s-8,17.8-17.8,17.8s-17.8-8-17.8-17.8S86.7,71.7,96.5,71.7z M145.4,40.6h-13.3v5.9l13.3,12V40.6z"/>
  </g>

  else if(category === 'atm') return <g>
    <path style={{fill: color || 'white'}} d="M163.8,66.6H29.3V55.4c0-6.2,5-11.2,11.2-11.2h112.1c6.2,0,11.2,5,11.2,11.2V66.6z M29.3,96.5h134.5v41.1
  c0,6.2-5,11.2-11.2,11.2H40.5c-6.2,0-11.2-5-11.2-11.2V96.5z M44.2,131.1c0,1.5,1.3,2.8,2.8,2.8h16.8c1.5,0,2.8-1.3,2.8-2.8v-9.3
  c0-1.5-1.3-2.8-2.8-2.8H47c-1.5,0-2.8,1.3-2.8,2.8V131.1z M74.1,131.1c0,1.5,1.3,2.8,2.8,2.8h31.8c1.5,0,2.8-1.3,2.8-2.8v-9.3
  c0-1.5-1.3-2.8-2.8-2.8H76.9c-1.5,0-2.8,1.3-2.8,2.8V131.1z"/>
  </g>

  else if(category === 'parking') return <g>
      <path style={{fill: color || 'white'}} d="M159.3,47.2v98.7c0,7.4-6,13.5-13.5,13.5H47.2c-7.4,0-13.5-6-13.5-13.5V47.2c0-7.4,6-13.5,13.5-13.5h98.7
    C153.3,33.7,159.3,39.7,159.3,47.2z M127.9,87.6c0-14.8-12.1-26.9-26.9-26.9H74.1c-2.5,0-4.5,2-4.5,4.5v62.8c0,2.5,2,4.5,4.5,4.5h9
    c2.5,0,4.5-2,4.5-4.5v-13.5H101C115.8,114.5,127.9,102.4,127.9,87.6z M110,87.6c0,4.9-4,9-9,9H87.6V78.6H101
    C105.9,78.6,110,82.6,110,87.6z"/>
  </g>

  else if(category === 'airplane') return <g>
      <path style={{fill: color || 'white'}} d="M184.1,96.5c0,10.4-17.8,18.8-28.2,18.8h-33.6l-30.9,54c-0.8,1.5-2.4,2.4-4.1,2.4H68.2c-3.1,0-5.4-3-4.5-6
      L78,115.3H47.8l-12.7,16.9c-0.9,1.2-2.3,1.9-3.8,1.9H19.6c-3.1,0-5.3-2.9-4.6-5.8l9.3-31.8l-9.3-31.8c-0.7-3,1.5-5.8,4.6-5.8h11.7
      c1.5,0,2.9,0.7,3.8,1.9l12.7,16.9H78L63.6,27.3c-0.9-3,1.4-6,4.5-6h19.2c1.7,0,3.3,1,4.1,2.4l30.9,54h33.6
      C166.3,77.7,184.1,86.1,184.1,96.5z"/>
  </g>
  
  else if(category === 'busstop') return <g>
    <path style={{fill: color || 'white'}} d="M165.8,68.4V90c0,3.6-2.9,6.5-6.5,6.5h-2.2v45.1c0,3.5-2.6,6.9-6.9,6.9h-1.7v8.7c0,4.8-3.9,8.7-8.7,8.7h-8.7
  c-4.8,0-8.7-3.9-8.7-8.7v-8.7h-52v8.7c0,4.8-3.9,8.7-8.7,8.7h-8.7c-4.8,0-8.7-3.9-8.7-8.7v-8.7c-4.8,0-8.7-3.9-8.7-8.7V96.5h-2.2
  c-3.6,0-6.5-2.9-6.5-6.5V68.4c0-3.6,2.9-6.5,6.5-6.5h2.2v-13c0-12.1,26.9-21.7,60.7-21.7s60.7,9.5,60.7,21.7v13h2.2
  C162.9,61.9,165.8,64.8,165.8,68.4z M66.2,126.9c0-4.8-3.9-8.7-8.7-8.7c-4.8,0-8.7,3.9-8.7,8.7c0,4.8,3.9,8.7,8.7,8.7
  C62.3,135.5,66.2,131.6,66.2,126.9z M131.2,105.2c4.8,0,8.7-3.9,8.7-8.7V61.9c0-4.8-3.9-8.7-8.7-8.7H61.9c-4.8,0-8.7,3.9-8.7,8.7
  v34.7c0,4.8,3.9,8.7,8.7,8.7H131.2z M144.2,126.9c0-4.8-3.9-8.7-8.7-8.7s-8.7,3.9-8.7,8.7c0,4.8,3.9,8.7,8.7,8.7
  S144.2,131.6,144.2,126.9z"/>
  </g>

  else if(category === 'boat') return <g>
    <path style={{fill: color || 'white'}} d="M163.5,133.5c3,0,5.4,2.4,5.4,5.4v3.6c0,3-2.4,5.4-5.4,5.4c-13.8,0-24.3-4.7-32.4-13.4
  c-3.3,7.9-11,13.4-20.1,13.4H82c-9,0-16.8-5.6-20.1-13.4c-8.1,8.8-18.6,13.4-32.4,13.4c-3,0-5.4-2.4-5.4-5.4v-3.6
  c0-3,2.4-5.4,5.4-5.4c13.9,0,23-7.2,27-17l-15.8-15.8c-3.8-3.8-2.2-10.4,2.9-12l9.5-3V53.9c0-4,3.2-7.2,7.2-7.2h14.5v-9
  c0-3,2.4-5.4,5.4-5.4h32.6c3,0,5.4,2.4,5.4,5.4v9h14.5c4,0,7.2,3.2,7.2,7.2v31.7l9.5,3c5.2,1.7,6.7,8.2,2.9,12l-15.8,15.8
  C140.5,126.5,149.7,133.5,163.5,133.5z M67.6,80.9l26.7-8.6c0.7-0.2,1.4-0.3,2.2-0.3c0.8,0,1.5,0.1,2.2,0.3l26.7,8.6V61.1H67.6
  V80.9z"/>
  </g>

  else if(category === 'train') return <g>
    <path style={{fill: color || 'white'}} d="M157.4,122.6c0,14.1-16.8,26.1-35.3,26.1l17.1,13.5c1.6,1.3,0.7,3.9-1.3,3.9H55.2c-2.1,0-3-2.6-1.3-3.9
  L71,148.7c-18.5,0-35.3-12-35.3-26.1V53c0-14.4,17.4-26.1,34.8-26.1h52.2c17.7,0,34.8,11.7,34.8,26.1V122.6z M90,59.6
  c0-3.6-2.9-6.5-6.5-6.5H55.2c-3.6,0-6.5,2.9-6.5,6.5V90c0,3.6,2.9,6.5,6.5,6.5h28.3c3.6,0,6.5-2.9,6.5-6.5V59.6z M48.7,118.3
  c0,7.2,5.8,13,13,13s13-5.8,13-13c0-7.2-5.8-13-13-13S48.7,111.1,48.7,118.3z M144.4,59.6c0-3.6-2.9-6.5-6.5-6.5h-28.3
  c-3.6,0-6.5,2.9-6.5,6.5V90c0,3.6,2.9,6.5,6.5,6.5h28.3c3.6,0,6.5-2.9,6.5-6.5V59.6z M118.3,118.3c0,7.2,5.8,13,13,13
  c7.2,0,13-5.8,13-13c0-7.2-5.8-13-13-13C124.1,105.2,118.3,111.1,118.3,118.3z"/>
  </g>

  else if(category === 'misc') return <g>
    <path style={{fill: color || 'white'}} d="M160.1,96.5c0,35.1-28.5,63.6-63.6,63.6c-35.1,0-63.6-28.5-63.6-63.6S61.4,33,96.5,33
      C131.6,33,160.1,61.4,160.1,96.5z M96.5,76C85.2,76,76,85.2,76,96.5S85.2,117,96.5,117s20.5-9.2,20.5-20.5S107.8,76,96.5,76z"/>
  </g>

  else if(category === 'swim') return <g>
    <path style={{fill: color || 'white'}} d="M168.5,121.7v7.2c0,2-1.6,3.6-3.6,3.6h-3.6c-8.7,0-16.4-2.7-21.6-7.2c-5.2,4.4-12.9,7.2-21.6,7.2
    s-16.4-2.7-21.6-7.2c-5.2,4.4-12.9,7.2-21.6,7.2s-16.4-2.7-21.6-7.2c-5.2,4.4-12.9,7.2-21.6,7.2h-3.6c-2,0-3.6-1.6-3.6-3.6v-7.2
    c0-2,1.6-3.6,3.6-3.6h3.6c5.9,0,10.3-1.9,12.6-4c2-1.8,4.4-3.2,7.1-3.2h3.7c2.7,0,5.1,1.3,7.1,3.2c2.3,2.1,6.8,4,12.6,4
    c5.9,0,10.3-1.9,12.6-4c2-1.8,4.4-3.2,7.1-3.2h3.7c2.7,0,5.1,1.3,7.1,3.2c2.3,2.1,6.8,4,12.6,4c5.9,0,10.3-1.9,12.6-4
    c2-1.8,4.4-3.2,7.1-3.2h3.7c2.7,0,5.1,1.3,7.1,3.2c2.3,2.1,6.8,4,12.6,4h3.6C166.9,118.1,168.5,119.7,168.5,121.7z M31.7,78.5
    c0-9.9,8.1-18,18-18s18,8.1,18,18s-8.1,18-18,18S31.7,88.5,31.7,78.5z M63.6,106.2l15.4-22.1c1.7-2.4,3.7-4.4,6-6.1l18-12.9
    c5.7-4.1,13-5.6,19.9-4.1l22.6,4.8c5.8,1.3,9.5,7,8.3,12.8c-1.3,5.8-7,9.5-12.8,8.3l-22.6-4.8c-1-0.2-2,0-2.8,0.6l-4,2.9l25.4,18.1
    c-3.9,0.2-7.8,1.9-11.1,5c-0.8,0.7-3.4,2.1-7.7,2.1s-6.9-1.4-7.7-2.1c-3.6-3.3-7.8-5.1-12-5.1h-3.7c-4.3,0-8.4,1.8-12,5.1
    c-0.8,0.7-3.4,2.1-7.7,2.1s-6.9-1.4-7.7-2.1C66.1,107.8,64.8,106.9,63.6,106.2z"/>
  </g>

  else if(category === 'party' || category === 'activity') return <g>
    <path style={{fill: color || 'white'}} d="M162.9,96.5c0,36.7-29.7,66.4-66.4,66.4c-36.7,0-66.4-29.7-66.4-66.4c0-36.7,29.7-66.4,66.4-66.4
    C133.2,30.1,162.9,59.8,162.9,96.5z M132.7,105.1H60.4c-2.6,0-4.6,2.3-4.3,4.8c2.4,19,18.6,33.7,38.3,33.7h4.3
    c19.7,0,35.9-14.7,38.3-33.7C137.3,107.3,135.2,105.1,132.7,105.1z M66.5,79.4c0,4.7,3.8,8.6,8.6,8.6c4.7,0,8.6-3.8,8.6-8.6
    c0-4.7-3.8-8.6-8.6-8.6C70.4,70.8,66.5,74.6,66.5,79.4z M107.2,86.1l2.6-2.3c4-3.5,12.4-3.5,16.3,0l2.5,2.3c2.2,2,5.8,0.1,5.3-2.9
    c-1.1-6.7-9.2-11.3-16-11.3c-6.9,0-15,4.5-16,11.3C101.5,86.2,105,88.1,107.2,86.1z"/>
  </g>

  else if(category === 'shopping') return <g>
	<path style={{fill: color || 'white'}} d="M153.7,72.7v67.6c0,11-8.9,19.9-19.9,19.9H62.2c-11,0-19.9-8.9-19.9-19.9V72.7h23.9v-8c0-17.5,14.3-31.8,31.8-31.8
		c17.5,0,31.8,14.3,31.8,31.8v8H153.7z M80.1,88.6c0-3.3-2.7-6-6-6s-6,2.7-6,6s2.7,6,6,6S80.1,91.9,80.1,88.6z M82.1,72.7h31.8v-8
		c0-8.8-7.1-15.9-15.9-15.9S82.1,56,82.1,64.7V72.7z M127.8,88.6c0-3.3-2.7-6-6-6s-6,2.7-6,6s2.7,6,6,6S127.8,91.9,127.8,88.6z"/>
</g>

  else if(category === 'healthcare') return <g>
	<path style={{fill: color || 'white'}} d="M153.9,103.5h-29.5l-6.9-13.7c-1.5-2.9-5.6-2.9-7.1,0l-12.2,24.3L83.8,82c-1.4-3.2-6-3.1-7.3,0.1l-8.9,21.4H42.1
		c-10.8-13.2-10.3-32.9,1.8-45.3l0.6-0.6c12.8-13.1,33.8-13.1,46.6,0l6.9,7.1l6.9-7.1c12.8-13.1,33.8-13.1,46.6,0l0.6,0.6
		C164.2,70.6,164.7,90.3,153.9,103.5z M119.4,111.5h27.1l-45.4,46.3c-1.8,1.8-4.6,1.8-6.4,0l-45.4-46.3h23.4l7.5-17.8L94.4,125
		c1.4,3.1,5.7,3.2,7.2,0.1l12.4-24.7L119.4,111.5z"/>
</g>

  else if(category === 'camping') return <g>
	<path style={{fill: color || 'white'}} d="M106.2,62.7l55.2,69c1.9,2.3,2.9,5.2,2.9,8.2v5.6c0,6.2-5.1,11.3-11.3,11.3H96.6H40.1c-6.2,0-11.3-5.1-11.3-11.3v-5.6
		c0-3,1-5.9,2.9-8.2l55.2-69L75.6,48.5c-2.6-3.2-2.1-8,1.2-10.6c3.2-2.6,8-2.1,10.6,1.2l9.2,11.5l9.2-11.5c2.6-3.2,7.3-3.8,10.6-1.2
		s3.8,7.3,1.2,10.6L106.2,62.7z M124.7,141.7L96.6,105l-28.1,36.8h28.1H124.7z"/>
</g>

  else if(category === 'themePark') return <path style={{fill: color || 'white'}} d="M162.4,93.2v44.5c0,2.7-2.2,4.9-4.9,4.9h-3.3c-2.7,0-4.9-2.2-4.9-4.9v-19.8c-2.5,1.7-5.3,2.9-8.2,3.7v16.1
  c0,2.7-2.2,4.9-4.9,4.9s-4.9-2.2-4.9-4.9v-14.9c-3.4-0.1-6.7-0.8-9.9-1.8v16.7c0,2.7-2.2,4.9-4.9,4.9s-4.9-2.2-4.9-4.9v-21.6
  c-3.8-2.6-7.1-6-9.7-9.9l-0.2-0.3v31.8c0,2.7-2.2,4.9-4.9,4.9s-4.9-2.2-4.9-4.9V91.1l-9.9-14.8v61.4c0,2.7-2.2,4.9-4.9,4.9
  s-4.9-2.2-4.9-4.9V65.8c-2.7-1.4-5.7-2.2-8.8-2.2c-0.4,0-0.7,0.1-1,0.1v74c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9V67
  c-5,3.5-8.2,9.2-8.2,15.7v54.9c0,2.7-2.2,4.9-4.9,4.9h-3.3c-2.7,0-4.9-2.2-4.9-4.9V82.8c0-17.8,14.5-32.3,32.3-32.3
  c10.8,0,20.9,5.4,26.9,14.4l22.7,34.1c1,1.5,2.2,2.9,3.6,4.2c4.4,4.2,10.3,6.6,16.5,6.6c9.1,0,16.5-7.4,16.5-16.5
  c0-6.7-4.1-12.5-9.9-15.1v22.4c-1.7,1.6-4.1,2.6-6.6,2.6c-1.1,0-2.2-0.2-3.3-0.4V77.1c-3.5,0.4-6.8,1.2-9.9,2.6V97
  c-0.5-0.6-1-1.1-1.5-1.7l-14.3-21.5c8.2-6.6,18.3-10.2,29-10.2C149.1,63.6,162.4,76.9,162.4,93.2z"/>

  else return <path style={{fill: color || 'white'}} d="M169.9,96.3c0,40.2-32.6,72.9-72.9,72.9s-72.9-32.6-72.9-72.9C24.1,56,56.8,23.4,97,23.4S169.9,56,169.9,96.3z
       M97,72.8c-13,0-23.5,10.5-23.5,23.5S84,119.8,97,119.8s23.5-10.5,23.5-23.5S110,72.8,97,72.8z"/>
}


export const getPointerColor = (colors:CupColorsSpec, marker:MapMarker) => {
  if (marker.type === 'arena' || marker.type === 'club') 
    return getColorString(colors.contrast.primary);
    
  if (marker.type === 'lodging' || marker.type === 'camping') 
    return getColorString(colors.contrast.complements[0]);

  if (marker.type === 'restaurant' ) 
    return getColorString(colors.contrast.complements[1]);

  if (marker.type === 'themePark' || marker.type === 'swim' || marker.type === 'party' || marker.type === 'activity' || marker.type === 'shopping') 
    return getColorString(colors.contrast.complements[2]);

  return getColorString(colors.contrast.complements[3]);
}

export const videoThumbnail = (thumbFileName:string|undefined, sport:string, size:400|1200) => {

  if(false && thumbFileName !== 'thumb') {
    return thumbFileName
  } else {
    if (sport === 'badminton' || 'basketball' || 'floorball' || 'football' || 'handball' || 'icehockey' || 'tennis' || 'volleyball') {
      return `https://static.cupmanager.net/images/generic-sport-images${size}/${sport}.jpg`
    } else {
      return `https://static.cupmanager.net/images/generic-sport-images${size}/other.jpg`
    }
  } 
  
}

export const dev = process.env.NODE_ENV !== 'production'; // Developement mode

export const formatMinutes = (milliSeconds: number) => Math.floor(milliSeconds/60000);


export const emojiFlag  = (code: string | undefined) => {
  if(code) {
    const getFlagUnicode = () => {
      const codePoints = code && code.toUpperCase().split('').map( c => 127397 + c.charCodeAt(0) );

      return codePoints && String.fromCodePoint(...codePoints);
    }
  
    return getFlagUnicode()

  }

  return '';
}

export const getInitials = (name:string) => {
  const nameArr = name.trim().split(' ');

  if(nameArr.length === 1) { // ONLY ONE NAME
    return nameArr[0].substring(0,2);
  }

  const initials = nameArr[0].substring(0,1) + nameArr.pop()?.substring(0,1);
  return initials;
}

export const getInitialOnFirstName = (name:string) => {
  const nameArr = name.trim().split(' ');

  if(nameArr.length === 1) { // ONLY ONE NAME
    return name;
  }

  const initials = nameArr[0].substring(0,1) + '. ' + nameArr.pop();
  return initials;
}

export const joinAnd = (items: string[], word: string) => 
  [items.slice(0, -1).join(', '), items.slice(-1)[0]].join(items.length < 2 ? '' : ` ${word} `);

type typeAndStatus = {
  type: 'cuppass' | 'leader' | 'favorite',
  status: 'enabled' | 'disabled'
}

export const getMeTeamTypeAndStatus = (team: Me$MeTeam) => {
  const isEmptyString = (string: string) => {
    return string.length === 0
      ? 'disabled'
      : 'enabled'
  }

  if (team.cupPassImageUrl) {
    return {
      type: 'cuppass',
      status: isEmptyString(team.cupPassImageUrl)
    } as typeAndStatus
  }

  if (team.teamAccountUrl) {
    return {
      type: 'leader',
      status: isEmptyString(team.teamAccountUrl)
    } as typeAndStatus
  }

  return {
    type: 'favorite',
    status: 'enabled'
  } as typeAndStatus
}

export const roundToOneDecimal = (number: number) => {
  return Math.round(number * 100 * 10) / 10;
}

export const getNumberOrZero = (percentageNumber: number) => !isNaN(percentageNumber) 
? percentageNumber
: 0

export const useCardColorWord = (color: 'green' | 'yellow' | 'red') => {
  const T = useTranslations('cmresults')

  if(color === 'green') {
    return T('Antal gröna')
  } else if(color === 'yellow') {
    return T('Antal gula')
  } else {
    return T('Antal röda')
  } 

}

export const makeContrastColor = (_color: string | undefined) => {

  if (_color) {
    const color = _color.replace(/\s/g, '').toLowerCase();
    const noneContrastColors = ['#fff', '#ffffff', 'rgb(255,255,255)'];
    const decidedColor = noneContrastColors.indexOf(color) > 0
      ? 'rgb(180, 180, 180)'
      : _color

    return decidedColor
  }

  return undefined
}

export const shortenFirstWord = ({string, cutIndex, between} : {
  string: string | undefined, 
  cutIndex: number, 
  between: string
}) => {
  if(string === undefined) return string;

  const indexOfFirstSpace = string.indexOf(' ');
  const firstWord = string.substring(0, cutIndex);
  const wordsAfterFirst = string.substring(indexOfFirstSpace + 1)

  return <>{firstWord + between + wordsAfterFirst}</>
}

export const isColorWhite = (color: string) => {

  const _color = color.toLowerCase().replace(' ', '');

  if (_color === '#fff' || _color === '#ffffff' || _color === 'rgb(255,255,255)' || _color === 'rgba(255,255,255)') {
    return true
  }
  return false
}