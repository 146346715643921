import { ReactNode, useEffect, useRef, useState } from "react"

import styles from './ToggleButtons.module.scss';

type Props = {
  children: ReactNode,
  padding: number
}

export default function ToggleButtons ({children, padding} : Props) {

  const widthRef = useRef<null | HTMLDivElement>(null);
  const [width, setWidth] = useState<'auto' | number>('auto')

  useEffect(() => {
    setTimeout(() => {
    if(widthRef.current) {
      setWidth(widthRef.current.getBoundingClientRect().width + (padding * 2));
    }
  }, 0)
  }, [widthRef])

  return <div className={styles.toggle_container} style={{width: `min(${width}px, 100%)`, '--padding': `${padding}px`} as any}>

    <div className={styles.toggle_container_inner} ref={widthRef}>
      {children}
    </div>
  </div>
}