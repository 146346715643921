import { ClubUtils$ShirtTypes } from '../../api/schema';
import styles from './Dress.module.scss';
var cn = require('classnames');

type props = {
  primary?: string,
  secondary?: string,
  tertiary?: string,
  code?: string,
  type?: ClubUtils$ShirtTypes,
  className?: string
}

export default function DressBasket ({primary, secondary, tertiary, type, code, className} : props) {

  const shortsInverted = false;

  // If tertiary color exists end if shorts should be inverted.
  const shortColors = shortsInverted
    ? {
      primary: secondary,
      secondary: tertiary 
        ? tertiary
        : primary
    }
    : {
      primary: primary,
      secondary: tertiary 
        ? tertiary
        : secondary
    }

    const flagUrl = code && 'https://static.cupmanager.net/images/flags_svg/4x3/'+ code.toLowerCase() +'.svg';

  return (
    <div className={cn(styles.dress, styles.basket, className)}>

      {code && <div className={styles.flag_container}>
        <div className={styles.flag}>
          <img src={flagUrl} alt="" />
        </div>
      </div>}

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 1025.1 1825.1">
      <g>
        <path fill={shortColors.primary} d="M883.2,1217.1c-213.1,47.6-370.6,40.1-370.6,40.1s-157.6,7.4-370.6-40.1l-1.9,5.7
          C63.2,1392.1,0,1755.6,0,1755.6c239.3,106.1,474.9,57,474.9,57c36.2-117,37.6-250.5,37.6-265.5c0,15,1.4,148.5,37.6,265.5
          c0,0,235.6,49.1,474.9-57c0,0-63.2-363.5-140-532.9L883.2,1217.1z"/>
      </g>
      <g>
        <path fill={primary} d="M914.3,615.1c-97-186.4-110.6-539.4-110.6-539.4s-71.7-35.5-224-62.8h-67.2h-67.2
          c-152.3,27.3-224,62.8-224,62.8s-13.6,353-110.6,539.4C117,1045.4,75.1,1310,75.1,1310c246.1,65.5,437.4,56.4,437.4,56.4
          s191.3,9,437.4-56.4C950,1310,909.6,1029.7,914.3,615.1z"/>
      </g>
      {type && <>
        {type === 'vertical' && <g id="Vertical_lines">
          <path style={{fill:secondary}} d="M439,222.2c-12.1,7.1-29.2-64-45-50.1c4.4,32.5,10,124.4,13.1,174.3c13,211.9-12,839.3-33.1,1014.4
              c36,3,59.7,4.1,82.7,4.6c28.6-376.8,14-1074.2,6.6-1110.7C453.4,243.4,444.8,232.1,439,222.2z"/>
          <path style={{fill:secondary}} d="M197.1,1338.6c29.1,5.3,56.7,9.6,82.7,13.1c65.1-412.2,34.4-1229.6,27.6-1306.6c-19.6,5.6-35,9.4-48.5,14.1
            c2.3,48.1-8.1,449-8.1,497.9C250.8,992.2,208.6,1227.6,197.1,1338.6z"/>
          <path style={{fill:secondary}} d="M586.5,222.2c12.1,7.1,29.2-64,45-50.1c-4.4,32.5-10,124.4-13.1,174.3c-13,211.9,12,839.3,33.1,1014.4
            c-36,3-59.7,4.1-82.7,4.6c-28.6-376.8-14-1074.2-6.6-1110.7C572.1,243.4,580.7,232.1,586.5,222.2z"/>
          <path style={{fill:secondary}} d="M828.4,1338.6c-29.1,5.3-56.7,9.6-82.7,13.1c-65.1-412.2-34-1229.6-27.1-1306.6c19.6,5.6,35.8,12.1,49.3,16.7
            c-2.3,48.1,6.8,446.3,6.8,495.3C774.7,992.2,816.9,1227.6,828.4,1338.6z"/>
        </g>}
      
        {type === 'horizontal' && <g>
          <path style={{fill:secondary}} d="M512.5,1313.1c0,0,188,8.9,431-54.7c-1.8-15.9-3.9-35.3-6-57.9c-77.2,17.6-224.6,43.4-424.9,43.4
            c-199.7,0-346.7-25.6-424.1-43.2c-2.3,22.7-4.5,42-6.4,57.9C324.8,1322,512.5,1313.1,512.5,1313.1z"/>
          <path style={{fill:secondary}} d="M512.5,1181c203.2,0,343.9-21.7,419.8-37.5c-1.4-16.5-2.8-34.2-4.1-52.8c-79.4,10.1-223.1,24-415.7,24
            c-191.8,0-335-13.7-414.6-23.8c-1.4,18.8-2.8,36.4-4.2,52.9C169.8,1159.5,310.2,1181,512.5,1181z"/>
          <path style={{fill:secondary}} d="M512.5,1059.9c196.6,0,334.6-12.8,412.2-22.7c-1-16.6-2-33.9-3-51.8c-80.6,5.7-221.7,13.1-409.2,13.1
            c-186.7,0-327.4-7.3-408.1-13c-0.9,18-1.9,35.3-2.9,51.9C179.2,1047.3,316.9,1059.9,512.5,1059.9z"/>
          <path style={{fill:secondary}} d="M512.5,925.4c191.3,0,327.2-8.5,405.8-15.4c-0.5-13.8-1-27.8-1.5-42.2c-81.4,2.7-220.5,6.2-404.3,6.2
            c-183.2,0-322-3.4-403.5-6.2c-0.4,14.4-0.9,28.5-1.4,42.2C186.4,916.9,322,925.4,512.5,925.4z"/>
          <path style={{fill:secondary}} d="M512.5,786c188.4,0,323-4.8,402.1-8.8c-0.2-10.6-0.3-21.4-0.4-32.2c-81.7-0.2-219.9-0.4-401.7-0.4
            c-181.6,0-319.5,0.2-401.3,0.4c-0.1,10.9-0.2,21.7-0.3,32.3C190.1,781.2,324.6,786,512.5,786z"/>
          <path style={{fill:secondary}} d="M914.1,641.4c0-6.1,0.1-12.3,0.2-18.4c-83.4-3-221.3-6.6-401.6-6.6c-180.5,0-318.5,3.6-401.8,6.6
            c0.1,6.2,0.2,12.3,0.2,18.4c80.8-1.7,215.3-3.6,401.6-3.6C698.8,637.7,833.2,639.7,914.1,641.4z"/>
        </g>}

        {/* type === 'horizontal' && <g>
          <path style={{fill:secondary}} d="M934,1162.2c-120.8,23.6-453.1,73-841.9-0.4c-8.7,96.4-17,149-17,149c246.1,65.5,437.4,56.4,437.4,56.4
            s191.3,9,437.4-56.4C950,1310.7,942.3,1257.1,934,1162.2z"/>
          <path style={{fill:secondary}} d="M925.6,1052.4c-3-45.9-5.7-97.1-7.8-153c-134.9,12.9-462.7,36.3-809.8,0.6c-2,56.8-4.7,108.4-7.6,154.4
            C224.6,1075.4,535,1113.2,925.6,1052.4z"/>
          <path style={{fill:secondary}} d="M914.8,786.8c-0.9-48.9-1.2-100.5-0.7-154.4c-375.9,9.4-747.6,1.3-803.2,0c0.6,54.3,0.5,106-0.2,154.7
            C375.2,803.9,850.5,789,914.8,786.8z"/>
        </g> */}
      </>}
      <g>
        <path style={{fill:shortColors.secondary}} d="M503.2,1677.6c0,0-7.3,75.7-28.2,136.2c0,0-235.6,49.1-474.9-57c0,0,20.7-132.9,60.9-286.6
          c0,0-28.9,241.5,157.2,282.6C376,1787.5,477,1742.3,503.2,1677.6z"/>
        <path style={{fill:shortColors.secondary}} d="M521.9,1676.4c0,0,7.3,75.7,28.2,136.2c0,0,235.6,49.1,474.9-57c0,0-20.7-132.9-60.9-286.6
          c0,0,28.9,241.5-157.2,282.6C649.1,1786.4,548,1741.1,521.9,1676.4z"/>
      </g>

      <g id="Highlight">
        <path style={{opacity:'0.3',fill:'#dddddd'}} d="M10.8,1695.6c-6.3,32.9-10.8,60-10.8,60c239.3,106.1,474.9,57,474.9,57c4-12.9,3.5-10.2,6.7-23.3
          C181.1,1809,59.5,1720.6,10.8,1695.6z"/>
        <path style={{opacity:'0.3',fill:'#dddddd'}} d="M1014.2,1695.6c6.3,32.9,10.8,60,10.8,60c-239.3,106.1-474.9,57-474.9,57c-4-12.9-3.5-10.2-6.7-23.3
          C844,1809,965.6,1720.6,1014.2,1695.6z"/>
        <path style={{fill:'none'}} d="M655.2,39c-48.8-17.4-142.9-16.3-142.9-16.3S418.2,21.6,369.4,39c-18.3,6.5-23.3,18.4-13.9,41
              c65.1,156.8,156.8,199.8,156.8,199.8s91.8-43,156.8-199.8C678.5,57.4,673.5,45.6,655.2,39z"/>
        <path style={{opacity:'0.3',fill:'#dddddd'}} d="M853.3,159.6l-3.8-58l-45.6-25c-35.7-19.6-100.2-34.9-100.2-34.9l-0.1,1.3c2.8,11.7,1,27.4-4.4,45.6
              l-0.2,2.3C794.2,111.6,853.3,159.6,853.3,159.6z"/>
        <path style={{fill:'none'}} d="M655.2,39c-48.8-17.4-142.9-16.3-142.9-16.3S418.2,21.6,369.4,39c-18.3,6.5-23.3,18.4-13.9,41
              c65.1,156.8,156.8,199.8,156.8,199.8s91.8-43,156.8-199.8C678.5,57.4,673.5,45.6,655.2,39z"/>
        <path style={{opacity:'0.3',fill:'#dddddd'}} d="M321.4,41.6c0,0-64.5,15.3-100.2,34.9l-45.6,25l-3.8,58c0,0,59.1-48,154.3-68.7v0
              C320,70.9,317.9,53.9,321.4,41.6z"/>
      </g>
      
      <g id="Detail_kopia">
        <g>
          <path style={{fill:secondary}} d="M111.5,615.4V479.6c0,0,47.7-79.8,65.1-378.5l45.6-25C222.1,76,222.1,415.9,111.5,615.4"/>
          <path style={{fill:secondary}} d="M913.6,615.4V479.6c0,0-47.7-79.8-65.1-378.5l-45.6-25C802.9,76,802.9,415.9,913.6,615.4"/>
          <path style={{fill:secondary}} d="M687.7,23.3C618-1.6,513.3,0,513.3,0S408.5-1.6,338.9,23.3c-69.7,25,76.3,256.3,174.4,324.3
            C611.3,279.7,757.3,48.3,687.7,23.3z M670.1,80C605,236.8,513.3,279.9,513.3,279.9s-91.8-43-156.8-199.8
            c-9.4-22.6-4.4-34.5,13.9-41c48.8-17.4,142.9-16.3,142.9-16.3s94.1-1.2,142.9,16.3C674.5,45.6,679.5,57.4,670.1,80z"/>
        </g>
        <path style={{opacity:'0.2'}} d="M686.9,23.3c0,0,22.5,43.1-35.9,136.2c-42.5,67.8-94.9,137.7-138.5,170.6c-43.6-32.9-96-102.8-138.5-170.6
          c-58.4-93.1-35.9-136.2-35.9-136.2c-69.7,25,76.3,256.3,174.4,324.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
          C610.6,279.6,756.6,48.3,686.9,23.3z"/>
        <path style={{opacity:'0.2',fill:'#dddddd'}} d="M655.4,39c18.3,6.5,23.3,18.4,13.9,41c-65.1,156.8-156.8,199.8-156.8,199.8s-91.8-43-156.8-199.8
          c-9.4-22.6-4.4-34.5,13.9-41c0,0-30.5,8-19.6,35.5c10.8,27.5,40.5,134.7,162.6,227.6C634.6,209.2,664.3,102,675.1,74.5
          C685.9,47,655.4,39,655.4,39z"/>
      </g>
      <g id="Shadow">
        <path style={{opacity:'0.2'}} d="M913.5,615.5l0.1-135.9c0,0-6.9-13.6-11.4-26.9c-4.5-13.4-9.6-31.3-9.6-31.3s-24.5,495,7.7,864.2
          c0,0-141.8,53-388.5,53s-388.5-53-388.5-53c32.2-369.2,8.8-867.8,8.8-867.8s-7.4,25.2-11.6,37.1S110,479.6,110,479.6v135.9
          c6.3,430.2-35.7,694.8-35.7,694.8c246.1,65.5,437.4,56.4,437.4,56.4s191.3,9,437.4-56.4C949.2,1310.3,907.2,1045.7,913.5,615.5z"
          />
        <path style={{opacity:'0.2'}} d="M74.5,1784.8c0,0-34.1-10.1-74.5-28.4c0,0,50.3-260.7,103.9-438.6l45.1,10.5c0,0-74.9,268.7-73.9,455.5"/>
        <path style={{opacity:'0.2'}} d="M950.5,1784.3c0,0,34.1-10.1,74.5-28.4c0,0-50.3-259.5-103.9-437.4l-43.3,10.1c0,0,73.1,267.9,72.1,454.8"/>
      </g>
      </svg>

    </div>
  )
}