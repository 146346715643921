import React, { ReactNode, useContext, useMemo } from "react"

// MAIN COLORS
const primary = {
  r: 0,
  g: 55,
  b: 112
} 

const secondary = {
  r: 0,
  g: 55,
  b: 112
}

const gray = {
  r: 100,
  g: 100,
  b: 100,
}

const gray_light = {
  r: 150,
  g: 150,
  b: 150,
}

const gray_background = {
  r: 245,
  g: 245,
  b: 245,
}

const gray_background_off = {
  r: 230,
  g: 230,
  b: 230,
}

const gray_dark = {
  r: 77,
  g: 77,
  b: 77,
}


function addHue(color : colorProps, extraHue: number, lightness?: number, maxSaturation?: number) {
  const hsl = getHSL(color);
  
  if(hsl.s < 5){
    hsl.h = 90;
    
  }

  hsl.s = Math.max(hsl.s, 40);  

  
  const hue = (hsl.h + extraHue)%360;


  return getRGB({h:hue, s: Math.min(hsl.s,maxSaturation === undefined ? 100 : maxSaturation), l: (lightness ? lightness : hsl.l)});
}

// TO HUE
type colorProps = {
  r: number,
  g: number,
  b: number;
}

function hue2rgb(p:number, q:number, t:number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1/6) return p + (q - p) * 6 * t;
  if (t < 1/2) return q;
  if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  return p;
}



export function getColorString(color:colorProps, alpha?:number): string;
export function getColorString(color:undefined, alpha?:number): undefined;
export function getColorString(color?:colorProps, alpha?: number) {
  if (color) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha === undefined ? 1 : alpha})`
  } else {
    return color;
  }
}

export const getRGB = ({h, s, l} : {h:number,s:number,l:number}) => {
  var r, g, b;

  h /= 360.0;
  s /= 100.0;
  l /= 100.0;


  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return {r:Math.round(r * 255),g:Math.round(g * 255),b:Math.round(b * 255)};
}

export const getHSL = ({r, g, b} : colorProps) => {

  r /= 255.0;
  g /= 255.0;
  b /= 255.0;

  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);

  const lightness = (max+min)/2;
  const delta = (max-min)/2;

  if (min === max) {
      return {h: 0, s:0, l:Math.round(lightness*100)};
  }

  let hue = 0;

  if (max === r) {
      hue = (g - b) / (max - min);

  } else if (max === g) {
      hue = 2 + (b - r) / (max - min);

  } else {
      hue = 4 + (r - g) / (max - min);
  }

  hue = hue * 60;
  if (hue < 0) hue = hue + 360;

  const saturation = delta > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min)

  return {h:Math.round(hue),s:Math.round(saturation*100),l:Math.round(lightness*100)};
}

function adjustGamma(p:number) {
  if (p <= 0.03928) {
      return p / 12.92;
  } else {
      return Math.pow( ( p + 0.055 ) / 1.055, 2.4 );
  }
}

function relativeLuminance(rgb:colorProps) {
  const r = adjustGamma( rgb.r / 255 );
  const g = adjustGamma( rgb.g / 255 );
  const b = adjustGamma( rgb.b / 255 );
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function contrastRatio(rl1:number,rl2:number) {
  const ratio = (rl1 + 0.05) / (rl2 + 0.05);
  return ratio >= 1 ? ratio : 1 / ratio;
}

export function contrastColor(rgb:colorProps, ratio:number) {
  const rl_white = relativeLuminance({r:255,g:255,b:255});

  const hsl = getHSL(rgb);

  if( contrastRatio(rl_white, relativeLuminance(getRGB({h:hsl.h, s:hsl.s, l:hsl.l}))) > ratio ){
    //console.log("Too dark colors, increasing brightness to find contrast")
    
    let inc = -0.01;
    let l2 = ( ( rl_white + 0.05 ) / ratio - 0.05 );
    
    if (l2 < 0) {
        l2 = ( ratio * ( rl_white + 0.05 ) - 0.05 );
        inc = -inc;
    }
    
    for (let i=0; i < 100; i++) {
      l2 += inc;
      if(contrastRatio(rl_white, relativeLuminance(getRGB({h:hsl.h, s:hsl.s, l:l2*100}))) > ratio){
        // one step back
        l2 -= inc;
        //console.log("found contrast")
        break;
      }
    }

    return getRGB({h:hsl.h, s:hsl.s, l:l2*100});
  } else {

    //console.log("Too light colors, decreasing brightness to find contrast")
    let inc = 0.01;
    let l2 = ( ( rl_white + 0.05 ) / ratio - 0.05 );
    
    if (l2 < 0) {
        l2 = ( ratio * ( rl_white + 0.05 ) - 0.05 );
        inc = -inc;
    }
    
    for (let i=0; i < 100; i++) {
      l2 += inc;
      if(contrastRatio(rl_white, relativeLuminance(getRGB({h:hsl.h, s:hsl.s, l:l2*100}))) < ratio){
        //console.log("found contrast")
        break;
      }
    }

    return getRGB({h:hsl.h, s:hsl.s, l:l2*100});
  }
}

export type CupColorSpec = {
    primary: colorProps,
    secondary: colorProps,
    complements: colorProps[]
}

export type CupColorsSpec = CupColorSpec & {
  hue: number,
  contrast: CupColorSpec,
  text: CupColorSpec,
  light: CupColorSpec,
  background: CupColorSpec,
  dark: CupColorSpec, 
}

const hueOffsets = [180, 120, 240];

const colors = (primary: colorProps, secondary: colorProps) => {
    return {
    hue: getHSL(primary).h,
    primary: primary,
    secondary: secondary,
    black: {
      primary: addHue(primary, 0, 10, 30)
    },gray: {
      primary: addHue(primary, 0, 21, 10),
    },
    contrast: {
      primary: contrastColor(primary,3.8),
      secondary: contrastColor(secondary,3.8),
      complements :[
        contrastColor(addHue(primary, hueOffsets[0]),3.8), // lodging
        contrastColor(addHue(primary, hueOffsets[1]),3.8), // resaturants, party
        contrastColor(addHue(primary, hueOffsets[2]),3.8), // party, themePark, swim, misc
        gray_dark // atm, parking, airplane, bus, boat, train
      ]
    },text: {
      primary: contrastColor(primary,4.5),
      secondary: contrastColor(secondary,4.5),
      complements :[
        contrastColor(addHue(primary, hueOffsets[0]),4.5), // lodging
        contrastColor(addHue(primary, hueOffsets[1]),4.5), // resaturants, party
        contrastColor(addHue(primary, hueOffsets[2]),4.5), // party, themePark, swim, misc
        gray_dark // atm, parking, airplane, bus, boat, train
      ]
    },
    light: {
      primary: addHue(primary, 0, 95),
      secondary: addHue(secondary, 0, 95),
      complements :[
        addHue(primary, hueOffsets[0], 45, 70), // lodging
        addHue(primary, hueOffsets[1], 45, 70), // resaturants, party
        addHue(primary, hueOffsets[2], 45, 70), // party, themePark, swim, misc
        gray_light // atm, parking, airplane, bus, boat, train
      ]
    },
    background: {
      primary: addHue(primary, 0, 90),
      secondary: addHue(secondary, 0, 90),
      complements :[
        addHue(primary, hueOffsets[0], 93, 45), // lodging
        addHue(primary, hueOffsets[1], 93, 45), // resaturants, party
        addHue(primary, hueOffsets[2], 93, 45), // party, themePark, swim, misc
        gray_background, // atm, parking, airplane, bus, boat, train,
        gray_background_off
      ]
    },
    dark: {
      primary: addHue(primary, 0, 30),
      secondary: addHue(secondary, 0, 30),
      complements :[
        addHue(primary, hueOffsets[0], 40, 45), // lodging
        addHue(primary, hueOffsets[1], 40, 45), // resaturants
        addHue(primary, hueOffsets[2], 40, 45), // party, themePark, swim, misc
        gray_dark // atm, parking, airplane, bus, boat, train
      ]
    }, complements :[
      addHue(primary, hueOffsets[0]),
      addHue(primary, hueOffsets[1]),
      addHue(primary, hueOffsets[2]),
      gray
    ]
  }
}

const ColorContext = React.createContext(colors(primary, secondary))

export function ColorProvider({primary, secondary, children} : {children:ReactNode, primary: colorProps, secondary: colorProps}) {
    const value = useMemo(() => (colors(primary,secondary)), [primary, secondary]);
    return <ColorContext.Provider value={value}>
      <style>
          {`:root {
            --clr-primary-hue: ${value.hue}deg;
            --clr-primary: ${value.primary.r}, ${value.primary.g}, ${value.primary.b};
            --clr-primary-contrast: ${value.contrast.primary.r}, ${value.contrast.primary.g}, ${value.contrast.primary.b};
            --clr-primary-gray: ${value.gray.primary.r}, ${value.gray.primary.g}, ${value.gray.primary.b};
            --clr-primary-black: ${value.black.primary.r}, ${value.black.primary.g}, ${value.black.primary.b};
            --clr-primary-text: ${value.text.primary.r}, ${value.text.primary.g}, ${value.text.primary.b};
            --clr-secondary: ${value.secondary.r}, ${value.secondary.g}, ${value.secondary.b};
            --clr-primary-light: ${value.light.primary.r}, ${value.light.primary.g}, ${value.light.primary.b};
            --clr-primary-background: ${value.background.primary.r}, ${value.background.primary.g}, ${value.background.primary.b};
            --clr-primary-dark: ${value.dark.primary.r}, ${value.dark.primary.g}, ${value.dark.primary.b};
            --clr-secondary-light: ${value.light.secondary.r}, ${value.light.secondary.g}, ${value.light.secondary.b};
          }`}
        </style>
        {children}
    </ColorContext.Provider>
}

export const hex2rgb = (hex:string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  
  return {r,g,b}
}

export const replaceWhite = (color:string) => {
  if (color === '#ffffff' || color === '#fff')
    return {
      color: 'rgb(245,245,245)',
      border: true
  }

  return {
    color: color,
    border: false
  };
}


type labColor = {l:number, a:number, b:number}
type xyzColor = {x:number, y:number, z:number}



export function labContrasts(color:colorProps){
  
  const hsl = getHSL(color);
  
  if( hsl.s < 5 ){
    return [{r:0, g:0, b:0}, {r:180, g:180, b:180}, {r:120, g:120, b:120} ]
  } else {
    const lab = xyzToLab(rgbToXyz(color));

    return [xyzToRgb(labToXyz({l: lab.l, a: lab.a, b: lab.b})),
      xyzToRgb(labToXyz({l: lab.l - 10, a: lab.a, b: lab.b })),
      xyzToRgb(labToXyz({l: lab.l - 20, a: lab.a, b: lab.b })),
      xyzToRgb(labToXyz({l: lab.l - 30, a: lab.a, b: lab.b }))];
  }
}


function rgbToXyz(color : colorProps) {
  let [red, green, blue] = [color.r, color.g, color.b].map(v => v / 255).map(v =>
      v > 0.04045 ? ((v + 0.055) / 1.055) ** 2.4 : v / 12.92);

  // Assume sRGB
  const x = (red * 0.4124 + green * 0.3576 + blue * 0.1805) * 100;
  const y = (red * 0.2126 + green * 0.7152 + blue * 0.0722) * 100;
  const z = (red * 0.0193 + green * 0.1192 + blue * 0.9505) * 100;

  return {x, y, z};
}

function xyzToLab(color: xyzColor) {
  // D65 reference white
  let xr = color.x / 95.047;
  let yr = color.y / 100.000;
  let zr = color.z / 108.883;

  [xr, yr, zr] = [xr, yr, zr].map(v =>
      v > 0.008856 ? Math.pow(v, 1/3) : (7.787 * v) + 16/116);

  const l = (116 * yr) - 16;
  const a = 500 * (xr - yr);
  const b = 200 * (yr - zr);

  return {l, a, b};
}

function labToXyz(color: labColor) {
  let y = (color.l + 16) / 116;
  let x = color.a / 500 + y;
  let z = y - color.b / 200;

  [x, y, z] = [x, y, z].map(v =>
      v ** 3 > 0.008856 ? v ** 3 : (v - 16 / 116) / 7.787);

  // Using D65 reference white
  x *= 95.047;
  y *= 100.000;
  z *= 108.883;

  return {x, y, z};
}

function xyzToRgb(color: xyzColor) {
  let x = color.x;
  let y = color.y;
  let z = color.z;

  x /= 100;
  y /= 100;
  z /= 100;

  let r = x * 3.2406 + y * -1.5372 + z * -0.4986;
  let g = x * -0.9689 + y * 1.8758 + z * 0.0415;
  let b = x * 0.0557 + y * -0.2040 + z * 1.0570;

  [r, g, b] = [r, g, b].map(v =>
      v > 0.0031308 ? 1.055 * (v ** (1 / 2.4)) - 0.055 : 12.92 * v);

  r = Math.min(Math.max(0, r), 1);
  g = Math.min(Math.max(0, g), 1);
  b = Math.min(Math.max(0, b), 1);

  return {r: r * 255, g: g * 255, b: b * 255};
}


export function useColors() {
  return useContext(ColorContext);
}


