import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentTournament } from '../../App';
import useScrolledFromTop from '../../utils/HasScrolledFromTop';
import MiddleEllipsisSpan from '../MiddleEllipsisSpan/MIddleEllipsisSpan';
import styles from './PageHeader.module.scss';

type props = {
  title : string,
  shortTitle?: string,
  scrolledTitle? : string,
  subtitle?: string,
  visuallyHidden?: boolean,
  crumbs?: ({
    title: string,
    url: string
  } | boolean) [],
  noExpand?: boolean,
  children?: JSX.Element,
  headerFooter?: JSX.Element,
  hiddenBgColor?: string,
  marginBottom?: string,
  heroImage?: string,
  maxHeight?: string
}

const PageHeader = ({title, visuallyHidden, crumbs, scrolledTitle, subtitle, shortTitle, noExpand, heroImage, headerFooter, children, hiddenBgColor, marginBottom, maxHeight} : props) => {

  const crumbsRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const t = useCurrentTournament();

  const crumbsWidth = crumbsRef && crumbsRef.current
    ? crumbsRef.current.clientWidth
    : 0
  ;
  useEffect(()=>{
    if( shortTitle && shortTitle != title &&  titleRef.current){
      const titleSpan = titleRef.current?.getElementsByClassName(styles.title)[0] as HTMLElement;
      const shortTitleSpan = titleRef.current?.getElementsByClassName(styles.short_title)[0] as HTMLElement;
      
      if( titleSpan.offsetWidth > (titleRef.current.parentElement?.parentElement?.offsetWidth || 0) ){
        titleSpan.style.display = "none";
        shortTitleSpan.style.display = "inline";
      }
    } else {
      const titleSpan = titleRef.current?.getElementsByClassName(styles.title)[0] as HTMLElement;
      const parentWidth = (titleRef.current?.parentElement?.parentElement?.offsetWidth || 0);
      if( titleSpan.offsetWidth > (titleRef.current?.parentElement?.parentElement?.offsetWidth || 0) ){
        var scale = (parentWidth) / titleSpan.offsetWidth;
        titleSpan.style.transform = "scale(" + scale + ")";
        //titleSpan.style.display = "scale(" + scale + ")";
      }
    }
  },[titleRef.current]);

  useEffect(() => {
    document.title = t.fullname + " - " + title + (subtitle ? " - "+subtitle:"");
  }, [title, subtitle, t.fullname])
  
  const onScroll = (pxFromTop: number) => {
    containerRef.current?.style.setProperty("--scrolled", pxFromTop/2+"px");
  }

  const scrolled = (useScrolledFromTop(children ? 200 : 170))
    || noExpand;

  const scrolled_start = (useScrolledFromTop(10, containerRef.current ? onScroll : undefined))
    || noExpand;



  return visuallyHidden 
    ? <h1 className="visually_hidden" >
        {title}
      </h1>
    : <>
      <div className={classNames("only-print", styles.print_header)}>
        <span>{crumbs && crumbs.map(c => typeof c !== "boolean" ? c.title : '').join(" / ")}</span>
        <h1>{title}</h1>
        {subtitle && <h2>{subtitle}</h2>}
      </div>
      <div className={classNames('page-header', 'no-print', scrolled 
      ? styles.scrolled 
      : (scrolled === undefined
        ? ''
        : styles.unscrolled ), scrolled_start && styles.scrolled_start)} style={{'--crumbs-width' : crumbsWidth+'px'} as any}>
        {children && <div className={styles.place_children}>
          {children}
        </div>}

        <div ref={containerRef} className={
          classNames(styles.header_container,
          
          children && styles.search_bar)
        } style={{
          "--max-height": maxHeight || "250px",
          "--child-height": (children ? "100px" : "0px")
        } as any}>
          <div className={classNames(styles.header_container_background, 
              t.cup.imageFilter && `image-filter-${t.cup.imageFilter}`,
              t.cup.imageFilter && 'image-filter',)} 
              style={{
                background: `url('${heroImage ? heroImage : t.cup.heroImage}')`, 
              }}></div>
          <div className={classNames(styles.inner_container, 'max-75')}>

            <div className={styles.position_container}>
              <div ref={crumbsRef} className={styles.crumbs}>
                {crumbs && crumbs.map((crumb, i) => <React.Fragment key={i}>
                  { typeof crumb !== "boolean" && <><Link to={`/${crumb.url}`} className={styles.breadcrumb_link}>
                    <MiddleEllipsisSpan text={crumb.title}/>
                    {/*<span>{crumb.title}</span>*/}
                  </Link>
                  <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} /></>}
                </React.Fragment>)}
                
                <h1 className='custom-font' ref={titleRef}>
                  <span>
                    {shortTitle && <span className={styles.short_title}>{shortTitle}</span>}
                    <span className={styles.title}>{title}</span>
                    <span className={styles.scrolled_title}>{scrolledTitle || shortTitle || title}</span>
                  </span>
                  {subtitle && <small>{subtitle}</small>}
                </h1>
                
              </div>
            </div>
          </div>
          
       {headerFooter && headerFooter}
        </div>

        {!noExpand && <div className={classNames(styles.underlay, children && styles.search_bar)}
            style={{background: hiddenBgColor, "--child-height": (children ? "100px" : marginBottom || "0px"), "--max-height": maxHeight || "250px" } as any}>

        </div>}

        {noExpand && <div style={{height:40}}></div>}

      </div>
    </>
}

export default PageHeader;