import { useTranslations } from "cupman-utils";
import { roundToOneDecimal } from "../../../utils";
import { Team$statistics } from "../../../api/schema";
import { RawRgb } from "./PointsWonLostBar";
import { getColorString } from "../../../colors";
import LabeledHorizontalBarsSingle from "./LabeledHorizontalBarSingle";
type Props = {
  statistics: Team$statistics,
  colors: {
    positive: RawRgb,
    negative: RawRgb
  }
}

export default function ShotEfficiency ({statistics, colors} : Props) {

  const T = useTranslations('cmresults');

  return <div>
    <LabeledHorizontalBarsSingle value={statistics.goals /statistics.shots} color={getColorString(colors.positive)} label={T('Skotteffektivitet') } valueLabel={`${roundToOneDecimal(statistics.goals / statistics.shots)}%`} extraLabel={`(${statistics.goals} / ${statistics.shots})`} />
  </div>
}

