import classNames from "classnames";
import { Team, Team$awards } from "../../api/schema"
import styles from './MatchTeamsAwards.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAwardSimple } from "@fortawesome/pro-light-svg-icons";
import { AwardTitle, awardImgPath } from "../ClubTeamHead/AwardWithText";
import { sortAwards } from "../ClubTeamHead/TeamAwards";
import { useTranslations } from "cupman-utils";
import ButtonWithTooltip from "../ButtonWithTooltip/ButtonWithTooltip";
import { useLink } from "granular";

type Props = {
  home: Team | undefined,
  away: Team | undefined
}

export default function MatchTeamsAwards({home, away} : Props) {

  const homeTeamAwards = useLink(home ? Team({id:home.id}) : undefined, {
    awards: []
  })?.awards;

  const awayTeamAwards = useLink(away ? Team({id:away.id}) : undefined, {
    awards: []
  })?.awards;

  const showAwards = (homeTeamAwards && homeTeamAwards.length > 0) || (awayTeamAwards && awayTeamAwards.length > 0)
  const sortedHomeAwards = sortAwards(homeTeamAwards);
  const sortedAwayAwards = sortAwards(awayTeamAwards);

  const T = useTranslations('cmresults');
  

  return showAwards
    ? <>
      <div className={classNames(styles.container)}>
        <AwardsPerTeam awards={sortedHomeAwards} />
        {/* <div className={styles.spacer}></div> */}
        <AwardsPerTeam awards={sortedAwayAwards} />
      </div>
    </>
    : <></>
}

/////////////////////

function AwardsPerTeam ({awards} : {awards: Team$awards | undefined}) {

  const transitionMs = 150;
  const T = useTranslations('cmresults');

  return <div className={classNames(styles.team_awards_container)} style={{'--transition-ms': `${transitionMs}ms`} as any}>
    <div  className={classNames(styles.team_awards_inner_container, awards && awards.length === 0 && styles.white)}>
      {awards && awards.map((award, i) => 
        <ButtonWithTooltip 
          key={i} 
          body={<div className={styles.tooltip_body_flex}>
            <img src={awardImgPath(award.type, award.placement)} alt={T.select(award.name)} />
            <div>
              <h4 className={'custom-font'}>
                <AwardTitle award={award}/>
              </h4>
              <p dangerouslySetInnerHTML={{ __html: T.select(award.name) }}></p>
            </div>
          </div>} 
          bodyClassName={styles.tooltip_styles}
          backgroundColor='rgb(30,30,30)'
        >
          <div className={styles.award_img}>
            <img src={awardImgPath(award.type, award.placement)} alt={T.select(award.name)} />
          </div>
        </ButtonWithTooltip>)}
    </div>
  </div>
}