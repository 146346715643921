import classNames from "classnames";
import React from "react";
import ContentLoader from 'react-content-loader'
import styles from './LoadingContent.module.scss';

type LoadingComponent = {
  Shape: React.FC<{y:number, amount?:number}>,
  height: number, 
  margin: number,
  amount?: number
}

function heightOfComponents(components:LoadingComponent[]){
    //console.log(components.reduce((total, c) => total + c.height, 0))
    return components.reduce((total, c) => total + (c.height + c.margin), 0)
}

export function LoadingContent({components, className, margin} : {components: LoadingComponent[], className?: string, margin?: number}) {
  
    const mask = 'linear-gradient(to bottom, black 25%, transparent 100%)';
    return <>
      <div className={classNames(className)}style={{
        WebkitMaskImage: mask,
        maskImage: mask,
        paddingTop: margin
      }}>
        <ContentLoader
          backgroundColor={'rgb(var(--clr-gray-100))'}
          foregroundColor={'rgb(var(--clr-gray-50))'}
          width="100%"
          height={`${heightOfComponents(components)}px`}
        >
          {components.map((c, i) => <c.Shape y={heightOfComponents(components.slice(0,i))} amount={c.amount} key={i}/>)}

        </ContentLoader>
      </div>
    </>
}


export const header = (height: number, margin: number) => ({
  Shape: ({y}:{y:number}) => {
    return <>
      <circle cx={height / 2} cy={y + height / 2} r={height / 2} ></circle>
      <rect x={height + 10} y={y} rx="6" ry="6" style={{width: '250px', height: height}} ></rect>
    </>
  },
  height: height,
  margin: margin
})

export const centerblock = (height: number, width:number, margin: number) => ({
  Shape: ({y}:{y:number}) => {
    return <>
      <rect x={`calc(50% - ${width/2}px)`} y={y} rx="6" ry="6" style={{width: width, height: height}} ></rect>
    </>
  },
  height: height,
  margin: margin
})

export const paragrapgh = (height: number, margin: number) => ({
  Shape: ({y}:{y:number}) => {
    return <>
      <rect x="0" y={y} rx="6" ry="6" width="100%" height={height} />
    </>
  },
  height: height,
  margin: margin
})

export const list = (height:number, margin:number, amount:number) => ({
  Shape: ({y}:{y:number}) => {
    const _amount = amount > 1 ? amount : 1;
    let output:any[] = [];

    const getLines = () => {
      let y_inner = y;
      for (let i = 0; i < _amount; i++) {

        if(i % 2 === 0) {
          output.push(<rect x="0" y={y_inner} key={i} rx="0" ry="0" width="100%" height={height} />);
        }

        y_inner += height;
      }
      return <>{output}</>
    }

    return <>
      {getLines()}
    </>
  },
  height: height * amount,
  margin: margin
})

export function LoadingMatches () {

  const headerHeight = 50;
  const matchHeight = 160;

  return <div className={classNames("max-75", styles.loading_matches)}>
  <div className={styles.loading_matches_header}>
    <div className={styles.top_header}>
    <LoadingContent margin={20} components={
      [header(30, 0)]
    }/>
    </div>
  </div>
  <div className={styles.loading_matches_header}>
    <LoadingContent margin={20} components={
      [header(headerHeight, 0)]
    }/>
  </div>
  <LoadingContent margin={0} components={
    [paragrapgh(matchHeight, 0)]
  }/>
  <LoadingContent margin={0} components={
    [paragrapgh(matchHeight, 0)]
  }/>
  <LoadingContent margin={0} components={
    [paragrapgh(matchHeight, 0)]
  }/>
  <LoadingContent margin={0} components={
    [paragrapgh(matchHeight, 0)]
  }/>
  <LoadingContent margin={0} components={
    [paragrapgh(matchHeight, 0)]
  }/>
  <LoadingContent margin={0} components={
    [paragrapgh(matchHeight, 0)]
  }/>
  </div>
}