import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {lost: number, goals:number}, away: {lost: number, goals:number}},
  statisticsTotal: {home: {lost: number, goals:number}, away: {lost: number, goals:number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
}

export default function ConcededGoals ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const totalLost = Math.max(statisticsTotal.home.goals + statisticsTotal.away.goals,
        statisticsTotal.home.lost + statisticsTotal.away.lost)


  return <LabeledOpposingHorizontalBars 
    label={T('Insläppta')}
    left={{
      value: statistics.home.lost / totalLost,
      color: teamColors.home,
      label: statistics.home.lost,
    }}
    right={{
      value: statistics.away.lost / totalLost,
      color: teamColors.away,
      label: statistics.away.lost
    }}
  />
}