import styles from './TeamTimeLine.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/pro-regular-svg-icons';
import { getEventPlaceBg } from './TeamTimeLine';
import { getColorString, useColors } from '../../colors';
import { Team$TimelineLocation } from '../../api/schema';
import { getIcon } from '../../utils';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import ExternalLink from '../Button/ExternalLink';
import { useCurrentTournament } from '../../App';

type props = {
    place: Team$TimelineLocation,
    hidden: boolean
    //name: string,
    //coordinates: string,
    //place: 'arena' | 'lodging' | 'arrival'
  }

export default function PlaceHeader({place, hidden} : props) {
  
  const colors = useColors();
  const T = useTranslations("cmresults");
  
  const googleUrl = `https://www.google.com/maps/search/?api=1&query=${place.travel?.to.latitude},${place.travel?.to.longitude}`;

  const t = useCurrentTournament();

  const to = place.travel?.to.visibility === "interestingPlaces" ? googleUrl
   : ('/map/' + place.travel?.to.id);

  return (
    
    <div className={classNames(styles.place_container, hidden && "date_hidden")}>

      <div className={classNames(styles.place_header, hidden && "date_hidden")} style={{
        background: getColorString(getEventPlaceBg(colors,place.type))
      }}>

      <div className={styles.place_icon} style={{
        background: getColorString(getEventPlaceBg(colors,place.type))
      }}>

        <svg version="1.1" viewBox="0 0 193.1 193.1">
          {getIcon(place.type, 'white')}
        </svg>
      </div> 
      <h4 className="custom-font">{place.name}</h4>
      
      {place.travel?.to.street && t.mapPublished &&
        <ExternalLink to={to} className={styles.map_btn} >
          <FontAwesomeIcon icon={faMap}/> 
          {T('Map')}
        </ExternalLink>}
    </div>
  </div>
  )
}