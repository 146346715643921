import { ClubUtils$ShirtTypes } from '../../api/schema';
import styles from './Dress.module.scss';
var cn = require('classnames');

type props = {
  primary?: string,
  secondary?: string,
  tertiary?: string,
  code?: string,
  type?: ClubUtils$ShirtTypes,
  className?: string
}


export default function DressSoccer ({primary, secondary, tertiary, type, code, className} : props) {

  const shortsInverted = false;

  // If tertiary color exists end if shorts should be inverted.
  const shortColors = shortsInverted
    ? {
      primary: secondary,
      secondary: tertiary 
        ? tertiary
        : primary
    }
    : {
      primary: primary,
      secondary: tertiary 
        ? tertiary
        : secondary
    }

    const flagUrl = code && 'https://static.cupmanager.net/images/flags_svg/4x3/'+ code.toLowerCase() +'.svg';

  return (
    <div className={cn(styles.dress, className)}>

      {code && <div className={styles.flag_container}>
        <div className={styles.flag}>
          <img src={flagUrl} alt="" />
        </div>
      </div>}

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 1537.3 1840.8">
      {/* Shorts Primary Fill */}
      <g>
        <path fill={shortColors.primary} d="M1139.3,1233.9c-213.1,47.6-370.6,40.1-370.6,40.1s-157.6,7.4-370.6-40.1l-1.9,5.7
          c-76.8,169.3-140,532.9-140,532.9c239.3,106.1,474.9,57,474.9,57c36.2-117,37.6-250.5,37.6-265.5c0,15,1.4,148.5,37.6,265.5
          c0,0,235.6,49.1,474.9-57c0,0-63.2-363.5-140-532.9L1139.3,1233.9z"/>
      </g>
      {/* // Shorts Primary Fill */}

      {/* Shirt Primary Fill */}
      <g>
        <path fill={primary} d="M1537.3,587.3c-98.6-186.2-271.5-368.1-281.8-378.8c0.1-0.1,0.2-0.2,0.2-0.2s-194.2-99.3-420-137.7h-67.2
          h-67.2c-225.8,38.4-420,137.7-420,137.7s0.1,0.1,0.2,0.2C271.5,219.1,98.6,401.1,0,587.3l248.4,182.9l118.5-97.4
          c-8.7,347.2-35.7,544.8-35.7,544.8c246.1,65.5,437.4,56.4,437.4,56.4s191.3,9,437.4-56.4c0,0-26.9-197.6-35.7-544.8l118.5,97.4
          L1537.3,587.3z"/>
      </g>
      {/* // Shirt Primary Fill */}

      
      
      {type && <>
        {type === 'vertical' && <g>
          
          <g style={{display: 'inline'}}>
            <path fill={secondary} d="M550.4,404.9c-5.5-121.8-57.4-215.1-96.3-268.6c-26.6,9.4-50.9,18.7-72.4,27.4
              c32.5,48.7,79.9,136.1,89.2,241.2c13.6,153-2.1,658.6-23.3,839.3c31.2,6,60.8,10.8,88.7,14.7
              C543.8,1154.3,567.4,779.7,550.4,404.9z"/>
            <path fill={secondary} d="M1067,404.9c9.3-104.9,56.6-192.2,89.1-241c-21.5-8.7-45.8-18-72.4-27.4c-38.8,53.6-90.6,146.8-96.1,268.4
              c-17,374.6,6.6,749.1,14.1,853.9c27.8-3.9,57.5-8.7,88.6-14.7C1069.1,1063.2,1053.5,557.9,1067,404.9z"/>
            <path fill={secondary} d="M675.1,250.7c-12.1,7.1-27.7,17.5-43.5,31.4c4.4,32.5,8.5,72.9,11.5,122.8c13,211.9-8.6,757.8-13.1,864.4
              c36,3,66.2,4.3,89.1,4.8c10.3-777.1-12.5-954.3-19.9-990.8C689.6,271.9,680.9,260.6,675.1,250.7z"/>
            <path fill={secondary} d="M861.6,250.7c12.1,7.1,27.7,17.5,43.5,31.4c-4.4,32.5-8.5,72.9-11.5,122.8c-13,211.9,8.6,757.8,13.1,864.4
              c-36,3-66.2,4.3-89.1,4.8c-10.3-777.1,12.5-954.3,19.9-990.8C847.2,271.9,855.9,260.6,861.6,250.7z"/>
          </g>
        </g>}

        {type === 'horizontal' && <g>
          <g>
            <path fill={secondary} d="M337.5,1159.9c-3.8,36-6.3,57.7-6.3,57.7c246.1,65.5,437.4,56.4,437.4,56.4s191.3,9,437.4-56.4
              c0,0-2.5-21.7-6.3-57.7c-75.4,17.6-225.3,44.9-431.1,44.9S412.9,1177.5,337.5,1159.9z"/>
            <path fill={secondary} d="M345.9,1072.2c-1.6,19.2-3.2,36.6-4.7,52.1c73.9,16,217.2,39.1,427.4,39.1s353.4-23.1,427.4-39.1
              c-1.5-15.5-3-32.9-4.7-52.1c-77.8,10.2-224.3,24.9-422.7,24.9S423.8,1082.3,345.9,1072.2z"/>
            <path fill={secondary} d="M353.3,969.7c-1.2,18.1-2.4,35.2-3.6,51.4c76.1,10,216.5,23.5,419,23.5s342.9-13.5,419-23.5
              c-1.2-16.2-2.4-33.3-3.6-51.4c-79.5,5.7-223,13.5-415.3,13.5C576.2,983.3,432.8,975.5,353.3,969.7z"/>
            <path fill={secondary} d="M359.9,859.3c-0.7,14.4-1.4,28.3-2.2,41.9c77.8,7,215.4,15.9,410.9,15.9s333.2-8.9,410.9-15.9
              c-0.8-13.6-1.5-27.5-2.2-41.9c-80.7,2.8-221.6,6.3-408.7,6.3C581.5,865.7,440.6,862.1,359.9,859.3z"/>
            <path fill={secondary} d="M364.7,751.4c-0.4,10.9-0.8,21.6-1.2,32.1c78.7,4,214.4,9,405.1,9s326.4-5,405.1-9
              c-0.4-10.5-0.8-21.2-1.2-32.1c-81.4-0.2-220.5-0.4-403.9-0.4C585.1,751,446.1,751.2,364.7,751.4z"/>
            <path fill={secondary} d="M1170.2,656.6c-81.7-3-219.9-6.8-401.6-6.8c-181.3,0-319.2,3.7-401,6.8l-0.3,18.4
              c79.2-1.7,213.6-3.7,401.3-3.7c188.2,0,322.8,2.1,401.9,3.7L1170.2,656.6z"/>
          </g>
        </g>}


        {/* type === 'unknown' && <g>
          <g>
            <path fill={secondary} d="M368.1,604.8c0,0,6.8-75,1.3-150.7c0,0,397.7,10.2,798.1,0c0,0-5.9,78.8,1.3,150.7
              C1168.8,604.8,643.5,623.3,368.1,604.8z"/>
            <path fill={secondary} d="M346.8,1058.3c-8.4,103.8-16,159.3-16,159.3c246.1,65.5,437.4,56.4,437.4,56.4s191.3,9,437.4-56.4
              c0,0-7.5-55.3-15.9-158.6C1079.5,1081.2,742.1,1135.1,346.8,1058.3z"/>
            <path fill={secondary} d="M363.5,758c-2,54.7-4.5,104.9-7.1,150.2c115.1,20.3,427.7,61.4,823.6-1.4c-2.6-45.1-5-94.8-7-149.1
              C1045.6,770.3,713.8,795.2,363.5,758z"/>
          </g>
        </g> */}
      </>}

      {/* Details Fill */}
      <g>
        <path style={{opacity:'0.5'}} d="M938.4,67.4c-19.2-36.6-169.8-32.2-169.8-32.2s-150.6-4.4-169.8,32.2
          c-19.2,36.6,76.8,182.3,106.1,209.4c29.4,27.1,63.6,57.6,63.6,57.6s34.3-30.5,63.6-57.6C861.6,249.7,957.6,104.1,938.4,67.4z"/>
        <g>
          <g>
            <path fill={secondary} d="M0,587.3l25.2-45.7c0,0,41.4,56.9,115.4,113.5s148.7,81.4,148.7,81.4l-41,33.7
              c0,0-58.4-20.7-137.7-81.6S0,587.3,0,587.3z"/>
            <path fill={secondary} d="M923.9,25.4C854.4-5.1,768.6-1.7,768.6-1.7s-85.8-3.4-155.2,27.1s-119.1,132.6,0,274.3
              c21.4-23,44.9-39.1,61.7-49c23.2,39.8,93.5,102.5,93.5,102.5s70.3-62.7,93.5-102.5c16.8,9.9,40.2,26,61.7,49
              C1043,158,993.3,55.9,923.9,25.4z M839.2,238.8c0,0,3,1.3,8.2,3.9c-12.3,14.9-47.4,55.7-78.7,74.9c-31.3-19.2-66.5-60-78.7-74.9
              c5.2-2.6,8.2-3.9,8.2-3.9s-59.3-77.9-57.6-116.8c1.7-38.9,42.9-50.8,128.1-52.5c85.3,1.7,126.4,13.5,128.1,52.5
              C898.5,160.9,839.2,238.8,839.2,238.8z"/>
            <path fill={secondary} d="M1538.1,587.3l-25.2-45.7c0,0-41.4,56.9-115.4,113.5c-74,56.6-148.7,81.4-148.7,81.4
              l41,33.7c0,0,58.4-20.7,137.7-81.6C1506.6,627.7,1538.1,587.3,1538.1,587.3z"/>
          </g>
          <path fill={shortColors.secondary} d="M354,1541.9c19.2-185.5,60-304.5,60-304.5c-15.3-3,0,0-16-3.6l-1.9,5.7c-76.8,169.3-140,532.9-140,532.9
            c93.3,41.4,186.1,59.2,264.5,65.4C333.7,1806.3,340.2,1675.4,354,1541.9z"/>
          <path fill={shortColors.secondary} d="M1183.3,
          1543.4c-19.2-185.5-60-304.5-60-304.5c15.3-3,0,0,17-3.6l1.9,5.7c76.8,169.3,140,532.9,140,531.9c-93.3,41.4-186.1,59.2-264.5,65.4C1203.6,1807.8,1197.1,1676.8,1183.3,1543.4z"/>
        </g>
      </g>
      {/* // Details Fill */}

      <g>
        <g style={{opacity: '0.2'}}>
          <path d="M768.6,1484.4c0,0-34.6,211.4-83.5,351.6c29.1-3.1,45.9-6.6,45.9-6.6c36.2-117,37.6-250.5,37.6-265.5
            c0,15,1.4,148.5,37.6,265.5c0,0,16.8,3.5,45.9,6.6C803.3,1695.8,768.6,1484.4,768.6,1484.4z"/>
          <path d="M1325.2,753.4l-156.3-138c-0.9-50.9-1.5-104.8-1.5-161.3c0-182.9,88.1-246.1,88.1-246.1c-69.1,44.6-112.8,136-118.7,310.7
            s20.5,673.2,20.5,673.2s-145.4,51.2-389,51.2s-388.9-51.2-388.9-51.2s26.3-498.5,20.4-673.2s-49.6-266.1-118.7-310.7
            c0,0,88.1,63.2,88.1,246.1c0,56.7-0.5,110.7-1.5,161.7L212.1,753.4l36.1,16.4l118.5-97.4c-8.7,347.2-35.7,544.8-35.7,544.8
            c23.3,6.2,46.2,11.7,68.4,16.7l-3.3,5.7c-76.8,169.3-140,532.9-140,532.9c21,9.3,42.1,17.5,62.9,24.5
            c35.8-299.2,91.9-481.2,118.6-555c192.6,38.2,330.8,31.7,330.8,31.7s138.4,6.5,331.2-31.8c26.7,73.8,82.8,255.8,118.6,555.1
            c20.8-7.1,41.9-15.2,62.9-24.5c0,0-63.2-363.5-140-532.9l-3.3-5.8c22.1-4.9,44.8-10.4,68-16.6c0,0-27-197.7-35.7-545.2l118.9,97.8
            L1325.2,753.4z"/>
        </g>

        <g style={{opacity: '0.03'}}>
          <g>
            <path d="M923.9,25.4C854.4-5.1,768.6-1.7,768.6-1.7s-85.8-3.4-155.2,27.1s-119.1,132.6,0,274.3
              c21.4-23,44.9-39.1,61.7-49c23.2,39.8,93.5,102.5,93.5,102.5s70.3-62.7,93.5-102.5c16.8,9.9,40.2,26,61.7,49
              C1043,158,993.3,55.9,923.9,25.4z M839.2,238.8c0,0,3,1.3,8.2,3.9c-12.3,14.9-47.4,55.7-78.7,74.9c-31.3-19.2-66.5-60-78.7-74.9
              c5.2-2.6,8.2-3.9,8.2-3.9s-59.3-77.9-57.6-116.8c1.7-38.9,42.9-50.8,128.1-52.5c85.3,1.7,126.4,13.5,128.1,52.5
              C898.5,160.9,839.2,238.8,839.2,238.8z"/>
          </g>
        </g>
      </g>

      <g>
        <g>
          <path style={{opacity: '0.2', fill:'#C4C4C4'}} d="M266.9,1712.4c-6.3,32.9-10.8,60-10.8,60c239.3,106.1,474.9,57,474.9,57c4-12.9,3.5-10.2,6.7-23.3
            C437.2,1825.9,315.6,1737.4,266.9,1712.4z"/>
          <path style={{opacity: '0.2', fill:'#C4C4C4'}} d="M544.9,107c-137.3,37.1-262.6,100.9-262.6,100.9S101.6,395.4,0,587.3l32.4,34.5
            c144.1-224.9,299.7-357.3,299.7-357.3c81.1-43.5,185.8-76.5,215.1-85.3C540.2,152.2,540.1,128.1,544.9,107z"/>
          <path style={{opacity: '0.2', fill:'#C4C4C4'}} d="M993.2,107c137.3,37.1,262.6,100.9,262.6,100.9s180.6,187.4,282.2,379.3l-32.4,34.5
            C1361.6,396.9,1206,264.5,1206,264.5c-81.1-43.5-185.8-76.5-215.1-85.3C997.9,152.2,998,128.1,993.2,107z"/>
          <path style={{opacity: '0.2', fill:'#C4C4C4'}} d="M1270.3,1712.4c6.3,32.9,10.8,60,10.8,60c-239.3,106.1-474.9,57-474.9,57c-4-12.9-3.5-10.2-6.7-23.3
            C1100.1,1825.9,1221.7,1737.4,1270.3,1712.4z"/>
        </g>
        
        <path style={{opacity: '0.5', fill:'#D8D8D8'}} d="M923.9,25.4C854.4-5.1,768.6-1.7,768.6-1.7s-85.8-3.4-155.2,27.1c-46.3,20.3-83.8,72.5-68.4,147
          c-3.7-47.6,16.5-76.9,47.5-103.2c31-26.3,110.2-44.9,176.1-43.7c65.9-1.2,145.1,17.4,176.1,43.7c31,26.3,51.2,55.7,47.5,103.2
          C1007.6,97.9,970.1,45.7,923.9,25.4z"/>
        <path style={{opacity: '0.5', fill:'#D8D8D8'}} d="M698.1,238.8c0,0-59.3-77.9-57.6-116.8c0,0-5.9,64,38.4,126.2
          C678.9,248.1,690.6,241.2,698.1,238.8z"/>
        <path style={{opacity: '0.5', fill:'#D8D8D8'}} d="M858.2,248.1c44.3-62.2,38.4-126.2,38.4-126.2c1.7,38.9-57.6,116.8-57.6,116.8
          C846.5,241.2,858.2,248.1,858.2,248.1z"/>
      </g>
      </svg>

    </div>
  )
}