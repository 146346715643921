import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";
import { getNumberOrZero, roundToOneDecimal } from "../../utils";
type Props = {
  statistics: {home: {goals: number, shots: number}, away: {goals: number, shots: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
}

export default function ShotEfficiency ({statistics, teamColors} : Props) {

  const T = useTranslations('cmresults')

  return <LabeledOpposingHorizontalBars 
    label={T('Skotteffektivitet')}
    left={{
      value: statistics.home.goals / statistics.home.shots,
      color: teamColors.home,
      label: `${roundToOneDecimal(getNumberOrZero(statistics.home.goals / statistics.home.shots))}%`,
      extraLabel: `(${statistics.home.goals}/${statistics.home.shots})`
    }}
    right={{
      value: statistics.away.goals / statistics.away.shots,
      color: teamColors.away,
      label: `${roundToOneDecimal(getNumberOrZero(statistics.away.goals / statistics.away.shots))}%`,
      extraLabel: `(${statistics.away.goals}/${statistics.away.shots})`
    }}
  />
}

