
import { faCar, faWalking, faRoute } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TeamTimeLine.module.scss';
import classNames from 'classnames';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Team$TimelineTravel } from '../../api/schema';
import { useTranslations } from 'cupman-utils';
import { useState } from 'react';
import ModalTrigger from '../../utils/ModalTrigger';
import { faBus, faTrain } from '@fortawesome/pro-light-svg-icons';
import LinkButton from '../Button/LinkButton';
import PoiCard from '../../Map/PoiCard';
import { getDistance } from '../../Map/MapTypes';
import Button from '../Button/Button';

type props = {
  travel: Team$TimelineTravel
  hidden: boolean
}



export default function Directions ({travel, hidden} : props) {

  const T = useTranslations("cmresults");

  const fromCoords = travel.from ? `${travel.from.latitude}, ${travel.from.longitude}` : undefined;
  const toCoords =  `${travel.to.latitude}, ${travel.to.longitude}`;
  
  const hasFromInfo = travel.fromDescription || !!travel.fromTravelInfo.length;
  const hasToInfo = travel.fromDescription || !!travel.toTravelInfo.length;

  const distance = travel.from ? getDistance(travel.from, travel.to) : 0;

  /*const minFromDistance = travel.from ? travel.fromTravelInfo.reduce((minDistance,info)=>{
    const d = getDistance(info.location,travel.from!); 
    if( d < minDistance){
      return d;
    } else {
      return minDistance;
    }
  },distance/2) : undefined;

  const minToDistance = travel.toTravelInfo.reduce((minDistance,info)=>{
    const d = getDistance(info.location,travel.to!); 
    if( d < minDistance){
      return d;
    } else {
      return minDistance;
    }
  },distance/2);*/
  /*
    Use distance to select what to show below
    Do not show alternatives where minToDistance + fromInfo.distance >= distance/modifyer
  */

  return (
    <div className={classNames(styles.directions_container,hidden && "date_hidden")}>
      <div className={styles.event_line}>
        <div className={classNames(styles.line)} style={{background: 'rgb(var(--clr-directions))'}}></div>
      </div>
      <div className={styles.route_image}>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 46 63" style={{enableBackground: 'new 0 0 46 63'} as any} xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <rect style={{fill:'white'}} width="46" height="62.8"/>
            
            <path style={{fill: 'none', stroke:'#DFEEEF', strokeWidth:4, strokeMiterlimit:10}} d="M23,
            62.8c0-5.8,
            4.7-10.4,
            10.4-10.4s10.4-4.7,
            10.4-10.4s-4.7-10.4-10.4-10.4H12.6c-5.8,
            0-10.4-4.7-10.4-10.4
            s4.7-10.4,
            10.4-10.4S23,
            5.9,
            23,
            0.2"/>
        </svg>
      </div>
      {/*<div className={styles.event_time} aria-hidden="true">
        00:00
  </div>*/}
      <div className={styles.directions_inner_container}>

        <ModalTrigger trigger={(doOpen) => <a onClick={(hasToInfo || hasFromInfo) ? doOpen : undefined}
                  href={!(hasToInfo || hasFromInfo) ? `https://www.google.com/maps/dir/?api=1&origin=${fromCoords}&destination=${toCoords}` : undefined} className={styles.directions_link} target="_blank" rel="noreferrer">
            <div className={styles.directions_info_container}>
              <div className={styles.directions_header}>
                <strong>{T('Travel to X', travel.to.name)}</strong>
              </div>
              {(travel.walking !== undefined || travel.driving !== undefined) && <div className={styles.directions_info}>
                {travel.walking && <span>
                  <FontAwesomeIcon icon={faWalking} /> {travel.walking} min
                </span>}
                {travel.driving && <span>
                  <FontAwesomeIcon icon={faCar} /> {travel.driving} min
                </span>}
                <span>
                  <FontAwesomeIcon icon={faRoute} /> {travel.distance === "0 m" ? T("Samma plats") : travel.distance}
                </span>
              </div>}
            </div>

            { travel.distance !== "0 m" && <span className={styles.arrow}>
              <FontAwesomeIcon icon={faChevronRight}/>
            </span> }

          </a>}

          modal={doClose => <div className={styles.directions_modal}>
            <h3><span>{T('Travel between X and Y',travel.from!.name, travel.to.name)}</span>
                {(travel.walking || travel.driving) && <><span>   </span>
                <span className={styles.subtitle}>{travel.walking && <span>
                  <FontAwesomeIcon icon={faWalking} /> {travel.walking} min
                </span>}
                {travel.driving && <span>
                  <FontAwesomeIcon icon={faCar} /> {travel.driving} min
                </span>}
                <span>
                  <FontAwesomeIcon icon={faRoute} /> {travel.distance}
                </span></span></>}
            </h3>

            <div className={styles.buttons}>
              <LinkButton size="lg" url={`https://www.google.com/maps/dir/?api=1&origin=${fromCoords}&destination=${toCoords}`} >
                {travel.walking ? <FontAwesomeIcon icon={faWalking} /> : travel.driving && <FontAwesomeIcon icon={faCar} />}  
                {T('Get travel directions')}
              </LinkButton>
            </div>

            {hasFromInfo && <div className={styles.from_info}>
              <h4>{T('Travel from X', travel.from?.name)}</h4>
              {travel.fromDescription && <div className={styles.description} dangerouslySetInnerHTML={{__html:T.select(travel.fromDescription)}}></div>}
              {travel.fromTravelInfo.map(info => <div>
                <PoiCard flatBottom={!!info.description} point={{...info.location, distance: getDistance(travel.from!,info.location)}} startPoint={travel.from!}/>
                <div className={classNames(styles.description, styles.poi)} dangerouslySetInnerHTML={{__html:T.select(info.description) || ''}}></div>
              </div>)}
            </div>}
            {hasToInfo && <div className={classNames(!hasFromInfo && styles.from_info)}>
              <h4>{T('Travel to X', travel.to?.name)}</h4>
              {travel.toDescription && <div className={styles.description} dangerouslySetInnerHTML={{__html:T.select(travel.toDescription)}}></div>}
              {travel.toTravelInfo.map(info => <div>
                <PoiCard flatBottom={!!info.description} point={{...info.location, distance: getDistance(travel.to,info.location)}} startPoint={travel.to}/>
                <div className={classNames(styles.description, styles.poi)} dangerouslySetInnerHTML={{__html:T.select(info.description) || ''}}></div>
              </div>)}
          </div>}
          <div className={styles.buttons}>
            <Button inverted onClick={doClose}>{T('Close')}</Button>
          </div>
        </div>}
      />
    </div>
</div>)}