import { faAngleRight, faSlidersVSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Capability$Feature as Feature, Location$MarkerImages, Location$location, MapLocation, MapLocation$MarkerLegend } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import HeaderSelect from "../../components/HeaderSelect/HeaderSelect";
import ListColumns from "../../components/ListColumns/ListColumns";
import MiddleEllipsisSpan from "../../components/MiddleEllipsisSpan/MIddleEllipsisSpan";
import PendingPage from "../../components/Pending/PendingPage";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import { getDistance } from "../../Map/MapTypes";
import MapOverlayLocation from "../../Map/MapOverlayLocation";
import MapWithOverlay from "../../Map/MapWithOverlay";
import useCatchBodyScroll from "../../useBodyScroll";
import { groupBy } from "../../utils";
import MapOverlayArena from "../Places/MapOverlayArena";
import styles from './MapPage.module.scss';
import { ArenasAndDays } from "../Places/PlacesPage";


// Used here and at PlacesPage
export const getPointsOfInterest = (locations: MapLocation[], location:Location$location | MapLocation) => {
  return locations.filter((_location: MapLocation) => 
    _location.id !== location.id && (_location.visibility === 'interestingPlaces' || _location.visibility === 'mapAndInterestingPlaces')).map(_location => ({
      ..._location,
      distance: Math.round(getDistance(_location, location))
    })).filter(point => point.distance < point.visibilityRadius).sort((a,b) => Math.sign(a.distance-b.distance)).slice(0,4).map((point, i) => ({
      ...point,
      character: ''+(i+1)
    }
  ));
}

const getIcon = (header:string|undefined, noColor?: boolean) => {
  if(!(header === 'miscellaneous' || header === 'other')) {
    return <img src={`https://static.cupmanager.net/images/icons_svg/marker-symbols/${header}.svg`} alt="" className={classNames(styles.header_icon, !noColor && "icon-primary")} />
  } else if(!!header) {
    return <img src={`https://static.cupmanager.net/images/icons_svg/marker-symbols/misc.svg`} alt="" className={classNames(styles.header_icon, !noColor && "icon-primary")} />
  }

  return false
}




export default function MapPage() {
  
  const T = useTranslations("cmresults");
  const admin = useAdmin();
  const t = useLink(useCurrentTournament(), {
    arenas:[
      {
        days: [{
        }],
        location: {
          location: {}
        }
      }
    ],
    days: [{}],
    locations: []
  });
  const [hasSelection, setHasSelection] = useState<boolean>(false);
  const [selectedMapLocation, setSelectedMapLocation] = useState<MapLocation | undefined>(undefined);
  const [map, setMap] = useState<any>(undefined);
  const [overlayScrolled, setOverlayScrolled] = useState<true|false|undefined> (undefined);
  
  const mapPublished = t.mapPublished || admin.overridePublished;

  const m = useParams<{locationId:string, arenaId:string}>();

  const locationArenas = selectedMapLocation && t.arenas.filter((arena) => {
    return arena.location.id === selectedMapLocation.id;
  });

  const selectedArena =  selectedMapLocation && (locationArenas?.find((arena) => {
     if( m.arenaId ){
        return arena.id === parseInt(m.arenaId);
      } else {
        return false
      }
    }) || (locationArenas && locationArenas[0]));
  
  const scrolling = useCatchBodyScroll();
  
  const allFeaturesOption = {
    id: "all" as "all",
    name: T('All locations')
  }

  const filterFeatures =  [...new Set (t.locations.map(location => location.features).flat(2))].map(feature => {
    return {
      id: feature,
      name: feature
    }
  });

  const [feature, setFeature] = useState<Feature|'all'>(allFeaturesOption.id);

  const filteredLocations = () => {
    if(feature === 'all') {
      return t.locations;
    } else {
      return t.locations.filter(location => location.features.indexOf(feature) !== -1)
    }
  }

  const pointsOfInterest = !hasSelection
    ? undefined
    : selectedMapLocation && getPointsOfInterest(t.locations, selectedMapLocation);
  
  

  const paramMapLocation = m 
    ? t.locations.find(location => 
      m && location.id === parseInt(
        m.locationId || '0'
      )
    ) 
    : undefined

  useEffect(() => {

    if(map) {
      if(paramMapLocation) {
        setHasSelection(true);
        setSelectedMapLocation(paramMapLocation)
        setOverlayScrolled(undefined);
      } else {
        navigate('/map');
        setHasSelection(false);
        setOverlayScrolled(undefined);
      }
    }
  }, [m.locationId, map])



  let navigate = useNavigate();

  const selectMapLocation = (mapLocation: MapLocation | undefined) => {
    if(mapLocation) {
      navigate(`/map/${mapLocation.id}`)
    } else {
      navigate('/map')
    }
    
  }

  const getLegendLabel = (legend: MapLocation$MarkerLegend) => {
    if(legend === "activity"){
      return T("Aktivitet")
    } else if( legend === "airplane" ){
      return T("Flygplats")
    } else if( legend === "arena"){
      return T("Spelplats")
    } else if( legend === "train"){
      return T("Tågstation")
    } else if( legend === "atm"){
      return T("Bankomat")
    } else if( legend === "boat"){
      return T("Hamn")
    } else if( legend === "busstop"){
      return T("Busshållplats")
    } else if( legend === "camping"){
      return T("Camping")
    } else if( legend === "club"){
      return T("Förening")
    } else if( legend === "healthcare"){
      return T("Sjukvård")
    } else if( legend === "lodging"){
      return T("Boende")
    } else if( legend === "other"){
      return T("Annat")
    } else if( legend === "parking"){
      return T("Parkerin")
    } else if( legend === "restaurant"){
      return T("Matplats")
    } else if( legend === "shopping"){
      return T("Shopping")
    } else if( legend === "swim"){
      return T("Badplats")
    } else if( legend === "themepark"){
      return T("Nöjespark")
    }
  }

const SFIconLocations = Object.entries(groupBy(filteredLocations().reduce((legendItems, location)=>{
    return [...legendItems,
      ...location.legends.map(l => ({key: l,item:location}))
    ]
  },[] as {key: MapLocation$MarkerLegend, item: MapLocation}[]), item => item.key)).map(entry => ({
    header: <>{getIcon(entry[0])} {getLegendLabel(entry[0] as MapLocation$MarkerLegend)}</>, 
    items: entry[1].sort((a, b) => a.item.name.localeCompare(b.item.name))
  }))

  /*const SFIconLocations = Object.entries(groupBy(filteredLocations(), location => location.type)).map(entry => ({
    header: <>{getIcon(entry[0])} {entry[0]}</>, 
    items: entry[1].sort((a, b) => a.name.localeCompare(b.name))
  }));*/

  const mobile =  window.matchMedia('(hover:none)').matches;

  return <>
    {mapPublished && <div className={styles.sticky}>
    <ErrorBoundary fallback={({error, errorInfo}) => 
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load map')} square />
    }>
      <MapWithOverlay
        hideMap={mobile && overlayScrolled}
        setMap={setMap}
        scrolling={scrolling}
        pointsOfInterest={(pointsOfInterest && pointsOfInterest.length > 0) ? pointsOfInterest.map(location => (
          {
            ...location,
            color: /*location.color ? location.color : */ undefined
          }
        )) : undefined}
        clusterLabel={markers=>T('X locations', markers.length)}
        controls={
          <HeaderSelect 
            icon={faSlidersVSquare} 
            legend={T('Locations')}
            className={classNames(styles.header_select)}
            onChange={feature=>{
              setFeature(feature)
            }} 
            selectedId={feature} 
            options={[allFeaturesOption, ...filterFeatures]} 
          />
        }
        visible={hasSelection}
        selectedTitle={
                <div className={classNames(styles.locationName,"max-75")}>
                  <Link to={"/"}>{T('...')}</Link>
                  <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} />
                  <Link to={"/map"}>{T('Map')}</Link>
                  <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} />
                  <h1 className="custom-font">
                  {getIcon(selectedMapLocation?.type, true)}

                  <MiddleEllipsisSpan inline text={selectedMapLocation?.name || ''}/>
                  </h1>
                </div>

        }
        zoomKey={feature}
        selectedOverlay={
          <>

            {selectedMapLocation 
              ? selectedMapLocation.type === 'arena' && locationArenas && selectedArena
                ? <MapOverlayArena onChange={ arenaId => navigate(`/map/${selectedMapLocation.id}/${arenaId}`)} arenas={locationArenas} arena={selectedArena} editionDays={t.days} setScrolled={setOverlayScrolled} scrolled={mobile && overlayScrolled} pointsOfInterest={pointsOfInterest} />
                : <MapOverlayLocation selectedLocation={selectedMapLocation} setScrolled={setOverlayScrolled} scrolled={mobile && overlayScrolled} pointsOfInterest={pointsOfInterest} 
              /> 
              : <></>}
          </>}
        markers={
          filteredLocations().map(location => (
            {
              ...location,
              color: location.color ? location.color :  undefined
            }
          ))
        }
        minInitWidth="tournament"
        selectedId={hasSelection ? selectedMapLocation?.id : undefined}
        onChange={(mapLocation) => selectMapLocation(mapLocation)}
      />
      </ErrorBoundary>
      <div className={classNames(styles.listHeader, scrolling && styles.scrolled)}>
        <h2 className={classNames("max-75","custom-font")}>
          <span>{T('All locations')}</span>
        </h2>
      </div>
    </div>}

    {mapPublished ? <div className={classNames(styles.map_body,"max-75")}>
    <ErrorBoundary fallback={({error, errorInfo}) => 
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load locations')} />
    }>
      <ListColumns
        list={SFIconLocations} 
        render={location => (
          <button key={location.item.id} 
            className={classNames(hasSelection && location.item.id === selectedMapLocation?.id && styles.selected)} 
            onClick={() => {
              selectMapLocation(location.item)}
            }
          >
            {location.item.name}
          </button>
        )}
      />
      </ErrorBoundary>
    </div> : <PendingPage text={T('The map is not published')} color="rgb(var(--clr-primary))"/>}
  </>
}
