import { Match, MatchCard, MatchComment, MatchEvent, MatchGoal, MatchLeaderFoul, MatchPlayerFoul, MatchPlayerPenalty, MatchTimeout } from "../../api/schema"
import LeaderFoul from "./LeaderFoul";
import PlayerFoul from "./PlayerFoul";
import Comment from './Comment';
import PlayerPenalty from "./PlayerPenalty";
import LiveFeed, { groupedComments } from "./LiveFeed";
import PlayerCard from "./PlayerCard";
import Commentator from "../Commentators/Commentator";
import Timeout from "./Timeout";
import Goal from "./Goal";
import { useTranslations } from "cupman-utils";
import { useCurrentTournament } from "../../App";
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group"
import PeriodStart from "./PeriodStart";
import styles from './LiveFeedDuringMatch.module.scss';
import StopWatch from "./StopWatch";
import { dev } from "../../utils";
import { FavoritesContext } from "../../context/storedTeams";
import { useWindowWidth } from "../../utils/useWindowSize";
import Shot from "./Shot";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowUpLong } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import Button from "../Button/Button";

type props = {
  events: MatchEvent[],
  game: Match<typeof LiveFeed.fragment>,
  soundOn: boolean,
  eventsBefore?: boolean,
  goalFxOn?: boolean,
  hiddenPeriods: Set<number>,
  setHiddenPeriods: (hiddenPeriods : Set<number>) => void
}

export default function LiveFeedDuringMatch ({eventsBefore, events, game: _game, goalFxOn, soundOn, hiddenPeriods, setHiddenPeriods} : props) {

  const sounds = useMemo(()=> {
    return {
      soundGoal: new Audio('https://static.cupmanager.net/sounds/applause_3.mp3?v=2'),
      soundComment:  new Audio('https://static.cupmanager.net/sounds/comment.mp3?v=2'),
      soundCard:  new Audio('https://static.cupmanager.net/sounds/card.mp3?v=2'),
      soundGoalOpposite:  new Audio('https://static.cupmanager.net/sounds/goal_opposite.mp3?v=2'),
      soundStart:  new Audio('https://static.cupmanager.net/sounds/match_start.mp3?v=2')}
  },[]);

  const t = useCurrentTournament();
  const T = useTranslations('cmresults');

  const [devResetTime, setDevResetTime] = useState(Date.now());
  const [renderingTime, setRenderingTime] = useState(devResetTime);
  const [gameFinished, setGameFinished] = useState(_game.finished);

  const game = {
    ..._game,
    finished: gameFinished
  }

  useEffect(() => {
    setRenderingTime(devResetTime)
  }, [devResetTime])

  const switchBotSide = function(event: MatchEvent) {

    const lastGoal = events.find(e => e.absoluteTime < event.absoluteTime && e.__typename === "MatchGoal");
    if( lastGoal && lastGoal.__typename === "MatchGoal"){
      return lastGoal.side;
    }
    
    return undefined;
  }


  const timeoutsHome = events.filter(event => event.__typename === 'MatchTimeout' && event.side === 'home').map(event => event.id);
  const timeoutsAway = events.filter(event => event.__typename === 'MatchTimeout' && event.side === 'away').map(event => event.id);

  const [suspenseEnded, setSuspenseEnded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth();

  const [toRender, setToRender] = useState<undefined | number>(undefined);

  //const firstStart = [...events].reverse().find(ev => ev.__typename === 'MatchStart');
  const lastStart = events.find(ev => ev.__typename === 'MatchStart');
  
  
  const shortedEvents = toRender === undefined
    ? events
    : [...events].slice(events.length-toRender)
    


  useLayoutEffect(() => {

    if(ref.current) {
      const childEls = Array.from(ref.current.getElementsByTagName("div")[0].children) as HTMLElement[];


      if(windowWidth >= 768) {
        let lastSide = 'both' as 'home' | 'away' | 'both';
        let distanceSameSide = 0;
        let lastHeight = 0;

        for(let el of childEls) {

          let timeHeight = 0;
          el = el.children[0] as HTMLElement;

          if (el.classList.contains('no_time')) {
            timeHeight = 0;
          } else {
            timeHeight = 75;
          }

          // CURRENT EL POSITION
          let thisSide = 'both' as 'home' | 'away' | 'both';

          let thisHeight = el.clientHeight;

          if (el.classList.contains('both')) {
            thisSide = 'both';
          }
          if (el.classList.contains('home')) {
            thisSide = 'home';
          }
          if (el.classList.contains('away')) {
            thisSide = 'away';
          }

          
          if( lastSide === 'both') {
            distanceSameSide = thisHeight;
          } else if(thisSide === lastSide) {
            distanceSameSide += thisHeight;
          } else if (thisSide === 'both') {
            // GÖR INGET 
          } else {
            // Bytt sida
            const distanceOtherSide = lastHeight/2 + thisHeight/2 - timeHeight;
            
            distanceSameSide += (lastHeight - thisHeight) / 2 + (timeHeight  + 16);
            
            const moveAmount = Math.min(distanceOtherSide,distanceSameSide); 

            if(moveAmount > 0 ){
              el.style.marginTop = `-${moveAmount}px`;
            }

            // Återställ distanceSameSide
            distanceSameSide = 0;

            distanceSameSide += (thisHeight-lastHeight)/2 + (distanceOtherSide-moveAmount);
          }

          lastSide = thisSide;
          lastHeight = thisHeight;

        }
      } else {
        for(let el of childEls) {
          el = el.children[0] as HTMLElement;
          el.style.marginTop = '1rem';
        }
      }
    }
  }, [suspenseEnded, windowWidth, toRender]);


  // STOPWATCH **********************************
  
  const isActive = !(events[0].__typename === 'MatchStop' || events[0].__typename === 'MatchTimeout')

  // / STOPWATCH **********************************

  const animationTimeOut = 500;

  const getEventEl = (event: MatchEvent) => {
    if (event.__typename === 'MatchPlayerFoul') {
      return <PlayerFoul key={event.id} event={event} code={game[event.side].team?.club.nation.code}/>
    }

    if (event.__typename === 'MatchLeaderFoul') {
      return <LeaderFoul key={event.id} event={event } code={game[event.side].team?.club.nation.code}/>
    }

    if (event.__typename === 'MatchPlayerPenalty') {
      return <PlayerPenalty event={event} key={event.id}/>
    }

    if (event.__typename === 'MatchShot') {
      
      return <Shot event={event} key={event.id}/>
    }

    if(event.__typename === 'MatchComment') {
      const commentKey = `${event.side}_${event.comment}`;
      return groupedComments(events)[commentKey][0].id === event.id && <Comment 
      key={event.id}
      event={event}
      commentCount={groupedComments(events)[commentKey].length - 1 /* Do not show if only 1 */}
      colors={
        {
          primary: game[event.side].team?.shirt?.color1 || 'rgb(var(--clr-gray-700))',
          secondary: game[event.side].team?.shirt?.anti || 'white'
        }
      }
      />
    }

    if (event.__typename === 'MatchCard') {
      return <PlayerCard event={event} key={event.id} />
    }

    if(event.__typename === 'MatchBotComment') {
      return <Commentator 
        side={switchBotSide(event)} 
        comment={T.select(event.comment) || ''} 
        key={event.id}
      />
    }

    if (event.__typename === 'MatchTimeout') {
      return <Timeout event={event} key={event.id} timeoutsHome={timeoutsHome} timeoutsAway={timeoutsAway} code={game[event.side].team?.club.nation.code} nationName={game[event.side].team?.club.nation.name} />
    }

    if(event.__typename === 'MatchGoal') {
      return <Goal 
        game={game}
        team={game[event.side].team}
        event={event} 
        key={event.id} 
        code={game[event.side].team?.club.nation.code}
        nationName={game[event.side].team?.club.nation.name}
        sport={t.subcup.sport.type} 
        colors={
          {
            primary: game[event.side].team?.shirt?.color1 || 'rgb(var(--clr-gray-700))',
            secondary: game[event.side].team?.shirt?.anti || 'white'
          }
        }
      />
    }
      
    if(event.__typename === 'MatchStart' && event.isPeriodStart) {
      return <React.Fragment key={event.id}>
        {event.period === 0 && t.botCommentScheme !== 'none' && <>

          <Commentator 
            team={'both'}
            type={'positive'}
            side={undefined}
            comment={
              T(`Vi välkomnar X och Y till denna spännande match! Vi arbetar för Cup Manager och heter Oscar och Sabina.`, T.select(game.home.name), T.select(game.away.name) )
            }
            key={event.id}
          />
        </>}
        {false && event.period > 0 && <div className={styles.period_arrow}>
          <FontAwesomeIcon icon={faArrowUp} />
        </div>}
        <PeriodStart 
          sport={t.subcup.sport.type}
          startTime={game.feed.liveStartTime}
          event={event}
          audio={sounds.soundStart}
          soundOn={soundOn}
          eventsBefore={eventsBefore}
          key={event.id}
          hiddenPeriods={hiddenPeriods}
          setHiddenPeriods={setHiddenPeriods}
          periodTypes={game.result.periodTypes}
          ordinaryPeriodCount={game.result.ordinaryPeriodCount}
        />
        
      </React.Fragment>
    }

    return false;
  }

  const nodeRef = useRef<any>(null);

  // EFFECTS AND SOUND
  const homeId = game.home.id;
  const awayId = game.away.id;

  const favorites = useContext(FavoritesContext).favorites;
  
  const getGoalSound = (goalTeamId: number) => {
    const isFavorites = favorites && favorites.length > 0;

    if(isFavorites) { //Favorites are set
      const isMatchActorFavorite = favorites.includes(homeId) || favorites.includes(awayId);
      const areMatchActorsFavorites = favorites.includes(homeId) && favorites.includes(awayId); 

      if(areMatchActorsFavorites) { // Both are favorites
        return sounds.soundGoal;
      } else if(isMatchActorFavorite) { // One is favorite
        if(favorites.includes(goalTeamId)){
          return sounds.soundGoal;
        } else {
          return sounds.soundGoalOpposite
        }
      }
    }

    return sounds.soundGoal;
  }

  // / EFFECTS AND SOUND

  useEffect(() => {
    const event = shortedEvents[0];
    if(event && event.absoluteTime > renderingTime){
      
      // GOAL
      if(event.__typename === "MatchGoal") {
        soundOn && getGoalSound(game[event.side].id).play(); // The audio
        goalFxOn && document.body.classList.add('goal'); // Show overlay
      }

      if(event.__typename === 'MatchComment' || event.__typename === 'MatchBotComment') {
        soundOn && sounds.soundComment.play()
      }

      if(event.__typename === 'MatchStart') {
        soundOn && sounds.soundStart.play();
      }


      if(event.__typename === 'MatchCard') {
        event.color !== 'green' && soundOn && sounds.soundCard.play();
      }

      setRenderingTime(event.absoluteTime);
    }
    
  }, [shortedEvents[0] && shortedEvents[0].id]);
  
  return <div ref={ref} className={styles.wrapper}  onAnimationEnd={()=> {
    !suspenseEnded && setSuspenseEnded(true)
  }} style={{'--event-timeout' : `${animationTimeOut}ms`} as any}>

    {!game.finished && lastStart && <StopWatch event={lastStart} max={game.duration} isActive={isActive} />}


    <TransitionGroup className={styles.events_wrapper}>
      {shortedEvents.map(event => {

        const element = getEventEl(event);

        return element && <CSSTransition
        timeout={animationTimeOut}
        key={event.id}
        ref={nodeRef}
        classNames={{
          enter: styles.eventEnter,
          enterActive: styles.eventEnterActive,
          exit: styles.eventExit,
          exitActive: styles.eventExitActive
        }}
      >
        <div className={classNames(styles.event, "period_" + event.period, hiddenPeriods.has(event.period) && event.__typename !== "MatchStart" && styles.period_hidden_event)} ref={nodeRef}>
        {element}
        </div>
      </CSSTransition>
      })}
    </TransitionGroup>
    {dev && <div className="dev-tools">
      <button onClick={() => setToRender(toRender === undefined ? 0 : toRender + 1)}>
        + MatchEvent
      </button>
      <button onClick={() => setDevResetTime(devResetTime === 0 ? Date.now() : 0)}> 
        {devResetTime === 0 ? <>Set time to now</> : <>Set time to 0</>}
      </button>
      <button onClick={() => setGameFinished(!gameFinished)}>
        {gameFinished ? <>Set not finished</> : <>Set finished</>}
      </button>
    </div>}
    
  </div>
}

