import styles from './HorizontalbarSingle.module.scss';

type Props = {
  color: string,
  value: number
}

export default function HorizontalbarSingle ({color, value} : Props) {
  return <div className={styles.bar_container}>
    <div 
      className={styles.bar}
      style={{
        '--width': !isNaN(value) ? `${value * 100}%` : '0%',
        background: color
      } as any}
    ></div>
  </div>
}