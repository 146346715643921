import { useTranslations } from "cupman-utils";
import { useDivision, Division } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import Games from "../../components/Game/Games";
import Conference from "../../components/Conference/Conference";
import Playoff from "../../components/Conference/Playoff";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader/PageHeader";
import styles from './DivisionPage.module.scss';
import TopScrollShadow from "../../components/TopScrollShadow/TopScrollShadow";
import classNames from "classnames";
import PrevNextStages from "../../components/PrevNextStages/PrevNextStages";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import PendingPage from "../../components/Pending/PendingPage";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import { useLink } from "granular";


const mobile = window.matchMedia('(hover: none)').matches;

const fragment =  Division.fragment({
  ___on_Conference: {
    pseudoTeams: [{}],
    table: {
      rows: [{
        team: {
          club: {
            nation: {}
          }
        }
      }],
    },
  },
  finished: {},
  stage: {
    nextStages: [{}],
  },
  category: {},
}, Playoff.fragment).___on_Division

export default function DivisionPageDivision ({division : _division} : {division: Division}) {

  const params = useParams<{id:string}>();
  const T = useTranslations('cmresults');

  const division = useLink(_division, fragment);

  return <><div className={classNames("max-50")}>
    <ErrorBoundary fallback={({error, errorInfo}) => <div style={{marginTop: '3rem'}}>
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load group.')}/>
    </div>}>
      {"table" in division && <div className={styles.conference_table_container}>
        <Conference 
          hideResult = {division.category.hideConferenceResults}
          linkTeams={true}
          matchesCanTie={division.category.matchesCanTie}
          nextStages={division.category.hideConferenceResults ? undefined : division.stage.nextStages}
          table={division.table as any} 
          name={T.select(division.name) || T('Group')}
          className={'full_width'}
        />
        {division.table.hasTiedTeams && division.finished.finished && !division.category.hideConferenceResults && division.table.rows.find(r => r.targetStage || r.targetStageTeamConference) && <div className={styles.result_warning}>
        <h3>{T('Please note:')}</h3>
          <span>{T('There are teams in the group that have exactly the same rank. Until it is resolved, the teams will not move to the next stage')}</span>
          </div>}
        {division.table.hasFixedRanks && !division.category.hideConferenceResults && division.table.rows.find(r => r.targetStage || r.targetStageTeamConference) && <div className={styles.result_warning}>
        <h3>{T('Please note:')}</h3>
          <span>{T('The teams in the group have been ordered in a fixed order, which might be different from the sort order below.')}</span>
          </div>}
        {!division.category.hideConferenceResults && <div className={styles.result_order}>
          <h3>{T('Sort order:')}</h3>
          {division.category.resultOrder.map((o, i) => <span>{i + 1}. {T.select(o)}</span>)}
        </div>}
      </div>}
    </ErrorBoundary>
    
  </div>

  <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load the playoff')}/>}>
    {division.__typename === "Playoff" && <Playoff playoff={division} />}
  </ErrorBoundary></>
}