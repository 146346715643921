import { Team$statistics } from "../../api/schema"
import ContentGrayContainer from "../ContentGrayContainer"
import styles from './matchStatistics.module.scss';
import { useTranslations } from "cupman-utils";
import WonMatches from "./WonMatches";
import TiedMatches from "./TiedMatches";
import LostMatches from "./LostMatches";
import SavesCount from "./SavesCount";
import SavesPercentage from "./SavesPercentage";
import GoalsCount from "./GoalsCount";
import ShotEfficiency from "./ShotEfficiency";
import PenaltiesMinutes from "./PenaltiesMinutes";
import PenaltiesCount from "./PenaltiesCount";
import classNames from "classnames";
import ConcededGoals from "./ConcededGoals";

type Props = {
  statistics: {home: Team$statistics, away: Team$statistics},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
  registerShots: boolean
}

export default function IcehockeyStatisticsTotal ({statistics, teamColors, registerShots} : Props) {

  const T = useTranslations('cmresults');
  
  return <>
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Matcher')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <WonMatches statistics={statistics} teamColors={teamColors} />
      <TiedMatches statistics={statistics} teamColors={teamColors} />
      <LostMatches statistics={statistics} teamColors={teamColors} />
    </ContentGrayContainer>

    <h3 className={classNames(styles.statistics_group_header, 'custom-font')}>
      {registerShots ? T('Skott & mål under cupen') : T('Mål under cupen')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      {registerShots && <ShotEfficiency statistics={statistics} teamColors={teamColors} />}
      <GoalsCount statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
      <ConcededGoals statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
    </ContentGrayContainer>

    {registerShots && <>
      <h3 className={classNames(styles.statistics_group_header, 'custom-font')}>
        {T('Räddningar')}
      </h3>
      <ContentGrayContainer className={styles.gray_container}>
        <SavesCount statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
        <SavesPercentage statistics={statistics} teamColors={teamColors} />
      </ContentGrayContainer>
      </>}

    <h3 className={classNames(styles.statistics_group_header, 'custom-font')}>
      {T('Utvisningar under cupen')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <PenaltiesMinutes statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
      <PenaltiesCount statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
    </ContentGrayContainer>
  </>
}