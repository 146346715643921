import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from "date-fns";
import { useState } from "react";
import useGlobalSecondTick from "../../utils/useGlobalSecondTick";
import Countdown from "./Countdown";

export default function ActiveCountdown({to, color} : {to: number, color?: string}) {
    function rest() {
        return {
            days: Math.max(0,differenceInDays(to, Date.now())),
            hours: Math.max(0,differenceInHours(to, Date.now())) % 24,
            mins: Math.max(0,differenceInMinutes(to, Date.now())) % 60,
            secs: Math.max(0,differenceInSeconds(to, Date.now())) % 60
        }
    }
    
    const [date, setDate] = useState(rest());
    
    useGlobalSecondTick(() => {
        setDate(rest());
    });
    
    return <Countdown color={color} days={date.days} hours={date.hours} mins={date.mins} secs={date.secs} />
}