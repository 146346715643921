type props = {
  color?: string,
  className?:string,
  style?: React.CSSProperties
}

export default function CupPassIconBold ({color, className, style} : props) {

  const iconColor = color ? color : 'white';

  return <svg version="1.1" id="Lager_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 278.4 359.6" xmlSpace="preserve" className={className} style={style}>
<g>
 <path style={{fill: iconColor}} d="M115.4,277.4H87.4c-3.1,0-5.7-2.6-5.7-5.7v-28.4c0-8.3-6.7-15-15-15s-15,6.7-15,15v28.4
   c0,19.7,16,35.7,35.7,35.7h27.9c8.3,0,15-6.7,15-15S123.7,277.4,115.4,277.4z"/>
 <path style={{fill: iconColor}} d="M65.8,209.5c8.3,0,15-6.7,15-15v-29.3c0-3.1,2.6-5.7,5.7-5.7h27.8c8.3,0,15-6.7,15-15s-6.7-15-15-15H86.4
   c-19.7,0-35.7,16-35.7,35.7v29.3C50.8,202.7,57.5,209.5,65.8,209.5z"/>
 <path style={{fill: iconColor}} d="M192,130.4h-28.1c-8.3,0-15,6.7-15,15s6.7,15,15,15H192c3.1,0,5.7,2.6,5.7,5.7v29.3c0,8.3,6.7,15,15,15
   s15-6.7,15-15v-29.3C227.7,146.4,211.7,130.4,192,130.4z"/>
 <path style={{fill: iconColor}} d="M212.7,228.2c-8.3,0-15,6.7-15,15v28.4c0,3.1-2.6,5.7-5.7,5.7h-28.1c-8.3,0-15,6.7-15,15s6.7,15,15,15h32.2
   c2.6,0,5.1-0.7,7.2-1.9c14.1-4.7,24.4-18.1,24.4-33.8v-28.4C227.7,235,221,228.2,212.7,228.2z"/>
 <path style={{fill: iconColor}} d="M128.7,189.8c-0.1-0.5-0.1-1-0.2-1.5c-0.1-0.5-0.2-1-0.4-1.4c-0.2-0.5-0.3-0.9-0.5-1.4
   c-0.2-0.5-0.4-0.9-0.6-1.3c-0.2-0.4-0.5-0.9-0.8-1.3c-0.3-0.4-0.6-0.8-0.9-1.2c-0.3-0.4-0.7-0.8-1-1.1c-0.4-0.4-0.7-0.7-1.1-1
   c-0.4-0.3-0.8-0.6-1.2-0.9c-0.4-0.3-0.8-0.5-1.3-0.8c-0.4-0.2-0.9-0.4-1.3-0.6c-0.5-0.2-0.9-0.4-1.4-0.5c-0.5-0.1-0.9-0.3-1.4-0.4
   c-0.5-0.1-1-0.2-1.5-0.2c-1-0.1-2-0.1-2.9,0c-0.5,0.1-1,0.1-1.5,0.2c-0.5,0.1-1,0.2-1.4,0.4c-0.5,0.1-0.9,0.3-1.4,0.5
   c-0.4,0.2-0.9,0.4-1.3,0.6s-0.9,0.5-1.3,0.8c-0.4,0.3-0.8,0.6-1.2,0.9s-0.8,0.6-1.1,1c-0.3,0.3-0.7,0.7-1,1.1
   c-0.3,0.4-0.6,0.8-0.9,1.2c-0.3,0.4-0.5,0.8-0.8,1.3c-0.2,0.4-0.4,0.9-0.6,1.3c-0.2,0.4-0.4,0.9-0.5,1.4c-0.1,0.5-0.3,0.9-0.4,1.4
   c-0.1,0.5-0.2,1-0.2,1.5c0,0.5-0.1,1-0.1,1.5c0,0.5,0,1,0.1,1.5c0,0.5,0.1,1,0.2,1.4c0.1,0.5,0.2,1,0.4,1.4
   c0.1,0.5,0.3,0.9,0.5,1.4c0.2,0.5,0.4,0.9,0.6,1.3c0.2,0.4,0.5,0.9,0.8,1.3c0.3,0.4,0.6,0.8,0.9,1.2c0.3,0.4,0.6,0.8,1,1.1
   c0.4,0.4,0.7,0.7,1.1,1c0.4,0.3,0.8,0.6,1.2,0.9c0.4,0.3,0.8,0.5,1.3,0.8s0.9,0.4,1.3,0.6c0.4,0.2,0.9,0.4,1.4,0.5
   c0.5,0.1,1,0.3,1.4,0.4c0.5,0.1,1,0.2,1.5,0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.5-0.1c0.5,0,1-0.1,1.5-0.2c0.5-0.1,1-0.2,1.4-0.4
   c0.5-0.1,0.9-0.3,1.4-0.5c0.5-0.2,0.9-0.4,1.3-0.6c0.4-0.2,0.9-0.5,1.3-0.8c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.6,1.1-1
   c0.3-0.4,0.7-0.7,1-1.1c0.3-0.4,0.6-0.8,0.9-1.2c0.3-0.4,0.5-0.8,0.8-1.3s0.4-0.9,0.6-1.3c0.2-0.4,0.3-0.9,0.5-1.4
   c0.1-0.5,0.3-1,0.4-1.4c0.1-0.5,0.2-1,0.2-1.4c0-0.5,0.1-1,0.1-1.5C128.8,190.8,128.8,190.3,128.7,189.8z"/>
 <path style={{fill: iconColor}} d="M128.5,242.2c-0.1-0.5-0.2-0.9-0.4-1.4c-0.2-0.5-0.3-0.9-0.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.3s-0.5-0.9-0.8-1.3
   c-0.3-0.4-0.6-0.8-0.9-1.2c-0.3-0.4-0.7-0.7-1-1.1c-0.4-0.4-0.7-0.7-1.1-1c-0.4-0.3-0.8-0.6-1.2-0.9c-0.4-0.3-0.8-0.5-1.3-0.8
   c-0.4-0.2-0.9-0.4-1.3-0.6c-0.5-0.2-0.9-0.4-1.4-0.5c-0.5-0.1-1-0.3-1.4-0.4c-0.5-0.1-1-0.2-1.5-0.2c-1-0.1-2-0.1-2.9,0
   c-0.5,0-1,0.1-1.5,0.2c-0.5,0.1-1,0.2-1.4,0.4c-0.5,0.1-0.9,0.3-1.4,0.5c-0.4,0.2-0.9,0.4-1.3,0.6c-0.4,0.2-0.9,0.5-1.3,0.8
   c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0.3-0.8,0.6-1.1,1c-0.3,0.4-0.7,0.7-1,1.1c-0.3,0.4-0.6,0.8-0.9,1.2c-0.3,0.4-0.5,0.8-0.8,1.3
   s-0.4,0.9-0.6,1.3c-0.2,0.4-0.4,0.9-0.5,1.4c-0.1,0.5-0.3,1-0.4,1.4c-0.1,0.5-0.2,1-0.2,1.5c0,0.5-0.1,1-0.1,1.5c0,0.5,0,1,0.1,1.5
   c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.2,1,0.4,1.4c0.1,0.5,0.3,0.9,0.5,1.4c0.2,0.5,0.4,0.9,0.6,1.3c0.2,0.4,0.5,0.9,0.8,1.3
   c0.3,0.4,0.6,0.8,0.9,1.2c0.3,0.4,0.6,0.8,1,1.1c0.4,0.4,0.7,0.7,1.1,1c0.4,0.3,0.8,0.6,1.2,0.9c0.4,0.3,0.8,0.5,1.3,0.8
   c0.4,0.2,0.9,0.4,1.3,0.6c0.4,0.2,0.9,0.4,1.4,0.5c0.5,0.1,1,0.3,1.4,0.4c0.5,0.1,1,0.2,1.5,0.2c0.5,0.1,1,0.1,1.5,0.1
   c0.5,0,1,0,1.5-0.1c0.5,0,1-0.1,1.5-0.2c0.5-0.1,1-0.2,1.4-0.4c0.5-0.1,0.9-0.3,1.4-0.5c0.5-0.2,0.9-0.4,1.3-0.6
   c0.4-0.2,0.9-0.5,1.3-0.8c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.6,1.1-1c0.3-0.3,0.7-0.7,1-1.1c0.3-0.4,0.6-0.8,0.9-1.2
   c0.3-0.4,0.5-0.8,0.8-1.3c0.2-0.4,0.4-0.9,0.6-1.3c0.2-0.4,0.3-0.9,0.5-1.4c0.1-0.5,0.3-1,0.4-1.4c0.1-0.5,0.2-1,0.2-1.5
   c0.1-0.5,0.1-1,0.1-1.5c0-0.5,0-1-0.1-1.5C128.7,243.2,128.6,242.7,128.5,242.2z"/>
 <path style={{fill: iconColor}} d="M153.7,195.6c0.1,0.5,0.3,0.9,0.5,1.4c0.2,0.5,0.4,0.9,0.6,1.3s0.5,0.9,0.8,1.3c0.3,0.4,0.6,0.8,0.9,1.2
   c0.3,0.4,0.6,0.8,1,1.1c0.3,0.4,0.7,0.7,1.1,1c0.4,0.3,0.8,0.6,1.2,0.9c0.4,0.3,0.8,0.5,1.3,0.8c0.4,0.2,0.9,0.4,1.3,0.6
   c0.5,0.2,0.9,0.4,1.4,0.5c0.5,0.1,0.9,0.3,1.4,0.4c0.5,0.1,1,0.2,1.5,0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.5-0.1
   c0.5,0,1-0.1,1.5-0.2c0.5-0.1,1-0.2,1.4-0.4c0.5-0.1,0.9-0.3,1.4-0.5c0.4-0.2,0.9-0.4,1.3-0.6s0.9-0.5,1.3-0.8
   c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.6,1.1-1s0.7-0.7,1-1.1c0.3-0.4,0.6-0.8,0.9-1.2c0.3-0.4,0.5-0.8,0.8-1.3
   c0.2-0.4,0.4-0.9,0.6-1.3c0.2-0.4,0.4-0.9,0.5-1.4c0.1-0.5,0.3-1,0.4-1.4c0.1-0.5,0.2-1,0.2-1.4c0-0.5,0.1-1,0.1-1.5
   c0-0.5,0-1-0.1-1.5c0-0.5-0.1-1-0.2-1.5c-0.1-0.5-0.2-1-0.4-1.4c-0.1-0.5-0.3-0.9-0.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.3
   c-0.2-0.4-0.5-0.9-0.8-1.3c-0.3-0.4-0.6-0.8-0.9-1.2c-0.3-0.4-0.6-0.8-1-1.1c-0.4-0.4-0.7-0.7-1.1-1c-0.4-0.3-0.8-0.6-1.2-0.9
   c-0.4-0.3-0.8-0.5-1.3-0.8s-0.9-0.4-1.3-0.6c-0.4-0.2-0.9-0.4-1.4-0.5c-0.5-0.1-0.9-0.3-1.4-0.4c-0.5-0.1-1-0.2-1.5-0.2
   c-1-0.1-2-0.1-2.9,0c-0.5,0.1-1,0.1-1.5,0.2c-0.5,0.1-1,0.2-1.4,0.4c-0.5,0.1-0.9,0.3-1.4,0.5c-0.4,0.2-0.9,0.4-1.3,0.6
   c-0.4,0.2-0.9,0.5-1.3,0.8c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0.3-0.8,0.6-1.1,1c-0.4,0.3-0.7,0.7-1,1.1c-0.3,0.4-0.6,0.8-0.9,1.2
   c-0.3,0.4-0.5,0.8-0.8,1.3c-0.2,0.4-0.4,0.9-0.6,1.3c-0.2,0.4-0.4,0.9-0.5,1.4c-0.2,0.5-0.3,0.9-0.4,1.4c-0.1,0.5-0.2,1-0.2,1.5
   c-0.1,0.5-0.1,1-0.1,1.5c0,0.5,0,1,0.1,1.5c0,0.5,0.1,1,0.2,1.4C153.4,194.7,153.5,195.1,153.7,195.6z"/>
 <path style={{fill: iconColor}} d="M207.6,0H70.9C31.8,0,0,31.8,0,70.9v217.9c0,39.1,31.8,70.9,70.9,70.9h136.7c39.1,0,70.9-31.8,70.9-70.9V70.9
   C278.4,31.8,246.7,0,207.6,0z M88.6,41.7h101.3c8.3,0,15,6.7,15,15s-6.7,15-15,15H88.6c-8.3,0-15-6.7-15-15S80.3,41.7,88.6,41.7z
    M248.4,288.8c0,22.5-18.3,40.9-40.9,40.9H70.9c-22.5,0-40.9-18.3-40.9-40.9l0.4-131.6c0-27.1,21.2-51.3,48.3-51.3h120.6
   c26.2,0,47.8,22.6,49.2,48.6V288.8z"/>
</g>
</svg>
}

/* 
/////// OLD VERSION
<svg version="1.1" id="Lager_1" className={className} style={style} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 205.3 269.9" xmlSpace="preserve" role="presentation">
    <path style={{fill: iconColor}} d="M168,0H37.3C16.7,0,0,16.7,0,37.3v195.3c0,20.6,16.7,37.3,37.3,37.3H168c20.6,0,37.3-16.7,37.3-37.3V37.3
    C205.3,16.7,188.6,0,168,0z M144,31.8c0,6.1-5,11.1-11.1,11.1H72.5c-6.1,0-11.1-5-11.1-11.1c0-6.1,5-11.1,11.1-11.1h60.4
    C139,20.8,144,25.7,144,31.8z M188.9,253.5c-5.6,5.6-13,8.7-20.9,8.7H37.3c-7.9,0-15.3-3.1-20.9-8.7c-5.6-5.6-8.7-13-8.7-20.9V91.1
    C9,75.2,22.5,62.6,38.7,62.6h128c15.8,0,29,12,30.9,27.3v142.6C197.6,240.4,194.5,247.9,188.9,253.5z"/>
    <path style={{fill: iconColor}} d="M155,91.8H50.3C39.1,91.8,30,100.9,30,112v104.6c0,11.2,9.1,20.3,20.3,20.3H155c11.2,0,20.3-9.1,20.3-20.3V112
      C175.3,100.9,166.2,91.8,155,91.8z M167.6,216.7c0,7-5.7,12.6-12.6,12.6H50.3c-7,0-12.6-5.7-12.6-12.6V112c0-7,5.7-12.6,12.6-12.6
      H155c7,0,12.6,5.7,12.6,12.6V216.7z"/>
    <g>
      <path style={{fill: iconColor}} d="M145.1,213.8h-24.6c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8h24.6c0,0,0,0,0,0l0-24.7
        c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8v24.6C152.8,210.3,149.3,213.8,145.1,213.8z"/>
      <circle style={{fill: iconColor}} cx="120.4" cy="179.3" r="8.2"/>
      <g>
        <path style={{fill: iconColor}} d="M84.9,155H60.2c-4.3,0-7.7-3.5-7.7-7.7v-24.6c0-4.3,3.5-7.7,7.7-7.7h24.6c4.3,0,7.7,3.5,7.7,7.7v24.6
          C92.6,151.6,89.1,155,84.9,155z M60.2,122.6C60.2,122.6,60.2,122.6,60.2,122.6l0,24.7c0,0,0,0,0,0l24.7,0c0,0,0,0,0,0
          c0,0,0,0,0,0l0-24.7c0,0,0,0,0,0L60.2,122.6z"/>
        <circle style={{fill: iconColor}} cx="72.5" cy="135" r="5.8"/>
      </g>
      <g>
        <path style={{fill: iconColor}} d="M84.9,213.8H60.2c-4.3,0-7.7-3.5-7.7-7.7v-24.6c0-4.3,3.5-7.7,7.7-7.7h24.6c4.3,0,7.7,3.5,7.7,7.7v24.6
          C92.6,210.3,89.1,213.8,84.9,213.8z M60.2,181.4C60.2,181.4,60.2,181.4,60.2,181.4l0,24.7c0,0,0,0,0,0l24.7,0c0,0,0,0,0,0
          c0,0,0,0,0,0l0-24.7c0,0,0,0,0,0L60.2,181.4z"/>
        <circle style={{fill: iconColor}} cx="72.5" cy="193.8" r="5.8"/>
      </g>
      <g>
        <path style={{fill: iconColor}} d="M145.1,155.1h-24.6c-4.3,0-7.7-3.5-7.7-7.7v-24.6c0-4.3,3.5-7.7,7.7-7.7h24.6c4.3,0,7.7,3.5,7.7,7.7v24.6
          C152.8,151.6,149.3,155.1,145.1,155.1z M120.4,122.7C120.4,122.7,120.4,122.7,120.4,122.7l0,24.7c0,0,0,0,0,0l24.7,0c0,0,0,0,0,0
          c0,0,0,0,0,0l0-24.7c0,0,0,0,0,0L120.4,122.7z"/>
        <circle style={{fill: iconColor}} cx="132.7" cy="135.1" r="5.8"/>
      </g>
    </g>
  </svg>

*/