import { faCircleExclamation, faGrid } from '@fortawesome/pro-light-svg-icons';
import { faAngleRight, faArrowRight, faInfoCircle, faRectangleVerticalHistory } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { useLink } from 'granular';
import { Edition, Tournament } from '../../api/schema';
import { useCurrentTournament } from '../../App';
import ContentContainer from '../../components/ContentContainer/ContentContainer';
import CustomIconFa from '../../components/CustomIconFa/CustomIconFa';
import { joinAnd } from '../../utils';
import styles from './HistoryPage.module.scss';

export default function HistoryPage () {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const tournamentWithEditions = useLink(t, {
    cup: {
      editions: [{
        tournaments: [{
          subcup: {
            sport: {}
          }
        }]
      }]
    }
  });

  const editions = tournamentWithEditions.cup.editions
    .filter(edition => edition.tournaments.some(tournament => tournament.teamsPublished));


  const getCountNumText = (num: number) => {
    if (num < 13) {
      let count;
      switch(num) {
        case 1:
          count = T('one');
          break;
        case 2:
          count = T('two');
          break;
        case 3:
          count = T('three');
          break;
        case 4:
          count = T('four');
          break;
        case 5:
          count = T('five');
          break;
        case 6:
          count = T('six');
          break;
        case 7:
          count = T('seven');
          break;
        case 8:
          count = T('eight');
          break;
        case 9:
          count = T('nine');
          break;
        case 10:
          count = T('ten');
          break;
        case 11:
          count = T('eleven');
          break;
        case 12:
          count = T('twelve');
          break;
      }
      return count;
    } else {
      return num;
    }
  }

  const getEditionsText = () => {
    if(editions.length === 1) {
      return T('X Y is the first time X was organized using Cup Manager.', t.cup.name, t.edition.name)
    }
    if(editions.length > 1) {
      return T('X has been organized Y previous time(s):Z using Cup Manager.', t.cup.name, getCountNumText(editions.length-1), editions.length-1)
    }
  }

  const editionWithMostTournaments = editions.reduce((editionWithMostTournaments, edition) => {
    return editionWithMostTournaments.tournaments.filter(t => t.teamsPublished).length < edition.tournaments.filter(t => t.teamsPublished).length
      ? edition
      : editionWithMostTournaments
  }, editions[0]);

  const editionTournamentsSports = editionWithMostTournaments.tournaments.map(tournament => {
    return T.select(tournament.subcup.name);
  });


  const getTournamentText = () => {
    if(editionWithMostTournaments.tournaments.length === 1) {
      return T.select(editionWithMostTournaments.tournaments[0].subcup.name);
    } else {
      return T('X was divided into Y tournaments: Z.', 
        t.cup.name,
        getCountNumText(
          editionWithMostTournaments.tournaments.length
        ),
        joinAnd(editionTournamentsSports, T('and')).toLowerCase()
      )
    }
  };

  const onlyOneTournament = editions.reduce((numTournaments, edition) => {
    return edition.tournaments.length > 1
      ? edition.tournaments.length
      : numTournaments;
  }, 1) === 1;

  return <div className="max-75">
    <h1>
      {T('X - History', t.cup.name)}
    </h1>
    <div className={styles.editions}>
      {editions.length > 1

        ? <>
          <h2>
            <FontAwesomeIcon icon={faInfoCircle}/> {T('Summary')}
          </h2>
          <ContentContainer>
            <div className={styles.summary}>
              {getEditionsText()} {getTournamentText()} {T('We look forwards to many more successful tournaments in the future')}
            </div>
          </ContentContainer>

          <h2>
            <FontAwesomeIcon icon={faGrid}/> {T('Editions')}
          </h2>
          <ul className={styles.edition_grid}>

            {onlyOneTournament
            // Only one tournament view
              ? editions.map(edition => {

                const editionPublished = edition.tournaments[0].teamsPublished;
                const thisTournament = t.id === edition.tournaments[0].id;
  
                return <li key={edition.id}>
                  {editionPublished
                    ? <h2>
                    {thisTournament 
                      ? <>
                        <FontAwesomeIcon icon={faArrowRight} /> {edition.name}
                      </>
                      : <>
                        <a href={edition.tournaments[0].publicResultsUrl} className={styles.tournament}>
                          {edition.name}
                        </a>
                      </>
                    }
                  </h2>
                  : <>
                      <h2 className={styles.not_published_edition}>{edition.name}</h2>
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faCircleExclamation} /> <span className={styles.not_published_tournament}>{T('No tournament published.')}</span>
                        </li>
                      </ul>
                    </>
                  }
                </li>
              })
              : editions.map(edition => {

                const editionPublished = edition.tournaments.some(tournament => tournament.teamsPublished);
  
                return <li key={edition.id}>
                  {editionPublished
                    ? <h2>{edition.name}</h2>
                    : <h2 className={styles.not_published_edition}>{edition.name}</h2>
                  }
  
                  {editionPublished && !onlyOneTournament
                  // Multiple tournament view
                    ? <ul className={styles.tournaments}>
  
                      {edition.tournaments.map(tournament => {
  
                        const tournamentPublished = tournament.teamsPublished;
  
                        return t.id === tournament.id
                        ? <span className={styles.active}>
                          <FontAwesomeIcon icon={faArrowRight} /> <CustomIconFa fontWeight="bold" icon={`field-${tournament.subcup.sport.type}`}/> {T.select(tournament.subcup.name)}
                        </span>
                        : <li key={tournament.id} className={classNames(!tournamentPublished && styles.not_published_tournament)}>

                          {tournamentPublished 
                            ? <a href={tournament.publicResultsUrl} className={styles.tournament}>
                                <CustomIconFa fontWeight="regular" icon={`field-${tournament.subcup.sport.type}`}/> {T.select(tournament.subcup.name)}
                              </a>
                            : <span className={classNames(styles.tournament, !tournamentPublished && styles.disabled)}><CustomIconFa fontWeight="regular" icon={`field-${tournament.subcup.sport.type}`}/> {T.select(tournament.subcup.name)}</span>
                          }

                          
                        </li>
                      })}
  
                    </ul>
                    : <ul>
                      <li>
                        <FontAwesomeIcon icon={faCircleExclamation} /> <span className={styles.not_published_tournament}>{T('No tournament published')}</span>
                      </li>
                    </ul>
                  }
                </li>
              })
            }

            {/*editions.map(edition => {

              const editionPublished = edition.tournaments.some(tournament => tournament.teamsPublished);

              return <li key={edition.id}>
                {editionPublished
                  ? <h2>{edition.name}</h2>
                  : <h2 className={styles.not_published_edition}>{edition.name}</h2>
                }

                {editionPublished && !onlyOneTournament
                  ? <ul className={styles.tournaments}>

                    {edition.tournaments.map(tournament => {

                      const status = tournament.teamsPublished;

                      return t.id === tournament.id
                      ? <span className={styles.active}>
                        <FontAwesomeIcon icon={faAngleRight} /> <CustomIconFa fontWeight="bold" icon={`field-${tournament.subcup.sport.type}`}/> {T.select(tournament.subcup.name)}
                      </span>
                      : <li key={tournament.id} className={classNames(!status && styles.not_published_tournament)}>
                        <a href="#" className={styles.tournament}>
                          <CustomIconFa fontWeight="regular" icon={`field-${tournament.subcup.sport.type}`}/> {T.select(tournament.subcup.name)}
                        </a> 
                      </li>
                    })}

                  </ul>
                  : <ul>
                    <li>
                      <FontAwesomeIcon icon={faCircleExclamation} /> <span className={styles.not_published_tournament}>{T('Ingen turnering publiserad.')}</span>
                    </li>
                  </ul>
                }
              </li>
            })*/}
          </ul>


        </>

        : <>
          <FontAwesomeIcon icon={faCircleExclamation} /> {T('X has not been organized using Cup Manager before.', t.fullname)}
        </>}
      </div>
  </div>
}