import { ReactFragment } from "react";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../utils";
import styles from './Button.module.scss';
var cn = require('classnames');


type props = {
  url: string,
  children: ReactFragment,
  className?: string,
  size?: 'sm' | 'lg' | 'md',
  inverted?: boolean,
  fullWidth?: boolean,
  scaleVal?: number,
  ariaLabel?: string,
  external?: boolean,
}

export default function LinkButton ({url, external,children, className, size, inverted, fullWidth, scaleVal, ariaLabel} : props) {

  const extUrl =  url.indexOf('http') > -1;

  return extUrl
    ? <a href={url} className={cn('btn', className && className, buttonStyle(size ? size : 'md', inverted), fullWidth && styles.full_width)} target="_blank" style={
      scaleVal && {
        '--scale-val' : scaleVal
      } as any
    } rel="noreferrer"
    aria-label={ariaLabel}>
      {children}
    </a>
    : <Link to={url} rel={external ? 'noreferrer' : undefined} target={external ? '_blank' : undefined} className={cn('btn', className && className, buttonStyle(size ? size : 'md', inverted), fullWidth && styles.full_width)} style={
      scaleVal && {
        '--scale-val' : scaleVal
      } as any
    }
    aria-label={ariaLabel}>
    {children}
  </Link>
}