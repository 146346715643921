
import ReactDOM from "react-dom";
//import { useWindowHeight } from "../../utils/useWindowSize";
import styles from './Modal.module.scss';

type props = {
  children: JSX.Element
}

export default function Modal ({children} : props) {

  const portal = document.getElementById('portal');
  
  /*const height = useWindowHeight();
  document.documentElement.style.setProperty('--app-height', `${height}`);*/

  if (!portal) return null;
  
  return ReactDOM.createPortal(
    
  <>
    <div className="search-modal" style={{/*height*/}}>
      {children}
    </div>
  </>,
  portal)
}