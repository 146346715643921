
import ContentGrayContainer from "../ContentGrayContainer"
import styles from './matchStatistics.module.scss';
import { useTranslations } from "cupman-utils"
import GoalsCount from "./GoalsCount";
import classNames from "classnames";
type Props = {
  statistics: {home: {goals: number, lost:number}, away: {goals: number, lost:number}},
  statisticsTotal: {home: {goals: number, lost:number}, away: {goals: number, lost:number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
}

export default function OtherStatisticsMatch ({statistics, statisticsTotal, teamColors} : Props) {

  const T = useTranslations('cmresults');
  
  return <>
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Mål')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <GoalsCount statistics={statistics} statisticsTotal={statisticsTotal} teamColors={teamColors} />
    </ContentGrayContainer>
  </>
}