import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons"
import styles from './TeamTimeLine.module.scss';
import DateHeader from "./DateHeader";
import PlaceHeader from "./PlaceHeader";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { format } from "date-fns";
import EventWrapper from "./EventWrapper";
import classNames from "classnames";
import Directions from "./Directions";
import { CupColorsSpec, getColorString, useColors } from "../../colors";
import { Location$MarkerImages, Referee, Referee$timeline, Team, Team$Timeline, Team$timeline, Team$TimelineEvent as TimelineEvent, Team$timelineForLeader, Team$TimelineLocation, TeamTimeline$TimelineEventType, MeReferee$timeline } from "../../api/schema";
import QRCode from "react-qr-code";
import { useTranslations } from "cupman-utils";
import ContentContainer from "../ContentContainer/ContentContainer";
import BranchSelect from "./BranchSelect";
import { useCurrentTournament } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhistle } from "@fortawesome/pro-light-svg-icons";
import Transport from "./Transport";



export const getEventBg = (colors: CupColorsSpec, type: TeamTimeline$TimelineEventType, place:string|undefined) => {
  if(place === 'arrival') {
    return colors.complements[3];
  }

  if (type === undefined || type === 'game') {
    return colors.background.complements[3];
  }

  if(type === 'food') {
    return colors.background.secondary
  }

  return colors.background.complements[3];
};

export const getEventPlaceBg = (colors: CupColorsSpec, place:string|undefined) => {
  if(place === 'arena') {
    return colors.dark.primary;
  }
  if(place === 'lodging') {
    return colors.dark.complements[0];
  }
  //arrival:
  return colors.dark.complements[3];
}


TeamTimeLine.fragment = Team$Timeline.fragment({ 
  branches: [{
    pseudoStageTeam: {},
    pseudoTeam: {},
  }]}).___on_Team$Timeline;


type props = {
  entity:  Team | Referee,
  timeline:Team$timelineForLeader<typeof TeamTimeLine.fragment> | Team$timeline<typeof TeamTimeLine.fragment> | Referee$timeline<typeof TeamTimeLine.fragment> | MeReferee$timeline<typeof TeamTimeLine.fragment>,
  scrollToUnfinished? : boolean
}

const dayKey = (date: number) => parseInt(format(date, 'd'));

export default function TeamTimeLine({entity, timeline, scrollToUnfinished} : props) {



  const T = useTranslations('cmresults');
  const [selectedBranchId,setSelectedBranchId] = useState<number|undefined>();
  const t = useCurrentTournament();

  
  
  const selectedTimeline = (selectedBranchId ? 
        timeline.branches?.find(b =>(b.pseudoTeam?.id || b.pseudoStageTeam?.id || 0) === selectedBranchId)?.timeline
        : timeline) as Team$timeline<{locations: {}}>;

  let dateBefore:undefined|number = undefined;
  
  const [hiddenDates, setHiddenDates]  = useState(Object.fromEntries(t.days.map(d => [dayKey(d.date), d.date + 25*60*60*1000 < Date.now()])));

  const getDateHeader = (date:number) => {

    if(!dateBefore) {
      dateBefore = date;
      return <DateHeader date={date} hidden={hiddenDates[dayKey(date)] || false} setHidden={(hidden: boolean) => setHiddenDates(
        {...hiddenDates,
          [dayKey(date)]: hidden
        }
      )}/>
    }

    if(dayKey(dateBefore) !== dayKey(date)) {
      dateBefore = date;
      return <DateHeader date={date} duringActivity hidden={hiddenDates[dayKey(date)] || false} setHidden={(hidden: boolean) => setHiddenDates(
        {...hiddenDates,
          [dayKey(date)]: hidden
        }
      )}/>
    }
  }

  const getEvent = (event:TimelineEvent, place:Location$MarkerImages, locationName:string, i:number) => {
    const hidden = hiddenDates[dayKey(event.startTime)] || false;
    if(!dateBefore || dayKey(dateBefore) ===  dayKey(event.startTime)) {
      return <EventWrapper hidden={hidden} entity={entity} eventData={event} place={place} locationName={locationName} index={i} />
    } else {
      dateBefore = event.startTime;
      return <>
        <DateHeader date={event.startTime} duringActivity={true} place={place} hidden={hiddenDates[dayKey(event.startTime)] || false} setHidden={(hidden: boolean) => setHiddenDates(
        {...hiddenDates,
          [dayKey(event.startTime)]: hidden
        }
      )}/>
        <EventWrapper hidden={hidden} entity={entity} eventData={event} place={place} locationName={locationName} index={i} />
      </>
    }
  }

  const getPlaceHeader = (place:Team$TimelineLocation) => {
    if(place.events[0]?.type !== 'arrival' && place.events[0]?.type !== 'departure') {
      return <PlaceHeader place={place} hidden={dateBefore && hiddenDates[dayKey(dateBefore)] || false}/>
    }
  }

  const getArrivalTransport = (place:Team$TimelineLocation, hidden: boolean) => {
    const e = place.events[0];
    if(e.type === 'arrival' && e.arrival?.transport) {
      return <Transport transport={e.arrival.transport} hidden={hidden} direction="arrival"/>
    }
  }

  const getDepartureTransport = (place:Team$TimelineLocation, hidden: boolean) => {
    const e = place.events[0];
    if(e.type === 'departure' && e.arrival?.transport) {
      return <Transport transport={e.arrival.transport} hidden={hidden} direction="departure"/>
    }
  }

  const eventWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = eventWrapper.current;
      if(el) {
      const theEl = el?.querySelector('.unfinished') as HTMLDivElement;
      const fromTop = theEl && theEl.getBoundingClientRect().y;


      scrollToUnfinished && setTimeout(() => {
        theEl && window.scrollTo({
          left:0, 
          top: fromTop - 350,
          behavior: 'smooth'
        })
      }, 400)
    }


  }, [eventWrapper, entity])

  return (
    <ContentContainer  fullWidth icon={faCalendarAlt} header={/*T('Tidslinje')*/ undefined}>
      
      <div className={classNames(styles.timeline_container, 'max-50')} style={{'--clr-directions' : `235, 235, 235`} as any} ref={eventWrapper}>
        {selectedTimeline.locations.map((location, index) => {
          
            const hidden = dateBefore && hiddenDates[dayKey(dateBefore)] || false;

            return <React.Fragment key={index}>
              {getDateHeader(location.events[0].startTime)}
              {location.travel?.from && <Directions travel={location.travel}  hidden={hidden} />}
              {getPlaceHeader(location)}
              
              {getDepartureTransport(location,hidden)}
              {/*<div className={classNames(styles.place_events_container, styles[location.type])}>*/}
              <div className={classNames(styles.place_events_container, styles[location.type])}>
                {location.events.map((event, i) => <React.Fragment key={i}>
                  {getEvent(event, location.type, location.name, index+i)}
                </React.Fragment>)}
                </div>
              {/*</div>*/}
              {getArrivalTransport(location,hidden)}
               

              {location.events.slice(-1)[0].match?.isLastConferenceMatch && (timeline.branches?.length || 0) > 0 &&
                <BranchSelect 
                lastLocation={index === timeline.locations.length-1} 
                timeline={timeline} 
                selectedBranchId={selectedBranchId} 
                setSelectedBranchId={setSelectedBranchId}></BranchSelect>}
            </React.Fragment>
          })}
        </div>
      </ContentContainer>
    )
}