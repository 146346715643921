import { useEffect } from "react";

type props = {
  elementWithScroll: HTMLDivElement | null,
  showEnd: (showLeft: boolean) => void, 
  showBeginning: (showRight: boolean) => void,
  scrollDirection?: 'horizontal' | 'vertical',
  margin?: number
}

export const useScrollShadows = ({elementWithScroll, showEnd, showBeginning, scrollDirection: _scrollDirection, margin: _margin} : props) => {
  
  const handleScroll = () => {

    // Determine scroll direction, or give direction as an argument
    const scrollDirection = _scrollDirection
      ? _scrollDirection
      : elementWithScroll!.scrollHeight > elementWithScroll!.clientHeight
        ? 'vertical'
        : elementWithScroll!.scrollWidth > elementWithScroll!.clientWidth
          ? 'horizontal'
          : (() => {
            throw new Error ('Unable to determine scroll direction, please send it in as a value ("vertical" or "horizontal") in key "scrollDirection".')
          })

    const scrollLength = scrollDirection === 'horizontal' 
      ? elementWithScroll!.scrollWidth 
      : elementWithScroll!.scrollHeight

    const scrollFromBeginning = scrollDirection === 'horizontal'
      ? elementWithScroll!.scrollLeft
      : elementWithScroll!.scrollTop

    const elLength = scrollDirection === 'horizontal'
      ? elementWithScroll!.clientWidth
      : elementWithScroll!.clientHeight

    const margin = _margin !== undefined 
      ? _margin 
      : 10;

    if(scrollLength <= elLength) {
      showBeginning(false);
      showEnd(false)
    } else {
      if(scrollFromBeginning > margin) {
        showBeginning(true)
      } else {
        showBeginning(false)
      }

      if(scrollFromBeginning + elLength >= scrollLength - margin) {
        showEnd(false)
      } else {
        showEnd(true)
      }
    }
  }

  useEffect(() => {
    if(elementWithScroll){
      handleScroll();

      elementWithScroll.addEventListener('scroll', handleScroll);

      return () => {
        elementWithScroll.removeEventListener('scroll', handleScroll);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementWithScroll])

  useEffect(() => {
    if(elementWithScroll){
      handleScroll();
    }
  })
}