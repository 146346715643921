import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {goals: number, lost:number}, away: {goals: number, lost:number}},
  statisticsTotal: {home: {goals: number, lost:number}, away: {goals: number, lost:number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
}

export default function GoalsCount ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const totalGoals = Math.max(statisticsTotal.home.goals + statisticsTotal.away.goals,
    statisticsTotal.home.lost + statisticsTotal.away.lost);

  return <LabeledOpposingHorizontalBars 
    label={T('Antal')}
    left={{
      value: statistics.home.goals / totalGoals,
      color: teamColors.home,
      label: statistics.home.goals,
    }}
    right={{
      value: statistics.away.goals / totalGoals,
      color: teamColors.away,
      label: statistics.away.goals
    }}
  />
}