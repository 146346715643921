import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { Match } from '../../api/schema';
import VideoListLink from '../VideoListLink/VideoListLink';
import styles from './VideoList.module.scss';


type props = {
  title?: string,
  index: number,
  future: boolean,
  solidSport: boolean,
  handbollplay: boolean,
  games: Match<{
    video: {},
    away: {
      team: {
        club: {
          nation: {}
        }
      }
    },
    home: {
      team: {
        club: {
          nation: {}
        }
      }
    }
  }>[]
}
 
export default function VideoList({title, games, index, future, solidSport, handbollplay} : props) {

  const T = useTranslations('cmresults');

  const streamingGames = future
    ? games.sort((a,b) => a.start - b.start)
    : games.sort((a,b) => b.start - a.start)

  return <div className={classNames(styles.outer_container, index && index % 2 && styles.light)}>
    <div className="max-75">
      <h2>{solidSport ? <img src='https://static.cupmanager.net/images/cm-results/solid-sport-red-icon.svg' alt="" />
        : handbollplay ? <img src='https://static.cupmanager.net/images/cm-results/handbollplay-icon.svg' alt="" />
        : <FontAwesomeIcon icon={faCirclePlay} />
      } {title ? title : T('Streams')}</h2>
      <div className={styles.video_list_container}>
        {streamingGames.map(game => <VideoListLink game={game} />)}
      </div>
    </div>
  </div>
}