import styles from './FilterButtonMenu.module.scss';
import AwesomeCheckbox from "../AwesomeCheckbox/AwesomeCheckbox";
import { useClickOutside } from "../../utils/useClickOutside";
import classNames from "classnames";
import Button from "../Button/Button";
import { CSSTransition } from "react-transition-group"
import { useRef, useState } from 'react';
import { useTranslations } from 'cupman-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { capitalize } from '../../utils';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { useMinWidth } from '../../utils/useMediaQuery';
import ModalTrigger from '../../utils/ModalTrigger';


type Props<T extends string | number> = {
  htmlForPrefix: string,
  items: {
    key : T,
    label: string,
    published?: boolean
  }[],
  label: string,
  selected: Record<T,boolean>,
  setSelected: (items: Record<T,boolean>) => void
}



export default function FilterButtonMenuV2<T extends string|number> ({htmlForPrefix, items, selected, setSelected, label} : Props<T>) {

  const Tr = useTranslations('cmresults');

  const [open, setOpen] = useState(false);

  const minMd = useMinWidth('md')

  const dialogRef = useRef<null | HTMLDivElement>(null);
  const outerRef = useRef(null)

  useClickOutside({
    ref: outerRef,
    callback: () => {
      setOpen(false)
    }
  });

  const transition = 150;

  const entries = Object.entries(selected)
  const selectedKeys = entries.filter(e => e[1]).map(e => e[0]);
  const allChecked = entries.length === selectedKeys.length;


  return minMd
    ? <div ref={outerRef} className={styles.outer_container} style={{'--transition-time' : `${transition}ms`} as any}>
      <Button 
        className={classNames(
          styles.open_dd_button, 
          open && styles.opened, 
          !allChecked ? styles.modified : ''
        )}
        
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className={styles.anchor_wrapper}></div>
        <span className={classNames(!allChecked && styles.filter_changed)}>
          {!allChecked && <FontAwesomeIcon icon={faFilter} />} {allChecked 
            ? capitalize(label)
            : selectedKeys.length === 1
              ? capitalize(items.find(item => item.key.toString() === selectedKeys[0])?.label)
              : capitalize(Tr('X av Y Z', selectedKeys.length, entries.length, label))
          }
        </span>
      </Button>
      <CSSTransition
        in={open}
        timeout={transition}
        nodeRef={dialogRef}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone
        }}
      >
        <div className={classNames(styles.drop_down)} ref={dialogRef}>
          <SelectItems 
            items={items} 
            htmlForPrefix={htmlForPrefix} 
            setOpen={(val: boolean) => setOpen(val)} 
            setSelected={setSelected}
            selected={selected}
          />
        </div>
      </CSSTransition>
    </div>
    : <div className={styles.outer_container}>
      <ModalTrigger
        scrollYOnly
        trigger={doOpen => <Button 
          className={classNames(
            styles.open_dd_button, 
            open && styles.opened, 
            !allChecked ? styles.modified : ''
          )}
          
          onClick={() => {
            doOpen()
          }}
        >
          <div className={styles.anchor_wrapper}></div>
          <span className={classNames(!allChecked && styles.filter_changed)}>
            {!allChecked && <FontAwesomeIcon icon={faFilter} />} {allChecked 
              ? capitalize(label)
              : selectedKeys.length === 1
                ? capitalize(items.find(item => item.key.toString() === selectedKeys[0])?.label)
                : capitalize(Tr('X av Y Z', selectedKeys.length, entries.length, label))
            }
          </span>
        </Button>}
        modal={doClose => <div className={classNames(styles.modal)}>
          <h3>{label}</h3>
          <SelectItems 
            items={items} 
            htmlForPrefix={htmlForPrefix} 
            setOpen={(val: boolean) => setOpen(val)} 
            setSelected={(items) => {
              setSelected(items);
              doClose()
            }}
            selected={selected}
          />
        </div>}
      />
    </div>
}









function SelectItems<T extends string|number> ({items, htmlForPrefix, setOpen, setSelected, selected} : {
  items: {
    key: T;
    label: string;
    published?: boolean;
  }[],
  htmlForPrefix: string,
  setOpen: (val: boolean) => void,
  setSelected: (items: Record<T,boolean>) => void,
  selected: Record<T, boolean>
}) {

  const [localSelected, setLocalSelected] = useState(selected)

  const entries = Object.entries(selected);
  const Tr = useTranslations('cmresults');

  return <div>
  <ul>
    {items.map((item, i) => {

      return <li key={i}>
        <AwesomeCheckbox 
          onChange={(val) => {
            setLocalSelected({
              ...localSelected,
              [item.key]: val
            })
          }}
          checked={!item.published && localSelected[item.key]}
          remSize={.8}
          remMargin={0}
          id={htmlForPrefix+""+item.key}
          disabled={item.published}
        />
        <label htmlFor={htmlForPrefix+""+item.key} className={classNames(item.published && styles.disabled)}>{item.label}</label>
      </li>
    })}
  </ul>
  <div className={styles.menu_buttons}>
    <Button onClick={() => {
      setOpen(false)
      setSelected(localSelected)
    }}>{Tr('Spara filter')}</Button>
    <Button inverted onClick={() => {
      setLocalSelected(  Object.values(localSelected).every(b => b)
        ? Object.fromEntries(entries.map(item => [item[0], false])) as Record<T, boolean>
        : Object.fromEntries(entries.map(item => [item[0], true])) as Record<T, boolean>)
    }}>
      <span className={styles.text_desktop}>
        {Object.values(localSelected).every(b => b)
          ? Tr('Avmarkera alla')
          : Tr('Markera alla')
        }
      </span>
      <span className={styles.text_mobile}>
        {Tr('Alla')}
      </span>
      <FontAwesomeIcon 
        icon={Object.values(localSelected).every(b => b) ? faSquare : faSquareCheck} 
      />
    </Button>
  </div>
</div>
}