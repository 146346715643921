import { faArrowRightToBracket, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactFragment, ReactNode, useState } from 'react';
import styles from './Index.module.scss';

type props = {
  children: ReactNode
}

export default function WebsiteEmulator({children} : props) {
  

  const [menuOptions, setMenuOptions] = useState({
    cupinfo: false,
    result: false,
    more: false
  });

  const setOpenDd = (key:string) => {
    setMenuOptions({
      ...menuOptions,
      [key]: true
    });
  }

  const setClosedDd = (key:string) => {
    setMenuOptions({
      ...menuOptions,
      [key]: false
    });
  }

  return <div className={styles.scroll_fix}>
    <div className={styles.fill_out}></div>
    <div className={classNames(styles.top_header, 'page-header')}>
      <div className={classNames("max-75", styles.header_flex)}>

        <a href="/" className={styles.logo}>
          <img src="https://static.cupmanager.net/uploads/v/X/7w1/irstalogo,crop0x25x1000x953.png" alt="logo"/>
        </a>

        <nav className={styles.nav}>
          <ul className={styles.main_nav}>
            <li 
              onMouseEnter={() => {
                setOpenDd('cupinfo');
              }}
              onMouseLeave={() => setClosedDd('cupinfo')}
            >
              <button>Cupinfo</button>
              <ul 
                className={classNames(menuOptions.cupinfo && styles.active)}
                onMouseEnter={() => {
                  setOpenDd('cupinfo');
                }}
                onMouseLeave={() => setClosedDd('cupinfo')}
              >
                <li>
                  <a href="/2022/cupinfo-1">Mattider och menyer</a>
                </li>
                <li>
                  <a href="/2022/klassindelning">Tävlingsexpedition</a>
                </li>
                <li>
                  <a href="/2022/deltagarkort">Deltagarkort</a>
                </li>
                <li>
                  <a href="/2022/klasser">Klassindelning</a>
                </li>
                <li>
                  <a href="/2022/tavlingsinformation">Tävlingsinformation</a>
                </li>
                <li>
                  <a href="/2022/forlaggning">Förläggning</a>
                </li>
                <li>
                  <a href="/2022/transporter">Transporter</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/contact">Kontakta oss</a>
            </li>
            <li onMouseEnter={() => {
                setOpenDd('result');
              }}
              onMouseLeave={() => setClosedDd('result')}>
              <button>Schema och resultat</button>
              <ul 
                className={classNames(menuOptions.result && styles.active)}
                onMouseEnter={() => {
                  setOpenDd('result');
                }}
                onMouseLeave={() => setClosedDd('result')}
              >
                <li>
                  <a href="/">Resultat - Start</a>
                </li>
                <li>
                  <a href="/streaming-games">Streamade matcher</a>
                </li>
                <li>
                  <a href="/clubs">Anmälda lag</a>
                </li>
                <li>
                  <a href="/categories">Klasser</a>
                </li>
                <li>
                  <a href="/map">Karta</a>
                </li>
                <li>
                  <a href="/places">Spelplatser</a>
                </li>
                <li>
                  <a href="/statistics">Statistik</a>
                </li>
              </ul>
            </li>
            <li onMouseEnter={() => {
              setOpenDd('more');
            }}
            onMouseLeave={() => setClosedDd('more')}>
              <button>Mer...</button>
              <ul 
                className={classNames(menuOptions.more && styles.active)}
                onMouseEnter={() => {
                  setOpenDd('more');
                }}
                onMouseLeave={() => setClosedDd('more')}
              >
                <li>
                  <a href="/2022/cupinfo-1">Klasser</a>
                </li>
                <li>
                  <a href="/2022/klassindelning">Anmälda lag</a>
                </li>
                <li>
                  <a href="/2022/deltagarkort">Statistik</a>
                </li>
              </ul>
            </li>
          </ul>
          
          <ul className={styles.utils_nav}>
            <li>
              <a href="/public/rec" className={classNames(styles.add_team, 'temp_bg_color')}>
                <FontAwesomeIcon icon={faArrowRightToBracket}/>
                <span>Anmäl lag</span>
              </a>
            </li>
            <li>
              <a href="/2022/reg/profile" className={styles.login}>
                <FontAwesomeIcon icon={faPlus}/>
                <span>Logga in</span>
              </a>
            </li>
          </ul>

          <button className={styles.burger}>
            <FontAwesomeIcon icon={faBars}/>
          </button>
        </nav>

      </div>
    </div>
    
    {children}

    <section>
      <div className={styles.footer_bar}>
        <div className="max-75">
          <div>
            <a href="https://irstablixten.cups.nu/2022,admin/admin" id="adminLoginButton" className={styles.cupmanager_login} style={{whiteSpace: 'nowrap'}}>
              Logga in
            </a>
          </div>

          <div className={styles.punsch}>
            <p>Proudly powered by</p>
            <h2>
              <a href="https://www.cupmanager.net" target="_blank" rel="noreferrer">Cup Manager</a> &amp; <a href="https://superinvite.com" target="_blank" rel="noreferrer">Superinvite</a> - Simplifying sports.
            </h2>
          </div>

          <div className={styles.cupmanager_logo_container}>
            <img src="https://static.cupmanager.net/thimmayya/images/cupmanager_dark.svg" alt="Cupmanager"/>
          </div>
        </div>
      </div>
    </section>
  </div>
}