import { faHand, faHandFist, faHandPeace } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { MatchPlayerPenalty } from "../../api/schema";
import { formatShirtNr } from "../../utils";
import { getSideClass } from "./LiveFeed";
import styles from './PlayerPenalty.module.scss';
import TimelineMinutes from "./TimelineMinutes";

export default function PlayerPenalty ({event} : {event: MatchPlayerPenalty}) {

  return <div className={
    classNames(
      styles.penalty_container, 
      event.side === 'away' && styles.away,
      getSideClass(event.side)
    )
  }>
    <div>
      <div className={classNames(styles.penalty_inner_container, event.side === 'home' ? styles.left : styles.right)}>
        <div className={styles.penalty_info}>
          <div className={styles.penalty_event_container}>
            <span className={styles.penalty_type}>
              {event.reason}
            </span>
            <HandSign name={event.reason}/>
          </div>

          <div className={styles.name_container}>
            {event.name}
          </div>
          <div className={styles.player_container}>
            <span className={styles.player_name}>{event.playerName}</span>

            {event.playerNr && <span className={styles.shirt_nr}>#{formatShirtNr(event.playerNr)}</span>}
          </div>
        </div>
      </div>
    </div>
    <TimelineMinutes time={event.displayRelativeTime||event.relativeTime} side={event.side} type={event.__typename} />
    <div></div>
  </div>
}



const HandSign = ({name} : {name: MatchPlayerPenalty['name']}) => {

  const getPenaltyIcon = (penalty:string) => {

    if(penalty === '5 min') {
      return <>
        <FontAwesomeIcon icon={faHand} className={styles.hand_icon} />
      </>
    }
    
    if (penalty === '2 min') {
      return <>
        <FontAwesomeIcon icon={faHandPeace} className={styles.hand_icon} />
      </>
    }
    
    // '2+10' or 'Match penalty'
    return <>
      <FontAwesomeIcon icon={faHandFist} className={styles.hand_icon} />
    </>
  }

  return <div className={styles.hand_sign}>
    {getPenaltyIcon(name)}
  </div>

}