import { useTranslations } from "cupman-utils";
import { RuleTemplate$PeriodTypes, Sport$SportTypes } from "../api/schema";

export function useSportSpecificWords(sport: Sport$SportTypes, ordinaryPeriodCount: number) : (type: 'start' | RuleTemplate$PeriodTypes, period:number) => string | undefined {

  const T = useTranslations("cmresults");

  // FOOTBALL
  if(sport === 'football') {
    return (type, period) => {
      if (type === 'start') return T('avspark');
      if (type === 'period') {
        if (ordinaryPeriodCount === 2) {
          if (period === 0) return T('första halvlek');
          if (period === 1) return T('andra halvlek');
        } else {
          return T("set X", period + 1);
        }
      }
      if (type === 'extension') return T('förlängning');
      if (type === 'penalties') return T('straffar');
      if (type === 'shootout') return T('shootout');
    }
  }

  // ICEHOCKEY
  if(sport === 'icehockey') {
    return (type, period) => {
      if (type === 'start') return T('nedsläpp');
      if (type === 'period') return T('period X', period + 1);
      if (type === 'extension') return T('förlängning');
      if (type === 'penalties') return T('straffar');
      if (type === 'shootout') return T('straffar');
    }
  }

  // HANDBALL
  if(sport === 'handball') {
    return (type, period) => {
      if (type === 'start') return T('avkast');
      if (type === 'period') {
        if (ordinaryPeriodCount === 2) {
          if (period === 0) return T('första halvlek');
          if (period === 1) return T('andra halvlek');
        } else {
          return T("period X", period + 1);
        }
      }
      if (type === 'extension') return T('förlängning');
      if (type === 'penalties') return T('straffar');
      if (type === 'shootout') return T('shootout');

    }
  }

  // BASKETBALL
  if(sport === 'basketball') {
    return (type, period) => {
      if (type === 'start') return T('uppkast');
      if (type === 'period') return T("period X", period + 1);
      if (type === 'extension') return T('förlängning');
      if (type === 'penalties') return T('straffkast');
      if (type === 'shootout') return T('straffkast');
    }
  }

  // TENNIS / BADMINTON / VOLLEYBALL
  if(sport === 'tennis' || sport === 'badminton' || sport === 'volleyball') {
    return (type, period) => {
      if (type === 'start') return T('serve');
      if (type === 'period') return T("set X", period + 1);
      if (type === 'extension') return T("set X", period + 1);
      if (type === 'penalties' || 'shootout') return T("set X", period);
    }
  }


  // FLOORBALL
  if(sport === 'floorball') {
    return (type, period) => {
      if (type === 'start') return T('tekning');
      if (type === 'period') return T("period X", period + 1);
      if (type === 'extension') return T('förlängning');
      if (type === 'penalties') return T('straffar');
      if (type === 'shootout') return T('straffar');
    }
  }

  // DEFAULT FALLBACK
  return (type, period) => {
    if (type === 'start') return T('start');
    if (type === 'period') return T("period X", period + 1);
    if (type === 'extension') return T('förlängning');
    if (type === 'penalties') return T('straffar');
    if (type === 'shootout') return T('shootouts');
  }
}