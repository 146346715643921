import styles from './VideoSlider.module.scss';
import { Match } from '../../api/schema';
import VideoSliderLink from '../VideoSliderLink/VideoSliderLink';

import { ErrorBoundary, ErrorFallbackNothingToSee } from "../../Error/ErrorBoundary";
import Games from '../Game/Games';
import classNames from 'classnames';
import { useCurrentTournament } from '../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useScrollShadows } from '../../utils/useScrollShadows';

type props = {
  games: Match<typeof Games.GameFragment>[],
  title: string,
  future?: boolean
  index?: number
}

export default function VideoSlider ({games, title, future, index} : props) {

  const t = useCurrentTournament();


  return <ErrorBoundary 
    fallback={ErrorFallbackNothingToSee}>

    <VideoSliderInner games={games} title={title} future={future} index={index} streamingProvider={t.streamingInfo?.provider || ''}/>

  </ErrorBoundary>
  }

type propsInner = {
  games: Match<typeof Games.GameFragment>[], 
  future?: boolean,
  title: string,
  index?: number,
  streamingProvider: string | undefined
}


function VideoSliderInner ({games, future, title, index, streamingProvider} : propsInner) {

  const streamingGames = future
    ? games.sort((a,b) => a.start - b.start)
    : games.sort((a,b) => b.start - a.start)

  
  const solidSport = streamingProvider === 'solidsport' || streamingProvider === 'solidtango';
  const handbollPlay = streamingProvider === 'handbollplay';

  const parentEl = useRef<null | HTMLDivElement>(null);

  const leftShadowEl = document.getElementById('leftShadow');
  const rightShadowEl = document.getElementById('rightShadow');

  useScrollShadows({
    elementWithScroll: parentEl.current, 
    showBeginning: (show:boolean) => {
      if(show) {
        leftShadowEl && (leftShadowEl.style.opacity = '1');
      } else {
        leftShadowEl && (leftShadowEl.style.opacity = '0');
      }
    }, 
    showEnd: (show: boolean) => {
      if(show) {
        rightShadowEl && (rightShadowEl.style.opacity = '1');
      } else {
        rightShadowEl && (rightShadowEl.style.opacity = '0');
      }
    }
  });

  const videosRef = useRef<HTMLDivElement | null>(null)
  const [maxWidthContainer, setMaxWidthContainer] = useState<undefined|number>(undefined);
  useLayoutEffect(() => {
    if(videosRef.current) {
      setMaxWidthContainer(videosRef.current.clientWidth);
    }
  }, [videosRef])

  return (
    <div style={{background: 'rgba(var(--clr-gray-50))', '--max-width' : `${maxWidthContainer}px`} as any}>
      <div className={classNames(styles.videos_slider_outer_container, index && index % 2 && styles.light)}>
        <h2 className={classNames(styles.header,"custom-font")}>
          {solidSport ? <img src='https://static.cupmanager.net/images/cm-results/solid-sport-red-icon.svg' alt="" />
            : handbollPlay ? <img src='https://static.cupmanager.net/images/cm-results/handbollplay-icon.svg' alt="" />
            : <FontAwesomeIcon icon={faCirclePlay} />
          } {title}
        </h2>

        <div className={styles.shadow_left} id='leftShadow'></div>
        <div className={styles.shadow_right} id='rightShadow'></div>

        <div className={styles.videos_slider_container} ref={parentEl}>
          <div>
            <div className={styles.videos_inner_container} ref={videosRef}>
              {streamingGames.map(streamingGame => <VideoSliderLink game={streamingGame} key={streamingGame.id} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}