
import { faCar, faWalking, faBus, faInfo, faClock, faRoute } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TeamTimeLine.module.scss';
import classNames from 'classnames';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Team$TimelineTransport, Team$TimelineTravel } from '../../api/schema';
import { useTranslations } from 'cupman-utils';
import { useState } from 'react';
import ModalTrigger from '../../utils/ModalTrigger';
import LinkButton from '../Button/LinkButton';
import PoiCard from '../../Map/PoiCard';
import { getDistance } from '../../Map/MapTypes';
import Button from '../Button/Button';
import { useCurrentTournament } from '../../App';
import { useFormatDate } from 'cupman-utils/lib/LangContext';
import { useAdmin } from '../AdminBanner/AdminBanner';

type props = {
  transport: Team$TimelineTransport
  hidden: boolean,
  direction: 'arrival'|'departure'
}



export default function Transport ({transport, hidden, direction} : props) {

  const t = useCurrentTournament();
  const T = useTranslations("cmresults");
  const admin = useAdmin();
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const published = (direction === "arrival" ? t.transportArrivalPublished : t.transportDeparturePublished) || admin.overridePublished;

  return (
    <div className={classNames(styles.directions_container,hidden && "date_hidden")}>
      <div className={styles.event_line}>
        <div className={classNames(styles.line)} style={{background: 'rgb(var(--clr-directions))'}}></div>
      </div>
      <div className={styles.route_image}>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 46 63" style={{enableBackground: 'new 0 0 46 63'} as any} xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <rect style={{fill:'white'}} width="46" height="62.8"/>
            
            <path style={{fill: 'none', stroke:'#DFEEEF', strokeWidth:4, strokeMiterlimit:10}} d="M23,
            62.8c0-5.8,
            4.7-10.4,
            10.4-10.4s10.4-4.7,
            10.4-10.4s-4.7-10.4-10.4-10.4H12.6c-5.8,
            0-10.4-4.7-10.4-10.4
            s4.7-10.4,
            10.4-10.4S23,
            5.9,
            23,
            0.2"/>
        </svg>
      </div>
      <div className={styles.directions_inner_container}>

        <div className={styles.directions_link}>
            <div className={styles.directions_info_container}>
              <div className={styles.directions_header}>
                <strong>{direction === 'arrival' ? T('Booked transport from X', transport.travelCenter.name) : T('Booked transport to X', transport.travelCenter.name)}</strong>
              </div>
              {published && <div className={styles.directions_info}>
                <span>
                  <FontAwesomeIcon icon={faRoute} /> {T.select(transport.transportAlternativeName)}
                </span>
                {transport.transportId && <span>
                  <FontAwesomeIcon icon={faBus} /> {transport.transportId}
                </span>}
                <span>
                  <FontAwesomeIcon icon={faClock} /> {formatDate(transport.transportTime, 'HH:mm')}
                </span>
              </div>}
              {!published && <div className={styles.directions_info}>
                <span>
                  {T('The transport schedule has not been published yet')}
                </span>
              </div>}
            </div>

          </div>
    </div>
</div>)}