
import styles from './LiveSymbols.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { useTranslations } from 'cupman-utils';
import { Video } from '../../api/schema';
import { useCurrentTournament } from '../../App';

type props = {
  video?: Video
  live: boolean,
  finished: boolean,
  start: number,
  marginTop?: string
}

export default function LiveSymbols ({video, live, finished, start} : props) {

  const t = useCurrentTournament();
  const provider = t.streamingInfo?.provider.toLocaleLowerCase();

  const solidSport = provider === 'solidsport' || provider === 'solidtango';
  const handbollPlay = provider === 'handbollplay';
  const T = useTranslations("cmresults");
  
  return (
    <>
      <div className={classNames(styles.live_container)}>
        <div className={classNames(styles.live_icon_container, 
          solidSport && video && styles.solidSport,
          handbollPlay && video && styles.handbollPlay,
          (!solidSport && !handbollPlay && video) && styles.provider,
          (video && live && !finished) && styles.live, 
          (video && finished) && styles.played,
          (video && !finished) && styles.upcoming,
          (!video && live && !finished) && styles.live_report
          )}>
          
          {video ? <>
              {solidSport && <img src='https://static.cupmanager.net/images/cm-results/solid-sport-white.svg' alt={T('Watch the game online')} />}   
              {handbollPlay && <img src='https://static.cupmanager.net/images/cm-results/handbollplay-logo.svg' alt={T('Watch the game online')} />}   
              <FontAwesomeIcon icon={faPlay} />
            </>
            : (live && !finished) && <span>{T('Live now!')}</span>}
        </div>
      </div>
    </>
  )
}