import styles from './Map.module.scss';
import classNames from 'classnames';
import { MapMarker } from './MapTypes';
import { useMemo } from 'react';
import { useColors } from '../colors';
import { getIcon, getPointerColor, setFontStyleCharacterMap } from '../utils';


const getPointer = (selected: boolean, marker: MapMarker, color: string|string[]) => {
    
  if( marker.cluster ){
    return getClusterPointer(color as string[], marker.id, marker.cluster);
  } else {
    const colors = selected 
      ? [color as string, 'white']
      : ['white', color as string];
  
    return getSinglePointer(colors);
  }
}

const getSinglePointer = (color:string[]) => <>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 379.1 445.2" className={styles.pointer_svg}>
    <g>
      <circle style={{opacity: '.8', fill: color[1]}} cx="189.6" cy="185.1" r="102.5"/>
      <path style={{fill: color[0]}} d="M378.3,188.8C378.3,84.5,293.8,0,189.5,0S0.8,84.5,0.8,188.8c0,52.4,21.4,99.9,55.9,134.1l0,0l132.8,122.3
          l132.8-122.3l0,0C356.9,288.7,378.3,241.2,378.3,188.8z M189,280.6c-53.3,0-96.5-43.2-96.5-96.5c0-53.3,43.2-96.5,96.5-96.5
          c53.3,0,96.5,43.2,96.5,96.5C285.5,237.4,242.3,280.6,189,280.6z"/>
      <path style={{fill: color[1]}} d="M355,190.8C355,99.1,280.7,24.8,189,24.8S22.9,99.1,22.9,190.8c0,46.1,18.8,87.9,49.2,118l0,0L189,416.4
        l116.9-107.6l0,0C336.2,278.7,355,237,355,190.8z M190.1,302.9c-65.6,0-118.8-53.2-118.8-118.8S124.5,65.3,190.1,65.3
        s118.8,53.2,118.8,118.8S255.7,302.9,190.1,302.9z"/>
      <path style={{opacity: '.15', fill: color[0]}} d="M71.3,184.1c0-65.2,52.6-118.2,117.7-118.8V24.8c-91.7,0-166.1,74.3-166.1,166.1c0,46.1,18.8,87.9,49.2,118
        l0,0L189,416.4V302.9C123.9,302.3,71.3,249.3,71.3,184.1z"/>
    </g>
  </svg>
</>

const randomTranslation = (seed:number, direction:'left'|'right') => {
  var x = Math.sin(seed) * 10000;
  var y = Math.sin(seed+20) * 10000;
  var z = Math.sin(seed+32) * 10000;
  z = (z - Math.floor(z))*0.2+0.85;
  
  x = (x - Math.floor(x))*45-(direction==="right"?5:30) + 200*(1/z-1);
  y = (y - Math.floor(y))*80-40;
  

  

  return `translate(${x}px,${y}px) scale(${z})`;
}


const getClusterPointer = (_color:string[], seed:number, count: number) => {
  
  const colors = [..._color];
  colors[1] = colors[1] || colors[0];
  colors[2] = colors[2] || colors[1];


  return <>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 607.1 445.2" className={styles.pointer_svg}>
      <g style={{transform:"translateX(30px)"}}><g>
        <g style={{transform: randomTranslation(seed,"left")}}>
          <circle style={{opacity: '.8', fill: colors[1]}} cx="139.7" cy="190" r="74.1"/>
          <path style={{fill: "#fff"}} d="M286.6,201.6c0-79.2-64.2-143.4-143.4-143.4S0,122.4,0,201.6c0,39.8,16.2,75.9,42.4,101.8l0,0l100.8,92.9
        l100.8-92.9l0,0C270.4,277.4,286.6,241.4,286.6,201.6z M142.9,271.3c-40.5,0-73.3-32.8-73.3-73.3s32.8-73.3,73.3-73.3
        s73.3,32.8,73.3,73.3S183.4,271.3,142.9,271.3z"/>
        <path style={{fill: colors[1], opacity: 0.15}} d="M286.6,201.6c0-79.2-64.2-143.4-143.4-143.4S0,122.4,0,201.6c0,39.8,16.2,75.9,42.4,101.8l0,0l100.8,92.9
        l100.8-92.9l0,0C270.4,277.4,286.6,241.4,286.6,201.6z M142.9,271.3c-40.5,0-73.3-32.8-73.3-73.3s32.8-73.3,73.3-73.3
        s73.3,32.8,73.3,73.3S183.4,271.3,142.9,271.3z"/>
          <path style={{fill: colors[1]}} d="M268.9,203.1c0-69.6-56.4-126-126-126s-126.1,56.4-126.1,126c0,35,14.3,66.7,37.4,89.6l0,0l88.8,81.7l88.8-81.7l0,0
        C254.7,269.9,268.9,238.2,268.9,203.1z M143.7,288.2c-49.8,0-90.2-40.4-90.2-90.2s40.4-90.2,90.2-90.2s90.2,40.4,90.2,90.2
        S193.5,288.2,143.7,288.2z"/>
        </g>
        {count > 2 && <g style={{transform: randomTranslation(seed+1, "right")}}>
          <circle style={{opacity: '.8', fill: colors[2]}} cx="412.3" cy="190" r="74.1"/>
          <path style={{fill: "#fff"}} d="M547.4,196.8c0-79.2-64.2-143.4-143.4-143.4s-143.3,64.2-143.3,143.4c0,39.8,16.2,75.9,42.4,101.8l0,0
        L404,391.5l100.8-92.9l0,0C531.1,272.7,547.4,236.6,547.4,196.8z M403.7,266.5c-40.5,0-73.3-32.8-73.3-73.3s32.8-73.3,73.3-73.3
        s73.3,32.8,73.3,73.3S444.1,266.5,403.7,266.5z"/>
        <path style={{fill: colors[2], opacity: 0.15}} d="M547.4,196.8c0-79.2-64.2-143.4-143.4-143.4s-143.3,64.2-143.3,143.4c0,39.8,16.2,75.9,42.4,101.8l0,0
        L404,391.5l100.8-92.9l0,0C531.1,272.7,547.4,236.6,547.4,196.8z M403.7,266.5c-40.5,0-73.3-32.8-73.3-73.3s32.8-73.3,73.3-73.3
        s73.3,32.8,73.3,73.3S444.1,266.5,403.7,266.5z"/>
          <path style={{fill: colors[2]}} d="M529.7,198.3c0-69.6-56.4-126-126-126s-126.1,56.4-126.1,126c0,35,14.3,66.7,37.4,89.6l0,0l88.8,81.7l88.8-81.7l0,0
        C515.4,265.1,529.7,233.4,529.7,198.3z M404.5,283.5c-49.8,0-90.2-40.4-90.2-90.2s40.4-90.2,90.2-90.2s90.2,40.4,90.2,90.2
        S454.3,283.5,404.5,283.5z"/>
        </g>}
      </g>
      <g>
        <circle style={{fill: colors[0]}} cx="276" cy="190" r="102.5"/>
        <circle style={{fill: "#fff", opacity:0.2}} cx="276" cy="190" r="102.5"/>
        <path style={{fill: "#fff"}} d="M462.3,188.8C462.3,84.5,377.8,0,273.5,0S84.8,84.5,84.8,188.8c0,52.4,21.4,99.9,55.9,134.1l0,0l132.8,122.3
        l132.8-122.3l0,0C440.9,288.7,462.3,241.2,462.3,188.8z M273,280.6c-53.3,0-96.5-43.2-96.5-96.5s43.2-96.5,96.5-96.5
        s96.5,43.2,96.5,96.5S326.3,280.6,273,280.6z"/>
        <path style={{fill: colors[0]}} d="M439,190.8c0-91.7-74.3-166-166-166s-166.1,74.3-166.1,166c0,46.1,18.8,87.9,49.2,118l0,0L273,416.4
        l116.9-107.6l0,0C420.2,278.7,439,237,439,190.8z M274.1,302.9c-65.6,0-118.8-53.2-118.8-118.8S208.5,65.3,274.1,65.3
        s118.8,53.2,118.8,118.8S339.7,302.9,274.1,302.9z"/>
        <path style={{opacity: '.15', fill: "#fff"}} d="M155.3,184.1c0-65.2,52.6-118.2,117.7-118.8V24.8c-91.7,0-166.1,74.3-166.1,166.1c0,46.1,18.8,87.9,49.2,118
        l0,0L273,416.4V302.9C207.9,302.3,155.3,249.3,155.3,184.1z"/>
      </g></g>
    </svg>
    </>
}

export 

type props = {
  onSelect?: () => void,
  marker: MapMarker,
  onOpen?: () => void,
  hidden?: boolean,
  hovered?: boolean,
  selected?: boolean,
  character?: number | string
}

export default function Pointer ({ selected, onSelect, marker, onOpen, hidden, hovered, character } : props) {

  const colors = useColors();
  const color = marker.color || getPointerColor(colors,marker);

  const icon = useMemo(()=> getIcon(marker.type, selected 
    ? (typeof color === 'string' ? color : color[0])
    : 'white'
  ),[selected])

  const mobile =  window.matchMedia('(hover:none)').matches;


  const pointer = useMemo(()=>getPointer(!!selected, marker, color),[selected]);


  return <div className={classNames(styles.pointer_container)} style={{opacity: hidden ? 0 : 1.0}}>
        <button  onMouseEnter={(event)=> {
            if(!mobile && hovered){ // Set hover after mouse eneter to avaoid animation flicker
              (event.target as any).classList.add(styles.hovered);
            }

            if(!mobile){
              !hovered && onOpen && onOpen()
            }
          }} 
          tabIndex={-1}
          className={classNames(
            styles.hover_target, 
            mobile && hovered && styles.hovered, // Always set hover when mobile
            !!selected && styles.selectedPin
           
          )}
          aria-label={marker.name}
          onClick={(event) => {
            
            if(!hovered && mobile){
              event.preventDefault();
              event.stopPropagation();
              onOpen && onOpen();
            } else {
              onSelect && onSelect();
            }}
          }
        ></button>
        <div className={classNames(styles.pointer, marker.cluster && styles.cluster)}>
          {pointer}
          <span className={styles.icon}>

          {character
            ? <span 
                className={styles.character}
                style={
                  setFontStyleCharacterMap()
                }
              >
                {character}
            </span>
            :<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 193.1 193.1" className={styles.pointer_icon}>
                {icon}
            </svg>
          }
          </span>
        </div>

        <span className={styles.text}>
          {marker.name}
        </span>
      </div>
  
}