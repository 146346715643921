import { faArrowRight, faMars, faVenus, faVenusMars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular"
import React from "react";
import { useParams } from "react-router-dom";
import { useCurrentTournament } from "../../App"
import LinkButton from "../../components/Button/LinkButton";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import PageHeader from "../../components/PageHeader/PageHeader";
import TopGoalsPlayerAwardContainer from "../../components/WinnersAwardContainer/TopGoalsPlayerAwardContainer";
import styles from '../WinnersPage/WinnersPage.module.scss';
import { groupBy } from "../../utils";
import { Player$PlayerStatistics, useCategory } from "../../api/schema";
import Select from "../../components/Select/Select";
import classNames from "classnames";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import { faMap, faTrophy, faUsers } from "@fortawesome/pro-light-svg-icons";
type props = {
  selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards',
  setSelectedSort: (val: 'total'|'totalAssists'|'totalPoints'|'greenCards') => void
}

export default function TopPlayersCategoryStatisticsPage ({selectedSort, setSelectedSort} : props) {

  const T = useTranslations('cmresults');
  const t = useLink(useCurrentTournament(), {finals: [], cup:{}});

  const {catId} = useParams<{catId: string}>();
  const categoryId = parseInt(catId as string) ;
  const tournamentId = t.id

  
  const category = useCategory({tournamentId, categoryId}, {
    topPlayers: [{
      team: {
        club: {
          nation: {}
        }
      }
    }]
  });

  const points = (p:Player$PlayerStatistics,selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards'  ) => {
    if( selectedSort == "total" ){
      return [p.totalPoints + p.totalAssists, p.totalPoints];
    } else if( selectedSort == "totalAssists"){
      return [p.totalAssists, p.totalPoints];
    } else if( selectedSort == "greenCards"){
      return [p.greenCards, p.totalPoints];
    } else {
      return [p.totalPoints, p.totalAssists];
    }
  }

  const cmp = (s1:Player$PlayerStatistics,s2:Player$PlayerStatistics,selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards') => {
    const p1 = points(s1,selectedSort);
    const p2 = points(s2,selectedSort);

    if( p1[0] === p2[0] ){
      return p2[1] - p1[1];
    } else {
      return p2[0] - p1[0];
    }
  } 
  
  const includePlayer = (p:Player$PlayerStatistics, selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards') => {
    if(selectedSort === 'total') {
      return p.totalPoints + p.totalAssists > 0
    } else if(selectedSort === 'totalAssists') {
      return p.totalAssists > 0
    } else if(selectedSort === 'greenCards') {
      return p.greenCards > 0
    } else {
      return p.totalPoints > 0
    }
  }
  
  const admin = useAdmin();
  const schedulePublished = t.schedulePublished || admin.overridePublished;


  const topPlayers = category.topPlayers.filter(p => includePlayer(p.stats, selectedSort)).sort((a,b) => cmp(a.stats,b.stats,selectedSort)).map(player => {
  return {
      ...player,
      stats: {
        ...player.stats,
        total: player.stats.totalPoints + player.stats.totalAssists
      }
    }
  }).slice(0,50)

  
  return (t.cup.modules.playerStatistics || admin.overridePublished)
    ? <>
      <PageHeader
        title={T('Top players in X',category.shortName)}
        shortTitle={category.shortName}
        crumbs={[
          {
            title: T('Results'),
            url: '/'
          },
          {
            title: T('Player statistics'),
            url: '/statistics/players'
          }
        ]}
      >
         {t.hasStatistics ? <Shortcuts links={[
        {
          to: '/statistics',
          title: T('Overview'),
          icon: faMap
        }, schedulePublished && {
          to: '/statistics/winners',
          title: T('Winners'),
          icon: faTrophy
        }, t.hasPlayerStatistics && {
            to: '/statistics/players',
            title: T('Players'),
            icon: faUsers
        }, t.schedulePublished && t.finals.length > 0 && {
          to: '/statistics/finals',
          title: T('Finals'),
          icon: faTrophy
        }/*, schedulePublished && {
          to: '/statistics/matches',
          title: T('Games'),
          icon: faTrophy
        }*/
      ]} />: undefined}
        </PageHeader>
      <div className={classNames("max-75", styles.wrapper)}>
        <div className={styles.gender_header} style={{marginBottom:"2rem"}}>
          <h2 className={classNames("custom-font", styles.content_header)}>
            {T('Sort by')}
          </h2>
          <Select 
            onChange={(e) => {
              setSelectedSort(e as any)
            }} 
            options={[
              {
                name: category.registerAssists ? T('Total') : T("Goals"),
                id: 'total'
              },
              ...(category.registerAssists ? [{
                name: T('Goals'),
                id: 'totalPoints'
              },{
                name: T('Assists'),
                id: 'totalAssists'
              }] : []),
              ...(category.registerGreenCards ? [{
                name: T('Green cards'),
                id: 'greenCards'
              }] : [])
            ]} 
            selectedId={selectedSort}
          />
        </div>

        <ContentContainer dark noPadding>
          <div className={styles.winners_grid}>
            {topPlayers.map((player, i) => <TopGoalsPlayerAwardContainer player={player} rank={i + 1} key={i} selectedSort={selectedSort} registerAssists={category.registerAssists} />)}
          </div>
        </ContentContainer>
      </div>
    </>
    : <></>
}