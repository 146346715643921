import { faCalendar, faHistory } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Match } from "../../api/schema";
import { groupBy } from "../../utils";
import ContentContainer from "../ContentContainer/ContentContainer";
import Game from "./Game";
import styles from './Games.module.scss';
import { formatDateDistance, useFormatDate } from "cupman-utils/lib/LangContext";
import React, { useState } from "react";
import { useTranslations } from "cupman-utils";
import Button from "../Button/Button";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { JsxElement } from "typescript";
import DateHeader from "../TeamTimeLine/DateHeader";
import PendingComponent from "../Pending/PendingComponent";
import { useCurrentTournament } from "../../App";
import { useAdmin } from "../AdminBanner/AdminBanner";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import AriaLivePortal from "../AriaLivePortal/AriaLivePortal";
import { useMediaQuery, useMinWidth } from "../../utils/useMediaQuery";

Games.GameFragment = Game.Fragment

type props = {
  games: Match<typeof Games.GameFragment>[],
  order: 'asc' | 'desc',
  title?: string,
  titleEl?: JSX.Element,
  knownDivision?: boolean,
  published: boolean,
  alwaysShow?: boolean
}


export default function Games({games, title, order, titleEl, knownDivision, published, alwaysShow} : props) {

  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);
  const T = useTranslations("cmresults");

  const sortedGamesWithDate = (games: Match<typeof Games.GameFragment>[], format:string, order:'asc'|'desc') => {
    return Object.entries(groupBy(games.sort((a, b) => order === 'desc' ? 
        (b.start - a.start) : 
        ((a.arena && a.arena.id !== 0 ?  a.start : Number.MAX_VALUE)  - (b.arena && b.arena.id !== 0 ?  b.start : Number.MAX_VALUE))), 
      game => game.arena && game.arena.id !== 0 ?  formatDate(game.start, format) : T('Not scheduled') ))
  };

  const gamesWithDateHeader =  sortedGamesWithDate(games, 'EEEE|d|LLLL', order);

  return <>
    {games.length > 0 && <ContentContainer 
      icon={faHistory} 
      header={title}
      fullWidth
    >
        <div className={classNames("max-75", styles.games_wrapper)}>
        {published ? gamesWithDateHeader.map((gameDate, i) => 
          <GamesDay alwaysShow={alwaysShow} dateTitle={gameDate[0]} games={gameDate[1]} key={i} titleEl={titleEl} knownDivision={knownDivision} />
        ) : <PendingComponent  text={T('The game schedule is not published yet')} color="rgb(var(--clr-primary))"/>}
      </div>
    </ContentContainer>}
  </>
}


type dayProps = {
  dateTitle?: string,
  games: Match<typeof Games.GameFragment>[],
  titleEl?: JSX.Element,
  knownDivision?: boolean,
  alwaysShow?: boolean
}

export function GamesDay({dateTitle, games: _games, titleEl, knownDivision, alwaysShow} : dayProps) {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  
  const formatDate = useFormatDate(t.cup.timeZoneId);
  const admin = useAdmin();

  const day = t.days.find(d => formatDate(d.date, 'EEEE|d|LLLL') === dateTitle);

  const schedulePublished = (day ? day.schedulePublished : t.schedulePublished ) || admin.overridePublished;

  _games = _games.filter(g => g.stage.schedulePublished || admin.overridePublished)

  _games = _games.filter(g => g.isScheduled || t.showUnscheduledGames || admin.overridePublished)

  //const print = useMediaQuery('print');

  const lg = useMinWidth('lg');

  const limit = lg ? 4 : 3;
  const limit_slack = lg ? 2 : 1;


  const possibleToExpand =  _games.length > limit + limit_slack && !alwaysShow && dateTitle;
  
  const games = possibleToExpand
    ? _games.slice(0,limit)
    : _games
  
  const gamesToExpand = possibleToExpand
  ? _games.slice(limit)
  : []

  const [expanded, setExpanded] = useState(false);
  const [asyncLoadedMessage, setAsyncLoadedMessage] = useState('')
  const duration = 200;

  if (games.length === 0) return null;
  
  return <>
    {/*React.cloneElement(_titleEl, {
      className: styles.date_header
    }, <>
      <FontAwesomeIcon icon={faCalendar} /> {dateTitle}
  </>)*/}
    {dateTitle && <DateHeader date={dateTitle} transparent />}
    {schedulePublished ? <><div className={styles.games}>
      <div>
        {games.map(game => <div key={game.id}>
          <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent 
              error={error} 
              errorInfo={errorInfo}
              text={T('The game could not be shown')}
              style={{height: 'calc(100% - 1.3rem)'}}
            />
          }>
            <Game data={game} knownDivision={knownDivision} />
          </ErrorBoundary>
        </div>)}
      </div>
      <TransitionGroup appear>
        {expanded && gamesToExpand.map((game, i) => (
          <CSSTransition
            key={game.id}
            timeout={duration * Math.min(8,i)}
            mountOnEnter
            classNames='match_card_animation'
          >
            <div style={{'--animate-delay': `${duration * Math.min(8,i)}ms` } as any}>
              <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The game could not be shown')} />}>
                <Game data={game} knownDivision={knownDivision} />
              </ErrorBoundary>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>

    <AriaLivePortal>
      {asyncLoadedMessage}
    </AriaLivePortal>
    {dateTitle && gamesToExpand.length > 0 && !expanded &&  <div className={classNames(styles.expand_btn_wrapper,"no-print")}>
      <Button onClick={() => {
        setExpanded(true);
        setAsyncLoadedMessage(T('More matches loaded'))
      }}>{T('Load all the {X} games',dateTitle.split('|')[0])}</Button>
      
    </div>}
    {dateTitle && gamesToExpand.length > 0 && !expanded && gamesToExpand.map((game, i) => {
      return <Game data={game} knownDivision={knownDivision} onlyPrint/>
    })}
    
    </> : <PendingComponent text={T('The game schedule during the X is not published yet', day ? formatDate(day?.date, 'd LLLL') : '')}  color="rgb(var(--clr-primary))"/>}
  </>
}