
import { faMap, faSitemap, faTrophy, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { useParams } from "react-router-dom";
import { useCurrentTournament } from "../../App";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import WinnersAwardContainer from "../../components/WinnersAwardContainer/WinnersAwardContainer";
import styles from './WinnersPage.module.scss';
import TopGoalsTeamAwardContainer from "../../components/WinnersAwardContainer/TopGoalsTeamAwardContainer";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useCategory } from "../../api/schema";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import classNames from "classnames";
import { useLink } from "granular";



export default function WinnersCategoryPage() {

  const params = useParams();
  const t = useLink(useCurrentTournament(), {finals: [], cup:{}});

  const tournamentId = t.id;
  const categoryId = params.catId
    ? parseInt(params.catId)
    : 123;

  const T = useTranslations('cmresults');

  const category = useCategory({tournamentId, categoryId}, {
    teamHighscore: {
      scoredGoals: [
        TopGoalsTeamAwardContainer.Fragment
      ],
      scoredGoalsPerGame: [
        TopGoalsTeamAwardContainer.Fragment
      ]
    },
    stages: [
      {
        rankings: [
            WinnersAwardContainer.Fragment
        ]
      }
    ]
  });

  const anyGoalsMade = category.teamHighscore.scoredGoals.reduce((sum, rankedTeam) => {
    const _tempSum = rankedTeam.lostGoals + rankedTeam.madeGoals;

    return sum + sum + _tempSum;
  }, 0);

  const anyGoalsPerGame = category.teamHighscore.scoredGoalsPerGame.reduce((sum, rankedTeam) => {
    const _tempSum = rankedTeam.lostGoals + rankedTeam.madeGoals;

    return sum + sum + _tempSum;
  }, 0);

  const admin = useAdmin();

  const schedulePublished = t.schedulePublished || admin.overridePublished;

  return <>
    <PageHeader 
        title={category.name}  
        crumbs={
          [{
            title: T('Results'),
            url: ''
          },{
            title: T('Statistics'),
            url: '/statistics'
          },{
              title: T('Winners'),
              url: 'statistics/winners'
          }]
        }
      >
        {t.hasStatistics ? <Shortcuts links={[
        {
          to: '/statistics',
          title: T('Overview'),
          icon: faMap
        }, schedulePublished && {
          to: '/statistics/winners',
          title: T('Winners'),
          icon: faTrophy
        }, t.hasPlayerStatistics && {
            to: '/statistics/players',
            title: T('Players'),
            icon: faUsers
        }, t.schedulePublished && t.finals.length > 0 && {
          to: '/statistics/finals',
          title: T('Finals'),
          icon: faTrophy
        }/*, schedulePublished && {
          to: '/statistics/matches',
          title: T('Games'),
          icon: faTrophy
        }*/
      ]} />: undefined}
    </PageHeader>
    <div className="max-75" style={{marginTop: "3rem"}}>

      <ContentContainer visibilityDesktop>
        <h2 className={classNames(styles.header, "custom-font")}>
          <FontAwesomeIcon icon={faSitemap} /> {T('Stadier')}
        </h2>
        {category.stages.map(stage => {
          return stage.rankings.length > 0 && <>
            <h3 className={classNames(styles.h3,"custom-font")}>{stage.name}</h3>
            <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load winners')} />}>
              <ContentContainer dark noPadding>
                <div className={styles.winners_grid}>
                  {stage.rankings.map((ranking, i) => {

                    if('match' in ranking) {
                      return <WinnersAwardContainer ranking={ranking} stageName={stage.name} key={i} />
                    }
      
                    if ('conference' in ranking) {
                      return <WinnersAwardContainer ranking={ranking} stageName={stage.name} key={i} />
                    }

                    return false;
                  })}
                </div>
              </ContentContainer>
            </ErrorBoundary>
          </>
        })}
      </ContentContainer>

      {anyGoalsMade > 0 && <ContentContainer visibilityDesktop>
        <h2 className={classNames(styles.header, "custom-font")}>
          <svg version="1.1" id="Lager_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64.5 69" xmlSpace="preserve" style={{maxHeight: '2rem'}}>
            <g>
              <path style={{fill: '#020203'}} d="M11.3,36.1c-0.3-0.2-0.6-0.3-0.8-0.5c-0.9-0.5-1.5-1.4-1.7-2.5c-0.1-0.4,0-0.9,0.1-1.4
                c0.1-0.6,0.4-1.1,0.8-1.5c3.6-4.1,12.9-14.2,20.9-19c4.9-3,10.9-4.1,16.9-2.6s10.8,5.2,13.8,10.1c6.4,10.5,3,24.3-7.6,30.7
                c-7.9,4.8-21.2,8.4-26.5,9.7c-0.5,0.1-1.1,0.1-1.7,0c-0.5-0.1-0.9-0.3-1.2-0.6c-0.8-0.6-1.4-1.6-1.4-2.6c0-0.3,0-0.6-0.1-0.9
                c-7.1,1.2-16.1,1.7-19.9,1.9c-0.3,0-0.5,0-0.8-0.1c-1.5-0.4-2.4-1.9-2-3.4c0.1-0.3,0.2-0.5,0.3-0.7C2.4,49.5,7,41.8,11.3,36.1z
                M24.2,51.1l2.1-0.4l0.1,2.1c0,1.1,0.1,2.2,0.1,2.9c5-1.3,17.9-4.8,25.4-9.3c8.8-5.3,11.7-16.8,6.3-25.7S41.3,9,32.4,14.4
                C25,18.9,16,28.7,12.4,32.7c0.6,0.2,1.5,0.8,2.5,1.3l1.8,1.1l-1.2,1.6c-4,4.9-8.7,12.5-11.1,16.6C9.1,53,17.9,52.4,24.2,51.1z
                M45.4,16.5c7.7,1.9,12.5,9.7,10.6,17.4s-9.7,12.5-17.4,10.6S26.1,34.8,28,27.1C29.9,19.4,37.7,14.6,45.4,16.5z"/>
            </g>
          </svg>
          {T('Mål')}
        </h2>

        {category.teamHighscore.scoredGoals && !!anyGoalsMade && <>
          <h3 className={classNames(styles.h3,"custom-font")}>
            {T('Most scored goals')}
          </h3>
          <ContentContainer dark noPadding>
          <div className={styles.winners_grid}>
            {category.teamHighscore.scoredGoals.map((rankedTeam, i) => {
              return <TopGoalsTeamAwardContainer rankedTeam={rankedTeam} rank={i + 1} />
            })}
          </div>
        </ContentContainer>
        </>}

        {category.teamHighscore.scoredGoalsPerGame && !!anyGoalsPerGame && <>
          <h3 className={classNames(styles.h3,"custom-font")}>
            {T('Most scored goals per game')}
          </h3>
          <ContentContainer dark noPadding className={styles.no_margin}>
          <div className={styles.winners_grid}>
            {category.teamHighscore.scoredGoalsPerGame.map((rankedTeam, i) => 
              <TopGoalsTeamAwardContainer perGame rankedTeam={rankedTeam} rank={i + 1} key={i} />
            )}
          </div>
        </ContentContainer>
        </>}
      </ContentContainer>}
    </div>
  </>
}