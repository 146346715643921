import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Fouls= {
  fouls_disqualifying: number, 
  fouls_personal: number, 
  fouls_technical: number, 
  fouls_unsportsmanlike: number
}

type Props = {
  statistics: {home: Fouls, away: Fouls},
  statisticsTotal: {home: Fouls, away: Fouls},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
}

export default function FoulsTotal ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const homeFoulsTotal = statisticsTotal.home.fouls_disqualifying + 
                    statisticsTotal.home.fouls_personal +
                    statisticsTotal.home.fouls_technical +
                    statisticsTotal.home.fouls_unsportsmanlike

  const awayFoulsTotal = statisticsTotal.away.fouls_disqualifying + 
                    statisticsTotal.away.fouls_personal +
                    statisticsTotal.away.fouls_technical +
                    statisticsTotal.away.fouls_unsportsmanlike

  const homeFouls = statistics.home.fouls_disqualifying + 
                    statistics.home.fouls_personal +
                    statistics.home.fouls_technical +
                    statistics.home.fouls_unsportsmanlike

  const awayFouls = statistics.away.fouls_disqualifying + 
                    statistics.away.fouls_personal +
                    statistics.away.fouls_technical +
                    statistics.away.fouls_unsportsmanlike
  
  

  return <LabeledOpposingHorizontalBars 
    label={T('Alla')}
    left={{
      value: homeFouls / (homeFoulsTotal + awayFoulsTotal),
      color: teamColors.home,
      label: homeFouls
    }}
    right={{
      value: awayFouls / (homeFoulsTotal + awayFoulsTotal),
      color: teamColors.away,
      label: awayFouls
    }}
  />
}