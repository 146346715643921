import { useEffect, useRef, useState } from "react"
import styles from './FilterButtonMenu.module.scss';
import AwesomeCheckbox from "../AwesomeCheckbox/AwesomeCheckbox";
import { useClickOutside } from "../../utils/useClickOutside";
import classNames from "classnames";
import Button from "../Button/Button";
import { CSSTransition } from "react-transition-group"

type Props = {
  checkValues: {
    [key: string] : boolean
  },
  setValues: (entries: {[key: string] : boolean}) => void,
  label: string,
  compareValues?: string[];
}

export function FilterButtonMenu ({checkValues: _checkValues, setValues, label, compareValues} : Props) {

  const [checkValues, setCheckValues] = useState(_checkValues);

  const [open, setOpen] = useState(false);

  const dialogRef = useRef<null | HTMLDivElement>(null);
  const outerRef = useRef(null)

  useClickOutside({
    ref: outerRef,
    callback: () => {
      setOpen(false)
      setCheckValues(_checkValues)
    }
  })

  const transition = 150;

  return <div ref={outerRef} className={styles.outer_container} style={{'--transition-time' : `${transition}ms`} as any}>
    <Button onClick={() => {
      setOpen(!open)
      open && setCheckValues(_checkValues)
    }}>{label}</Button>
    <CSSTransition
      in={open}
      timeout={transition}
      nodeRef={dialogRef}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone
      }}
    >
      <div className={classNames(styles.drop_down)} ref={dialogRef}>
        <h4>{label}</h4>
        <ul>
          {Object.entries(checkValues).sort((a, b) => a[0].localeCompare(b[0])).map((value) => {
          const uuid = (crypto as any).randomUUID();
          
          return <li>

            <AwesomeCheckbox onChange={(val) => {
                setCheckValues({
                  ...checkValues,
                  [value[0]] : val
                })
              }}
              checked={value[1]}
              id={uuid}
              remSize={.8}
              remMargin={0}
            />
            <label htmlFor={uuid} className={compareValues && classNames(compareValues.indexOf(value[0]) < 0 && styles.not_included)}>{value[0]}</label>
          </li>
        })}
        </ul>
        <Button inverted onClick={() => {
          setValues(checkValues)
          setOpen(false)
        }}>Spara filter</Button>
      </div>
    </CSSTransition>
  </div>
}