import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { Link } from 'react-router-dom';
import { Player, RegisteredTeam } from '../../api/schema';
import Flag from '../Flag/Flag';
import TopGoalsIcon from '../WinnersIcon/TopGoalsIcon';
import styles from './WinnersAwardContainer.module.scss';

TopGoalsPlayerAwardContainer.Fragment = {
  team: {
    club: {
      nation: {} 
    }
  },
  stats: {}
};



type props = {
  player: Player<typeof TopGoalsPlayerAwardContainer.Fragment>,
  rank: number,
  selectedSort: 'total'|'totalAssists'|'totalPoints'|'greenCards',
  registerAssists: boolean
}


export default function TopGoalsPlayerAwardContainer ({player, rank, selectedSort, registerAssists} : props) {


  const T = useTranslations('cmresults');

  const rankClr = rank === 1
    ? 'rgb(var(--clr-gold))'
    : rank === 2
      ? 'rgb(var(--clr-silver))'
      : rank === 3
        ? 'rgb(var(--clr-bronze))'
        : 'rgb(var(--clr-gray-500))'


  return <Link to={`/team/${player.team.id}`} className={classNames(
    styles.wrapper
  )}>
    <TopGoalsIcon placement={rank} />

    <div className={styles.info}>
      <h2 className={
        classNames(
          styles.award_header,
          rank === 1 && styles.gold,
          rank === 2 && styles.silver,
          rank === 3 && styles.bronze,
          rank <= 3 && styles.bold,
          rank > 3 && styles.gray,
          'custom-font'
        )
      }>
        {selectedSort === 'total' 
          ? <>
            {registerAssists
             ? T('X:(st|nd|rd|th):X place - Y points', rank, player.stats.totalPoints + player.stats.totalAssists)
             : T('X:(st|nd|rd|th):X place - Y goals', rank, player.stats.totalPoints)} 

            {registerAssists && <span> (
              {T('Goals')}: {player.stats.totalPoints}, {T('Assist')}: {player.stats.totalAssists}
            )</span>}
          </>
          : selectedSort === 'totalAssists'
            ? <>
              {T('X:(st|nd|rd|th):X place - Y assists', rank, player.stats.totalAssists)} <span>({T('Total')}: {player.stats.totalPoints+player.stats.totalAssists}, {T('Goals')}: {player.stats.totalPoints})</span>
            </>
            : selectedSort === 'greenCards' 
            ? <>
              {T('X:(st|nd|rd|th):X place - Y green cards', rank, player.stats.greenCards)} 
              <span> (
                {registerAssists && <>{T('Total')}: {player.stats.totalPoints+player.stats.totalAssists}, </>}
                {T('Goals')}: {player.stats.totalPoints}
              )</span>
            </>
            :<>
            {T('X:(st|nd|rd|th):X place - Y goals', rank, player.stats.totalPoints)} <span>({T('Total')}: {player.stats.totalPoints + player.stats.totalAssists}, {T('Assist')}: {player.stats.totalAssists})</span>
        </>}
      </h2>
      <span className={styles.team_goals}>
        
        <span className={classNames(styles.team_name, rank < 4 && styles.bold, rank > 3 && styles.gray )}>
          #{player.number} {player.name}
        </span>
      </span>
      <span className={styles.player_club}>
        <Flag code={player.team.club.nation.code} alt={T.select(player.team.club.nation.name)} borderColor={rankClr} /> {T.select(player.team.name.clubName)}
      </span>
    </div>
  </Link>
}