import { faCity } from "@fortawesome/pro-light-svg-icons";
import { faArrowRightFromBracket, faArrowRightToBracket, faCircleInfo, faEarth, faExternalLink, faGlobe, faInfoCircle, faLocationDot, faMap, faMapLocationDot, faMapMarked, faRoute } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { Suspense, useRef } from "react";
import { MapLocation, useLocation } from "../api/schema";
import { useCurrentTournament } from "../App";
import LinkButton from "../components/Button/LinkButton";
import ContentContainer from "../components/ContentContainer/ContentContainer";
import { header, list, LoadingContent, paragrapgh } from "../Loading/LoadingContent";
import { useRefScrolledFromTop } from "../utils/HasScrolledFromTop";
import styles from './MapOverlayLocation.module.scss';
import PoiCard from "./PoiCard";

type props = {
  selectedLocation: MapLocation,
  pointsOfInterest?: (MapLocation & {distance:number})[],
  setScrolled: (scrolled: boolean) => void, 
  scrolled?: boolean,

}

export default function MapOverlayLocation ({selectedLocation, pointsOfInterest, setScrolled, scrolled} : props) {


  return (
    <div className={classNames(styles.location_overlay)}>
      <Suspense fallback={
        <LoadingContent className="max-50" margin={20} components={
          [header(17, 24), paragrapgh(286, 27), list(37,0, 10)]
        }/>
      }>
        <MapOverlayLocationInner 
          setScrolled={setScrolled} scrolled={scrolled}
          selectedLocation={selectedLocation} 
          pointsOfInterest={pointsOfInterest} 
        />
      </Suspense>
    </div>
  )
}



function MapOverlayLocationInner({selectedLocation:loc, pointsOfInterest, scrolled, setScrolled} : props) {

  const T = useTranslations("cmresults");
  const t = useCurrentTournament();
  
  const location = useLocation({id: loc.id, tid: t.id},{arenas:[{}]});
  const container = useRef(null as null | HTMLDivElement);

  useRefScrolledFromTop(container.current, setScrolled);


  return <div>
    <div className={styles.top_shadow}></div>

    <div className={classNames(styles.place_nav_links, scrolled && styles.scrolled)}>
      <LinkButton size="lg" className={styles.nav_link} url={`https://www.google.com/maps/dir/?api=1&destination=${loc.latitude},${loc.longitude}`}>
        <FontAwesomeIcon icon={faRoute} /> <span className={styles.btn_txt}>{T('Directions')}</span>
        <div className={styles.link_shadow}>
          <div></div>
        </div>
      </LinkButton>
      <LinkButton size="lg" className={classNames(styles.nav_link, styles.optional)} url={`https://www.google.com/maps/search/?api=1&query=${loc.latitude},${loc.longitude}`}>
        <FontAwesomeIcon icon={faGlobe}/> <span className={styles.btn_txt}>{T('Show on Google maps')}</span>
        <div className={styles.link_shadow}>
          <div></div>
        </div>
      </LinkButton>
    </div>


    {/*loc.type === "arena" && <div className={classNames(styles.arena_link)}>
      <LinkButton size="lg" url={`/arena/${loc.id}`}>{T('View results')}<div className={styles.arena_link_shadow}>
          <div></div>
        </div></LinkButton>
        
</div>*/}
    
    <div ref={container} className={classNames(styles.map_overlay_content_container)}>
      <div className="max-50">

      <h4 className={classNames(styles.adress,"custom-font")}>
        <span><FontAwesomeIcon icon={faLocationDot}/> 
        {loc.street}, {loc.postal}</span>
        <span><FontAwesomeIcon icon={faCity}/>
        {loc.city}</span>
      </h4>

        {((pointsOfInterest  && pointsOfInterest.length > 0) || location.description) && <>
          {/*<h3 className={styles.more_margin}>
            <FontAwesomeIcon icon={faCircleInfo}/> {T('Information')}
        </h3>
          <ContentContainer>*/}

            {location.description && <div className={styles.inner_info} dangerouslySetInnerHTML={{__html: T.select(location.description)}}></div>}
            {location.toDescription && <>
                  <h4 className={classNames(styles.info_header,"custom-font")}>
                    <FontAwesomeIcon icon={faArrowRightToBracket}/> {T('Travel to X', location.name)}
                  </h4>
                  <div className={styles.inner_info} dangerouslySetInnerHTML={{__html: T.select(location.toDescription)}}></div>
                </>}
            {location.fromDescription && <>
              <h4 className={classNames(styles.info_header,"custom-font",styles.directions_title)}>
                <FontAwesomeIcon icon={faArrowRightFromBracket}/> {T('Travel from X', location.name)}
              </h4>
              <div className={styles.inner_info} dangerouslySetInnerHTML={{__html: T.select(location.fromDescription)}}></div>
            </>}
            {pointsOfInterest && pointsOfInterest.length > 0 && <>
              <h4 className={classNames(styles.info_header,"custom-font")}>
                <FontAwesomeIcon icon={faMapLocationDot}/> {T('Interesting place(s):Y close to X', location.name, pointsOfInterest.length)}
              </h4>
              <div className={styles.points_of_interest}>
                {pointsOfInterest.map(point => <PoiCard point={point} startPoint={loc} />)}
              </div>
            </>}

          {/*</ContentContainer>*/}
        </>}

      </div>
    </div>
  </div>
}