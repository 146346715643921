
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { useCurrentTournament } from "../../App";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Games from "../../components/Game/Games";
import PageHeader from "../../components/PageHeader/PageHeader";
import SolidSportInfo from "../../components/SolidSportInfo/SolidSportInfo";
import TopScrollShadow from "../../components/TopScrollShadow/TopScrollShadow";
import VideoList from "../../components/VideoList/VideoList";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import { capitalize } from "../../utils";

export default function StreamingPage (){

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();

  const streamingProvider = t.streamingInfo?.provider.toLocaleLowerCase();

  /*const fakeData = {
    prices: {
      full: 199,
      one: 59
    },
    links: {
      full: 'http://www.solidsport.se/fullpass',
      one: 'http://www.solidsport.se/onegame'
    }
  }*/


  return <div>
    <PageHeader 
      title={T('Streamed games')} 
      crumbs={[{
        title: T('Results'),
        url: ''
        }
      ]}
    />

    <TopScrollShadow top={120}/>
    <div className="max-75" style={{textAlign: 'center'}}>
      <h2>{T('Games that are streamed')}</h2>
      <p>{T('We will stream games during the tournament together with X.', capitalize(streamingProvider || ''))}</p>
    </div>
    
    <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75">
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('The stream could not be loaded')} />
    </div>}>
      <AllStreams />
    </ErrorBoundary>
    
    {(streamingProvider === 'solidsport' || streamingProvider === 'solidtango' || streamingProvider === 'handbollplay') && <SolidSportInfo />}
    
  </div>
}



function AllStreams () { 
  const t = useCurrentTournament();
  const T = useTranslations('cmresults');

  const {comingMatches, finishedMatches, liveMatches} = useLink(t, {
    comingMatches: [Games.GameFragment],
    finishedMatches: [Games.GameFragment],
    liveMatches: [Games.GameFragment]
  });

  const liveStreams = liveMatches.filter(match => match.video);
  const upcomingStreams = comingMatches.filter(match => match.video);
  const pastStreams = finishedMatches.filter(match => match.video);

  const provider = t.streamingInfo?.provider.toLocaleLowerCase();
  const solidSport = provider === 'solidsport' || provider === 'solidtango';
  const handbollplay = provider === 'handbollplay';

  const streamingChapters = [
    {
      games: liveStreams.length > 0
      ? liveStreams
      : [],
      title: T('Live streams'),
      future: false
    },
    {
      games: pastStreams.length > 0
      ? upcomingStreams
      : [],
      title: T('Coming streams'),
      future: true
    },
    {
      games: pastStreams.length > 0
        ? pastStreams
        : [],
      title: T('Replays'),
      future: false
    }
  ]

  return <>
    {streamingChapters.map((chapter, i) => chapter.games.length > 0 && <VideoList 
      title={chapter.title} 
      games={chapter.games} 
      future={chapter.future} 
      index={i}
      key={i}
      solidSport={solidSport}
      handbollplay={handbollplay}
    />)}
  </>
}