import { MutationSpec } from 'granular'

export function Me$addFavoriteTeam_Optimization(params:{optionalCupId?:number|undefined}, payload:number, query:never) : Partial<MutationSpec<number,never,any>> {
	return {
		fetchRefsIfInvalidatedByMutation: [],
		optimistics: {
			// Add your optimistics here like [Ref({paramId:123})]: (data:Ref) => { return data; }
		}
	}
}
