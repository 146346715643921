import styles from './Commentator.module.scss';
import classNames from 'classnames';
import { capitalize } from '../../utils';
import { useTranslations } from 'cupman-utils';
import { getSideClass } from '../LiveFeed/LiveFeed';
import { useMemo } from 'react';

type props = {
  team?: 'away' | 'home' | 'both',
  type?: 'positive' | 'neutral' | 'negative',
  side: undefined | 'home' | 'away',
  comment: string | JSX.Element,
  wide?: boolean
}

export default function Commentators ({team, type:_type, comment, side, wide} : props) {

  const T = useTranslations('cmresults')

  const randomInArray = (arr:string[]) => {
    return arr[Math.floor(Math.random() * arr.length)];
  }




  // AS LONG AS TEAM DOES NOT EXIST
  //const sides = ['home', 'away'];
  /*const team = _team 
    ? _team
    : randomInArray(sides)*/
  // / AS LONG AS TEAM DOES NOT EXIST

  // AS LONG AS TYPE DOES NOT EXIST
  const types = ['positive', 'neutral'];
  const type = _type
    ? _type
    : randomInArray(types)
  // / AS LONG AS TYPE DOES NOT EXIST





  const mood = () => {
    
    const availableMoods = type === 'positive'
      ? ['happy', 'laughing'] // POSITIVE
      : type === 'negative'
        ? ['sad', 'angry'] // NEGATIVE
        : ['surprised', 'thoughtful'] // NEUTRAL

    return randomInArray(availableMoods);
  }

  const setMood = useMemo(() => mood(), [])

  const name = 'home'
    ? 'home_avatar'
    : 'away_avatar'

  return <>
    {team === 'both'
      ? 

        /* BOTH */
        <div className={classNames(styles.commentator_container, styles.both)}>
          <img src={`https://static.cupmanager.net/images/commentators/oscar-${setMood}.svg?v=2`} alt={T('Oscar from Cup Manager')} className={styles.home_avatar} />
          <div className={classNames(styles.comment_container, wide && styles.wide)}>
            <span>{comment}</span>
          </div>
          <img src={`https://static.cupmanager.net/images/commentators/sabina-${setMood}.svg?v=2`} alt={T('Sabina from Cup Manager')} className={styles.away_avatar} />
        </div>

      : 
      
        /* ONE SIDE */
        <div className={classNames(styles.commentator_container, side === 'away' 
          ? styles.home 
          : styles.away,
        side && getSideClass(side) === 'away' ? 'home' : 'away'
        )}>
          <img src={`https://static.cupmanager.net/images/commentators/${side === 'away'
            ? 'oscar'
            : 'sabina'
          }-${setMood}.svg?v=2`} alt={`${capitalize(name)}`} className={styles[name]} />
          <div className={classNames(styles.comment_container, wide && styles.wide)}>
            <span>{comment}</span>
          </div>
        </div>
      
    }
  </>
}