import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { MatchComment, MatchFeed$Comments } from "../../api/schema";
import { replaceWhite } from "../../colors";
import { groupBy } from "../../utils";
import styles from './CommentAroundGame.module.scss';
import TimelineTime from "./TimelineTime";
import { useCommentTexts } from "./useCommentTexts";

type props = {
  events: MatchComment[],
  colors: Record<string, string>,
  hideTime?: boolean,
  emojiFlagsOn: boolean,
  nationCode: string | undefined
}

export default function CommentAroundGame ({emojiFlagsOn, events: _events, colors, hideTime, nationCode} : props) {


  const __events = _events.sort((a, b) =>  b.relativeTime - a.relativeTime); // Sort by newest first
  const events = Object.values(groupBy(__events, (event => event.author))).map(author => author[0]);

  const side = events[0].side;
  const arrayPartOne = events.slice(0,5).sort((a, b) => a.absoluteTime - b.absoluteTime);
  const arrayPartTwo = events.slice(5);
  const T = useTranslations('cmresults');


  const flag = emojiFlagsOn
    ? <img src={`https://static.cupmanager.net/images/flags_svg/4x3/${nationCode?.toLowerCase()}.svg`} alt={T(`Flag ${nationCode}`)} className={styles.flag} />
    : ''
  
  const commentTexts = useCommentTexts();


  return <div className={classNames(styles.comment_container, side === 'away' && styles.away, side === 'away'

    ? 'away'
    : 'home'

  )} style={
    {
      ['--clr-team-secondary' as any]: colors.secondary,
      ['--clr-team-primary' as any]: replaceWhite(colors.primary).color
    }
  }>

    <div className={styles.comment_inner_container}> 
      <div className={styles.author_container}>
        <div className={styles.bubble}>
          {flag}
          <span className={styles.comment}>
            {commentTexts[events[0].comment as MatchFeed$Comments] || events[0].comment}
          </span>
        </div>
        <div className={classNames(styles.authors)}>
        {arrayPartTwo.length > 0 && <span className={styles.extra_likes}>+ {arrayPartTwo.length}</span>}
          {arrayPartOne.map((event) => {
            return <div className={styles.author} key={event.id} style={{border: replaceWhite(colors.primary).border ? '1px solid rgb(var(--clr-gray-300))' : ''}}>
              <div className={styles.author_inner}>
                <span>{event.author}</span>
              </div>
            </div>
          })}
        </div>
        
      </div>
    </div>
    {!hideTime && <TimelineTime time={events[0].absoluteTime} side={side}/>}
    <div></div>
  </div>
}
