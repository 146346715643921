import { useTranslations } from "cupman-utils";
import PageHeader from "../../components/PageHeader/PageHeader";

export default function StatisticsMatchesPage () {

  const T = useTranslations('cmresults');

  return <>
    <PageHeader
      title={T('Game statistics')}
      crumbs={[
        {
          title: T('Results'),
          url: '/'
        },
        {
          title: T('Statistics'),
          url: '/statistics'
        }
      ]}
    />
    <div className="max-75">
    erew
    </div>
  </>
}