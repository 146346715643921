import { Team$statistics } from "../../api/schema"
import ContentGrayContainer from "../ContentGrayContainer"
import styles from './matchStatistics.module.scss';
import { useTranslations } from "cupman-utils"
import WonMatches from "./WonMatches";
import TiedMatches from "./TiedMatches";
import LostMatches from "./LostMatches";
import classNames from "classnames";
import GoalsCount from "./GoalsCount";
import ConcededGoals from "./ConcededGoals";

type Props = {
  statistics: {home: Team$statistics, away: Team$statistics},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
  showTied: boolean
}

export default function OtherStatisticsTotal ({statistics, teamColors, showTied} : Props) {

  const T = useTranslations('cmresults');
  
  return <>
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Matcher')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <WonMatches statistics={statistics} teamColors={teamColors} />
      {showTied && <TiedMatches statistics={statistics} teamColors={teamColors} />}
      <LostMatches statistics={statistics} teamColors={teamColors} />
    </ContentGrayContainer>
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Mål under cupen')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <GoalsCount statistics={statistics} statisticsTotal={statistics} teamColors={teamColors} />
      <ConcededGoals statistics={statistics} statisticsTotal={statistics} teamColors={teamColors} />
    </ContentGrayContainer>
  </>
}