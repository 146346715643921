import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {fouls_disqualifying: number}, away: {fouls_disqualifying: number}},
  statisticsTotal: {home: {fouls_disqualifying: number}, away: {fouls_disqualifying: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
}

export default function FoulsDiscqualifying ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')
  
  const totalFoulsDiscqualifying = statisticsTotal.home.fouls_disqualifying + statisticsTotal.away.fouls_disqualifying;

  return <LabeledOpposingHorizontalBars 
    label={T('Diskvalificerande')}
    left={{
      value: statistics.home.fouls_disqualifying / totalFoulsDiscqualifying,
      color: teamColors.home,
      label: statistics.home.fouls_disqualifying
    }}
    right={{
      value: statistics.away.fouls_disqualifying / totalFoulsDiscqualifying,
      color: teamColors.away,
      label: statistics.away.fouls_disqualifying
    }}
  />
}