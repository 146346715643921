import { faClock, faStarShooting, faChevronsUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { MatchStart, RuleTemplate$PeriodTypes, Sport$SportTypes } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import styles from './PeriodScore.module.scss';
import { useSportSpecificWords } from "../../utils/useSportSpecificWords";
import { capitalize } from "../../utils";
import FaSvgIcon from "../FaSvgIcon/FaSvgIcon";
import Button from "../Button/Button";
import { faBracketRound, faBracketRoundRight, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import HidePeriodButton from "./HidePeriodButton";


type props = {
  homeScore: number,
  awayScore: number,
  end?: boolean,
  winByPeriod?: boolean
}


export default function PeriodScore ({homeScore, awayScore, end, winByPeriod} : props) {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();

  return <div className={styles.score_container}>
    {end && <FaSvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 53" aria-hidden="true" focusable="false">
        <path fill='rgb(255,255,255)' d="M11.3,28.1c-0.3-0.2-0.6-0.3-0.8-0.5c-1.3-0.8-2-2.3-1.6-3.8c0.1-0.6,0.4-1.1,0.8-1.5
          c3.6-4.1,12.9-14.2,20.9-19c10.6-6.4,24.3-3,30.7,7.6s3,24.3-7.6,30.7c-7.9,4.8-21.2,8.4-26.5,9.7c-0.5,0.1-1.1,0.1-1.7,0
          c-0.5-0.1-0.9-0.3-1.2-0.6C23.4,50,22.9,49,22.9,48c0-0.3,0-0.6-0.1-0.9C16.2,48,9.6,48.7,2.9,49C1.4,49,0.1,47.9,0,46.3
          c0-0.3,0-0.5,0.1-0.8c0.1-0.3,0.2-0.5,0.3-0.7C2.3,41.5,7,33.7,11.3,28.1z M24.2,43.1l2.1-0.4l0.1,2.1c0,1.1,0.1,2.2,0.1,2.9
          c5-1.3,17.9-4.8,25.4-9.3c8.9-5.4,11.7-16.9,6.3-25.8C52.8,3.8,41.3,1,32.4,6.3c-7.5,4.5-16.5,14.3-20,18.3c0.9,0.4,1.7,0.8,2.5,1.3
          l1.8,1.1l-1.3,1.6c-4,5.3-7.8,10.8-11.1,16.6C11,45,17.6,44.2,24.2,43.1z M45.4,8.5C53.1,10.4,57.9,18.2,56,26
          c-1.9,7.7-9.7,12.5-17.4,10.6c-7.7-1.9-12.5-9.7-10.6-17.4C29.9,11.3,37.7,6.6,45.4,8.5z"/>
      </svg>
    </FaSvgIcon>}
    
    <span className={styles.score}>
      {!end && !winByPeriod && <span style={{verticalAlign: -1}}><FontAwesomeIcon icon={faBracketRound}/></span>}
      <span style={{fontWeight: homeScore > awayScore ? '700' : '600'} as any}>{homeScore}</span>
       {winByPeriod ? <span> - </span> : <span>-</span> } 
      <span style={{fontWeight: awayScore > homeScore ? '700' : '600'} as any}>{awayScore}</span>
      {!end && !winByPeriod && <span style={{verticalAlign: -1}}><FontAwesomeIcon icon={faBracketRoundRight}/></span>}
    </span>
    {end && <FaSvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 53" aria-hidden="true" focusable="false">
        <path fill='rgb(255,255,255)' d="M64.1,44.8c0.1,0.2,0.2,0.5,0.3,0.7c0.4,1.5-0.5,3-2,3.4C62.1,49,61.9,49,61.6,49c-3.8-0.2-12.8-0.7-19.9-1.9
        c0,0.3,0,0.6-0.1,0.9c-0.1,1-0.6,2-1.4,2.6c-0.4,0.3-0.8,0.5-1.2,0.6c-0.6,0.1-1.1,0.1-1.7,0c-5.3-1.3-18.6-4.8-26.5-9.7
        C0.2,35.1-3.2,21.3,3.2,10.8C6.2,5.9,11,2.1,17,0.7c6-1.5,12-0.4,16.9,2.6c7.9,4.8,17.2,14.9,20.9,19c0.4,0.4,0.6,0.9,0.8,1.5
        c0.1,0.5,0.1,0.9,0.1,1.4c-0.2,1-0.8,1.9-1.7,2.5c-0.2,0.1-0.5,0.3-0.8,0.5C57.5,33.7,62.2,41.5,64.1,44.8z M60.2,45.3
        c-2.4-4.1-7.1-11.7-11.1-16.6L47.8,27l1.8-1.1c1-0.6,1.9-1.1,2.5-1.3c-3.5-4-12.5-13.8-20-18.3C23.2,1,11.7,3.8,6.3,12.7
        s-2.5,20.4,6.3,25.8c7.5,4.5,20.4,8,25.4,9.3c0-0.8,0.1-1.8,0.1-2.9l0.1-2.1l2.1,0.4C46.6,44.4,55.5,45,60.2,45.3z M36.6,19.1
        c1.9,7.7-2.8,15.5-10.6,17.4c-7.7,1.9-15.5-2.8-17.4-10.6c-1.9-7.7,2.8-15.5,10.6-17.4C26.8,6.6,34.7,11.3,36.6,19.1z"/>
      </svg>
    </FaSvgIcon>}
    </div>
}