import PendingIcon from "./PendingIcon"
import styles from './PendingPage.module.scss';

type props = {
  color?: string,
  text: string
}

export default function PendingPage ({color, text} : props) {

  return <div className={styles.wrapper}>
    <PendingIcon color={color} className={styles.icon} />
    <p className={styles.info}>
      {text}
    </p>
  </div>

}