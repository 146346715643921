import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {saves:number}, away: {saves:number}},
  statisticsTotal: {home: {saves:number}, away: {saves:number}}
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
}

export default function SavesCount ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const totalSaves = statisticsTotal.home.saves + statisticsTotal.away.saves;


  return <LabeledOpposingHorizontalBars 
    label={T('Antal')}
    left={{
      value: statistics.home.saves / totalSaves,
      color: teamColors.home,
      label: statistics.home.saves,
    }}
    right={{
      value: statistics.away.saves / totalSaves,
      color: teamColors.away,
      label: statistics.away.saves
    }}
  />
}