import { EventStatistics$PeriodStat, EventStatistics$TeamStats, Match, MatchFeed$statistics } from "../../api/schema"
import ContentGrayContainer from "../ContentGrayContainer"
import styles from './matchStatistics.module.scss';
import { useTranslations } from "cupman-utils"
import GoalsCount from "./GoalsCount";
import CardsCount from "./CardsCount";
import classNames from "classnames";

type Props = {
  statistics: MatchFeed$statistics | EventStatistics$PeriodStat,
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
  registerGreenCards: boolean,
  registerYellowCards: boolean,
  registerRedCards: boolean,
  statisticsOnlyTotal: {
    home: EventStatistics$TeamStats;
    away: EventStatistics$TeamStats;
  }
}

export default function FootballStatistics ({statistics, teamColors, registerYellowCards, registerRedCards, registerGreenCards, statisticsOnlyTotal} : Props) {

  const T = useTranslations('cmresults');
  
  return <>
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Mål')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <GoalsCount 
        statistics={statistics} 
        teamColors={teamColors} 
        statisticsTotal={statisticsOnlyTotal} 
      />
    </ContentGrayContainer>
    
    {(registerRedCards || registerYellowCards || registerGreenCards) && <>
      <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
        {T('Kort')}
      </h3>
      <ContentGrayContainer className={styles.gray_container}>
        {registerGreenCards && <CardsCount statistics={statistics} teamColors={teamColors} color='green' />}
        {registerYellowCards && <CardsCount statistics={statistics} teamColors={teamColors} color='yellow' />}
        {registerRedCards &&   <CardsCount statistics={statistics} teamColors={teamColors} color='red' />}
      </ContentGrayContainer>
    </>}
  </>
}