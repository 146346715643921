import styles from './TeamPageStatistics.module.scss';
import classNames from 'classnames';
import HorizontalbarMultiple from './HorizontalBarMultiple';

type Props = {
  bars: {
    value:number, 
    color: string
    label: string,
    valueLabel: string
  }[]
}

export default function LabeledHorizontalBarMultiple ({bars} : Props) {


  return <div>
    <h4 className={classNames(styles.header, 'custom-font')}>
      {bars.map((bar, i) => {
        return bar.value > 0 && <span key={i}>
          <span className={styles.inner}>
            <span className={styles.color_ref} style={{'--color' : bar.color} as any}></span>
            {bar.label}
          </span>
          <span className={styles.extra_label}>{bar.valueLabel}</span>
        </span>
      })}
    </h4>
    <HorizontalbarMultiple bars={bars}/>
  </div>
}