import { faArrowUpRightFromSquare, faCirclePlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { Match } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { videoThumbnail } from "../../utils";
import styles from './StreamingPlayer.module.scss';

StreamingPlayer.fragment = {
  video: {},
  home: {},
  away: {}
}

type props = {
  game: Match<typeof StreamingPlayer.fragment>
}


export default function StreamingPlayer({game} : props) {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();

  const sport = t.subcup.sport.type;
  const headerTxt = T('Stream the game!');

  return <div 
    className={classNames(styles.video_wrapper, (game.live && !game.finished) && styles.live)}  
    style={{backgroundImage: `url(${videoThumbnail(game.video?.thumbnail, sport, 1200)}`}}
  >
    <div className={styles.video_sides}></div>
    <div className={styles.video_wrapper_inside} style={{backgroundImage: `url(${videoThumbnail(game.video?.thumbnail, sport, 1200)}`}}>
      <div className={styles.video_content_wrapper}>
        <div className={styles.team_names}>
          <div className={styles.float}>
            <img src="https://static.cupmanager.net/images/cm-results/live-stream_light.svg" alt="" className={styles.streaming_symbol} />
            <div className={styles.header_wrapper}>
              <h2 aria-hidden="true" className={styles.overlay}>
                {headerTxt}
              </h2>    
              <h2 className={styles.underlay}>
                {headerTxt}
              </h2>
            </div>
          </div>

          <span className="txt_no_wrap">{T.select(game.home.name)}</span> - <span className="txt_no_wrap">{T.select(game.away.name)}</span>
        </div>
        
        {game.video && <a 
          href={game.video.externalLink} 
          className={styles.video_link} 
          target="_blank" 
          rel="noreferrer"
        >
          <span className={styles.video_link_inner}>
            <FontAwesomeIcon 
              icon={faArrowUpRightFromSquare} 
              className={styles.external_link} 
            /> 
            <span className={styles.btn_txt}>
              {game.live && !game.finished ? T('Watch the game live') : T('Watch the game')} {game.live && game.finished && <FontAwesomeIcon icon={faCirclePlay}/>}
            </span>
          </span>
        </a>}
      </div>

      <div className={styles.solidSport}>
        <span>{T('The game is streamed by')}</span> 
        <img src='https://static.cupmanager.net/images/cm-results/solid-sport-white.svg' alt={T('Solid Sport')}/>
      </div>
      
    </div>
    <div className={styles.video_sides}></div>
  </div>
}