import { EventStatistics$PeriodStat, EventStatistics$TeamStats, Match, MatchFeed$statistics } from "../../api/schema"
import ContentGrayContainer from "../ContentGrayContainer"
import styles from './matchStatistics.module.scss';
import { useTranslations } from "cupman-utils"
import ThreePointsCount from "./ThreePointsCount"
import BasketPoints from "./BasketPoints";
import ScoredFreeThrows from "./ScoredFreeThrows";
import FoulsDiscqualifying from "./FoulsDiscqualifying";
import FoulsUnsportsmanlike from "./FoulsUnsportsmanlike";
import FoulsPersonal from "./FoulsPersonal";
import FoulsTechnical from "./FoulsTechnical";
import FoulsTotal from "./FoulsTotal";
import Timeouts from "./Timeouts";
import classNames from "classnames";
import ConcededPoints from "./ConcededPoints";

type Props = {
  statistics: MatchFeed$statistics | EventStatistics$PeriodStat,
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
  statisticsOnlyTotal: {
    home: EventStatistics$TeamStats;
    away: EventStatistics$TeamStats;
  }
}

export default function BasketballStatistics ({statistics, teamColors, statisticsOnlyTotal} : Props) {

  const T = useTranslations('cmresults');
  
  return <>
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Poäng')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <BasketPoints statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
      <ThreePointsCount statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
      <ScoredFreeThrows statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
    </ContentGrayContainer>

    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Fouls')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <FoulsTotal statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
      <FoulsPersonal statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
      <FoulsTechnical statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
      {(statistics.away.fouls_disqualifying > 0 || statistics.home.fouls_disqualifying > 0) && <FoulsDiscqualifying statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />}
      {(statistics.away.fouls_unsportsmanlike > 0 || statistics.home.fouls_unsportsmanlike > 0) && <FoulsUnsportsmanlike statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />}
    </ContentGrayContainer>

    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Timeouts')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <Timeouts statistics={statistics} teamColors={teamColors} statisticsTotal={statisticsOnlyTotal} />
    </ContentGrayContainer>
  </>
}