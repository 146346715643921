import { MutationSpec } from 'granular'
import { Me$CommentPayload } from '..'

export function Me$addMatchComment_Optimization(params:{optionalCupId?:number|undefined}, payload:Me$CommentPayload, query:never) : Partial<MutationSpec<Me$CommentPayload,never,any>> {
	return {
		fetchRefsIfInvalidatedByMutation: [],
		optimistics: {
			// Add your optimistics here like [Ref({paramId:123})]: (data:Ref) => { return data; }
		}
	}
}
