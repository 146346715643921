import { faChartLine, faUser, faUsers, faUsersClass, faMap, faCalendar, faWhistle, faTableList } from "@fortawesome/pro-regular-svg-icons";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { useCurrentTournament } from "../../App";
import FavoritesStart from "../../components/FavoritesStart/FavoritesStart";
import PlayedGames from "../../components/PlayedGames/PlayedGames";
import StatisticsSummaryHeader from "../../components/StatisticsSummaryHeader/StatisticsSummaryHeader";
import PageHeader from "../../components/PageHeader/PageHeader";
import SearchBar from "../../components/Search/SearchBar";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import PendingPage from "../../components/Pending/PendingPage";
import { ErrorBoundary, ErrorFallbackNothingToSee } from "../../Error/ErrorBoundary";
import StartPageMatchesAndStreams from "../../components/StartPageMatchesAndStreams/StartPageMatchesAndStreams";
import ErrorComponent from "../../Error/ErrorComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Suspense, useState } from "react";
import { LoadingMatches } from "../../Loading/LoadingContent";
import ErrorModal from "../../Error/ErrorMoal";
import LinkButton from "../../components/Button/LinkButton";

function RootStats() {
  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const admin = useAdmin();
  
  const lotPublished = t.lotPublished || admin.overridePublished;
  const schedulePublished = t.schedulePublished || admin.overridePublished;

  

  // STATISTICS
  const categories = useLink(t.lotCategories, [{
    statistics: {}
  }]);
  // / STATISTICS

    // PLAYED GAMES
    const summaryData = [
      {
        name: T('Team'),
        icon: faUsers,
        amount: categories.map(category => category.statistics?.teamCount || 0).reduce((sum, teams) => sum + teams, 0)
      },
      {
        name: T('Players'),
        icon: faUser,
        amount: categories.map(category => category.statistics?.playerCount || 0).reduce((sum, teams) => sum + teams, 0) || 0
      },
      {
        name: T('Categories'),
        icon: faUsersClass,
        amount: categories.filter(c => !lotPublished || c.lotPublished).length
      }
    ]

  
    const matchesPerDay = categories.reduce((sum, cat) => {
      const entries  = [
        ...Object.entries(sum), 
        ...Object.entries(cat.statistics?.matches || {})
      ];
  
      return entries.reduce((sum2, entry) => (
          {
          ...sum2, 
          [entry[0]]: {
            count: (sum2[entry[0]]?.count || 0) + entry[1].count,
            finished: (sum2[entry[0]]?.finished || 0) + entry[1].finished
          }
        }
      ), {} as Record<string, {count: number, finished: number}>);
      }, {} as Record<string, {count: number, finished: number}>)
  
    const gamesDays = t.days.map(day => {
      return (
          {
            date: day,
            matches: matchesPerDay[day.id].count,
            finished: matchesPerDay[day.id].finished,
          }
        )
      }
    )
    // / PLAYED GAMES
  
  return <>
    {lotPublished && <ErrorBoundary fallback={({error, errorInfo}) => 
      <ErrorFallbackNothingToSee error={error} errorInfo={errorInfo} />
    }>
      <StatisticsSummaryHeader data={summaryData} header={T('Tournament statistics')} />  
    </ErrorBoundary>}

    {schedulePublished && <ErrorBoundary fallback={({error, errorInfo}) => 
      <ErrorFallbackNothingToSee error={error} errorInfo={errorInfo} />
    }>
      <PlayedGames games={gamesDays} />
    </ErrorBoundary>}
  </>
}


export function RootPage() {
  
  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const admin = useAdmin();

  const lotPublished = t.lotPublished || admin.overridePublished;
  const schedulePublished = t.schedulePublished || admin.overridePublished;

  return <div>
    <PageHeader 
      title={T('Results X', t.edition.name)}
      hiddenBgColor="rgba(var(--clr-gray-100), 0.6)"
    >
      <>
        <SearchBar />
        <Shortcuts links={[
        (admin.overridePublished || t.lotPublished) && {
          to: '/categories',
          title: T('Categories'),
          icon: faUsersClass
        },(admin.overridePublished || t.clubsPublished) && {
          to: '/clubs',
          title: T('Registered'),
          icon: faUsers
        }, (admin.overridePublished || t.mapPublished) && {
          to: '/map',
          title: T('Map'),
          icon: faMap
        },(admin.overridePublished || t.schedulePublished) && {
          to: '/places',
          title: T('Arenas'),
          icon: faMap
        },(admin.overridePublished || t.schedulePublished) && t.hasStatistics && {
          to: '/statistics',
          title: T('Statistics'),
          icon: faChartLine
        },(admin.overridePublished || t.refereesPublic)  && {
          to: '/referees',
          title: T('Referees'),
          icon: faWhistle
        }
      ]} />
    </>
    </PageHeader>

    <Suspense fallback={ <><StatisticsSummaryHeader/><PlayedGames/></>}>
      <RootStats/> 
    </Suspense>

   
    
    {(t.teamsPublished || lotPublished) && <ErrorBoundary fallback={null}>
      <FavoritesStart />
      <div className="max-75" style={{marginTop: '-1rem', display: 'flex', justifyContent: 'center'}}>
      <LinkButton url="/games" size="lg" inverted>
        <FontAwesomeIcon icon={faTableList}/> {T('Se alla matcher')}
      </LinkButton>
    </div>
    </ErrorBoundary>}

    {!lotPublished &&  <PendingPage text={T('The game plan for X - Y is not published yet', t.cup.name, t.edition.name )} color="rgb(var(--clr-primary))"/>}

    <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75">
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('The games could not be loaded')} header={<h2>
        <FontAwesomeIcon icon={faCalendar}/> {T('Games')}
      </h2>} centerHeader />
      </div>}>
      {<Suspense fallback={<LoadingMatches/>}>
        <StartPageMatchesAndStreams schedulePublished={schedulePublished} />
      </Suspense>}
    </ErrorBoundary>
  </div>
}