import { useTranslations } from 'cupman-utils';
import { Team$statistics } from '../../../api/schema';
import ContentGrayContainer from '../../ContentGrayContainer';
import { GroupHeader } from './BasketballStatistics';
import styles from './TeamPageStatistics.module.scss';
import GoalsWonLostBar from './GoalsWonLostBar';
import ShotEfficiency from './ShotEfficiency';
import SavesPercentage from './SavesPercentage';
import SingleValue from './SingleValue';
import { getColorString, labContrasts } from '../../../colors';
import OutfieldPlayersTable from '../../TeamMembers/OutFieldPlayersTable';
import GoalkeepersTable from '../../TeamMembers/GoalKeepersTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';

type Props = {
  statistics: Team$statistics,
  teamColors: {
    color1: string,
    color2: string,
    color3: string,
    anti: string
  },
  settings: {
    assists: boolean,
    shots: boolean,
    showResults: boolean
  }
}

export default function IcehockeyStatistics({statistics, teamColors, settings} : Props) {

  const T = useTranslations('cmresults');

  const positiveGreen = {r:165, g:219, b:127};
  const negativeRed = {r:255, g:105, b:105}

  const rgbs = labContrasts(negativeRed)

  return  <>
    <GroupHeader label={`${T('Mål')}`}/>
    <ContentGrayContainer className={styles.gray_container}>
      <GoalsWonLostBar statistics={statistics} colors={{
        positive: positiveGreen,
        negative: negativeRed
      }} />
    </ContentGrayContainer>

    {settings.shots && <>
      <GroupHeader label={`${T('Skott & mål')}`}/>
      <ContentGrayContainer className={styles.gray_container}>
        <ShotEfficiency statistics={statistics} colors={{
          positive: positiveGreen,
          negative: negativeRed
        }} />
        <SavesPercentage statistics={statistics} colors={{
          positive: positiveGreen,
          negative: negativeRed
        }} />
      </ContentGrayContainer>
    </>}

    <div>
      <GroupHeader label={`${T('Utvisningar')}`}/>
      <div className={styles.single_values}>
        <SingleValue value={statistics.penaltiesCount} backgroundColor="rgb(var(--clr-gray-50))" color={getColorString(rgbs[0])} label={T('Antal')} />
        <SingleValue value={statistics.penaltiesMinutes} backgroundColor="rgb(var(--clr-gray-50))" color={getColorString(rgbs[1])} label={T('Minuter')} />
      </div>
    </div>

    {statistics.players.length > 0 && <>
      <h2 className={classNames(styles.player_statistics_header, 'custom-font')}>
        <FontAwesomeIcon icon={faUser}/> {T('Spelarstatistik')}
      </h2>

      {settings.shots && <>
      <GroupHeader label={`${T('Målvakter')}`}/>
        <GoalkeepersTable 
          players={statistics.goalkeepers} 
          isHome 
          colors={{
            primary: teamColors.color1,
            secondary: teamColors.anti
          }}
        />
      </>}

      {settings.shots && <GroupHeader label={`${T('Utespelare')}`}/>}
      <OutfieldPlayersTable 
        players={statistics.players} 
        isHome 
        colors={{
          primary: teamColors.color1,
          secondary: teamColors.anti
        }}
        settings={settings}
      />
    </>}
  </>
}