import { faThumbsUp, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { useMutation } from 'granular';
import { useEffect, useRef, useState } from 'react';
import { Match, useMe } from '../../api/schema';
import { useCurrentTournament } from '../../App';
import ModalTrigger from '../../utils/ModalTrigger';
import LiveFeed from './LiveFeed';
import styles from './SupporterButton.module.scss';
import { CSSTransition } from "react-transition-group"
import LikeInitials from '../LikesInitials.tsx/LikeInitials';
import Button from '../Button/Button';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { useCommentTexts } from './useCommentTexts';
import { useClickOutside } from '../../utils/useClickOutside';

type props = {
  game: Match<typeof LiveFeed.fragment>,
  side: 'home' | 'away'
}


export default function SupporterButton ({game: _game, side} : props) {

  const game = {
    ..._game,
  }

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const me = useMe({optionalCupId: t.cup.id},{});
  const [addMatchComment, addingMatchComment] = useMutation(me.addMatchComment);
  const availableComments = game.feed.availableComments;


  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const outerRef = useRef(null);
  const nodeRef = useRef<any>(null);
  const animationTimeOut = 300;

  const authors = [...new Set(game.feed.events.reduce((authors, event) => {

    if(event) {
      if(event.__typename === 'MatchComment') {
        if(event.side === side)
          return [
            ...authors,
            event.author
          ]
      }

      if(event.__typename === 'MatchGoal') {
        if(event.side === side)
          return [
            ...authors,
            ...event.likedBy
          ]
      }
    }

    return authors;
  }, [] as string[]))];

  const emojiFlagsOn = game.home.team?.club.nation.code !== game.away.team?.club.nation.code;

  const team = side === 'home'
    ? game.home.team
    : game.away.team

  const buttonIsWhite = team?.shirt?.color1.toLowerCase() === '#ffffff' || team?.shirt?.color1.toLowerCase() === '#fff';


  const getMatchStatus = () => {

    const leader = (scoreA:number, scoreB:number) => {
      return scoreA > scoreB
        ? 'leader'
        : 'under'
    }

    if(game.finished) {
      return 'finished'
    }
    
    if(game.live) {
      if(side === 'home') {
        return leader(game.result.homeGoals, game.result.awayGoals)
      }

      return leader(game.result.awayGoals, game.result.homeGoals)
    }

    return 'before'
  }

  const colors = game[side].team?.shirt;

  const flag = emojiFlagsOn
    ? <img src={`https://static.cupmanager.net/images/flags_svg/4x3/${game[side].team?.club.nation.code.toLowerCase()}.svg`} alt={T(`Flag ${game[side].team?.club.nation.code}`)} className={styles.flag} />
    : ''

    useClickOutside({ref: ref, outerRef: outerRef, callback: () => {
      setIsVisible(false)}
    });

  const commentTexts = useCommentTexts();
  
  return <>
    <div className={classNames(styles.wrapper)} ref={outerRef}>

      <button 
        className={classNames(styles.dd_button, buttonIsWhite && styles.btn_is_white, side === 'away' && styles.away)}
        onClick={() => {
          setIsVisible(!isVisible)
        }}
      >
      <FontAwesomeIcon icon={faThumbsUp} style={{fontSize: '1.5rem', position: 'relative', top: '-2px'}} />
        <span className={styles.button_txt}>{T('Show your support')}</span>
        <div className={classNames(styles.supporter_count, side === 'away' && styles.away)}>
          <FontAwesomeIcon icon={faUser} /> {authors.length}
        </div>
      </button>
      <div ref={ref} style={{'--map-timeout' : `${animationTimeOut}ms`} as any}>
        <CSSTransition
          in={isVisible}
          timeout={animationTimeOut}
          nodeRef={nodeRef}
          classNames={{
            enter: styles.ddEnter,
            enterActive: styles.ddEnterActive,
            enterDone: styles.ddEnterDone,
            exit: styles.ddExit,
            exitActive: styles.ddExitActive,
            exitDone: styles.ddExitDone
          }}
        >
          <div ref={nodeRef} className={classNames(styles.support_dropdown, side === 'away' && styles.right)}>

            <h3 style={{fontFamily: 'var(--txt-body)', textTransform: 'initial', fontSize: '1rem', fontWeight: 'bold'}}>
                {T('Cheer for X:', T.select(game[side].name))}
            </h3>

            {availableComments[getMatchStatus()].map((btnTxt, i) => {
              return <div className={styles.btn_wrapper} key={i}>

              <ModalTrigger 
                trigger={doOpen => 
                  <button 
                    onClick={async () => { 
                      if (!me.interactionName) {
                        doOpen();
                      } else {
                        addMatchComment({matchId:game.feed.matchId, comment: btnTxt, side})
                      }
                      setIsVisible(false)
                    }}
                    style={{background: colors?.color1, color: colors?.anti}}
                    className={classNames(buttonIsWhite && styles.btn_white_border)}
                  >
                    {flag} {commentTexts[btnTxt]}
                  </button>
                }
                modal={doClose => <LoginModal
                  colors={{
                    primary: game[side].team?.shirt?.color1 || 'rgb(var(--clr-gray-700))',
                    secondary: game[side].team?.shirt?.anti || 'white'
                  }}
                  teamName={T.select(game[side].name)}
                  comment={commentTexts[btnTxt]}
                  onClose={doClose} 
                  onLogin={() => {
                    addMatchComment({matchId:game.feed.matchId, comment: btnTxt, side})
                  }}
                />
                }
              />

            </div>
            })}
          </div>
        </CSSTransition>
      </div>
    </div>
  </>
}

type modalProps = {
  onClose: ()=>void, 
  onLogin: ()=>void, 
  teamName:string | undefined,
  comment: string,
  colors: Record<string, string>
}

function LoginModal({onClose, onLogin, comment, colors } : modalProps) {
  const [username, setUsername] = useState("");
  const t = useCurrentTournament();
  const me = useMe({optionalCupId: t.cup.id},{});
  const [setInteractionName] = useMutation(me.setInteractionName);

  const T = useTranslations('cmresults');

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => ref.current && ref.current.focus(), 0);
  }, [])

  const _likedBy = [
    username.length === 0 ? '??' : username
  ]
  

  return <div className={styles.modal_wrapper}>
    <span role="presentation" className={styles.emojis}>
    {`\u{1F44F} \u{1F680} \u{1F973}`}
    </span>
    <form onSubmit={async (e) => {
      e.preventDefault();
      
      await setInteractionName(username);
      onLogin();
      onClose();
      return false;
    }}>
      <label htmlFor='name'>{T('Please enter your name so that we can show your initials to other visitors')}</label>
      <input type="text" value={username} className={styles.input_name} id='name' onChange={e => setUsername(e.target.value)} ref={ref}/>

      <div className={styles.bubble_wrapper}>
        <div className={styles.bubble}>
          {comment}
        </div>
        <LikeInitials likedBy={_likedBy} size="lg" colors={colors} />
      </div>

      <div className={styles.buttons}>
        <Button disabled={username.length < 2}>
          <FontAwesomeIcon icon={faPaperPlane}/> {T('Send')}
        </Button>
      </div>
    </form>
  </div>
}





