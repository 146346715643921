import { Match, Team, useMe, useTeam } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { TeamHeaderBottom, TeamHeaderTop } from "../../components/ClubTeamHead/TeamHeader";
import Games from "../../components/Game/Games";
import Conferences from "../../components/Conferences/Conferences";
import TeamTimeLine from "../../components/TeamTimeLine/TeamTimeLine";
import { useTranslations } from "cupman-utils";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useParams } from "react-router-dom";
import styles from './TeamPage.module.scss';
import { renderLinks } from "../../utils";
import classNames from "classnames";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import PendingPage from "../../components/Pending/PendingPage";
import { ErrorBoundary, ErrorFallbackNothingToSee } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import { useLink } from "granular";
import LinkButton from "../../components/Button/LinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faRoute } from "@fortawesome/pro-solid-svg-icons";
import { faBedFront } from "@fortawesome/pro-regular-svg-icons";
import TeamAccommodation from "../../components/TeamAccomodation/TeamAccomodation";
import VideoSlider from "../../components/VideoSlider/VideoSlider";
import TeamStatisticsTotalForCup from "../../components/Statistics/TeamPage/TeamStatisticsTotalForCup";

export function TeamPage({timeline}:{timeline?:boolean}) {
  const params = useParams<{id:string}>();
  const teamId = parseInt(params.id || '0');

  const t = useCurrentTournament();
  
  const T = useTranslations('cmresults');
  const me = useMe({optionalCupId: t.cup.id},{
    teams: [{
      team: {}
    }]
  });

  const admin = useAdmin();
  const hasLeaderAccess = me.teams.find(t => t.team?.id === teamId && (t.hasLeaderAccess || t.cupPassUrl));

  const team = useTeam({id:teamId},Team.fragment({
   
      matches: [Match.fragment(Games.GameFragment)],
      divisions: [Conferences.fragment],
      shirt: {},
      category: {},
      club: {
        nation: {},
        registeredTeams: [{}],
        description: {}
      },
      description: {},
      awards: [],
      extraMatches: [{matches:[Games.GameFragment]}],
      timeline: TeamTimeLine.fragment

    }));

  const lotPublished = team.category.lotPublished || admin.overridePublished;
  const schedulePublished = team.category.schedulePublished || admin.overridePublished;
  const accommodationPublished = (t.lodgingPublished && t.foodPublished) || admin.overridePublished;
  const showTable = !team.category.hideConferenceResults;
  
  const timelineForLeader = useLink((hasLeaderAccess || admin.isAdmin) ? team.timelineForLeader : undefined, TeamTimeLine.fragment)
  const accommodationForLeader = useLink((hasLeaderAccess || admin.isAdmin) && (t.lodgingPublished || admin.overridePublished) ? team.accommodation : undefined, {lodgings:[{location:{persons:[]}}]})

 
  const formguideState = (finished: boolean, winner: 'home' | 'away' | undefined, role: 'home' | 'away') => {
    if(!finished) {
      return '-';
    } else {
      if(winner === undefined) {
        return 'tie'
      } else {
        return winner === role ? 'win':'loss';
      }
    }
  }

  let stageId :undefined | number = undefined;
  let formGuide: string[][] = [];
  team.matches.forEach(game => {
    if(game.division.stage.id !== stageId) {
      stageId = game.division.stage.id // set new ID

      formGuide.push([
        teamId === game.home.id 
          ? formguideState(game.finished, game.result.winner, 'home')
          : formguideState(game.finished, game.result.winner, 'away')
      ])
    } else {
      // id match
      formGuide[formGuide.length -1].push(
          teamId === game.home.id 
          ? formguideState(game.finished, game.result.winner, 'home')
          : formguideState(game.finished, game.result.winner, 'away')
        )
    }
  })

  const streams = team.matches.filter(m => m.video);

  // short team stats
  const teamShortStats = {
    wins: 0,
    losses: 0,
    ties: 0,
    goals: 0
  }

  const getStatsData = (teamPoints:number, opponentPoints:number) => {
    if (teamPoints > opponentPoints) {
      teamShortStats.wins++
    }

    if (teamPoints < opponentPoints) {
      teamShortStats.losses++
    }

    if (teamPoints === opponentPoints) {
      teamShortStats.ties++
    }

    teamShortStats.goals += teamPoints
  }

  team.matches.forEach(game => {
    if( game.finished ){
      if(teamId === game.home.id) {
        return getStatsData(game.result.homeGoals, game.result.awayGoals)
      }
      return getStatsData(game.result.awayGoals, game.result.homeGoals)
    }
  })

  // short team stats

  var loginUrl = new URL(t.cup.loginUrl);
  loginUrl.searchParams.append("redirectTo", window.location.href);

  return <div className={styles.content_wrapper}>
    <div>
        <PageHeader 
          title={team.name.clubName} 
          subtitle={team.category.name} 
          scrolledTitle={team.name.categoryName} 
          crumbs={
            [{
              title: T('Results'),
              url: ''
              }, !!timeline && {
              title: team.name.fullName,
              url: `team/${team.id}`
            }, !timeline && {
              title: team.club.name,
              url: `clubs/${team.club.id}`
            }]
          }
          headerFooter={
            <ErrorBoundary fallback={({error, errorInfo}) => <ErrorFallbackNothingToSee error={error} errorInfo={errorInfo} />}>
              <TeamHeaderTop
                sport={t.subcup.sport.type} 
                dress={team.shirt}
                team={{
                  teamName: team.name.fullName,
                  teamId: team.id,
                  teamLogoUrl: team.clubLogoUrl
                }}
                club={team.club}
              />
            </ErrorBoundary>
          }

        />
    </div>

    <div className={styles.bottom_content}>
      <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The team statistics could not be loaded')} square />}>
        <TeamHeaderBottom
          formGuide={!lotPublished || timeline || team.category.hideMatchResults ? undefined : formGuide}
          teamShortStats={!lotPublished || timeline || team.category.hideMatchResults ? undefined : teamShortStats}
          awards={timeline ? undefined : team.awards}
        />
      </ErrorBoundary>
  
      {!timeline && schedulePublished && streams.length > 0 && <VideoSlider 
        games={streams}
        title={T('Live streams')}
        future
      />}

      {showTable && !timeline && <TeamStatisticsTotalForCup team={team} sport={t.subcup.sport.type} category={team.category}/>}

      
       
      {!timeline && <div className={classNames("max-50", styles.description)}>
        <p>{lotPublished ? renderLinks(T.select(team.description.participation)) : ''}
        {lotPublished ? renderLinks(T.select(team.description.placement)) : ''}</p>
        <p>{renderLinks(T.select(team.description.previousResult))}</p>
        <p>{renderLinks(T.select(team.club.description.distance))}
        {renderLinks(T.select(team.club.description.area))}</p>
      </div>}

      {/* foodPublished/lodgingPublished */ timeline && accommodationForLeader && TeamAccommodation.shouldShow(accommodationForLeader, t, admin) && <div className="max-50">
      <h2 className={classNames("custom-font",styles.accommodation_title)}><FontAwesomeIcon icon={faBedFront} /> {T('Accommodation')}</h2>
        <TeamAccommodation accommodation={accommodationForLeader} />
      </div>}
      
      {lotPublished && !timeline && !team.category.hideConferenceResults && <ErrorBoundary fallback={({error, errorInfo}) => 
        <div className="max-75">
          <ErrorComponent error={error} errorInfo={errorInfo} text={T('The group could not be loaded')} />
        </div>
      }>
        <Conferences divisions={team.divisions} team={team} />
      </ErrorBoundary>
      }
      
      
      {schedulePublished && <div className={classNames(styles.buttonbar_outer, "no-print")}>
        <div className={styles.buttonbar}>
        
          <strong>{T('Match schedule:')}</strong>
          <div>
            {(!timeline && !team.category.hideMatchResults) && <LinkButton size="lg" url={`/team/${team.id}/timeline`} >
              <FontAwesomeIcon icon={faRoute} />
              <span>{T('Show team timeline')}</span>
            </LinkButton>}

            <LinkButton size="lg" url={team.matchesCalendarUrl} aria-label={T('Download match schedule as iCal file')}>
              <FontAwesomeIcon icon={faDownload} />
              <span>{T('iCal')}</span>
            </LinkButton>

            <LinkButton size="lg" url={team.matchesExcelUrl} aria-label={T('Download match schedule to Excel')} >
              <FontAwesomeIcon icon={faDownload} />
              <span>{T('Excel')}</span>
            </LinkButton>
          </div>
        </div>

      </div>}

      {(!timeline && !team.category.hideMatchResults) && <>
          <Games published={schedulePublished} games={team.matches} title={/*T('Alla matcher')*/undefined} order="asc" />
          {team.extraMatches.map((em) => <>
            <Games published={schedulePublished} games={em.matches} title={T.select(em.title)} order='asc' />
          </>)}
        </>
      }

      {(timeline || team.category.hideMatchResults) && <>
        {schedulePublished ? 
            <>{team.timeline.hasLeaderEvents && <div className={classNames("max-50", styles.timelineinfo)}>
              {timelineForLeader ?
                 <span>{T("Since X is your team, you have access to the detailed timeline below",team.name.fullName)}</span>
                :<>
                  <span>{T("You do not have access to the detailed timeline of X",team.name.fullName)}</span>
                  <span><a href={loginUrl.toString()}>{T("Log in as a leader")}</a> {T("or scan the Cup Pass of the team to gain access")}</span>
                </>}
              </div>}
              <TeamTimeLine entity={team} timeline={timelineForLeader || team.timeline}/> 
            </>
          : <PendingPage text={T('The game schedule is not published yet')} color="rgb(var(--clr-primary))"/>}
      </>}

    </div>
  </div>
}