import { faSquare, faSquareCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './AwesomeCheckbox.module.scss';
import classNames from "classnames";

type Props = {
  onChange: (val: boolean) => void,
  checked: boolean,
  remSize: number,
  remMargin: number,
  id: string | number,
  disabled?: boolean,
  className?: string
}

export default function AwesomeCheckbox ({onChange, checked, remSize, remMargin, id, disabled, className} : Props) {



  return <div className={styles.outer_container}>
    <input 
      type="checkbox"
      id={''+id}
      checked={checked}
      disabled={disabled}
      onChange={() => onChange(!checked)} 
      style={{
        width: `${remSize}rem`,
        height: `${remSize}rem`,
        margin: `${remMargin}rem`
      }}
    />
    <div className={classNames(styles.fake_checkbox, className && className, disabled && styles.disabled)}>
      <FontAwesomeIcon 
        icon={checked ? faSquareCheck : faSquare} 
        style={{
          height: `${remSize + .3}rem`,
          margin: `${remMargin}rem`
        }}
      />
    </div>
  </div>
}