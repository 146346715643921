import { useTranslations } from "cupman-utils";
import { Division, Match, Team } from "../../api/schema"
import { groupBy } from "../../utils";
import Games from "../Game/Games"
import Conference from "../Conference/Conference";
import Playoff, { PlayoffBoxComponent } from "../Conference/Playoff";
import ContentContainer from "../ContentContainer/ContentContainer";
import styles from './Conferences.module.scss';
import classNames from "classnames";
import React from "react";

Conferences.fragment = Division.fragment({
    ___on_Conference: {
      table: {
        rows: [{team: {
          club: {
            nation: {}
          }
        }}],
      }
    },
    category:{}
})

type props = {
  divisions: Division<typeof Conferences.fragment>[],
  team: Team
}




export default function Conferences ({divisions, team} : props) {

  

  //const divisions : Division<{category:{}}>[] = [];
  const T = useTranslations('cmresults');

  /*games.map(game => game.division).forEach(div => {
    if ( !divisions.find(_ => _.id === div.id)) {
      divisions.push(div);
    }
  });*/

  return <>
    <ContentContainer fullWidth header={/*divisions.length > 1 ? T('Grupper') : T('Grupp')*/undefined} className={styles.no_padding}>
      <div className={classNames(divisions.length > 1 ?'max-75':'max-50', styles.groups_inner_container)}>
         {divisions.map((division, i) => {
          if(division.__typename === "Conference") {
            return <React.Fragment key={division.id}>
              <Conference 
                linkTeams={false}
                key={i}
                table={division.table as any}
                matchesCanTie={division.category.matchesCanTie}
                id={division.id}
                className={divisions.length === 1 ? 'full_width' : ''}
                name={T.select(division.name) || T('Groups')}/>
             
            </React.Fragment>
          }
          if(division.__typename === 'Playoff') {
            return <PlayoffBoxComponent playoff={division} team={team}/>
          }
          // Missing league and withdrawn

          return false;
        })}
      </div>
    </ContentContainer>
  </>
}