import { faTrophy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import styles from './MatchEnd.module.scss';
import HidePeriodButton from "./HidePeriodButton";
import { MatchResult, RuleTemplate$PeriodTypes, Sport, Sport$SportTypes } from "../../api/schema";
import { useSportSpecificWords } from "../../utils/useSportSpecificWords";
import PeriodScore from "./PeriodScore";
import Score from "../Score/Score";

type props = {
  teamName: string | undefined, 
  winner: 'home' | 'away' | undefined,
  lastPeriod: number,
  lastPeriodType: RuleTemplate$PeriodTypes,
  sport: Sport$SportTypes,
  ordinaryPeriodCount: number,
  hiddenPeriods: Set<number>,
  result: MatchResult,
  setHiddenPeriods: (hiddenPeriods: Set<number>) => void
}

export default function MatchEnd ({teamName, winner, lastPeriod, lastPeriodType, sport, ordinaryPeriodCount, result, hiddenPeriods, setHiddenPeriods} : props) {

  const T = useTranslations('cmresults');

  const formatStartWord = useSportSpecificWords(sport, ordinaryPeriodCount);
  const lastPeriodName = lastPeriod == 0 ? T('matchen') : 
      formatStartWord(lastPeriodType, lastPeriod);
      

  return <><div className={styles.wrapper}>
    <div className={styles.trophy}>
      {winner === 'home' && <div>
        <FontAwesomeIcon icon={faTrophy} />
      </div>}
    </div>
    <div className={styles.info}>
      <span>{T('The game is finished')}</span>
      <Score result={result} />
      <span className={styles.congrats}>{teamName 
        ? T('Congrats X!', teamName) 
        : T('Tied')}
      </span>
    </div>
    <div className={styles.trophy}>
      {winner === 'away' && <div>
        <FontAwesomeIcon icon={faTrophy} />
      </div>}
    </div>
   

  </div>

  {result.winByPeriods &&  result.periodScores.at(-1) &&
      <div className={styles.last_period}>
        <span>{lastPeriodName}</span>
        <PeriodScore homeScore={result.periodScores.at(-1)?.homeGoals || 0} awayScore={result.periodScores.at(-1)?.awayGoals || 0} winByPeriod={true}/>
    </div>}

 
  
  <HidePeriodButton hiddenPeriods={hiddenPeriods} 
                            setHiddenPeriods={setHiddenPeriods} 
                            period={lastPeriod}
                            periodName={lastPeriodName}/>
  </>
}