import classNames from 'classnames';
import svgMask from '../../img/clip-path-logo.svg';
import styles from './TeamLogo.module.scss';
import { ReactNode } from 'react';

type Props = {
  logoImg: string | Element | ReactNode,
  className?: string,
  bgColor?: string
}

export default function TeamLogo ({logoImg, className, bgColor} : Props) {



  return <div 
    className={classNames(styles.team_icon_container, className)} 
    style={{'--image-mask': `url(${svgMask})`} as any}
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      viewBox="0 0 125.4 113.2"
      className={styles.border}
    >
      <g style={{fill: 'rgb(var(--clr-favorite-dark))'}}>
        <path d="M85.4,0h-45.4c-6.2,0-11.9,3.3-14.9,8.6L2.3,48c-3.1,5.3-3.1,11.9,0,17.2l22.7,39.4c3.1,5.3,8.8,8.6,14.9,8.6h45.4c6.2,0,11.9-3.3,14.9-8.6l22.7-39.4c3.1-5.3,3.1-11.9,0-17.2l-22.7-39.4c-3.1-5.3-8.8-8.6-14.9-8.6Z"/>
      </g>
    </svg>

    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      viewBox="0 0 125.4 113.2"
      className={styles.icon_bg}
    >
      <g style={{fill: bgColor ? bgColor : 'white'}}>
        <path d="M85.4,0h-45.4c-6.2,0-11.9,3.3-14.9,8.6L2.3,48c-3.1,5.3-3.1,11.9,0,17.2l22.7,39.4c3.1,5.3,8.8,8.6,14.9,8.6h45.4c6.2,0,11.9-3.3,14.9-8.6l22.7-39.4c3.1-5.3,3.1-11.9,0-17.2l-22.7-39.4c-3.1-5.3-8.8-8.6-14.9-8.6Z"/>
      </g>
    </svg>
    <div className={styles.mask}>
      {typeof logoImg === 'string'
        ? <img src={logoImg} alt="cat" />
        : <>{logoImg}</>
      }
    </div>
  </div>
}