import { useEffect } from "react";


const funcList : Function[] = [];

setInterval(() => {
    funcList.forEach(func => func());
}, 1000);

export default function useGlobalSecondTick(func : Function) {
    useEffect(() => {
        funcList.push(func);
        return () => {
            var idx = funcList.indexOf(func);
            funcList.splice(idx, 1);
        }
    }, []);
}
