import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useColors } from "../../colors";
import styles from './TeamTimeLine.module.scss';
import { MeReferee$timeline, Referee$timeline, Team$timeline, Team$timelineForLeader } from "../../api/schema";
import { useTranslations } from "cupman-utils";
import { faSplit } from "@fortawesome/pro-light-svg-icons";
import HeaderSelect from "../HeaderSelect/HeaderSelect";


export default function BranchSelect({timeline, selectedBranchId, setSelectedBranchId, lastLocation}: 
  { timeline: Team$timeline<{branches:[{pseudoStageTeam:{}, pseudoTeam:{}}]}>
            | Team$timelineForLeader<{branches:[{pseudoStageTeam:{}, pseudoTeam:{}}]}>
            | Referee$timeline<{branches:[{pseudoStageTeam:{}, pseudoTeam:{}}]}>
            | MeReferee$timeline<{branches:[{pseudoStageTeam:{}, pseudoTeam:{}}]}> ,
    selectedBranchId: number|undefined, 
    setSelectedBranchId: (selectedBranchId: number|undefined) => void,
    lastLocation: boolean
  }) {

    const T = useTranslations("cmresults");
    const colors = useColors();
  return (
    <div className={styles.branch_select_container}>
        <div className={styles.event_line}>
           
            <div className={classNames(styles.line, styles.line_one)} style={{background:  '#DFEEEF' }}></div>
            {(!lastLocation || selectedBranchId) && <div className={classNames(styles.line, styles.line_two)} style={{background: '#DFEEEF' }}></div>}
        </div>

        <h3>
            <FontAwesomeIcon icon={faSplit} className={styles.icon} />
        </h3>
        <div className={styles.branch_select}>
            <span>{T('Show coming games for:')}</span>
            {timeline.branches && <HeaderSelect 
                    onChange={t => {
                      setSelectedBranchId(t);
                    }}
                    selectedId={selectedBranchId} 
                    options={timeline.branches?.map(b => (b.pseudoStageTeam || b.pseudoTeam || {id: -1, name: {en : ''}}))}/>}

            {/*timeline.branches?.map(b => {
              if( b.pseudoTeam ){
                return <button className={classNames(styles.button, b.pseudoTeam?.id == selectedBranchId && styles.selected)} onClick={()=>setSelectedBranchId(b.pseudoTeam?.id)}>
                  {T.select(b.pseudoTeam.name)}
                </button>
              } else if( b.pseudoStageTeam ) {
                return <button className={classNames(styles.button, b.pseudoStageTeam?.id == selectedBranchId && styles.selected)} onClick={()=>setSelectedBranchId(b.pseudoStageTeam?.id)}>
                  {T.select(b.pseudoStageTeam.name)}
                </button>
              }
             
            })*/}
        </div>
    </div>
  )
}