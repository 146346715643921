import { faMap,faTrophy, faUsers } from "@fortawesome/pro-regular-svg-icons";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { useCurrentTournament } from "../../App";
import PageHeader from "../../components/PageHeader/PageHeader"
import styles from '../StatisticsPage/StatisticsPage.module.scss';
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import Games from "../../components/Game/Games";

export default function StatisticsFinalsPage () {

  const T = useTranslations('cmresults');

  const t = useLink(useCurrentTournament(), {
    finals: [Games.GameFragment]
});

  const admin = useAdmin();

  const lotPublished = t.lotPublished || admin.overridePublished;
  const schedulePublished = t.schedulePublished || admin.overridePublished;

  return <>
    <PageHeader title={T('Finals')}
      crumbs={[
        {
          title: T('Results'),
          url: ''
        }
      ]}
    >
      {t.hasStatistics ? <Shortcuts links={[
        {
          to: '/statistics',
          title: T('Overview'),
          icon: faMap
        }, schedulePublished && {
          to: '/statistics/winners',
          title: T('Winners'),
          icon: faTrophy
        }, t.hasPlayerStatistics && {
            to: '/statistics/players',
            title: T('Players'),
            icon: faUsers
        }, {
          to: '/statistics/finals',
          title: T('Finals'),
          icon: faTrophy
        }
      ]} />: undefined}
    </PageHeader>

    <div className={classNames("max-75", styles.content_wrapper)}>
      
      {lotPublished && <div>
        {schedulePublished && t.finals.length > 0 && <Games 
          published={schedulePublished}
          games={t.finals}
          alwaysShow
          title={T('Finals')}
          order='asc'
    />}
    </div>}

    </div>
    </>
  }