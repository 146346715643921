import { useTranslations } from "cupman-utils"
import { Link, useParams } from "react-router-dom"
import { useLink } from "granular";
import { Referee, useReferee } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import PageHeader from "../../components/PageHeader/PageHeader"
import TeamTimeLine from "../../components/TeamTimeLine/TeamTimeLine";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import { useState } from "react";

import searchBarStyles from '../../components/Search/SearchBar.module.scss';
import acStyles from '../../components/Search/AutocompleteResults.module.scss';
import classNames from "classnames";
import SearchGroupHeader from "../../components/Search/SearchGroupHeader";

import refereesPageStyles from './RefereesPage.module.scss';
import { faRoute } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RefereesPage() {

  const _t = useCurrentTournament();
  const t = useLink(_t, {
    referees: [{}]
  });

  const T = useTranslations('cmresults');
  
  const [q, setQ] = useState('');
  const matchRegexp = new RegExp(q, 'i');
  function refereeMatches(referee: Referee) {
    if (q.length <= 2) return false;
    return referee.name?.match(matchRegexp) || referee.number.match(matchRegexp);
  }

  return <>
    <PageHeader 
      title={T('Referee schedule')} 
      crumbs={[{
        title: T('Resultat'),
        url: ''
        }
      ]}
    />
    <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75" style={{marginTop: '4rem'}}>
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load referees')} />
    </div>}>
      <div  className="max-50">
        
        <div style={{marginTop: "1rem"}} className={classNames(
          searchBarStyles.search_wrapper, 
          searchBarStyles.typed, 
          searchBarStyles.results
        )}>
          <form 
            role="search" 
            className={searchBarStyles.inner_search_wrapper}
            method="get"
          >
            <input className={searchBarStyles.search_input} type="search" placeholder={T('Search referee by name or number')} onChange={(e) => setQ(e.target.value)} value={q} />
          </form>
        </div>

        
          {t.referees.map(referee => <div 
              className={acStyles.search_group} 
              style={{display: refereeMatches(referee) ? 'block':'none'}}>
            <SearchGroupHeader type="referees" title={referee.name}>
              <div style={{flex: 1}}></div>
              <Link style={{whiteSpace: "nowrap"}} className={refereesPageStyles.btn_link} 
                to={`/referee/${referee.id}`}
              ><FontAwesomeIcon icon={faRoute} /> {T('Schedule')}</Link>
            </SearchGroupHeader>
          </div>)}
      </div>
    </ErrorBoundary>
  </>
} 