import styles from './MatchSummary.module.scss';

import Score from "../Score/Score";
import MatchTeam from "./MatchTeam";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomIconFa from '../CustomIconFa/CustomIconFa';
import { Match, Sport$SportTypes } from '../../api/schema';
import ActiveCountdown from "../Countdown/ActiveCountdown";
import { useCurrentTournament } from '../../App';
import { useTranslations } from 'cupman-utils';
import classNames from 'classnames';
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { faCalendar, faClock } from '@fortawesome/pro-light-svg-icons';
import { useRef } from 'react';
import useScrolledFromTop from '../../utils/HasScrolledFromTop';

MatchSummary.fragment = {
  home: MatchTeam.fragment,
  away: MatchTeam.fragment,

  result: {},
  arena: {
    location: {}
  },
  video: {
   
  },
  division: {
    stage: {
      category: {
      }
    }
  }
}

type props = {
  game: Match<typeof MatchSummary.fragment>
}


export default function MatchSummary({game} : props) {
  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const sport = t.subcup.sport.type;

  const T = useTranslations('cmresults');

  const getGlow = () => {
    if(game.video && game.live && !game.finished) 
      return styles.live_now;
    
    if(!game.video && game.live && !game.finished) 
      return styles.reporting_now;
    
    return false;
  }

  const ref = useRef<HTMLDivElement>(null);

  const sticked = useScrolledFromTop(170);
  const cn = classNames(styles.match_summary_container, getGlow(), sticked ? styles.sticked : undefined);
  
  return <>
    
    <div className={cn} ref={ref}>
      <div className={styles.overlay_black}>
        <div className="max-75">

          <div className={classNames(styles.teams, sticked ? styles.sticked : undefined)}>
            <MatchTeam
              side='home'
              sticked={sticked}
              team={game.home}
              sport={sport as Sport$SportTypes | undefined}
            />

            {(game.live || game.finished ) && !game.division.stage.category.hideMatchResults
              ? <Score
              result={game.result}
                  className={classNames(
                    styles.scoreSize, sticked ? styles.sticked : undefined
                  )}
                  sticked={sticked}
                
                />
              : <div className={classNames(styles.countdown, sticked && styles.sticked)}>
                  <ActiveCountdown to={game.start} color={"100, 100, 100"}/>
                </div>
            }

            <MatchTeam
              side='away'
              sticked={sticked}
              team={game.away} 
              sport={sport as Sport$SportTypes | undefined}
            />
          </div>

          <div className={classNames(styles.game_date_time, sticked ? styles.sticked : undefined)}>
            <div>
              <span>
                <FontAwesomeIcon icon={faClock}/> {formatDate(game.start, 'HH:mm')}
              </span> 
              <span>
                <FontAwesomeIcon icon={faCalendar}/> {formatDate(game.start, 'eee. d MMM')}
              </span>
              {/*<span>
                <CustomIconFa icon='icon-arena' fontWeight="bold" white />{game.arena?.completeName}
              </span>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}


