import { useFormatDate } from "cupman-utils/lib/LangContext";
import { useEffect, useState } from "react";
import { MatchEvent } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { formatMinutes } from "../../utils";
import styles from './StopWatch.module.scss';

type props = {
  event: MatchEvent,
  isActive: boolean,
  max: number
}

export default function StopWatch ({event, isActive, max} : props) {
  

  const [time, setTime] = useState(
    Date.now() - event.absoluteTime + event.relativeTime
  );

  
  useEffect(() => {
    let interval = null as any;
  
    if(isActive) {
      interval = setInterval(() => {
        setTime(Date.now() - event.absoluteTime + event.relativeTime)
      }, 1000)
    }
  
    return () => clearInterval(interval)
  }, [isActive, time])

  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);



  return <span className={styles.stop_watch_container}>
    <span>
      {time < max*100
        ? formatMinutes(time)
        : formatMinutes(max)
      }
    </span> : <span>
      {time < max*100
        ? formatDate(time, 'ss')
        : `${formatDate(max, 'ss')}+`
      }
    </span>
  </span>
}