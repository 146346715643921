import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { useLink } from 'granular';
import React, { useEffect, useState } from 'react';
import { Conference, Division } from '../../api/schema';
import Button from '../Button/Button';
import styles from './ConferenceRounds.module.scss';


ConferenceRounds.Fragment = Conference.fragment({
    rounds: [{state: {}}],
  });
  
type props = {
    conference: Conference,
    selectedRoundHref: undefined | string,
    setSelectedRoundHref: (id: string) => void
}
  
export default function ConferenceRounds({conference : _conference, selectedRoundHref, setSelectedRoundHref} : props) {

    const conference = useLink(_conference,ConferenceRounds.Fragment);
    
    useEffect(()=>{
        if( selectedRoundHref === undefined ){

            const round = conference.rounds.find(r => r.state.state !== "finished") || conference.rounds[0];
            setSelectedRoundHref(round.href);
        }
    },[])

    const T = useTranslations("cmresults");
   
    return <div className={classNames(styles.container,"max-75")}>
        <h2 className={classNames(styles.title,"custom-font")}>{T('Select round')}</h2>
        <div className={styles.rounds}>
            {conference.rounds.map(r => {
                return <div className={classNames(styles.round,
                    r.state.state === 'finished' && styles.finished,
                    r.state.state === 'ongoing' && styles.ongoing,
                    r.state.state === 'coming' && styles.coming,
                    selectedRoundHref === r.href && styles.selected)}>
                    <Button className={styles.button} onClick={() => {
                        setSelectedRoundHref(r.href)
                    }}>
                        <span>{r.rank+1}</span>
                    </Button>
                
                </div>
            })}
        </div>
    </div> 
}