import { faMars, faPlus, faVenus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { Call, useClient } from "granular";
import { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Search, Search$SearchCategory, Search$SearchClub, Search$SearchLocation, SearchLocation$SearchArena } from "../../api/schema";
import Flag from "../Flag/Flag";
import SearchGroupHeader from "./SearchGroupHeader";
import styles from './AutocompleteResults.module.scss';
import { useCurrentTournament } from "../../App";
import GameFieldIcon from "../GameFieldIcon/GameFieldIcon";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { SearchClubResults } from "./SearchTeamsResults";

type acResults = {
  query: string | undefined,
  tournamentId: number,
  setAutoResults: (bol: boolean) => void,
  searchRef: React.RefObject<HTMLInputElement>,
  noMutation?: boolean
}

export default function AutocompleteResults({
  query: _query, 
  tournamentId,
  setAutoResults,
  searchRef,
  noMutation
} : acResults) {

  const [acResults, setAcResults] = useState<any>();



  const client = useClient();
  const T = useTranslations('cmresults');
  const query = _query ? _query : ''; 

  const [momentQuery, setMomentQuery] = useState<undefined | string>(undefined);

  const [catExpanded, setCatExpanded] = useState(false);


  const render = (array: any) => {
    if( array && array.length > 0 ) {
      return true;
    }
    
    return false;
  }


  useEffect(() => {

    query !== momentQuery && setCatExpanded(false);

    if (query !== "") {
      const callLink = Search({tournamentId,query});  //  ...../search?tournamentId=123&query=Ar
      const call = Call.fromLink(callLink, {
        clubs: [{
          nameClub: {
            nation: {}
          },
          teams: [{
            team: {}
          }]
        }],
        /*nations: [{
          nation: {}
        }],*/
        categories: [{
          category: {}
        }],
        locations: [{
          location: {
            location: {}
          },
          arenas: [{
            arena: {}
          }]
        }]
      });  // call = link + expansion

      client.fetch(call).then(result => {
        const entity = client.readFromCache(call);
        setAcResults(entity);
      })
    }

  }, [query, client, tournamentId]);


  useEffect(() => {

    const isResults = () => {

      if (acResults) {
        if(render(acResults.clubs) || render(acResults.categories) || render(acResults.locations))
          return true;
        return false;
      }
      return false;
    }

    setAutoResults(isResults());

  },[acResults])


  
  const categoryChoosen = acResults?.categories.filter((cat: Search$SearchCategory) => cat.chosen) || [];
  const categoryOthers = acResults?.categories.filter((cat: Search$SearchCategory) => !cat.chosen) || [];



  return <>
    {acResults && <div className={styles.ac_results}>
      {render(acResults.clubs) && <div className={styles.search_group} >

        {acResults.clubs.map((club: Search$SearchClub<{
          nameClub: {},
          teams: [{
            team: {}
          }]
        }>) => {
          return <>
              <SearchClubResults club={club} setMomentQuery={setMomentQuery} query={query} searchRef={searchRef} noMutation={noMutation} /> 
          </>

        })}
        

      </div>}


      {render(acResults.locations) && <>
        {acResults.locations.filter((location:Search$SearchLocation<{
          location: {
            location: {}
          },
          arenas: [{
            arena: {}
          }]
        }>) => location.arenas.length > 0).map((location:Search$SearchLocation<{
          location: {
            location: {}
          },
          arenas: [{
            arena: {}
          }]
        }>) => <AutoCompleteArenasResults location={location} setMomentQuery={setMomentQuery} query={query} searchRef={searchRef}/>)}
      </>}



      {render(acResults.categories) && <div className={styles.search_group}>
        <SearchGroupHeader type="categories" />
        <div className={styles.content}>
          <ul>
            {categoryChoosen.map((category:any) => <li key={category.category.id}>
              <Link to={`/categories/${category.category.id}`} className={styles.link} onClick={() => document.body.classList.remove('search-focused')}>
                <span>{category.category.gender === 'men' 
                  ? <FontAwesomeIcon icon={faMars} /> 
                  : <FontAwesomeIcon icon={faVenus} />
                } {T.select(category.name)}</span>
              </Link>
            </li>)}

            {catExpanded && <li className={styles.separator}></li>}

            {catExpanded && categoryOthers.map((category:Search$SearchCategory<{
                category: {}
            }>) => (
              <li key={category.category.id}>
                <Link to={`/categories/${category.category.id}`} className={styles.link} onClick={() => document.body.classList.remove('search-focused')}>
                <span>{category.category.gender === 'men' 
                  ? <FontAwesomeIcon icon={faMars} /> 
                  : <FontAwesomeIcon icon={faVenus} />
                } {T.select(category.name)}</span>
                </Link>
              </li>
            ))}
          </ul>

          {categoryOthers.length > 0 && !catExpanded && <button className={styles.show_more} onClick={() => {   
            setCatExpanded(true);
            setMomentQuery(query);
            searchRef.current?.focus();
          }}>
            <span>
              <FontAwesomeIcon icon={faPlus} /> {T('Show all categories')}
            </span>
          </button>}

        </div>
      </div>}
    </div>}
  </>
}

function AutoCompleteArenasResults ({location, query, setMomentQuery, searchRef} : {
  query: string,
  setMomentQuery: React.Dispatch<React.SetStateAction<string | undefined>>,
  location: Search$SearchLocation<{
    location: {
      location: {}
    },
    arenas: [{
      arena: {}
    }]
  }>,
  searchRef: React.RefObject<HTMLInputElement>
}) {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const arenasChoosen = location.arenas.filter(arena => arena.chosen);
  const arenasOther = location.arenas.filter(arena => !arena.chosen);

  const [arenasExpanded, setArenasExpanded] = useState(false);

  return <div className={styles.search_group} key={location.location.id}>
    <SearchGroupHeader type="locations" title={T.select(location.name)} />
    <div className={styles.location_wrapper}>
      <div className={styles.content}>
        <ul>
        {arenasChoosen.map((arena: SearchLocation$SearchArena<{
            arena: {}
          }>) => {
            
            return <li key={arena.arena.id}>
              <Link to={`/places/${arena.arena.id}`} className={styles.link} onClick={() => document.body.classList.remove('search-focused')
              }>
                <GameFieldIcon icon={t.subcup.sport.type} fontWeight="bold"/> <span>{arena.arena.completeName} </span>
              </Link>
            </li>}
          )}
          

          {arenasExpanded && <li className={styles.separator}></li>}

          {arenasExpanded && arenasOther.map((arena: SearchLocation$SearchArena<{
            arena: {}
          }>) => {

            return <li key={arena.arena.id}>
              <Link to={`/places/${arena.arena.id}`} className={styles.link} onClick={() => document.body.classList.remove('search-focused')
              }>
                <GameFieldIcon icon={t.subcup.sport.type} fontWeight="bold"/> <span>{arena.arena.completeName} </span>
              </Link>
            </li>}
          )}
        </ul>

        {arenasOther.length > 0 && !arenasExpanded && <button className={styles.show_more} onClick={() => {   
          setArenasExpanded(true);
          setMomentQuery(query);
          searchRef.current?.focus();
        }}>
          <span>
            <FontAwesomeIcon icon={faPlus} /> {T('Show all arenas')}
          </span>

        </button>}
      </div>
      <div className={styles.map}>
        <a aria-label={T('Show location on Google Maps')} href={`https://www.google.com/maps/search/?api=1&query=${location.location.location.latitude},${location.location.location.longitude}`} target="_blank" rel="noopener noreferrer">
          <img src={`https://static.cupmanager.net/uploads/staticMap,${location.location.id},400x240,zoom=10,hash=ENHBBCRB.png`} alt={''} />
        </a>
      </div>
    </div>
  </div>
}