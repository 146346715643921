import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import styles from './LinkCardLoading.module.scss';
const loadingAnimation = 'https://static.cupmanager.net/images/loading.min.svg';

type props = {
  loadingWhat?: string,
  show?: boolean,
  video?: boolean
}

const LinkCardLoading = ({loadingWhat, show, video} : props) => {

  const T = useTranslations('cmresults');

  return <div className={classNames(styles.container, show && styles.visible, video && styles.video)}>
    <div className={classNames(styles.inner, show && styles.visible)}>
      <img src={loadingAnimation} alt="" />
      {show && 
        <>{!loadingWhat 
          ? <span>{T('Loading')}</span>
          : <span>{T('Loading X', loadingWhat)}</span>}
        </>
      }
    </div>
  </div>
}

export default LinkCardLoading;