import classNames from "classnames";
import { useContext } from "react";
import { Link } from "react-router-dom";
import HeartButton from "../ButtonHeart/HeartButton";
import styles from './TeamRow.module.scss';
import { useTranslations } from "cupman-utils";
import { useMutation } from 'granular';
import { useMe } from "../../api/schema";
import { useCurrentTournament } from "../../App";

type props = {
  team : {
    name: string,
    id?:number
  },
  className?: string
}

export default function TeamRow ({team, className} : props) {

  const T = useTranslations('cmresults');

  const t = useCurrentTournament();
  const me = useMe({optionalCupId: t.cup.id},{
    teams: [{
      team: {}
    }]
  });
  const [addFavoriteTeam] = useMutation(me.addFavoriteTeam);
  const [removeFavoriteTeam] = useMutation(me.removeFavoriteTeam);
  const favoriteMarked = me.teams.some(t => t.team?.id === team.id);

  return (
    <div className={classNames(className, styles.team_row_inner)}>
      {team.id
        ? <>
          <Link to={`/team/${team.id}`} className={styles.team_name}>{team.name}</Link>
          {team.id && <HeartButton 
            size="sm" 
           // teamName={team.name}
            onChange={async () => {
              if (favoriteMarked) {
                team.id && removeFavoriteTeam(team.id).then(window.trigger_magicbutton_refresh);
              } else {
                team.id && addFavoriteTeam(team.id).then(window.trigger_magicbutton_refresh);
              }
            }} 
            likeStatus={!!favoriteMarked}
            color='rgb(var(--clr-primary))' 
            className={styles.heart_button_inner} 
          />}
        </>
        : <>
          {team.name} - {T('Not published')}
        </>
        
      }
    </div>
  )
}