import classNames from 'classnames';
import styles from './WinnersIcon.module.scss';

type props = {
  placement: number,
  phase: 'upcoming' | 'live' | 'finished'
}

export default function WinnersIcon ({phase, placement} : props) {

  const color = phase === 'upcoming'
    ? 'rgb(var(--clr-gray-400))'
    : '#fff'


    
  return <div className={classNames(styles.wrapper, phase === 'live' && styles.live)} style={{'--clr-icon' : color} as any}>
  {/* placement IS LARGER THAN 3 - ALWAYS THIS SYMBOL */

  placement > 3 ? 
    <>
      {placement && <div className={styles.placement}>
        <svg viewBox="0 0 22 22">
          <text x="50%" y="17"  style={{
            fill: 'var(--clr-icon)',
            textAnchor: 'middle'

          }}>{placement}</text>
        </svg>
      </div>}

      <svg version="1.1" id="Lager_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 123.7 112.1" xmlSpace="preserve">
        <path style={{fill: 'var(--clr-icon)'}} d="M83.59304,112.09659H40.13297c-6.55612,0-12.66529-3.52698-15.94381-9.20509L2.45889,65.25291c-3.27852-5.67717-3.27852-12.73207,0-18.41018L24.18916,9.20509C27.46675,3.52698,33.57639,0,40.13297,0h43.46007c6.55705,0,12.66575,3.52698,15.94428,9.20509l21.7298,37.63765c3.27759,5.67811,3.27759,12.73301,0,18.41018l-21.7298,37.63858c-3.27852,5.67811-9.38816,9.20509-15.94428,9.20509ZM40.13297,3.82588c-5.19427,0-10.03406,2.79375-12.63073,7.29215L5.77198,48.75568c-2.59667,4.4984-2.59667,10.08683,0,14.5843l21.73027,37.63858c2.59713,4.4984,7.43693,7.29215,12.63073,7.29215h43.46007c5.19333,0,10.03359-2.79375,12.63026-7.29215l21.7298-37.63858c2.5976-4.49746,2.5976-10.0859,0-14.5843L96.2233,11.11803c-2.59667-4.4984-7.43599-7.29215-12.63026-7.29215H40.13297Z"/>
        
      </svg>
    </>
    
  : <>
    {/* placement IS LESS THAN 3 */}
    {phase === 'finished'
      ? <>
        {/* FINISHED - SHOW REAL ACHIEVEMNTS */}
        {placement === 1 && <img src="https://static.cupmanager.net/images/achievements/placement-gold.svg" alt="" className={styles.glow_gold} />}

        {placement === 2 && <img src="https://static.cupmanager.net/images/achievements/placement-silver.svg" alt=""  className={styles.glow_silver}/>}

        {placement === 3 && <img src="https://static.cupmanager.net/images/achievements/placement-bronze.svg" alt=""  className={styles.glow_bronze}/>}
      </>
      : <>
        {/* NOT FINISHED */}
        <svg version="1.1" id="Lager_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 125.4 113.2"  xmlSpace="preserve">

          <path style={{fill: 'var(--clr-icon)'}} d="M85.4,4.2c4.7,0,9.1,2.5,11.5,6.6l22.7,39.4c2.4,4.1,2.4,9.2,0,13.2l-22.7,39.4c-2.4,4.1-6.8,6.6-11.5,6.6H40
            c-4.7,0-9.1-2.5-11.5-6.6L5.8,63.4c-2.4-4.1-2.4-9.2,0-13.2l22.7-39.4c2.4-4.1,6.8-6.6,11.5-6.6H85.4 M85.4,0.2H40
            c-6.2,0-11.9,3.3-14.9,8.6L2.3,48.2c-3.1,5.3-3.1,11.9,0,17.2L25,104.8c3.1,5.3,8.8,8.6,14.9,8.6h45.4c6.2,0,11.9-3.3,14.9-8.6
            l22.7-39.4c3.1-5.3,3.1-11.9,0-17.2L100.4,8.8C97.3,3.5,91.6,0.2,85.4,0.2L85.4,0.2z"/>

          {/* THROPY SYMBOL */}
          {placement === 3 && <path style={{fill: 'var(--clr-icon)'}} d="M94,35.6H80.8v-6.2c0-0.8-0.6-1.4-1.4-1.4H45.9c-0.7,0-1.4,0.6-1.4,1.4v6.2H31.4c-0.7,0-1.4,0.6-1.4,1.4v7.4
            c0,7.5,7.7,16.1,18.2,17.8c3.3,7.3,8.4,10.8,12.6,11.6v11.7h-9.8c-1.6,0-2.9,1.4-2.9,3.1c0,0.4,0.3,0.7,0.7,0.7h27.7
            c0.4,0,0.7-0.3,0.7-0.7c0-1.7-1.3-3.1-2.9-3.1h-9.8V73.8c4.3-0.8,9.3-4.3,12.6-11.6c10.5-1.6,18.2-10.3,18.2-17.8V37
            C95.4,36.2,94.8,35.6,94,35.6z M33.7,44.4v-5h10.9v2.9c0,6.2,0.8,11.4,2.1,15.6C38.9,55.8,33.7,49.3,33.7,44.4z M71.6,56.1
            c-0.4,0.9-1,1.7-1.8,2.3c-0.8,0.6-1.8,1.1-3,1.5c-1.2,0.3-2.6,0.5-4.3,0.5c-1.1,0-2.1-0.1-3-0.2c-0.9-0.1-1.7-0.3-2.4-0.5
            c-0.7-0.2-1.3-0.4-1.8-0.7c-0.5-0.3-1-0.5-1.4-0.8v-6.6h6.2v0.9c0,0.8,0.2,1.4,0.5,1.8c0.4,0.4,0.9,0.5,1.6,0.5s1.2-0.2,1.6-0.6
            c0.4-0.4,0.5-1.1,0.5-2.1c0-0.8-0.2-1.3-0.5-1.7c-0.3-0.4-0.9-0.6-1.7-0.6H60v-4.7h2.2c0.8,0,1.4-0.2,1.7-0.6
            c0.3-0.4,0.5-0.9,0.5-1.5c0-0.8-0.2-1.4-0.5-1.7c-0.4-0.4-0.9-0.5-1.6-0.5s-1.2,0.2-1.6,0.5c-0.4,0.4-0.5,1-0.5,1.8v0.5h-6.2v-6.4
            c0.4-0.2,0.8-0.4,1.4-0.7c0.5-0.2,1.2-0.5,1.9-0.7c0.7-0.2,1.6-0.4,2.5-0.5c0.9-0.1,2-0.2,3.2-0.2c2.9,0,5.1,0.6,6.7,1.8
            c1.5,1.2,2.3,2.9,2.3,5.1c0,0.7-0.1,1.3-0.3,1.9c-0.2,0.6-0.4,1-0.7,1.4c-0.3,0.4-0.6,0.8-0.9,1.1c-0.3,0.3-0.7,0.6-1,0.8v0.3
            c0.3,0.2,0.7,0.5,1.1,0.7c0.4,0.3,0.7,0.6,1.1,1.1c0.3,0.4,0.6,0.9,0.8,1.5c0.2,0.6,0.3,1.3,0.3,2C72.2,54.2,72,55.2,71.6,56.1z
            M91.7,44.4c0,4.9-5.2,11.4-13,13.5c1.3-4.2,2.1-9.4,2.1-15.6v-2.9h10.9V44.4z"/>}

          {placement === 2 && <path style={{fill: 'var(--clr-icon)'}} d="M94.1,35.7H81v-6.2c0-0.8-0.6-1.4-1.4-1.4H46c-0.7,0-1.4,0.6-1.4,1.4v6.2H31.5c-0.7,0-1.4,0.6-1.4,1.4v7.4
            c0,7.5,7.7,16.1,18.2,17.8c3.3,7.3,8.4,10.8,12.6,11.6v11.7h-9.8c-1.6,0-2.9,1.4-2.9,3.1c0,0.4,0.3,0.7,0.7,0.7h27.7
            c0.4,0,0.7-0.3,0.7-0.7c0-1.7-1.3-3.1-2.9-3.1h-9.8V73.9c4.3-0.8,9.3-4.3,12.6-11.6C87.8,60.7,95.5,52,95.5,44.5v-7.4
            C95.5,36.3,94.9,35.7,94.1,35.7z M33.8,44.5v-5h10.9v2.9c0,6.2,0.8,11.4,2.1,15.6C39,55.9,33.8,49.4,33.8,44.5z M73.1,60.1H53.3
            v-4.8c1.6-1.4,3.1-2.7,4.5-3.9c1.4-1.2,2.5-2.4,3.5-3.4c1-1,1.7-2,2.3-2.8c0.5-0.8,0.8-1.4,0.8-1.9c0-0.8-0.2-1.4-0.5-1.8
            c-0.4-0.4-0.9-0.5-1.6-0.5s-1.2,0.2-1.6,0.5c-0.4,0.4-0.5,1-0.5,1.8v1.6h-6.2v-7.4c0.5-0.3,1.1-0.5,1.8-0.7
            c0.6-0.2,1.4-0.4,2.3-0.6c0.9-0.2,1.8-0.4,2.8-0.5c1-0.1,2-0.2,3-0.2c2.9,0,5.2,0.6,6.6,1.8c1.5,1.2,2.2,3,2.2,5.3
            c0,1.3-0.3,2.5-0.9,3.6c-0.6,1.2-1.3,2.3-2.3,3.3c-0.9,1-1.9,2-3,3c-1.1,0.9-2.1,1.8-3.1,2.6H67v-1.8h6.1V60.1z M91.9,44.5
            c0,4.9-5.2,11.4-13,13.5c1.3-4.2,2.1-9.4,2.1-15.6v-2.9h10.9V44.5z"/>}

          {placement === 1 && <path style={{fill: 'var(--clr-icon)'}} d="M93.8,35.4H80.6v-6.2c0-0.8-0.6-1.4-1.4-1.4H45.7c-0.7,0-1.4,0.6-1.4,1.4v6.2H31.2c-0.7,0-1.4,0.6-1.4,1.4v7.4
            c0,7.5,7.7,16.1,18.2,17.8c3.3,7.3,8.4,10.8,12.6,11.6v11.7h-9.8c-1.6,0-2.9,1.4-2.9,3.1c0,0.4,0.3,0.7,0.7,0.7h27.7
            c0.4,0,0.7-0.3,0.7-0.7c0-1.7-1.3-3.1-2.9-3.1h-9.8V73.7c4.3-0.8,9.3-4.3,12.6-11.6c10.5-1.6,18.2-10.3,18.2-17.8v-7.4
            C95.2,36.1,94.6,35.4,93.8,35.4z M33.5,44.2v-5h10.9v2.9c0,6.2,0.8,11.4,2.1,15.6C38.7,55.6,33.5,49.1,33.5,44.2z M70.2,59.8H55.6
            v-5.1h3v-14h-3.9v-3.5l6.9-1.6h6v19.1h2.6V59.8z M91.5,44.2c0,4.9-5.2,11.4-13,13.5c1.3-4.2,2.1-9.4,2.1-15.6v-2.9h10.9V44.2z"/>}

        </svg>
      </>
    }
  </>}
  </div>
}