import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HeaderSelect.module.scss';
import buttonStyles from '../Button/Button.module.scss';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';

type props<T> = {
  icon?: IconDefinition,
  legend?: string
  options: {
    name: string | Record<string, string>,
    id: T
  }[],
  selectedId: T | undefined,
  onChange: (id:T) => void,
  className?: string,
  styled?: 'sm' | 'sm-alt' | 'lg' | 'lg-alt'
}


export default function HeaderSelect<Ty extends string|number>({icon, legend, options, onChange, selectedId, className, styled:_style} : props<Ty>) {

  const selectId = uuidv4();

  const T = useTranslations('cmresults');

 
  return (
    <div className={classNames(styles.select_container, className)}>
      {/*<FontAwesomeIcon icon={icon} className={styles.icon} />*/}
      {/*<label className={styles.legend} htmlFor={selectId}>{legend}:</label>*/}
      <div className={styles.select_inner_container}>

        <select onChange={(e) => {
          const selected = options.find((option) => option.id == e.target.value); 
          selected && onChange(selected.id)
        }} id={selectId}>
          {selectedId === undefined && <option selected>{legend}</option>}
          {options.map((option) => {
            
            return <option 
              selected={selectedId===option.id} 
              key={option.id} 
              value={option.id}>
                {typeof option.name === 'string' ? option.name : T.select(option.name)}
              </option>
            }
          )}
          
        </select>
        
        <FontAwesomeIcon icon={faAngleDown} className={styles.select_arrow}/>
      </div>

    </div>
  )
}