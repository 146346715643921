import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { MatchEvent } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import styles from './TimelineTime.module.scss';


export default function TimelineTime ( { time, side } : {time: number, side?: string, type?: MatchEvent['__typename']} ) {

  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);


  return <div className={classNames(styles.container, side === 'away' && styles.away)}>
    {side && <>
      {side === 'home' && <FontAwesomeIcon icon={faAngleLeft} className={styles.direction_left} />}
      {side === 'away' && <FontAwesomeIcon icon={faAngleRight} className={styles.direction_right} />}
    </>}
    <div className={styles.live_minutes}>
      <span className={styles.time}>
        {formatDate(time, 'HH:mm')}
      </span>
      <span className={styles.date}>
        {formatDate(time, 'dd MMM')}
      </span>
    </div>
  </div>

}