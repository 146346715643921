import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import styles from './SolidSportInfo.module.scss';

const SolidSportInfo = () => {

  const T = useTranslations("cmresults");

  return <>
    <div className={styles.top}>
      <h2 className="custom-font">
        {T('Livesänd din turnering')}
        <span>{T('Tjäna pengar till klubben och laget')}</span>
      </h2>
    </div>
    <div className={classNames("max-75", styles.solidsport_container)}>
      <h2 className={styles.solidsport_logo}>
        <img src="https://static.cupmanager.net/images/cm-results/solid-sport-red.svg" alt="Solidsport logo" />
      </h2>
      <h3>
        {T('Livesänd era matcher - Allt som behövs finns redan i din ficka.')}
      </h3>
      <p>{T('Med hjälp av en smartphone kan alla sporter på alla nivåer streama sport helt gratis – och samtidigt tjäna pengar. Intäkterna kommer från tittarna som köper sändningarna. Hälften av intäkterna går alltid till laget eller föreningen som sänder.')}</p>

      <h3>{T('Solidsport och Cup Manager')}</h3>
      <p>{T('Det är enkelt att använda Solidsport med era cup-hemsidor. CupManager och Solidsport har ett nära samarbete och vi är helt integrerade i våra lösningar.')}</p>

      <h3>{T('Analysera matcher')}</h3>
      <p>{T('Med hjälp av Solidsport kan ni som lag, klubb, tränare och spelare enkelt gå in och kolla på repriser för att analysera spelet. Ett verktyg för att gå in och kolla på allt från övergripande spel till detaljer.')}</p>
    </div>
  </>
}

export default SolidSportInfo;