import classNames from "classnames";
import {useMemo} from 'react';
import { MatchComment, MatchFeed$Comments } from "../../api/schema";
import { getEventBg } from "../TeamTimeLine/TeamTimeLine";
import styles from './Comment.module.scss';
import { useCommentTexts } from "./useCommentTexts";

type props = {
  event: MatchComment,
  colors: Record<string, string>,
  commentCount?:number,
  hideTime?: boolean,
  randomMargin?: boolean
}

export default function Comment ({event, colors, commentCount, hideTime, randomMargin} : props) {

  let margin = useMemo(() => {
    return `${Math.floor(Math.random() * 60)}px`
  }, []);
  
  const bubbleMargin = randomMargin 
    ? event.side === 'home'
      ? {
        marginLeft: margin
      }
      : {
        marginRight: margin
      }
    : {}

  const commentTexts = useCommentTexts();

  return <div className={classNames(styles.comment_container, event.side === 'away' && styles.away, event.side === 'away'
    ? 'away'
    : 'home'
  )} style={
    {
      ['--clr-team-secondary' as any]: colors.secondary,
      ['--clr-team-primary' as any]: colors.primary
    }
  }>
    <div className={classNames(styles.comment_inner_container, !!commentCount && styles.comment_margin)} style={
      bubbleMargin
    }> 
      <div className={styles.author_container}>
        <div className={styles.author}> 
          <div className={styles.inner_author}>
            <span>{event.author}</span>
            {!!commentCount && <div className={styles.author_number}>+{commentCount}</div>}
          </div>
        </div>
      </div> 
      <div className={styles.bubble}>
        <span>{commentTexts[event.comment as MatchFeed$Comments] || event.comment}</span>
      </div>
    </div>
    {/*!hideTime && <TimelineMinutes time={event.relativeTime}/>*/}
    <div></div>
  </div>
}
