import classNames from "classnames";
import OpposingHorizontalBars from "./OpposingHorizontalBars";
import styles from './LabeledOpposingHorizontalBars.module.scss';

type Bar = {
  value: number,
  color: string | undefined,
  label: string | number,
  extraLabel?: string
}

type Props = {
  left: Bar,
  right: Bar,
  label: string
}

export default function LabeledOpposingHorizontalBars ({left, right, label} : Props) {


  return <div>
    <h4 className={classNames(styles.opposing_horizontal_bars_header, 'custom-font')}>
      <span className={classNames(styles.value_fields, styles.left_fields)}>
      {left.extraLabel && <span className={styles.extra_field}>
          {left.extraLabel}
        </span>}
        <span>
          {left.label}
        </span>
      </span>
      {label}
      <span className={styles.value_fields}>
        <span>{right.label}</span>
        {right.extraLabel && <span className={styles.extra_field}>{right.extraLabel}</span>}
      </span>
    </h4>
    <OpposingHorizontalBars
      left={{
        length: left.value,
        color: left.color || '#000'
      }}
      right={{
        length: right.value,
        color: right.color|| '#000'
      }}
     />
  </div>
}