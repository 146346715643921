import { ReactNode, useEffect, useRef, useState } from "react";
import styles from './ButtonWithTooltip.module.scss';
import classNames from "classnames";
import { useClickOutside } from "../../utils/useClickOutside";

type Props = {
  children: ReactNode,
  body: string | ReactNode,
  buttonClassName?: string,
  bodyClassName?: string,
  backgroundColor?: string
}

export default function ButtonWithTooltip ({
    children, 
    body, 
    buttonClassName, 
    bodyClassName,
    backgroundColor
  } : Props) {

    const toolTipBodyEl = useRef<HTMLDivElement | null>(null);

    const calculateOverflowAndSetClassNames = () => {
      if (toolTipBodyEl.current) {

        // RESET POSITION BEFORE CACULATING
        toolTipBodyEl.current.classList.remove(styles.to_bottom);
        toolTipBodyEl.current.classList.remove(styles.to_top);
        toolTipBodyEl.current.classList.remove(styles.to_left);
        toolTipBodyEl.current.classList.remove(styles.to_right);

        // CHECK IF ELEMENT IS OVERFLOWING VIEWPORT
        const rect = toolTipBodyEl.current.getBoundingClientRect();
        const overflowingLeft = rect.left < 0;
        const overflowingRight = rect.right > document.documentElement.clientWidth;
        const overflowingTop = rect.top < 0;
        const overflowingBottom = rect.bottom > window.innerHeight;

        if (overflowingLeft) {
          toolTipBodyEl.current.classList.add(styles.to_left)
        } else if (overflowingRight) {
          toolTipBodyEl.current.classList.add(styles.to_right);
        }

        if (overflowingTop) {
          toolTipBodyEl.current.classList.add(styles.to_top);
        } else if (overflowingBottom) {
          toolTipBodyEl.current.classList.add(styles.to_bottom);
        }
      }
    }

    const transitionTime = 150;

    const ref = useRef(null);

    const [isActive, setIsActive] = useState(false);

    useClickOutside({ref: ref, callback: () => {
      setIsActive(false)}
    });


  return <div className={classNames(
    styles.tooltip_container, 
    isActive && styles.active)} style={{'--transition-ms' : `${transitionTime}ms`} as any}>
    <button
      ref={ref} 
      onMouseEnter={() => {
        if(!window.matchMedia("(pointer: coarse)").matches) {
          calculateOverflowAndSetClassNames();
          setIsActive(true);
        }
      }} 
      onMouseLeave={() => {
        if(!window.matchMedia("(pointer: coarse)").matches) {
          setIsActive(false)
        }
      }}
      onClick={() => {
        if(window.matchMedia("(pointer: coarse)").matches) {
          calculateOverflowAndSetClassNames()
          setIsActive(!isActive)
        }
      }}
      className={classNames(styles.tooltip_btn, buttonClassName)}
    >
      {children}
    </button>

    <div className={classNames(
        styles.tooltip_body, 
        bodyClassName,
        isActive && styles.active
      )}
      style={{'--background' : backgroundColor ? backgroundColor : 'white'} as any}
    >
        <div ref={toolTipBodyEl} className={bodyClassName}>
          <div className={styles.bubble_pointer}></div>
          {body}
        </div>
      </div>
  </div>
}