import { useEffect, useState } from "react";

var listeners = [] as (() => void)[]; 
window.onscroll = () => {
  //console.log(listeners);
  listeners.forEach(listener => listener())
}



export const useScrolledFromTop = (pxFromTop? : number | undefined, onScroll? : (pxFromTop: number) => void) => {

  const [scrolled, setScrolled] = useState<true|false|undefined> (window.pageYOffset > (pxFromTop || 1) || undefined);
  
  useEffect(() => {
    
    const listener = () => {

      onScroll && onScroll(window.pageYOffset);
      
      if(window.pageYOffset > (pxFromTop || 1)) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    listeners.push(listener);

    onScroll && onScroll(window.pageYOffset);
    
    return ()=> {
      listeners.splice(listeners.indexOf(listener),1);
    }
  }, [pxFromTop, onScroll === undefined]);

  return scrolled;
}



export const useRefScrolledFromTop = (ref: HTMLDivElement|null, setScrolled: (scrolled: boolean) => void, pxFromTop? : number | undefined) => {

  useEffect(() => {

    if( ref ){
      const listener = () => {
        if(ref.scrollTop > (pxFromTop || 1)) {
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      }

      ref.addEventListener('scroll',listener);
      return ()=> {
        ref.removeEventListener('scroll',listener);
      }
    }
  }, [pxFromTop,ref]);
}





export const useSticked = (el: HTMLElement | null) => {
  
  const [sticked, setSticked] = useState<true|false|undefined> (undefined);
  
  useEffect(() => {

    if( el ){

      let originalOffset = el.offsetTop;

      const listener = () => {
        if(el.offsetTop > originalOffset) {
          setSticked(true)
        } else {
          setSticked(false)
        }
      }

      listeners.push(listener);
      return ()=> {
        listeners = listeners.splice(listeners.indexOf(listener),1);
      }
    }

  },[el]);

  return sticked;
}

export default useScrolledFromTop;