import { replaceWhite } from '../../colors';
import { getInitials } from '../../utils';
import styles from './LikeInitials.module.scss';
var cn = require('classnames');

type props = {
  side?: 'home' | 'away',
  likedBy: string[],
  colors?: Record<string, string>,
  size?: 'lg'
}

export default function LikeInitials ({side, likedBy, colors: _colors, size} : props) {


  const colors = {
    primary: (_colors && replaceWhite(_colors.primary).color) || 'black',
    secondary: (_colors && _colors.secondary) || 'white'
  }


  return <div className={cn(styles.like_initials_container, size === 'lg' && styles.large, side === 'away' ? styles.away : styles.home)} style={
    {
      ['--clr-team-primary' as any]: colors.primary,
      ['--clr-team-secondary' as any]: colors.secondary
    }}>
              
  {likedBy && likedBy.slice(0,5).map((like, i) => {
    if(like.length > 0) {
      return <div className={cn(styles.like_initials, styles.one)} style={{border: _colors &&replaceWhite( _colors.primary).border ? '1px solid rgb(var(--clr-gray-300))' : ''}} key={i}>
        <div className={cn(styles.white, styles.one)}>
          <span>{getInitials(like)}</span>
        </div>
      </div>
    }

    return <></>;
  })}
  

  {/* DECORATION */}
  {likedBy && likedBy.length > 5 && <div className={cn(styles.initials_decoration, side === 'away' ? styles.away : styles.home)}>
    <div className={cn(styles.like_initials)}>
      <div className={cn(styles.white, styles.six)}></div>
    </div>
    <div className={cn(styles.like_initials)}>
      <div className={cn(styles.white, styles.seven, side === 'away' ? styles.away : styles.home)}></div>
    </div>
  </div>}

</div>
}