import { useTranslations } from "cupman-utils";
import { Match } from "../../api/schema";
import { LoadingPath, useCurrentTournament } from "../../App";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCirclePlay, faClock } from "@fortawesome/pro-light-svg-icons";
import styles from './VideoSliderLink.module.scss';
import Flag from "../Flag/Flag";
import { format } from "date-fns";
import classNames from "classnames";
import ActiveCountdown from "../Countdown/ActiveCountdown";
import { useContext } from "react";
import LinkCardLoading from "../LinkCardLoading/LinkCardLoading";
import { useFormatDate } from "cupman-utils/lib/LangContext";

type Team = {
  team: {
    club: {
      nation: {}
    }
  }
}

type props = {
  game: Match<{
    video: {},
    home: Team,
    away: Team,
  }>,
  dark?: boolean
}


export default function VideoSliderLink({game, dark} : props) {

  const loadingPath = useContext(LoadingPath);
  const loadingThisPath = loadingPath === `/match/${game.id}`;

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const sport = t.subcup.sport.type;
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const thumbUrl = 'https://static.cupmanager.net/images/generic-sport-images400/'+ sport.toLowerCase() +'.jpg';

  return <a href={game.video?.externalLink || '#'/*`/match/${game.id}`*/} className={styles.video_slider_container} key={game.id}>
  <div className={classNames(styles.player_outer_container, {[styles.handbollplay]: t.streamingInfo?.provider === 'handbollplay'})}>
    <LinkCardLoading show={loadingThisPath} video loadingWhat={T('match')} />
    <div className={styles.player} style={{backgroundImage: `url(${thumbUrl})`}}>
      <div className={styles.video_overlay}>
        {game.finished ?
          <span className={styles.play}>
           <FontAwesomeIcon icon={faCirclePlay} className={styles.play_icon} />
           {T("Watch replay")}
          </span>
          : game.start < Date.now()
            ? <span className={styles.play}>
              <FontAwesomeIcon icon={faCirclePlay} className={styles.play_icon} />
              Se live!
            </span>
            : <span style={{transform:'scale(0.8)'}}>
                <ActiveCountdown to={game.start} />
              </span>}
        
        {t.streamingInfo?.provider === 'solidsport' && <img className={styles.video_provider} src='https://static.cupmanager.net/images/cm-results/solid-sport-white.svg' alt={T('Watch the game online')} />}
        {t.streamingInfo?.provider === 'handbollplay' && <img className={styles.video_provider} src='https://static.cupmanager.net/images/cm-results/handbollplay-logo.svg' alt={T('Watch the game online')} />}
      </div>

      

    </div>

    <div className={classNames(styles.video_info, dark && styles.dark)}>
      <div className={styles.team}>
        <Flag code={game.home.team?.club.nation.code} alt={T.select(game.home.team ? game.home.team.club.nation.code : '')} /> <span>{T.select(game.home.name)}</span>
      </div>
      <div className={styles.team}>
        <Flag code={game.away.team?.club.nation.code} alt={T.select(game.away.team ? game.away.team.club.nation.code : '')} /> <span>{T.select(game.away.name)}</span>
      </div>
      <div className={styles.video_meta}>
        <span>
          <FontAwesomeIcon icon={faCalendar} /> {formatDate(game.start, 'dd/MM - yy')} 
        </span>
        <span>
          <FontAwesomeIcon icon={faClock} /> {formatDate(game.start, 'HH:mm')}
        </span>
      </div>
    </div>
    </div>
  </a>
}