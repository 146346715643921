import { useTranslations } from "cupman-utils";
import { useDivision, useStageTeamConference } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import Games from "../../components/Game/Games";
import Conference from "../../components/Conference/Conference";
import Playoff from "../../components/Conference/Playoff";
import NextStages from "../../components/PrevNextStages/PrevNextStages";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader/PageHeader";
import styles from './StageTeamConferencePage.module.scss';
import TopScrollShadow from "../../components/TopScrollShadow/TopScrollShadow";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import PendingPage from "../../components/Pending/PendingPage";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";

export default function StageTeamConferencePage () {

  const params = useParams<{catId:string,stageId:string,rank:string}>();
  const catId = parseInt(params.catId || '0');
  const stageId = parseInt(params.stageId || '0');
  const rank = parseInt(params.rank || '0');

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();

  const admin = useAdmin();
  
  const division = useStageTeamConference({cid:catId, rank: rank, sid: stageId, tid: t.id},{
    table: {
      rows: [{
        team: {
          club: {
            nation: {}
          }
        }
      }],
    },
    jumboTable: {},
    stage: {
      nextStages: [{
        teams: [{}]
      }]
    },
    category: {},
    matches: [Games.GameFragment]
  });

  const lotPublished = division.stage.lotPublished || admin.overridePublished;
  const schedulePublished = division.stage.schedulePublished || admin.overridePublished;
  const excludeMatches = division.jumboTable.maxRank !== division.jumboTable.minRank && rank <= division.jumboTable.minRank
    && division.category.stageTeamStrategy === "excludeMatches";
   
  const perMatch = division.jumboTable.maxRank !== division.jumboTable.minRank && division.category.stageTeamStrategy === "scorePerMatch";


  return <div className={styles.content_wrapper}>
    <PageHeader 
      title={T.select(division.name) || 'Gruppspel'} 
      crumbs={[{
        title: T('Results'),
        url: ''
      },
      {
        title: division.category.shortName,
        url: `categories/${division.category.id}`
      },
      {
        title: division.stage.name,
        url: `categories/${division.category.id}/${division.stage.id}`
      }
    ]}
    >
      {lotPublished ? <NextStages nextStages={division.stage.nextStages} catId={division.category.id} />:undefined}
    </PageHeader>
    
    <div className={styles.bottom_content}>
      <TopScrollShadow top={120}/>
      {lotPublished ? <>
        <div className="max-50">
          <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The group could not be loaded')}/>}>
            <Conference 
              linkTeams={true}
              nextStages={division.stage.nextStages}
              matchesCanTie={division.category.matchesCanTie}
              table={division.table as any} 
              resultPerMatch={perMatch}
              jumboTable={excludeMatches ? division.jumboTable : undefined}
              name={T.select(division.name) || T('Group')}
              className={'full_width'}
              hideResult={division.category.hideConferenceResults}
            />
          </ErrorBoundary>
              {!division.finished && 
                <div className={styles.result_warning}>
                  <h3>{T('Please note:')}</h3>
                  <span>{T('All the groups in X have not finished yet. The teams might change as more games are finished', division.stage.name)}</span>
              </div>}
              {division.table.hasTiedTeams && division.finished && 
                <div className={styles.result_warning}>
                  <h3>{T('Please note:')}</h3>
                  <span>{T('There are teams that have the exact same ranking. Until it is resolved the teams will not move to the next stage')}</span>
              </div>}

            {!division.category.hideConferenceResults && <>
              <div className={styles.result_order}>
                <h3>{ perMatch ? 
                      T('Sort order (result per game):'): excludeMatches  ?
                      T('Sort order *:') : T('Sort order (result in totalt):') }
                </h3>
              
                {division.category.stageResultOrder.map((o, i) => <span>{i + 1}. {T.select(o)}</span>)}
              </div>
              
              {excludeMatches && <div className={styles.jumbo_info}>
                {T('* The teams that are marked with an * in the table played in a group with X instead of Y teams. In order to compare the results of the teams from all the groups, the game against the team that finished last in the X-team groups is not used in the ordering of the Z',
                division.jumboTable.maxRank, division.jumboTable.minRank, T.select(division.name))}
              </div>}
            </>}
          
        </div>
        <ErrorBoundary fallback={({error, errorInfo}) =>    <ErrorComponent error={error} errorInfo={errorInfo} text={T('The games could not be loaded')}/>}>
          <Games published={schedulePublished} games={division.matches} title={T('Games')} order='asc' knownDivision />
        </ErrorBoundary>
      </> 
      : <PendingPage text={T('The game schedule for X in Y is not published yet', division.stage.name, division.category.name )} color="rgb(var(--clr-primary))"/>}
    </div>
  </div>
}