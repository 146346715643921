import { faFlag, faQuestion, faShield, faUsersClass, faWhistle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { Link } from "react-router-dom";
import CustomIconFa from "../CustomIconFa/CustomIconFa";
import styles from './SearchGroupHeader.module.scss';

type props = {
  type: 'clubs' | 'nations' | 'categories' | 'locations' | 'referees',
  title?: string,
  children?: React.ReactNode
}



export default function SearchGroupHeader ({type, title, children} : props) {

  const T = useTranslations('cmresults');

  const getIcon = (type: string) => {

    if(type === 'clubs') {
      return <FontAwesomeIcon icon={faShield}/>
    }
    if(type === 'nations') {
      return <FontAwesomeIcon icon={faFlag} />
    }
    if(type === 'categories') {
      return <FontAwesomeIcon icon={faUsersClass} />
    }
    if(type === 'locations') {
      return <CustomIconFa icon='icon-arena' fontWeight="bold" white />
    }
    if(type === 'referees') {
      return <FontAwesomeIcon icon={faWhistle} />
    }

    return <FontAwesomeIcon icon={faQuestion} />
  }



  const getHeader = (type: string) => {

    if(type === 'clubs') {
      return T('Clubs')
    }
    if(type === 'nations') {
      return T('Nations')
    }
    if(type === 'categories') {
      return T('Categories')
    }
    if(type === 'locations') {
      return T('Places')
    }
    if(type === 'referees') {
      return T('Referees')
    }

    return <></>
  } 

  return <div className={styles.search_group_title_wrapper}>
    <div className={styles.search_group_icon}>
      {getIcon(type)} 
    </div>
    <span className={styles.search_group_title}>{!title ? getHeader(type) : title}</span>
    {children}
  </div>
}