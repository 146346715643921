import { faLongArrowAltUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { MapLocation } from "../api/schema";
import { getColorString, useColors } from "../colors";
import { getPointerColor, setFontStyleCharacterMap } from "../utils";
import { getBearingBetween, MapMarker, useBearingNameFromRad } from "./MapTypes";
import styles from './PoiCard.module.scss';
import { Link } from "react-router-dom";
import ExternalLink from "../components/Button/ExternalLink";
import classNames from "classnames";

export default function PoiCard ({point, startPoint, flatBottom} : {
    point: MapLocation & {distance:number}, startPoint: MapMarker, flatBottom?: boolean}) {

  const colors = useColors();

  const iconUrl = `https://static.cupmanager.net/images/icons_svg/marker-symbols/${point.type}.svg`;
  const T = useTranslations('cmresults')
  const startCoords = `${startPoint.latitude},${startPoint.longitude}`;
  const bearing = getBearingBetween(startPoint, point);
  const bearingName = useBearingNameFromRad(bearing);

  const to = point.visibility === "interestingPlaces" ? `https://www.google.com/maps/dir/?api=1&origin=${startCoords}&destination=${point.latitude},${point.longitude}`
   : ('/map/' + point.id);


  return <ExternalLink to={to} className={classNames(styles.poi_card, flatBottom && styles.flat_bottom)} target={point.visibility === "interestingPlaces"?"_blank":undefined}>
    <div className={styles.icon_holder}>
      {point.character && <div className={styles.character_container} style={{
      ...setFontStyleCharacterMap(point.character),
      background: getPointerColor(colors,point)
    }}>
        <div className={styles.character}>{point.character}</div>
      </div>}
      <img src={iconUrl} alt={point.type} className={styles.header_icon}/>
    </div>
    <div className={styles.info}>
      <h4 className={"custom-font"}>{point.name}</h4>
      <div className={styles.links}>

        <div className={styles.compass}>
          <FontAwesomeIcon icon={faLongArrowAltUp} style={{transform: `rotate(${bearing}rad)`}}/>
        </div>
        <span className={styles.distance_txt}>{T('X meters Y', point.distance, bearingName)}</span>

        {/*<Button onChange={() => onChange(point)} aria-label={T('Vägbeskrivning')} className={styles.pointer_link}>
          <FontAwesomeIcon icon={faArrowsMinimize}/>
        </Button>*/}
      </div>
    </div>
  </ExternalLink>
}