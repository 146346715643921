import { useRef, useEffect } from "react";
import Tick from "@pqina/flip";
import "./Flippen.scss";
//import "@pqina/flip/dist/flip.css";

export const Flippen = ({value}) => {
  const divRef = useRef();
  const tickRef = useRef();

  useEffect(() => {
    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
      value,
      didInit: tick => {
        tickRef.current = tick;
      }
    });
    return () => Tick.DOM.destroy(tickValue);
  }, []);

  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = value;
    }
  }, [value]);

  return (
    <div ref={divRef} className="tick">
      <div data-repeat="true">
        <span data-view="flip">-</span>
      </div>
    </div>
  );
};
