import { faCalendarDay, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { getColorString, useColors } from "../../colors";
import { getEventPlaceBg } from "./TeamTimeLine";
import styles from './TeamTimeLine.module.scss';
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { useCurrentTournament } from "../../App";
import Button from "../Button/Button";
import { useRef } from "react";
import { useTranslations } from "cupman-utils";
import { useDivision, useMatch } from "../../api/schema";
import { useLink } from "granular";

type props = {
  date:number|string, 
  duringActivity?: boolean, 
  place?:string,
  transparent?: boolean,

  hidden?: boolean,
  setHidden?: (hidden:boolean) => void
}

export default function DateHeader({date, duringActivity, place, transparent, hidden, setHidden}: props) {

  const t = useCurrentTournament();
  const T = useTranslations("cmresults");
  const formatDate = useFormatDate(t.cup.timeZoneId);

  

  const ref = useRef<HTMLDivElement>(null);

  const colors = useColors();

  const innerHeader = <><div className="scroll_to_div"></div><div ref={ref} className={classNames(styles.date_inner_container, transparent && styles.transparent)}>
      <h3 className={"custom-font"}>
        <FontAwesomeIcon icon={faCalendarDay} className={styles.icon} /> <span>
          {typeof date == "string" ? date.split('|')[1] : formatDate(date, 'd')}
        </span>
        <div className={styles.day_month}>
          <div>{typeof date == "string" ? date.split('|')[2] : formatDate(date, 'LLLL')}</div>
          <div>{typeof date == "string" ? date.split('|')[0] : formatDate(date, 'EEEE')}</div>
        </div>
      </h3>
      {setHidden && <Button inverted size="sm" className={classNames(styles.hide_date_button, styles.no_border, !hidden && styles.date_open)} onClick={() => {
        setHidden(!hidden)
        if( !hidden ) {
          // @ts-ignore
           if( ref.current?.previousElementSibling?.offsetTop < window.pageYOffset ){
            // @ts-ignore
            window.scrollTo(0,ref.current?.previousElementSibling?.offsetTop-36);
           }
        }
      }}><FontAwesomeIcon icon={faChevronRight}/><span>{hidden ? T("Show day") : T("Hide day")}</span></Button>}
    </div>
    {/*<div className={classNames(styles.date_bottom_border,  transparent && styles.transparent)}></div>*/}
    <div className={classNames(styles.date_top_border,"no-print",  transparent && styles.transparent)}></div>
  </>;

  return (
    duringActivity
      ? <>{innerHeader}
          
          <div className={classNames(styles.date_container, hidden && "date_hidden")} >
            <div style={{background: getColorString(getEventPlaceBg(colors,place))}} className={classNames(styles.line, styles.line_between, !place && styles.directions)}>
            </div>
          </div>
        </>
      : <>
        {innerHeader}
      </>
  )
}