import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {penaltiesMinutes: number}, away: {penaltiesMinutes: number}},
  statisticsTotal: {home: {penaltiesMinutes: number}, away: {penaltiesMinutes: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
}

export default function PenaltiesMinutes ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const totalPenaltyMinutes = statisticsTotal.home.penaltiesMinutes + statisticsTotal.away.penaltiesMinutes;


  return <LabeledOpposingHorizontalBars 
    label={T('Minuter')}
    left={{
      value: statistics.home.penaltiesMinutes / totalPenaltyMinutes,
      color: teamColors.home,
      label: statistics.home.penaltiesMinutes,
    }}
    right={{
      value: statistics.away.penaltiesMinutes / totalPenaltyMinutes,
      color: teamColors.away,
      label: statistics.away.penaltiesMinutes
    }}
  />
}