import { useTranslations } from 'cupman-utils';
import styles from './Loading.module.scss';

type props = {
  loadingThis?: string
}

export default function PageLoading ({loadingThis} : props) {

  const T = useTranslations('cmresults')
  const loadingAnimation = 'https://static.cupmanager.net/images/loading.min.svg';

  return <div className={styles.large_loading_container}>

    <div className={styles.large_loading_inner_container}>
      <img src={loadingAnimation} alt="" className={styles.icon} />
      <span className={styles.info}>
        {loadingThis ? T('Loading X', loadingThis) : T('Loading')}
      </span>
    </div>

  </div>
}