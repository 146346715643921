import { faClock, faStarShooting, faChevronsUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { MatchStart, RuleTemplate$PeriodTypes, Sport$SportTypes } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import styles from './HidePeriodButton.module.scss';
import { useSportSpecificWords } from "../../utils/useSportSpecificWords";
import { capitalize } from "../../utils";
import FaSvgIcon from "../FaSvgIcon/FaSvgIcon";
import Button from "../Button/Button";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";


type props = {
  period: number,
  periodName: string|undefined
  hiddenPeriods: Set<number>,
  setHiddenPeriods: (hiddenPeriods: Set<number>) => void
}


export default function HidePeriodButton ({period, periodName, hiddenPeriods, setHiddenPeriods} : props) {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();


  return <div className={styles.hide_period_button_container}><Button size="sm" className={classNames(styles.hide_period_button, !hiddenPeriods.has(period) && styles.period_open)} onClick={() => {
      if( hiddenPeriods.has(period) ){
          hiddenPeriods = new Set(hiddenPeriods);
          hiddenPeriods.delete(period);
          setHiddenPeriods(hiddenPeriods);
        } else {
          hiddenPeriods = new Set(hiddenPeriods);
          hiddenPeriods.add(period);
          setHiddenPeriods(hiddenPeriods)
        }
    }}>
    <FontAwesomeIcon icon={faChevronRight}/>
    <span>{hiddenPeriods.has(period) ? 
        T("Visa händelser i X",periodName) : 
        T("Göm X", periodName)}
    </span>
  </Button></div>
}