import { MutationSpec } from 'granular'
import { MeReferee$AcceptRefereeCostsPayload } from '..'

export function MeReferee$acceptRefereeCosts_Optimization(params:{id:number}, payload:MeReferee$AcceptRefereeCostsPayload, query:never) : Partial<MutationSpec<MeReferee$AcceptRefereeCostsPayload,never,any>> {
	return {
		fetchRefsIfInvalidatedByMutation: [],
		optimistics: {
			// Add your optimistics here like [Ref({paramId:123})]: (data:Ref) => { return data; }
		}
	}
}
