import { useTranslations } from "cupman-utils";
import { CupColorsSpec } from "../colors";

export type MapMarker = {
    type: string,
    color?: string | string[],
    longitude: number,
    latitude: number,
    name: string,
    id: number,
    cluster?: number
  }

  export const getDistance = (marker1: MapMarker, marker2: MapMarker) => {
    const R = 6371*1000;
    const value = Math.acos(
        Math.sin(toRadians(marker1.latitude))*Math.sin(toRadians(marker2.latitude))+
        Math.cos(toRadians(marker1.latitude))*Math.cos(toRadians(marker2.latitude))*
        Math.cos(toRadians(marker1.longitude-marker2.longitude)));
    return Math.round(value*R);
  }

  
const toRadians = (degrees:number) => degrees * (Math.PI/180);



export const useBearingNameFromRad = (angle:number) => {
  const offset = Math.PI/8;
  const step = Math.PI/4;

  const T = useTranslations("cmresults");
  
  if( angle >= offset && angle < offset+step ){
    return T('North east');
  } 
  
  if( angle >= offset+step && angle < offset+2*step ){
    return T('East');
  } 
  
  if( angle >= offset+2*step && angle < offset+3*step ){
    return T('South east');
  }
  
  if( angle >= offset+3*step && angle < offset+4*step ){
    return T('South');
  }
  
  if( angle >= offset+4*step && angle < offset+5*step ){
    return T('South west');
  }
  
  if( angle >= offset+5*step && angle < offset+6*step ){
    return T('West');
  }
  
  if( angle >= offset+6*step && angle < offset+7*step ){
    return T('North west');
  }
  
  return T('North');
}
	
export const getBearingBetween = (marker1: MapMarker, marker2: MapMarker)=> {
  const dLon = toRadians(marker2.longitude - marker1.longitude);
  
  const lat1 = toRadians(marker1.latitude);
  const lat2 = toRadians(marker2.latitude);
  
  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1)*Math.sin(lat2) -
          Math.sin(lat1)*Math.cos(lat2)*Math.cos(dLon);

  const brng = (Math.atan2(y, x) +2*Math.PI) % (2*Math.PI);
  
  return brng;
  
}


 
