import { CSSProperties, ReactNode } from "react"
import styles from './ContentGrayContainer.module.scss';
import classNames from "classnames";

type Props = {
  children: ReactNode,
  className?: string
}

export default function ContentGrayContainer ({children, className} : Props) {
  return <div className={classNames(styles.wrapper, className)}>
      {children}
    </div>
}