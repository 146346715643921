import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";
import { EventStatistics$TeamStats } from "../../api/schema";

type Props = {
  statistics: {home: {penaltiesCount: number}, away: {penaltiesCount: number}},
  statisticsTotal: {home: {penaltiesCount: number}, away: {penaltiesCount: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
}

export default function PenaltyCount ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')
  const totalPenaltyCount = statisticsTotal.home.penaltiesCount + statisticsTotal.away.penaltiesCount;

  return <LabeledOpposingHorizontalBars 
    label={T('Antal')}
    left={{
      value: statistics.home.penaltiesCount / totalPenaltyCount,
      color: teamColors.home,
      label: statistics.home.penaltiesCount,
    }}
    right={{
      value: statistics.away.penaltiesCount / totalPenaltyCount,
      color: teamColors.away,
      label: statistics.away.penaltiesCount
    }}
  />
}