import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { dev } from './utils';
import WebsiteEmulator from './WebsiteEmulator';

const rootEl = document.getElementById('root');


ReactDOM.createRoot(rootEl as any).render(
  //
  <React.StrictMode>
    {dev && <WebsiteEmulator>
      <App {...window.cm_results_props} />
    </WebsiteEmulator>}

    {!dev && <App {...window.cm_results_props} />}
  </React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();