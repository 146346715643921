import PendingIcon from "./PendingIcon"
import styles from './PendingComponent.module.scss';
import classNames from "classnames";
import { ReactNode } from "react";

type props = {
  color?: string,
  text: ReactNode,
  className?: string
}

export default function PendingComponent ({color, text, className} : props) {
  return <div className={classNames(styles.wrapper, className)}>
    <PendingIcon color={color} className={styles.icon} />
    <p className={styles.info}>
      {text}
    </p>
  </div>
}