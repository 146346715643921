import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Select.module.scss';

type props<T> = {
  options: {
    id: T,
    name: string
  }[],
  selectedId: T,
  onChange: (selectedId: T) => void
}

export default function Select<T extends string> ({options, onChange, selectedId} : props<T>) {
  return <div className={styles.select_wrapper}>
    <select className={styles.select} value={selectedId} onChange={(e) => {
      onChange(e.target.value as T);
    }}>
      {options.map(option => <option value={option.id}>{option.name}</option>)}
    </select>
    <FontAwesomeIcon className={styles.arrow} icon={faAngleDown} />
  </div>
}