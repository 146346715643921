type props = {
  icon: string,
  fontWeight?: 'thin' | 'regular' | 'bold',
  white?: boolean,
  className?: string
}

export default function CustomIconFa ({icon, fontWeight, white, className} : props) {

  const iconUrl = `https://static.cupmanager.net/images/icons_svg/${white ? 'white/' : ''}${!!fontWeight ? fontWeight : 'regular'}/${icon}.svg`

  return (
    <img className={className} src={iconUrl} alt="" aria-hidden="true" />
  )
}