import { useLayoutEffect, useState } from "react";

export const useWindowWidth = () => {

  const [windowSize, setWindowSize] = useState(0)
  
  const handleSize = () => {
    setWindowSize(window.innerWidth)
  }

  useLayoutEffect(() => {
    handleSize();

    window.addEventListener('resize', () => {
      handleSize()
    });
  }, [])

  return windowSize;
}




export const useWindowHeight = () => {

  const [windowSize, setWindowSize] = useState(0)
  
  const handleSize = () => {
    setWindowSize(window.innerHeight)
  }

  useLayoutEffect(() => {
    handleSize();

    window.addEventListener('resize', () => {
      handleSize()
    });
  }, [])

  return windowSize;
}

export const useViewPortHeight = () => {

  const [windowSize, setWindowSize] = useState(0)
  
  const handleSize = () => {
    window.visualViewport && setWindowSize(window.visualViewport.height)
  }

  useLayoutEffect(() => {
    handleSize();

    window.visualViewport && window.visualViewport.addEventListener('resize', () => {
      handleSize()
    });
  }, [])

  return windowSize;
}