import ReactMapGl, { MapRef, Marker } from 'react-map-gl/maplibre'
import maplib, { Map } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import layers from 'protomaps-themes-base';


//import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useMemo, useRef, useState } from 'react'; 
import Pointer from './Pointer';
import styles from './Map.module.scss';
import { CupColorsSpec, useColors } from '../colors';
import WebMercatorViewport from 'viewport-mercator-project';
import { getPointerColor } from '../utils';
import useSupercluster from 'use-supercluster';
import { MapLocation } from '../api/schema';
import { useLanguage } from 'cupman-utils/lib/LangContext';
//import  maplib  from 'mapbox-gl';
import { useTranslations } from 'cupman-utils';
import { MapMarker } from './MapTypes';
import Supercluster from 'supercluster';



type props<MarkerType> = {
  minInitWidth: 'registrations' | 'tournament' | 'mixed',
  markers: MarkerType[],
  selectedId: number|undefined,
  onChange: (p:MarkerType|undefined) => void,
  zoomKey?: any,
  mapHeight: string,
  mapStyle: any,
  pointsOfInterest?: (MapLocation & {character: string})[]
  clusterLabel: (markers:MarkerType[]) => string,
  setMap: (map:any) => void
}


function _Marker<MarkerType extends MapMarker>({marker,hidden, selected, hovered,onOpen,onSelect}:{marker:MarkerType,zoom:number,hidden:boolean,selected:boolean,hovered:boolean,onOpen:()=>void,onSelect:()=>void}){
  
  return <Marker
    latitude={marker.latitude}
    longitude={marker.longitude}
    key={marker.id}
  >
    <Pointer 
      marker={marker}
      hidden={hidden}
      selected={selected} 
      hovered={hovered}
      onSelect={onSelect}
      onOpen={onOpen}
    />
  </Marker>
}

const getBounds = (markers:{latitude:number, longitude:number}[], minLatBounds: number) => {
  
  const allLat = () => markers.map((marker) => marker.latitude);
  const allLong = () => markers.map((marker) => marker.longitude);

  
  let maxLat = Math.max(...allLat());
  let minLat = Math.min(...allLat());
  let maxLong = Math.max(...allLong());
  let minLong = Math.min(...allLong());

  const currentLatBounds = maxLat-minLat;
  if(currentLatBounds < minLatBounds){
    const howMuchShouldLatBoundsChange = minLatBounds-currentLatBounds; 

    maxLat += (howMuchShouldLatBoundsChange)/2;
    minLat -= (howMuchShouldLatBoundsChange)/2;
  }

  const southEast = [minLong, minLat];
  const northWest = [maxLong, maxLat];

  return [southEast, northWest];
}


const MemoizedMarker = React.memo(_Marker,(prev,curr)=>{
  return prev.marker.id === curr.marker.id && prev.hidden === curr.hidden && prev.selected === curr.selected && prev.hovered === curr.hovered && curr.zoom === prev.zoom;
});


export default function MapLibre<MarkerType extends MapMarker>({setMap:_setMap, minInitWidth, mapStyle, markers /*: _markers*/, onChange, selectedId, zoomKey, mapHeight, clusterLabel, pointsOfInterest} : props<MarkerType>) {
  // const pointsOfInterest = _pointsOfInterest?.sort((markerA, markerB) => Math.sign(markerB.latitude - markerA.latitude))
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //const markers = useMemo(()=>_markers.sort((markerA, markerB) => Math.sign(markerB.latitude - markerA.latitude)),[zoomKey]);
  const colors = useColors();

  const minLatBounds = () => {
    if(minInitWidth === 'registrations' || minInitWidth === 'mixed') {
      return .09;
    }
    if(minInitWidth === 'tournament') {
      return .05;
    }
    return 1;
  }

  const maxZoom = minInitWidth === 'registrations' || minInitWidth === 'mixed' ? 11 : 13;
  const zoomIn = minInitWidth === 'registrations' || minInitWidth === 'mixed' ? 3 : 5;

  const [hovered, setHovered] = useState<MarkerType | undefined>(undefined);
  const [hoveredCluster, setHoveredCluster] = useState<MapMarker | undefined>(undefined);
  const [map,setMap] = useState<Map|undefined>(undefined);
  const [zoom, setZoom] = useState<number>(1.5874997051284159)
  
  
  const defaultBounds = [
    -1.2411810957931664,
    52.61208435908725,
    -1.0083656811012531,
    52.64495957533833,
  ];

  const points = useMemo(() => markers.map(m => ({
    type: "Feature",
    properties: m,
    geometry: {
      type: "Point",
      coordinates: [
        m.longitude,
        m.latitude
      ]
    }
  })),[zoomKey]);

  
  const bounds = getBounds(markers, minLatBounds());

  const getCenterLatitude = () => bounds[0][1] +(bounds[1][1]-bounds[0][1])/2;
  const getCenterLongitude = () => bounds[0][0] +(bounds[1][0]-bounds[0][0])/2;
  
  const mapContainerRef = useRef(null);

  //const [width, height] = useSize(mapContainerRef);

  const [viewPort,setViewport] = useState({ // START POINT
    latitude: getCenterLatitude(),
    longitude: getCenterLongitude()//,
  //  width: map?._canvas.scrollWidth || 400,
 //   height: map?._canvas.scrollHeight || 400
  });

  const { clusters:_clusters, supercluster } = useSupercluster({
    points: points as any,
    bounds: map?.getBounds().toArray().flat() as [number,number,number,number],
    zoom:  map ? map.getZoom(): 1,
    options: { 
      radius: 30, 
      maxZoom: maxZoom-1, 
      log: true, 
      map: (props: any) => {
        return {
        ...props,           
        colors: [props.color || getPointerColor(colors,props as MapMarker)],
        markers: [props]
      }},
      reduce: (agg:{colors:string[], markers:MarkerType[]}, props:{colors:string[], markers:MarkerType[]} ) => {
        agg.colors = [...new Set([...agg.colors, ...props.colors])];
        agg.markers = [...agg.markers, ...props.markers]
      }
     },
    
  }) as {clusters:(Supercluster.PointFeature<any> | Supercluster.PointFeature<Supercluster.ClusterProperties & {
    colors: string[];
    markers: MarkerType[];
}>)[], supercluster:any};

  
  const freeMarkers = _clusters.filter(c => !c.properties.cluster).map(c => c.properties as MarkerType)
  const clusters = _clusters.filter(c => c.properties.cluster);
  
  const language = useLanguage();
  

  useEffect(() => {
    console.log(map);
    if(map){
      fitBounds();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomKey, map])

 

  const fitBounds = ()=>{

    if( map ){
      const canvas = map.getCanvas();

      const markerBounds = getBounds(markers,minLatBounds())
      const xpadding = Math.max((canvas.scrollWidth - 1024)/2,50);

      
      
      console.log(canvas.scrollHeight, markerBounds);

        const containerHeight = canvas.offsetParent ? 
                (canvas.offsetParent as any).offsetParent?.offsetParent.scrollHeight
              : canvas.scrollHeight;
        const ypadding = Math.max((canvas.scrollHeight - containerHeight),90);

        try {
          const NEXT_VIEWPORT = new WebMercatorViewport({
            ...(viewPort as any),
            width: canvas.scrollWidth,
            height: canvas.scrollHeight
          }).fitBounds(markerBounds as any, {
            padding: {left: xpadding, right: xpadding, top:ypadding, bottom: ypadding}
          });

          setZoom(NEXT_VIEWPORT.zoom)
          //console.log("new zoom: " + Math.ceil(NEXT_VIEWPORT.zoom))
          /*setViewport({...NEXT_VIEWPORT,
            transitionDuration: 1000,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: (x:any) => x < 0.5 ? 8 * x  * x *  x * x : 1 - Math.pow(-2 * x + 2, 4) / 2
          } as any);*/
          map.flyTo({center:[NEXT_VIEWPORT.longitude,NEXT_VIEWPORT.latitude], 
            zoom:NEXT_VIEWPORT.zoom,
            easing: (x) => x < 0.5 ? 8 * x  * x *  x * x : 1 - Math.pow(-2 * x + 2, 4) / 2,
            duration: 1000});
        } catch (e){
          console.log(e);
        }
      }
  }

  const zoomThisOnClick = (keepZoom: boolean) => {
    /** Never less than 7, Never more than zoom+zoomIn (or the current zoom), otherwizw zoom+2 */
    
    const newZoom = Math.min(Math.max(zoom+zoomIn,5),maxZoom);
    
    if( keepZoom ){
      return Math.max(newZoom, map?.getZoom() || newZoom);
    } else {
      return newZoom;
    }
  }

  const selected = markers.find((marker) => marker.id === selectedId)


  useEffect(() => {
    
    if(selected && zoom !== undefined && map) {
      const keepZoom = map.getBounds().contains({lat: selected.latitude, lng: selected.longitude});
      console.log(zoom);
      console.log(zoomThisOnClick(keepZoom));
      
      const canvas = map.getCanvas();
      const vp = new WebMercatorViewport({
        ...viewPort,
        width: canvas.scrollWidth,
        height: canvas.scrollHeight,
        latitude: selected.latitude,
        longitude: selected.longitude,
        zoom: zoomThisOnClick(keepZoom),
      })

      const xymarker = vp.project([selected.longitude, selected.latitude]);
      const lnglat = vp.unproject([xymarker[0], xymarker[1]-30]); 
      
      /*setViewport({
        ...vp,
        latitude: lnglat[1],
        longitude: lnglat[0],
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator()
      } as any)*/
      map.flyTo({center:[lnglat[0],lnglat[1]], 
        zoom:vp.zoom,
        duration: 500});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId])



  const onOpen = (marker: {longitude:number, latitude:number}) => {
    console.log("onOpen")
    if( map && !map.isMoving()){
      const center = map.getCenter();

      const xycenter = map.project([center.lng, center.lat]);
      const xymarker = map.project([marker.longitude, marker.latitude]);

      const xynorthwest = map.project(map.getBounds().getNorthWest());
      const xysoutheast = map.project(map.getBounds().getSouthEast());

      console.log({map, xycenter, xymarker, xynorthwest, xysoutheast})

      const canvas = map.getCanvas();

      const containerHeight = (canvas.offsetParent as any).offsetParent.offsetParent.scrollHeight;
      const zoomDiff = (canvas.scrollHeight - containerHeight)/2;
      console.log({sh: canvas.scrollHeight, containerHeight, zoomDiff})
      xynorthwest.y += zoomDiff;
      xysoutheast.y -= zoomDiff;

      const markerwest = xymarker.x - 65;
      const markernorth = xymarker.y - 180;
      
      const markereast = xymarker.x + 65;
      const markersouth = xymarker.y + 100;
      
      const translate_north = Math.max(0, xynorthwest.y - markernorth);
      const translate_west = Math.max(0, xynorthwest.x - markerwest);

      const translate_east = Math.min(0, xysoutheast.x - markereast);
      const translate_south = Math.min(0, xysoutheast.y - markersouth);

      xycenter.y -= translate_north;
      xycenter.x -= translate_west;
      xycenter.x -= translate_east;
      xycenter.y -= translate_south;
      
      const lnglat = map.unproject(xycenter); 

      /*setViewport({
        zoom: map.getZoom(),
        latitude: lnglat.lat,
        longitude: lnglat.lng,
        transitionDuration: 150,
        transitionInterpolator: new FlyToInterpolator()

      } as any);*/
      map.flyTo({center:[lnglat.lng,lnglat.lat], 
        zoom:map.getZoom(),
        duration: 150});
    }
  }

  return (
    <>
      <div className={styles.map_container} style={{height: mapHeight}} ref={mapContainerRef} aria-hidden="true" tabIndex={-1}>
        
        <ReactMapGl
          mapLib={maplib}
          {...viewPort}
          ref={ref => {
            if(ref && map !== ref.getMap()){
              setMap(ref.getMap());
              _setMap(ref.getMap());
            };
          }}
          onLoad={()=>{
            /*const labels = [ 'country-label', 'state-label', 
                 'settlement-subdivision-label', 
                 'airport-label', 'poi-label', 'water-point-label', 
                 'water-line-label', 'natural-point-label', 
                 'natural-line-label', 'waterway-label', 'road-label' ];
                  
            labels.forEach(label => {
                map.setLayoutProperty(label, 'text-field', ['get','name_' + language]);
              });*/
          }}
          onMove={(event) =>  setViewport(event.viewState)}
          onMoveEnd={() => console.log("end")}
          attributionControl={false}
          mapStyle={{
            version: 8, 
            glyphs:'https://cdn.protomaps.com/fonts/pbf/{fontstack}/{range}.pbf',
            sources: {
              protomaps: {
                type: "vector",
                tiles: [
                  //"https://api.protomaps.com/tiles/v3/{z}/{x}/{y}.mvt?key=166f8aeacbcb9884",
                  "https://static.cupmanager.net/uploads/protomap_tiles/{z}/{x}/{y}.txt"
                ],
                maxzoom: 15
              }
            }, 
            layers: layers("protomaps","light")
          }} 
          style={{position:'relative', zIndex: 1, height: "50vh", width: "100%"}}

          dragRotate={false}
        >

          {!pointsOfInterest && clusters.sort((a,b)=>Math.sign(b.geometry.coordinates[1]-a.geometry.coordinates[1])).map((cluster) => 
            <MemoizedMarker 
                key={cluster.properties.cluster_id}
                marker={{
                  color:cluster.properties.colors,
                  id: cluster.properties.cluster_id,
                  latitude: cluster.geometry.coordinates[1],
                  longitude: cluster.geometry.coordinates[0],
                  name: clusterLabel(cluster.properties.markers),
                  cluster: cluster.properties.point_count,
                  type: cluster.properties.type
                }}
                zoom={zoom}
                hidden={cluster.properties.cluster_id === hoveredCluster?.id}
                selected={false}
                hovered={false}
                onSelect={() => {}}
                onOpen={() => {
                  //({cluster});
                  setHovered(undefined);
                  setHoveredCluster({
                    color:cluster.properties.colors,
                    id: cluster.properties.cluster_id,
                    latitude: cluster.geometry.coordinates[1],
                    longitude: cluster.geometry.coordinates[0],
                    name: clusterLabel(cluster.properties.markers),
                    cluster: cluster.properties.point_count,
                    type: cluster.properties.type,

                  })
                  onOpen({
                    latitude: cluster.geometry.coordinates[1],
                    longitude: cluster.geometry.coordinates[0]
                  })
                }}
              />
          )}

            {!pointsOfInterest && freeMarkers.sort((a,b)=>Math.sign(b.latitude-a.latitude)).map((marker) => 
            <MemoizedMarker 
                key={marker.id}
                marker={marker}
                zoom={zoom}
                hidden={marker.id === hovered?.id || marker.id === selected?.id}
                selected={false}
                hovered={false}
                onSelect={() => {
                  onChange(marker)
                  setHovered(undefined)
                }}
                onOpen={() => {
                  setHoveredCluster(undefined);
                  setHovered(marker)
                  onOpen(marker)
                }}
              />)}

            {!pointsOfInterest && hoveredCluster && clusters.find(c => c.id === hoveredCluster.id) && <Marker
              latitude={hoveredCluster.latitude}
              longitude={hoveredCluster.longitude}
              key={"hoveredCluster_"+hoveredCluster.id}
            >
              <Pointer 
                hovered
                marker={hoveredCluster} 
                selected={false}
                onSelect={() => {
                  if( map ){
                    const leaves =  supercluster.getLeaves(hoveredCluster.id, 100) as any[];
                    
                    const bounds = getBounds(leaves.map((l) => ({
                      longitude: l.geometry.coordinates[0], 
                      latitude: l.geometry.coordinates[1]
                    })), minLatBounds()/4)


                    const canvas = map.getCanvas();

                    const containerHeight = (canvas.offsetParent as any).offsetParent.offsetParent.scrollHeight;
                    const ypadding = Math.max((canvas.scrollHeight - containerHeight),50);
                    const xpadding = Math.max((canvas.scrollWidth - 1024)/2,50);

                    const NEXT_VIEWPORT = new WebMercatorViewport({
                      ...(viewPort as any),
                      width: canvas.scrollWidth,
                      height: canvas.scrollHeight
                    }).fitBounds(bounds as any, {
                      padding: {top:selected ? ypadding + 75 : ypadding + 50, left:xpadding, right:xpadding, bottom: ypadding},
                      maxZoom: Math.min(maxZoom+2, 20)
                    });

                   
                    /*setViewport({...NEXT_VIEWPORT,
                      zoom: Math.max(supercluster.getClusterExpansionZoom(hoveredCluster.id),NEXT_VIEWPORT.zoom),
                      transitionDuration: 1000,
                      transitionInterpolator: new FlyToInterpolator(),
                      transitionEasing: (x:any) => x < 0.5 ? 8 * x  * x *  x * x : 1 - Math.pow(-2 * x + 2, 4) / 2
                    } as any)*/
                    map.flyTo({center:[NEXT_VIEWPORT.longitude,NEXT_VIEWPORT.latitude], 
                      zoom:Math.max(supercluster.getClusterExpansionZoom(hoveredCluster.id),NEXT_VIEWPORT.zoom),
                      easing: (x) => x < 0.5 ? 8 * x  * x *  x * x : 1 - Math.pow(-2 * x + 2, 4) / 2,
                      duration: 1000});

                    //setHoveredCluster(undefined);
                  }
                }}
              />
            </Marker>}

            {pointsOfInterest && pointsOfInterest.sort((a,b)=>Math.sign(b.latitude-a.latitude)).map((point) => <Marker
              latitude={point.latitude}
              longitude={point.longitude}
              key={point.id}
            >
              <Pointer
                marker={point}
                character={point.character}
              />
            </Marker>)}

            {selected && selected.id !== hovered?.id && <Marker
              latitude={selected.latitude}
              longitude={selected.longitude}
              key={"hovered_"+selected.id}
            >
              <Pointer 
                onOpen={() => {
                  if(window.matchMedia('(hover:none)').matches){
                    onChange(undefined);
                  } else {
                    setHovered(selected)
                  }
                  
                }}
                marker={selected} 
                selected
              />
            </Marker>}

            {hovered && markers.find(m=>m.id === hovered.id) && <Marker
              latitude={hovered.latitude}
              longitude={hovered.longitude}
              key={"hovered_"+hovered.id}
            >
              <Pointer 
                hovered
                marker={hovered} 
                selected={selected?.id === hovered.id}
                onSelect={() => {
                  if( selected?.id === hovered.id){
                    onChange(undefined);
                  } else {
                    onChange(hovered)
                    setHovered(undefined)
                  }
                  
                }}
              />
            </Marker>}


        </ReactMapGl>
      </div>
    </>
  )
}



