import { faHand, faHandFist, faHandPeace } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { MatchShot } from "../../api/schema";
import { formatShirtNr } from "../../utils";
import { getSideClass } from "./LiveFeed";
import styles from './Shot.module.scss';
import TimelineMinutes from "./TimelineMinutes";
import FaSvgIcon from "../FaSvgIcon/FaSvgIcon";
import { useTranslations } from "cupman-utils";

export default function Shot ({event} : {event: MatchShot}) {
  const T = useTranslations('cmresults');
  return <div className={
    classNames(
      styles.penalty_container, 
      event.side === 'away' && styles.away,
      getSideClass(event.side)
    )
  }>
    <div>
      <div className={classNames(styles.penalty_inner_container, event.side === 'home' ? styles.left : styles.right)}>
        <div className={styles.penalty_info}>
          <div className={styles.penalty_event_container}>
            <span className={styles.title}>
              {T('Shot on goal')}
            </span>
            <span className={styles.icon} style={{"--clr-primary-background": "black"} as React.CSSProperties}>
            {event.side === 'away' ?
              <FaSvgIcon>
                <svg id="Lager_1" data-name="Lager 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.531 51.282">
                  <path  fill='rgb(var(--clr-pale-red))' d="m64.145,44.789c.138.233.241.485.305.747.064.26.09.528.077.796-.072,1.537-1.377,2.724-2.914,2.652-6.661-.308-13.302-.955-19.897-1.939-.011.34-.04.649-.05.93-.059,1.039-.576,1.999-1.412,2.62-.372.277-.795.477-1.245.587-.549.136-1.124.134-1.672-.007-5.334-1.297-18.597-4.845-26.544-9.652C.22,35.123-3.162,21.365,3.237,10.792,9.637.22,23.395-3.162,33.968,3.237c7.945,4.808,17.233,14.912,20.867,19.03.375.428.641.939.776,1.491.374,1.491-.279,3.049-1.603,3.829-.244.14-.506.298-.8.474,4.301,5.694,8.993,13.448,10.938,16.729Zm-3.929.49c-3.359-5.754-7.07-11.295-11.112-16.592l-1.32-1.636,1.829-1.052c.806-.497,1.642-.943,2.504-1.335-3.515-3.996-12.511-13.789-20.024-18.332C23.236.984,11.721,3.815,6.352,12.66c-5.378,8.86-2.556,20.403,6.304,25.781,7.51,4.546,20.362,7.973,25.407,9.318.041-.775.091-1.797.134-2.933l.086-2.109,2.062.41c6.573,1.124,13.209,1.843,19.87,2.152Zm-23.642-26.211c1.902,7.731-2.823,15.54-10.553,17.444-7.732,1.904-15.543-2.82-17.447-10.552-1.904-7.732,2.82-15.543,10.552-17.447,7.732-1.9,15.542,2.824,17.448,10.556Zm-19.992-2.547c-.709.709-.708,1.857,0,2.566l3.426,3.426-3.426,3.426c-.709.709-.708,1.857,0,2.566s1.857.709,2.566,0l3.426-3.426,3.426,3.426c.709.709,1.857.709,2.566,0s.708-1.857,0-2.566l-3.426-3.426,3.426-3.426c.708-.708.708-1.857,0-2.566s-1.857-.709-2.566,0l-3.426,3.426-3.426-3.426c-.708-.708-1.857-.709-2.566,0Z"/>
                </svg>
              </FaSvgIcon>
              : 
              <FaSvgIcon>
                <svg id="Lager_1" data-name="Lager 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.531 51.282" aria-hidden="true" focusable="false">
                  <path fill='rgb(var(--clr-pale-red))' d="m11.323,28.061c-.293-.176-.556-.334-.8-.474-1.324-.78-1.977-2.338-1.603-3.829.135-.552.401-1.064.776-1.491,3.634-4.118,12.921-14.221,20.867-19.03,10.572-6.4,24.331-3.017,30.73,7.555,6.4,10.572,3.017,24.331-7.555,30.73-7.948,4.807-21.211,8.355-26.544,9.652-.548.141-1.123.143-1.672.007-.45-.11-.874-.31-1.245-.587-.836-.621-1.353-1.58-1.412-2.62-.01-.281-.039-.59-.05-.93-6.595.984-13.236,1.631-19.897,1.939-1.537.072-2.842-1.115-2.914-2.652-.013-.268.013-.536.077-.796.064-.263.167-.515.305-.747,1.945-3.281,6.637-11.035,10.938-16.729Zm12.861,15.066l2.062-.41.086,2.109c.043,1.136.093,2.158.134,2.933,5.045-1.345,17.897-4.772,25.407-9.318,8.86-5.378,11.683-16.921,6.304-25.781C52.81,3.815,41.295.984,32.437,6.332c-7.513,4.543-16.509,14.336-20.024,18.332.862.392,1.698.838,2.504,1.335l1.829,1.052-1.32,1.636c-4.042,5.297-7.753,10.838-11.112,16.592,6.661-.31,13.297-1.028,19.87-2.152ZM45.404,8.512c7.732,1.904,12.456,9.715,10.552,17.447-1.904,7.732-9.715,12.456-17.447,10.552-7.73-1.904-12.455-9.713-10.553-17.444,1.906-7.731,9.715-12.456,17.448-10.556Zm-.021,8.008l-3.426,3.426-3.426-3.426c-.708-.708-1.857-.708-2.566,0s-.709,1.857,0,2.566l3.426,3.426-3.426,3.426c-.709.709-.709,1.857,0,2.566s1.857.709,2.566,0l3.426-3.426,3.426,3.426c.709.709,1.857.709,2.566,0s.709-1.857,0-2.566l-3.426-3.426,3.426-3.426c.708-.708.709-1.857,0-2.566s-1.857-.708-2.566,0Z"/>
                </svg>
              </FaSvgIcon>}
            </span>
          </div>

          {event.playerNr !== undefined && <div className={styles.player_container}>
            <span className={styles.player_name}>{event.playerName}</span>

            <span className={styles.shirt_nr}>#{formatShirtNr(event.playerNr)}</span>
          </div>}
        </div>
      </div>
    </div>
    
    <div></div>
  </div>
}

