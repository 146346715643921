import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";
import { useCardColorWord } from "../../utils";

type Props = {
  statistics: {home: {greenCards: number, yellowCards: number, redCards: number}, away: {greenCards: number, yellowCards: number, redCards: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
  color: 'green' | 'yellow' | 'red'
}

export default function CardsCount ({statistics, teamColors, color} : Props) {

  const key = color + 'Cards' as  'greenCards' | 'yellowCards' | 'redCards';
  const totalCards = statistics.home[key] + statistics.away[key];

  return <LabeledOpposingHorizontalBars 
    label={useCardColorWord(color)}
    left={{
      value: statistics.home[key] / totalCards,
      color: teamColors.home,
      label: statistics.home[key]
    }}
    right={{
      value: statistics.away[key] / totalCards,
      color: teamColors.away,
      label: statistics.away[key]
    }}
  />
}