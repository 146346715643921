import classNames from "classnames";
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { faHeart, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flag from '../Flag/Flag';
import styles from './FavoritesStart.module.scss';
import { Link } from "react-router-dom";
import { Suspense } from "react";
import { useMe, useTeam } from "../../api/schema";
import { useTranslations } from "cupman-utils";
import { useCurrentTournament } from "../../App";
import Button from "../Button/Button";
import { targetMobileSearch, touch } from "../Search/SearchBar";
import { header, LoadingContent } from "../../Loading/LoadingContent";


export default function FavoritesStart() {
  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const me = useMe({optionalCupId: t.cup.id},{
    teams: [{
      team: {}
    }]
  });

  const favoriteTeams = me.teams;



  return (
    <div className="max-75">

      
      {favoriteTeams && favoriteTeams.length > 0
        ? <>
          <h2 className={classNames(styles.title,"custom-font")} style={{textAlign: 'center'}}>
            <FontAwesomeIcon icon={faHeart} /> {T('My teams')}
          </h2>
          <div className={styles.favorites_container}>
            <div className={styles.favorites_grid}>

              {favoriteTeams.map((team, i) => 
                <Suspense key={team.team?.id} fallback={
                  <LoadingContent className="max-50" margin={20} components={
                    [header(37, 8)]
                  }/>
                }>
                  <FavoriteRow id={team.team?.id || i} cupPass={team.cupPassImageUrl !== undefined || team.teamAccountUrl !== undefined} />
                </Suspense>
                )
              }

            </div>
          </div>
        </>
        : <>
          <div className={styles.no_favorites}>
            <div className={styles.favorite_info}>
              <h3>
                {T('Search & save your favorite teams')}
              </h3>
              <Button size="xl" onClick={(e) => {
                e.preventDefault()
                const searchEl = document.getElementById("search-input");

                if (touch) {
                  //mobile
                
                  targetMobileSearch();

                  
                } else {
                  // Desktop
                  window.scrollTo({ top: 0 });

                  let scrollTimeout: ReturnType<typeof setTimeout>;

                  window.addEventListener('scroll', () => {
                    clearTimeout(scrollTimeout);
                    scrollTimeout = setTimeout(() => {
                      searchEl && searchEl.focus();
                    }, 100)
                  });

                  searchEl && searchEl.focus()
                }

              }}>{T('Search for your teams now')} 
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </div>
        </>
      }
    </div>
  )
}

type favoriteRowProps = {
  id:number,
  cupPass: boolean
}

const FavoriteRow = ({id, cupPass} : favoriteRowProps) => {

  const T = useTranslations('cmresults')

  const team = useTeam({id}, {
    category: {},
    club: {
      nation: {}
    },
    matches: [{
      video: {}
    }]
  });

  

  const isLiveStreaming = team.matches.find(match => match.video?.externalLink && match.live && !match.finished);

  const isLiveReporting = team.matches.find(match => !match.video?.externalLink && match.live && !match.finished);

  return <Link to={`/team/${id}`} className={classNames(styles.favorite, cupPass && styles.cup_pass)}>
    <Flag code={team.club.nation.code} size="lg" alt={T.select(team.club.nation.name)} borderColor="white" />

    {isLiveStreaming && <img src='https://static.cupmanager.net/images/cm-results/solid-sport-red-icon-white-bg.svg' className={classNames(styles.icon, styles.live_icon)} alt={T('Streaming right now!').toUpperCase()} />}

    {isLiveReporting && 
      <img src='https://static.cupmanager.net/images/cm-results/live-report_light.svg' className={classNames(styles.icon, styles.report_icon)} alt={T('Live-report active!').toUpperCase()} />
    }
    <span className={styles.name}>{team.name.fullName} {isLiveStreaming && <span className={styles.live_now}> - {T('Streaming right now!').toUpperCase()}</span>}</span>
    <span className={styles.class}>{team.category.shortName}</span>
    <FontAwesomeIcon className={styles.arrow} icon={faAngleRight} />
  </Link>
}