import { ReactNode } from 'react';
import { buttonStyle } from '../../utils';
var cn = require('classnames');

type buttonTypes = {
  size?: 'sm' | 'lg' | 'xl',
  inverted?: boolean,
  children: ReactNode,
  className?: string,
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  style?: Record<string,string>,
  disabled?: boolean
}

export default function Button({size, inverted, onClick, className, children, style, disabled} : buttonTypes) {
  

  return (
    <button 
      disabled={disabled}
      className={cn('btn', className && className, buttonStyle(size 
        ? size 
        : 'lg'
      , inverted))}
      style={style && style}
      onClick={onClick}
    >
      {children}
    </button>
  )
}