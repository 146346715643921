import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './HeartButton.module.scss';
import { faHeart as faHeartRegular } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons';
import { faHeart, faHeartBroken } from "@fortawesome/pro-solid-svg-icons";
import { useTranslations } from "cupman-utils";
import { useState } from "react";
import classNames from "classnames";
var cn = require('classnames');


type props = {
  color:string,
  blowColor?:string,
  afterColor?:string,
  borderColor?: string,
  onChange?: (p:boolean) => void,
  likeStatus: boolean,
  activeColor?: string,
  className?: string,
  size?: 'sm' | 'lg',
  likeUnlikeWhat?: string
}

export default function HeartButton ({color, onChange, likeStatus, blowColor, afterColor, activeColor, borderColor, className, size:_size, likeUnlikeWhat} : props) {

  const [likedAlready, setLikedAlready] = useState(likeStatus);

  const T = useTranslations('cmresults')

  const size = () => {
    if(_size) {
      if(_size === 'sm') return styles.sm
    }

    return false
  }

  const likeText = likeStatus
    ? likeUnlikeWhat
      ? T('Unlike X', likeUnlikeWhat)
      : T('Unlike')
    : likeUnlikeWhat
      ? T('Like X', likeUnlikeWhat)
      : T('Like')



  return (
    <>

      <div className={cn(styles.heart_button_container, className)} style={{
          '--clr-heart': color,
          '--clr-heart-border': 'rgba(0,0,0, .2)',
          '--clr-heart-blow': blowColor ? blowColor : color,
          '--clr-heart-active': activeColor ? activeColor : '#ff003c'
        } as any}>
        <label aria-label={likeText}>
        <input 
          className={classNames(styles.toggle_heart, likedAlready && styles.liked)}
          type="checkbox" 
          checked={likeStatus} 
          tabIndex={-1}
          onChange={(e)=> {

            if (likeStatus) {
              setLikedAlready(false)
            }
            onChange && onChange(e.target.checked)
          }}
        />

        <span
          className={cn(styles.toggle_heart_label, styles.heart_button, size(), likedAlready && styles.liked)}
        >
          {likeStatus && <FontAwesomeIcon icon={faHeartSolid} style={{color: afterColor}} />}
          {!likeStatus && <FontAwesomeIcon icon={faHeartRegular} className={styles.pulse} />}
        </span>
        </label>
      </div>
      
    </>
  )
}