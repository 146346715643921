import { useTranslations } from "cupman-utils";
import { Match, Team$matches, useMe } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import Games from "../../components/Game/Games";
import PageHeader from "../../components/PageHeader/PageHeader";

export default function MyMatchesPage () {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const me = useMe({optionalCupId: t.cup.id}, {
    teams: [{
      team: {
        matches: [
         Games.GameFragment
        ]
      }
    }]
  })

  const myMatches = me.teams.reduce((myMatches, team) => {
    if(team.team) {
      return [
        ...myMatches,
        ...team.team.matches
      ]
    }

    return [...myMatches]
  }, [] as Match<typeof Games.GameFragment>[])



  return <>
  <PageHeader 
      title={T('My games')}
      crumbs={[{
        title: T('Results'),
        url: ''
        }
      ]}
    />

    <Games games={myMatches} order='asc' published={t.schedulePublished} />
  </>
}