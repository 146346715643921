import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { FavoritesContext } from '../../context/storedTeams';
import styles from './Flag.module.scss';
var cn = require('classnames');

type flagTypes = {
  code?: string,
  size?: 'sm' | 'md' | 'lg',
  borderColor?: string,
  className?: string,
  teamId?: number,
  alt: string
}

export default function Flag({code, size, borderColor, className, teamId, alt} : flagTypes) {

  const flagUrl = code && 'https://static.cupmanager.net/images/flags_svg/1x1/'+ code.toLowerCase() +'.svg';

  const favoritesContext = useContext(FavoritesContext) || [];

  const flagOrFavorite = () => {

    if (teamId && favoritesContext.favorites.includes(teamId)) {
      return <FontAwesomeIcon icon={faHeart} className={styles.heart_symbol} />
    }

    if(code) {
      return <img src={flagUrl} className={cn(
        styles.flag,
        size && styles[size],
        borderColor && styles.border, className
      )}
      style={{borderColor: borderColor}}
      alt={alt ? alt : ''} />
    }

    return <span className={styles.flag}></span>
  }

  return flagOrFavorite()
}