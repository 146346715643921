

import { faMarsAndVenus, faGenderless, faUsers, faUsersClass, faVenus, faMars, IconDefinition, faWhistle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { Category, Stage, useCategory } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import LinkButton from "../../components/Button/LinkButton";
import Conference from "../../components/Conference/Conference";
import { PlayoffBoxComponent } from "../../components/Conference/Playoff";
import PrevNextStages from "../../components/PrevNextStages/PrevNextStages";
import PageHeader from "../../components/PageHeader/PageHeader";
import StatisticsSummaryHeader from "../../components/StatisticsSummaryHeader/StatisticsSummaryHeader";
import TopScrollShadow from "../../components/TopScrollShadow/TopScrollShadow";
import styles from './CategoriesPage.module.scss';
import PlayedGames from "../../components/PlayedGames/PlayedGames";
import Games from "../../components/Game/Games";
import ListColumns from "../../components/ListColumns/ListColumns";
import { groupBy } from "../../utils";
import Flag from "../../components/Flag/Flag";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import PendingPage from "../../components/Pending/PendingPage";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import classNames from "classnames";
import DivisionPageDivision from "../DivisionPage/DivisionPageDivision";
import { useState } from "react";
import ConferenceRounds from "../../components/Conference/ConferenceRounds";

type props = {
  categoryId?: string
}

export default function CategoriesPage ({categoryId: _categoryId} : props) {
  const t = useCurrentTournament();
  const admin = useAdmin();

  const T = useTranslations('cmresults')
  const match = useParams<{catId:string, stageId:string}>();

  
  const categories = useLink(t.lotCategories, [{
    statistics: {
      matches: {}
    }
  }]).filter(c => c.lotPublished || admin.overridePublished);


  const categoryId = categories.length == 1 ? categories[0].id : parseInt(match.catId||'0');
  const stageId = parseInt(match.stageId||'0');


  const lotPublished = t.lotPublished || admin.overridePublished;

  const categoriesByGender = {
    men: categories.filter(cat => cat.gender === 'men'),
    woman: categories.filter(cat => cat.gender === 'women'),
    mixed: categories.filter(cat => cat.gender === 'mixed'),
    none: categories.filter(cat => cat.gender === 'none')
  }

  const matches = categories.map(category => {
    return Object.values(category.statistics.matches)
      .reduce((sum, category) => sum + category.count, 0)
  }).reduce((sum, matches) => sum + matches, 0);


  return <>
    {categoryId !== 0 && <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75" style={{marginTop: '4rem'}}>
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load the category')}/>
    </div>}>
      <CategoryStage categoryId={categoryId} tournamentId={t.id} stageId={stageId}/>
    </ErrorBoundary>}

    {!categoryId && <>
      <PageHeader 
        title={T('Categories')} 
        crumbs={[{
          title: T('Results'),
          url: ''
        }]}
      />

      
      {!lotPublished && <PendingPage  text={T('The game plan for X - Y is not published yet', t.cup.name, t.edition.name)} color="rgb(var(--clr-primary))"/>}
      {lotPublished && <div className={styles.container}>
        <StatisticsSummaryHeader data={[
          {
            name: T('Categories'),
            icon: faUsersClass,
            amount: categories.length
          },
          {
            name: T('Teams'),
            icon: faUsers,
            amount: categories.map(category => category.statistics.teamCount).reduce((sum, teams) => sum + teams, 0)
          },
          {
            name: T('Games'),
            icon: faWhistle,
            amount: matches
          },
        ]} />
        <TopScrollShadow top={120}/>
        
        <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75" style={{marginTop: '4rem'}}>
          <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load the categories')}/>
        </div>}>
          <CategoriesOfGender categories={categoriesByGender.woman} headerText={T('Women')} icon={faVenus} />
        </ErrorBoundary>

        <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75" style={{marginTop: '4rem'}}>
          <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load the categories')}/>
        </div>}>
          <CategoriesOfGender categories={categoriesByGender.men} headerText={T('Men')} icon={faMars} />
        </ErrorBoundary>

        <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75" style={{marginTop: '4rem'}}>
          <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load the categories.')}/>
        </div>}>
          <CategoriesOfGender categories={categoriesByGender.mixed} headerText={T('Mixed')} icon={faMarsAndVenus} />
        </ErrorBoundary>

        <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75" style={{marginTop: '4rem'}}>
          <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load the categories.')}/>
        </div>}>
          <CategoriesOfGender categories={categoriesByGender.none} headerText={T('Categories')} icon={faGenderless} />
        </ErrorBoundary>

      </div>}
    </>}
  </>
}



type cProps = {
  categoryId: number,
  tournamentId: number,
  stageId?:number
}

const CategoryStage = ({categoryId, tournamentId, stageId} : cProps) => {
  const T = useTranslations('cmresults')
  const t = useCurrentTournament();
  const admin = useAdmin();

 
  const category = useCategory({tournamentId, categoryId}, {
    statistics: {},
    stages: [{
      divisions: [{
        matches: [],
        ___on_Playoff: {},
        ___on_Conference: {
          table: {
            rows: [{
              team: {
                club: {
                  nation: {}
                }
              }
            }],
          }
        }
      }],
     
      nextStages: [{}],
      previousStages: [{}],
      category: {},
      matches: [Games.GameFragment],
      statistics: {}
    }],
  })


  const _stageId = (typeof stageId !== "number" || isNaN(stageId) || stageId === 0)
    ? category.stages[0].id
    : stageId


  const stage = category.stages.filter(stage => stage.id === _stageId)[0]
  const hasIm = stage.divisions.some((div) => div.__typename === 'Conference' && (div as any).table.rows.some((row:any) => row.isIm));

  
  const games = t.days.filter(day => category.statistics?.matches[day.id])
                    .map(day => {
    return ({
          date: day.date,
          month: day.date,
          matches: category.statistics?.matches[day.id].count,
          finished: category.statistics?.matches[day.id].finished,
  })})

  const lotPublished = stage.lotPublished || admin.overridePublished;
  const schedulePublished = stage.schedulePublished || admin.overridePublished;

  const [selectedRoundHref, setSelectedRoundHref] = useState<string|undefined>(undefined);

  const matches = stage.matches.filter(m => selectedRoundHref === undefined || m.round.href === selectedRoundHref);

  return <>
      <PageHeader 
        shortTitle={category.shortName}
        title={category.name} 
        subtitle={stage.name}
        crumbs={[{
          title: T('Results'),
          url: ''
        },{
          title: T('Categories'),
          url: 'categories'
        }]}
      >
        {(lotPublished && (stage.nextStages.length > 0 || stage.previousStages.length > 0)) ? <>
          <PrevNextStages catId={categoryId} 
            nextStages={stage.nextStages} 
            previousStages={stage.previousStages}/></> : undefined
        }

      </PageHeader>
    {schedulePublished && stage.rank === 1 && !t.league && <PlayedGames games={games} />}

    {lotPublished ? <><div className="max-75">

      {stage.isSingleConference && category.hideConferenceResults && <>
        <h2 className={classNames(styles.teams_header,"custom-font")}>{T('Teams in X', stage.category.name)}</h2>
        <StageTeamList stage={stage}/>
      </>}
      
      
      <h2 style={{marginBottom: '0'}} className="visually_hidden">
        <FontAwesomeIcon icon={faUsers}/> {stage.name}
      </h2>

      {stage.isSingleConference && stage.divisions.length && stage.divisions[0].__typename === 'Conference' ? 
        <DivisionPageDivision division={stage.divisions[0]} />
      : <><div className={styles.divisions}>
        {stage.divisions.map(division => {
          if(division.__typename === 'Conference') {
           return <Conference 
                linkTeams={false}
                key={division.id}
                id={division.id}
                name={T.select(division.name)}
                table={division.table as any}
                doNotShowImBtn={true}
                className={stage.divisions.length === 1 ? 'full_width' : ''}
                hideResult={stage.category.hideConferenceResults}
                matchesCanTie={stage.category.matchesCanTie}
                matchAmount={stage.category.hideConferenceResults === false 
                  ? undefined
                  : division.matches.length
                }
              />
          } else if (division.__typename === 'Playoff'){
            return <PlayoffBoxComponent playoff={division} />
          }

          return <></>
        })}
      </div>
      {stage.divisions.length > 1 && (stage.nextStages.length > 0 || stage.previousStages.length > 0) && <div className="max-75"><PrevNextStages 
            nextStages={stage.nextStages} 
            previousStages={stage.previousStages}
            catId={category.id}
            inBody
          /></div>}
      </>
    }
    </div>

    {t.league && stage.isSingleConference && stage.divisions.length && stage.divisions[0].__typename === 'Conference' && <ConferenceRounds conference={stage.divisions[0]} selectedRoundHref={selectedRoundHref} setSelectedRoundHref={setSelectedRoundHref}/>}
      
    {stage.matches.length > 0 && <><Games 
          games={matches}
          published={schedulePublished}
          title={stage.divisions.length === 0 && !stage.isSingleConference ? stage.name : T('Games')} 
          order="asc"
    />
     {(((stage.isSingleConference && stage.divisions.length) || stage.divisions.length === 0)  && (stage.nextStages.length > 0 || stage.previousStages.length > 0)) && <div className="max-75">
          <PrevNextStages 
            nextStages={stage.nextStages} 
            previousStages={stage.previousStages}
            catId={category.id}
            inBody
          />
        </div> || undefined}
    </>}
    </> : <PendingPage  text={T('The game plan for X in Y is not published yet', stage.name, stage.category.name )} color="rgb(var(--clr-primary))"/>}
  </>
}


function StageTeamList({stage : _stage} : {stage:Stage}){
  const T = useTranslations('cmresults');
  const stage = useLink(_stage,{realTeams:[{team:{ club:{ nation: {}}}}]});

  const teams = Object.entries(
    groupBy(
      stage.realTeams.sort((a, b) => (a.team?.name.clubName || '').localeCompare(b.team?.name.clubName || '')), 
      filteredClub => (filteredClub.team?.name.clubName || '-')[0]))
        .map(a => ({
            header: a[0], 
            items:a[1]
          }));


  return <ListColumns 
    list={teams} 
    render={team => <span key={team.team?.id} className={styles.team_link}><Link to={`/team/${team.team?.id}`}>
      <Flag code={team.team?.club.nation.code} alt={team.team ? T.select(team.team.club.nation.name) : ''} /> <span>{team.team?.name.clubName}</span>
    </Link></span>}
  />
}






type categoriesOfGenderProps = {
  categories: Category<{
    statistics: {
        matches: {};
    };
  }>[],
  headerText: string,
  icon?: IconDefinition
}

function CategoriesOfGender ({categories, headerText, icon} : categoriesOfGenderProps) {

  const T = useTranslations('cmresults');

  return <div className="max-75">
    {categories.length > 0 && <>
      <h3 className={styles.gender_header}>
        {icon && <FontAwesomeIcon icon={icon} />} {headerText}
      </h3>
      <div className={styles.category_buttons}>
        {categories.map((category => <LinkButton 
          url={`/categories/${category.id}`} 
          fullWidth
          className={styles.class_btn}
          key={category.id}
          scaleVal={1.025}
        >
          <span className={styles.category_name}>
            <span className={styles.category_name_inner}>
              {category.name}&lrm;
            </span>
          </span>
          <span>
            {category.statistics.teamCount} {T('teams')}
          </span>
        </LinkButton>))}
      </div>
    </>}
  </div>
}