import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { Link } from 'react-router-dom';
import { TeamHighscore$RankedTeam } from '../../api/schema';
import Flag from '../Flag/Flag';
import TopGoalsIcon from '../WinnersIcon/TopGoalsIcon';
import styles from './WinnersAwardContainer.module.scss';

TopGoalsTeamAwardContainer.Fragment = {
  team: {
    club: {
      nation: {}
    }
  }
}

type props = {
  rankedTeam: TeamHighscore$RankedTeam<typeof TopGoalsTeamAwardContainer.Fragment>
  rank: number,
  perGame?: boolean
}

// export const placementSuffix = (rank: number) => {
//   return rank < 3
//     ? 'a'
//     : 'e'
// }

export default function TopGoalsTeamAwardContainer ({rankedTeam, rank, perGame} : props) {


  const T = useTranslations('cmresults');

  const rankClr = rank === 1
    ? 'rgb(var(--clr-gold))'
    : rank === 2
      ? 'rgb(var(--clr-silver))'
      : rank === 3
        ? 'rgb(var(--clr-bronze))'
        : 'rgb(var(--clr-gray-500))'

  const goalsScored = perGame
    ? Math.round(rankedTeam.madeGoals / Math.max(rankedTeam.played, 1) * 10) / 10
    : rankedTeam.madeGoals

  return <Link to={`/team/${rankedTeam.team.id}`} className={classNames(
    styles.wrapper
  )}>
    <TopGoalsIcon placement={rank} />

    <div className={styles.info}>
      <h2 className={
        classNames(
          styles.award_header,
          rank === 1 && styles.gold,
          rank === 2 && styles.silver,
          rank === 3 && styles.bronze,
          rank <= 3 && styles.bold,
          rank > 3 && styles.gray,
          'custom-font'
        )
      }>{perGame ? T('X:(st|nd|rd|th):X place - Y goals per game', rank, goalsScored) : T('X:(st|nd|rd|th):X place - Y goals', rank, goalsScored)}</h2>
      <span className={styles.team_goals}>
        <Flag code={rankedTeam.team.club.nation.code} alt={T.select(rankedTeam.team.club.nation.name)} borderColor={rankClr} />
        <span className={classNames(styles.team_name, rank < 4 && styles.bold, rank > 3 && styles.gray )}>{T.select(rankedTeam.team.name.clubName)}</span>
      </span>
    </div>
  </Link>
}