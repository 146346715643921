import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {fouls_personal: number}, away: {fouls_personal: number}},
  statisticsTotal: {home: {fouls_personal: number}, away: {fouls_personal: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
}

export default function FoulsPersonal ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')
  
  const totalFoulsPersonal = statisticsTotal.home.fouls_personal + statisticsTotal.away.fouls_personal;

  return <LabeledOpposingHorizontalBars 
    label={T('Personlig')}
    left={{
      value: statistics.home.fouls_personal / totalFoulsPersonal,
      color: teamColors.home,
      label: statistics.home.fouls_personal
    }}
    right={{
      value: statistics.away.fouls_personal / totalFoulsPersonal,
      color: teamColors.away,
      label: statistics.away.fouls_personal
    }}
  />
}