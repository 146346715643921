import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { centerblock, LoadingContent, paragrapgh } from '../../Loading/LoadingContent';
import { useFormatDate } from "cupman-utils/lib/LangContext";
import styles from './PlayedGames.module.scss';
import { useCurrentTournament } from '../../App';
import { EditionDay } from '../../api/schema';
import { Link } from 'react-router-dom';


type props = {
  games?: {
    date: number | EditionDay,
    matches: number,
    finished: number
  }[]
}

export default function PlayedGames ({games} : props) {

  const T = useTranslations('cmresults');

  if( games ){
    const played = games.reduce((totalPlayed, day) => totalPlayed + day.finished, 0);
    const total = games.reduce((totalMatches, day) => totalMatches + day.matches, 0);

    return (
      <div className={classNames(styles.played_games_cotainer, "no-print")}>
        <h2 className="visually_hidden">
          {T('Number of games')}
        </h2>
        <span className={styles.played_games_header}>
          {played} {T('of')} {total}
          <span>{T('games finished')}</span>
        </span>
        {games.length < 10 && <div className={classNames(styles.progressbar_container, 'max-75')}>
          <div className={styles.progressbar_inner_container}>
            {games.filter((day) => day.matches > 0).map((day, i) => 
              <GamesDay day={day}  key={i} />
            )}
          </div>
        </div>}
        
      </div>)
    } else {
      return <div className={styles.played_games_cotainer}>
        <LoadingContent className={"max-75"} components={[centerblock(45,100,12), paragrapgh(22,18)]}/>
      </div>
    }
}

function GamesDay ({day} : {day: {
  date: number | EditionDay,
  matches: number,
  finished: number
}}) {

  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);
  const time = typeof day.date === "object" ? day.date.date : day.date;


  return <div style={{flexBasis: `${day.matches * 100 || 100 }%`}}>
  <div className={styles.progressbar_played_container}>
    <div className={styles.progressbar_played} style={{width: `${day.finished / day.matches * 100}%`}}></div>
  </div>

  <div className={styles.tick}></div>
  {typeof day.date === "object" ? 
    <Link to={`/games?day=${day.date.index}`}> 
      <div className={styles.date}>
        {formatDate(time, 'dd')}<br />
        <span className={styles.month}>{formatDate(time, 'MMM')}</span>
      </div>
    </Link> 
    : <div className={styles.date}>
        {formatDate(time, 'dd')}<br />
      <span className={styles.month}>{formatDate(time, 'MMM')}</span>
    </div>
  }
</div>
}