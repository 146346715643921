import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {matchesWon: number, matchesLost: number, matchesTied: number}, away: {matchesWon: number, matchesLost: number, matchesTied: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
}

export default function LostMatches ({statistics, teamColors} : Props) {

  const T = useTranslations('cmresults')

  const totalWon = statistics.home.matchesWon + statistics.away.matchesWon;
  const totalLost = statistics.home.matchesLost + statistics.away.matchesLost;
  const totalTied = statistics.home.matchesTied + statistics.away.matchesTied;
  const scale = Math.max(totalWon, totalLost, totalTied)


  return <LabeledOpposingHorizontalBars 
    label={T('Förlorade')}
    left={{
      value: statistics.home.matchesLost / scale,
      color: teamColors.home,
      label: statistics.home.matchesLost,
    }}
    right={{
      value: statistics.away.matchesLost / scale,
      color: teamColors.away,
      label: statistics.away.matchesLost
    }}
  />
}