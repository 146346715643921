import classNames from 'classnames';
import { useTranslations } from 'cupman-utils/lib/useTranslations';
import { NameClub, Team$shirt as Shirt, Team$awards } from '../../api/schema';
import DressBasket from '../Dress/DressBasket';
import DressSoccer from '../Dress/DressSoccer';
import Flag from '../Flag/Flag';
import styles from './TeamHeader.module.scss';
import useScrolledFromTop from '../../utils/HasScrolledFromTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';

type props = {
  sport: string,
  dress?: Omit<Shirt,'__typename'>,
  club: NameClub<{
    nation: {},
    registeredTeams: []
  }>,
  awards?: Team$awards
}

export default function ClubHeader ({sport, dress, club, awards} : props) {

  const T = useTranslations('cmresults');
  const scrolled = useScrolledFromTop();

  const getShirtColors = () => {
    const colorArr = [];

    if(dress?.color1) {
      colorArr.push(dress?.color1)
    } else {
      colorArr.push('#d5d5d5')
    }

    if(dress?.color2) {
      colorArr.push(dress?.color2)
    } else {
      colorArr.push('#b6b6b6')
    }

    if(dress?.color3) {
      colorArr.push(dress?.color3)
    }

    return colorArr;
  }

  return <>
    <div className={classNames(styles.team_head_container, scrolled && styles.scrolled)}>
      <div className="max-75">
        <div className={styles.club_container}>
          <span>{T.select(club.nation.name)}</span>
          <div className={styles.flag_container}>
            <Flag code={club.nation.code} alt={T.select(club.nation.name)} size="lg" borderColor="rgb(var(--clr-favorite-dark))" />
          </div>
        </div>
        <div className={styles.dress_container}>
          {sport === 'basketball' && <DressBasket
            primary={getShirtColors()[0]} 
            secondary={getShirtColors()[1]}
            tertiary={getShirtColors()[2]} 
            type={dress?.type}
            code={club.nation.code}
          />}

          {sport !== 'basketball' && <DressSoccer 
            primary={getShirtColors()[0]} 
            secondary={getShirtColors()[1]}
            tertiary={getShirtColors()[2]}
            type={dress?.type}
            code={club.nation.code}
          />}
        </div>

        <div className={styles.teams_length_container}>

          <span className={styles.teams_icon}>
            <FontAwesomeIcon icon={faUsers} />
          </span> <span className={styles.teams_length}>{T('X team(s):X', club.registeredTeams.length)}</span>
        </div>
      </div>
    </div>
  </>
}