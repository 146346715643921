import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import React, { useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentTournament } from '../../App';
import styles from './SearchBar.module.scss';
import AutocompleteResults from './AutocompleteResults';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import Modal from '../Modal/Modal';

export const touch = window.matchMedia('(pointer: coarse)').matches && !window.matchMedia('(hover: hover)').matches

export const targetMobileSearch = () => {
  const el = document.getElementById('search-input-mobile');
  document.body.classList.add('search-focused');
  el && el.focus();
}

export default function SearchBar({query, up, white, noMutation} : {query?: string, up?:boolean, white?: boolean, noMutation?: boolean}) {

  const t = useCurrentTournament();
  const T = useTranslations('cmresults');
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState<undefined | string>(query)
  const searchRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const mobileSearchRef = useRef<HTMLInputElement>(null);
  const [autoResults, setAutoResults] = useState(false);

  const defferedQuery = useDeferredValue(searchQuery);

  useEffect(() => {
    setSearchQuery(query);
  }, [query])

  const [fokusedWithin, setFocusedWithin] = useState(false);

  useEffect(() => {
    if(wrapperRef) { 
      document.addEventListener('click', () => {
          if(wrapperRef.current?.contains(document.activeElement)) {
            setFocusedWithin(true);
          } else {
            setFocusedWithin(false);
            setAutoResults(false);
          }
      })
      document.addEventListener('keydown', () => {
        if(wrapperRef.current?.contains(document.activeElement)) {
          setFocusedWithin(true);
        } else {
          setFocusedWithin(false);
          setAutoResults(false);
        }
      })
    }

  }, [wrapperRef])



  const tabIndex = !!searchQuery ? 0 : -1;

  const onSubmit = (e:React.FormEvent) => {
    e.preventDefault();
    
    document.body.classList.remove('search-focused');
    return !searchQuery ? false : navigate('/search/' + searchQuery);
  }

  const onReset = (ref: React.RefObject<HTMLInputElement>) => {
    setSearchQuery('');
    ref.current && ref.current.focus();
  }


  const userClientIos = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);

  return <div className={classNames('max-50', styles.max_width, up && styles.dir_up, white && styles.white, styles.search_bar_wrapper)} ref={wrapperRef}>
    <div className={classNames(
      styles.search_wrapper, 
      focused && styles.focused,
      searchQuery && styles.typed, 
      autoResults && searchQuery && styles.results
    )}>

      {/*
      ################
      ONLY MOBILE
      ################
      */}
      <Modal>
        <div className={styles.mobile_search_wrapper}>
          <form 
            role="search" 
            className={styles.inner_search_wrapper}
            method="get"
            onKeyUp={(e) => {
              e.preventDefault();
              if(e.key === 'Escape') {
                onReset(mobileSearchRef);
              }
            }}
            onSubmit={(e) => onSubmit(e)}
          >
            <label htmlFor="search-input" className="visually_hidden">{T('Search')}</label>
            <button className={styles.back_btn} type="button" onClick={(e) => {
              e.preventDefault();
              document.body.classList.remove('search-focused');
            }} aria-label={T('Close')}><FontAwesomeIcon icon={faArrowLeft}/></button>

            <input 
              type="text"
              ref={mobileSearchRef}
              id="search-input-mobile" 
              className={styles.search_input} 
              onFocus={() => setFocused(true)}
              size={10}
              onBlur={() => setFocused(false) } 
              value={searchQuery}
              autoComplete="off"
              onChange={(e) => setSearchQuery(e.target.value)}
              role="combobox"
              aria-controls="auto_complete_mobile"
              aria-expanded={autoResults}
              autoCapitalize="off"
              spellCheck="false"
              aria-haspopup="listbox"
            />
            <div className={classNames(styles.tools, !!searchQuery && styles.visible)}>
              <button 
                type='reset'
                aria-label={T('Clear')} 
                className={styles.btn_clear} 
                tabIndex={tabIndex}
                onClick={(e) => {
                  e.preventDefault();
                  onReset(mobileSearchRef);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <div className={styles.separator}></div>
              <button aria-label={T('Search now')} type="submit" className={styles.btn_search} tabIndex={tabIndex} onClick={() => document.body.classList.remove('search-focused')}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </form>
          <div className={styles.auto_complete_wrapper}>
            <div className={styles.auto_complete} id="auto_complete_mobile">
              <div>
                <AutocompleteResults 
                  query={defferedQuery} 
                  tournamentId={t.id} 
                  setAutoResults={(isSet: boolean) => setAutoResults(isSet)}
                  searchRef={searchRef}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/*
      ################
      / ONLY MOBILE
      ################
      */}


      <form 
        role="search" 
        className={styles.inner_search_wrapper}
        method="get"
        onKeyUp={(e) => {
          e.preventDefault();
          if(e.key === 'Escape') {
            onReset(searchRef);
          }
        }}
        onSubmit={(e) => onSubmit(e)}
      >

        {/* NOT IN CONTEXT */}
          <label htmlFor="search-input" className="visually_hidden">{T('Search')}</label>
          <FontAwesomeIcon icon={faSearch} role="presentation" className={styles.presentation_search} />
        {/* / NOT IN CONTEXT */}

        {touch && <button 
          onClick={(e) => {
            if(!userClientIos) {
              e.preventDefault();
              targetMobileSearch();
            }
          }}
          onTouchStart={(e) => {
            if(userClientIos) {
              e.preventDefault();
              targetMobileSearch();
            }
          }}
          className={styles.search_touch_btn}
          id="search-input-fake"
          aria-label={T('Open search')}
          type="button"
        ></button>}

        <input 
          type="text"
          ref={searchRef}
          id="search-input" 
          className={styles.search_input} 
          onFocus={() => setFocused(true)}
          size={10}
          onBlur={() => setFocused(false) /*setTimeout(() => {setFocused(false)}, 300)*/} 
          disabled={touch}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoComplete="off"
          role="combobox"
          aria-controls="auto_complete"
          aria-expanded={autoResults}
          autoCapitalize="off"
          spellCheck="false"
          aria-haspopup="listbox"
        />

        {/* TOOLS */}
        <div className={classNames(styles.tools, !touch && !!searchQuery && styles.visible)}>
          <button 
            type='reset'
            aria-label={T('Clear')} 
            className={styles.btn_clear} 
            tabIndex={tabIndex}
            onClick={(e) => {
              e.preventDefault();
              onReset(searchRef);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className={styles.separator}></div>
          <button aria-label={T('Search now')} type="submit" className={styles.btn_search} tabIndex={tabIndex}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        
        {/* / TOOLS */}

      </form>

      {fokusedWithin && <div className={styles.auto_complete} id="auto_complete" tabIndex={0}>
        <div>
          <AutocompleteResults 
            query={defferedQuery}
            searchRef={searchRef}
            tournamentId={t.id} 
            setAutoResults={(isSet: boolean) => setAutoResults(isSet)}
            noMutation={noMutation}
          />
        </div>
      </div>}
    </div>
  </div>
}