
import { faRoute } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { useParams } from "react-router-dom";
import { Match, RegisteredTeamLink, TeamLink, useMe, useNameClub } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import LinkButton from "../../components/Button/LinkButton";
import ClubMap from "../../components/ClubMap/ClubMap";
import ClubPerformanceInfo from "../../components/ClubPerformanceInfo/ClubPerformanceInfo";
import ClubAwards from "../../components/ClubTeamHead/ClubAwards";
import ClubHeader from "../../components/ClubTeamHead/ClubHeader";
import ClubTeams from "../../components/ClubTeams/ClubTeams";
import Games from "../../components/Game/Games";
import PageHeader from "../../components/PageHeader/PageHeader";
import PendingComponent from "../../components/Pending/PendingComponent";
import TeamAccommodation from "../../components/TeamAccomodation/TeamAccomodation";
import { renderLinks, truthy } from "../../utils";
import styles from './ClubPageAccommodation.module.scss';

export function ClubPageAccommodation() {
  const params = useParams<{clubId:string}>();
  const clubId = parseInt(params.clubId || '0');

  const t = useCurrentTournament();

  const me = useMe({optionalCupId: t.cup.id},{});
  const nc = useNameClub({id:clubId},{
    nation: {},
    shirt: {},
    registeredTeams: [{}],
    address: {},
    description: {}
  });

  const T = useTranslations('cmresults');
  const admin = useAdmin();
  
  const myTeams = me.teams.filter(t => t.hasLeaderAccess);
  const commonTeams = admin.overridePublished ? nc.registeredTeams 
    : nc.registeredTeams.filter(regTeam => myTeams.find(meTeamLink => meTeamLink.team?.href === regTeam.team?.href));

  return <>
    <PageHeader 
      title={T('Accommodation')} 
      crumbs={[{
        title: nc.name,
        url: `clubs/${clubId}`
      }]}
    />

    <ClubHeader 
      sport={t.subcup.sport.type} 
      dress={nc.shirt}
      club={nc}
    />
    <div className={classNames("max-50", styles.container)}>
      {commonTeams.map(tl => 
        <TeamAccommodationFetcher regTeamLink={tl}/>
      )}
      {commonTeams.length === 0 && <PendingComponent 
        text={<>
          {T('You dont have access to any of the teams in this club')}
          <br/>
          {me.userEmail 
            ? <small>{T('You are logged in as X', me.userEmail)}</small>
            : <small>{T('You are not logged in.')}</small>}
        </>} 
        className={styles.pending_margin} />}
    </div>
  </>
}

function TeamAccommodationFetcher({regTeamLink} : {regTeamLink: RegisteredTeamLink}) {
  const T = useTranslations("cmresults");
  const admin = useAdmin();
  const t = useCurrentTournament();

  const {team} = useLink(regTeamLink, {
    team: {accommodation : {lodgings:[{location:{persons:[]}}]}}
  });

  const accommodationPublished = t.lodgingPublished || admin.overridePublished;
  
  return  team && team.accommodation && TeamAccommodation.shouldShow(team.accommodation, t,admin) ? <div>
      <h2 className={classNames("custom-font", styles.team_title)}>{T('Accommodation for X', team.name.categoryName)}</h2>
      {accommodationPublished && team.accommodation && <TeamAccommodation timelineLinkId={team.id} accommodation={team.accommodation} />}
      
    </div>: null
}