import { useTranslations } from "cupman-utils";
import LabeledHorizontalBarMultiple from "./LabeledHorizontalBarMultiple";
import { Team$statistics } from "../../../api/schema";
import { roundToOneDecimal } from "../../../utils";
import { getColorString, labContrasts } from "../../../colors";
import { RawRgb } from "./PointsWonLostBar";

type Props = {
  statistics: Team$statistics,
  color: RawRgb
}

export default function PointsWonBar ({statistics, color} : Props) {

  
  const T = useTranslations('cmresults');

  const TwoPointersPoints = statistics.twoPointers * 2;
  const ThreePointersPoints = statistics.threePointers * 3;
  const totalPoints = TwoPointersPoints + ThreePointersPoints + statistics.onePointers;

  const rgbs = labContrasts(color)

  const bars = [
    {
      label: T('3-poängare'),
      value: ThreePointersPoints / totalPoints,
      valueLabel: `${ThreePointersPoints} P (${roundToOneDecimal(ThreePointersPoints / totalPoints)}%)`,
      color: getColorString(rgbs[0])
    },
    {
      label: T('2-poängare'),
      value: TwoPointersPoints / totalPoints,
      valueLabel: `${TwoPointersPoints} P (${roundToOneDecimal(TwoPointersPoints / totalPoints)}%)`,
      color: getColorString(rgbs[1])
    },
    {
      label: T('1-poängare'),
      value: statistics.onePointers / totalPoints,
      valueLabel: `${statistics.onePointers} P (${roundToOneDecimal(statistics.onePointers / totalPoints)}%)`,
      color: getColorString(rgbs[2])
    }
  ]

  return <div>
    <LabeledHorizontalBarMultiple bars={bars} />
  </div>
}




