import styles from './ErrorComponent.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { ErrorType } from './ErrorBoundary';

type props = {
  text: string,
  header?: string | JSX.Element
  large?:boolean,
  centerHeader?: boolean,
  style?: React.CSSProperties,
  square?: boolean,
  error?: ErrorType,
  errorInfo: string
}

export default function ErrorComponent ({
  text, 
  header: _header, 
  large, 
  centerHeader,
  style,
  square,
  error,
  errorInfo
} : props) {

  const header = _header
    ? typeof _header === 'string'
      ? <h2>{_header}</h2>
      : _header
    : undefined

    console.log('error:', {
      error: error,
      errorInfo: errorInfo
    })

  return <>
    {header && <div className={classNames(centerHeader && styles.center)}>
      {header}
    </div>}
    <div className={classNames(styles.wrapper, large && styles.large, square && styles.square)} style={style}>
      <FontAwesomeIcon icon={faCircleExclamation} className={styles.icon} />
      <p className={styles.info}>
        {text}
      </p>
    </div>
  </>
}