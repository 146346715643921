import classNames from 'classnames';
import styles from './OpposingHorizontalBars.module.scss';

type Bar = {
  length: number,
  color: string
}

type Props = {
  left: Bar,
  right: Bar
}


export default function OpposingHorizontalBars ({left, right} : Props) {
  return <div className={styles.bars_wrapper}>
    <div className={classNames(styles.bar_wrapper, styles.bar_wrapper_left)}>
      <div 
        className={classNames(styles.bar, styles.bar_left)} 
        style={{
          '--width': !isNaN(left.length) ? `${left.length * 100}%` : '0%',
          background: left.color
        } as any}
      ></div>
    </div>
    <div className={classNames(styles.bar_wrapper, styles.bar_wrapper_right)}>
      <div 
        className={classNames(styles.bar, styles.bar_right)} 
        style={{
          '--width': !isNaN(right.length) ? `${right.length * 100}%` : '0%',
          background: right.color
        } as any}
      ></div>
    </div>
  </div>
}