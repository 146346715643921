import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'cupman-utils';
import { LoadingContent, paragrapgh } from '../../Loading/LoadingContent';
import styles from './StatisticsSummaryHeader.module.scss';

type props = {
  data?: {
    name: string,
    icon: IconDefinition,
    amount: number,
  }[],
  header?: string
}

export default function StatisticsSummaryHeader({data, header: title} : props) {

  return (
    <div className={styles.container}>
      
      {title && <h2 className="visually_hidden">{title}</h2>}

      <div className="max-75">
        {data ? data.map((thisData, i) => 
        <div key={i}>
          <div>
            <div className={styles.icon_num}>
              <FontAwesomeIcon icon={thisData.icon}/>
              <span>{thisData.amount}</span> 
            </div>
            <div className={styles.name}>{thisData.name}</div>
          </div>
        </div>
        ) : <LoadingContent components={[paragrapgh(40,0)]}/>}
      </div>
    </div>
  )
}