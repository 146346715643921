import styles from './LiveFeed.module.scss';
import { faCommentLines, faEye, faEyeSlash, faSignalStream, faVolume, faVolumeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Match, MatchComment, MatchEvent } from '../../api/schema';
import { useTranslations } from 'cupman-utils';
import { groupBy } from '../../utils';
import LiveFeedDuringMatch from './LiveFeedDuringMatch';
import LiveFeedAroundGame from './LiveFeedAroundGame';
import MatchEnd from './MatchEnd';
import { useState } from 'react';
import classNames from 'classnames';
import LiveHeaders from './LiveHeaders';
import { useCurrentTournament } from '../../App';


LiveFeed.fragment = Match.fragment({
  home: {
    team: {
      club: {
        nation: {}
      },
      shirt: {}
    }
  },
  away: {
    team: {
      club: {
        nation: {}
      },
      shirt: {}
    }
  },
  feed: {
    events: [{}]
  },
  result: {}
});

// Used in child react components to get side css class
export const getSideClass = (side: 'home' | 'away') => { 
  return side === 'home' ? 'home' : 'away';
}

export const groupedComments = (events:MatchEvent[]) => groupBy(
  events.filter(event => event.__typename === 'MatchComment') as MatchComment[], 
  (event => `${event.side}_${event.comment}`)
);

type props = {
  game: Match<typeof LiveFeed.fragment>
}

export default function LiveFeed({game} : props) {
  const t = useCurrentTournament();

  const _events = game.feed.events.filter(event => event !== undefined);
  const endMatchIndex = game.finished ? _events.length - [..._events].reverse().findIndex(event => event.__typename !== 'MatchComment') : _events.length;

  let startMatchIndex = _events.findIndex((event) => event.__typename === 'MatchStart');
  if (startMatchIndex === -1) {
    startMatchIndex = _events.findIndex((event) => event.__typename !== 'MatchComment');
  }

  const duringMatch = [..._events.slice(startMatchIndex, endMatchIndex)].reverse();

  const preMatch = startMatchIndex > -1 
    ? [..._events.slice(0, startMatchIndex)].reverse().filter(event => event.__typename === 'MatchComment') as MatchComment[]
    : [..._events].reverse().filter(event => event.__typename === 'MatchComment') as MatchComment[];

  const afterMatch = [..._events].slice(endMatchIndex).reverse().filter(event => event.__typename === 'MatchComment') as MatchComment[];
  const thereIsAFeed = duringMatch.some(event => event.__typename === 'MatchStart') || preMatch.length > 0 || afterMatch.length > 0;
  

  const T = useTranslations('cmresults');

  const retrievedCm = localStorage.getItem('cmResults');
  const [soundOn, setSoundOn] = useState(!retrievedCm
    ? false
    : JSON.parse(retrievedCm).soundOn) // false | true
  
  const emojiFlagsOn = game.home.team?.club.nation.code !== game.away.team?.club.nation.code;

  const periods = [...duringMatch.reduce((periods, e) => {
    periods.add(e.period);
    return periods;
  }, new Set<number>())].sort();
  const [hiddenPeriods, setHiddenPeriods] = useState(new Set(!game.finished ? periods.slice(0,-1) : periods))

  return (
    <div className={classNames("max-75", styles.livefeed_super_container)}>
      <div className={styles.live_title}>
        <h2 className={"custom-font"} style={{textAlign: 'center', whiteSpace: 'nowrap'}}>
          {game.live 
            ? <>
              <FontAwesomeIcon icon={faSignalStream} /> {T('Live report')}
            </>
            : <>
              <FontAwesomeIcon icon={faCommentLines} /> {T('Pep talk')}
            </>}
        </h2>
        <span className={styles.btn_wrapper}>
          <button 
            aria-label={soundOn 
              ? T('Sound effects on') 
              : T('Sound effects off')
            } 
            className={styles.btn_tool}
            onClick={() => {
              localStorage.setItem('cmResults', JSON.stringify({
                soundOn: !soundOn
              }))
              setSoundOn(!soundOn);
            }}
          >
              <FontAwesomeIcon icon={faVolume} className={classNames(styles.sound_icon, soundOn && styles.visible)} /> 
              <FontAwesomeIcon icon={faVolumeSlash} className={classNames(styles.sound_icon, !soundOn && styles.visible)} />
          </button>
          <span className={styles.btn_txt}>{soundOn ? <>{T('Sound on')}</> : <>{T('Sound of')}</>}</span>
        </span>
      </div>

      <div className={styles.livefeed_container}>
        {/* HOME TEAM */}
        <LiveHeaders game={game} thereIsAFeed={thereIsAFeed} />
        
        <div className={styles.live_container}>
          <div className={styles.feed_container}>
            <div>
              {startMatchIndex > -1 && afterMatch.length > 0 && 
              <LiveFeedAroundGame 
                events={afterMatch} 
                game={game} 
                title={T('Post game talk')}
                emojiFlagsOn={emojiFlagsOn}
              />}

              {game.finished && game.live && <MatchEnd 
                teamName={ game.result.winner
                  ? T.select(game[game.result.winner].name)
                  : undefined
                }
                result={game.result}
                winner={game.result.winner} 
                hiddenPeriods={hiddenPeriods}
                setHiddenPeriods={setHiddenPeriods}
                lastPeriod={periods.slice(-1)[0]}
                lastPeriodType={game.result.periodTypes[periods.slice(-1)[0]]}
                ordinaryPeriodCount={game.result.ordinaryPeriodCount}
                sport={t.subcup.sport.type}
                />}

              {duringMatch.length > 0 && game.live &&  <LiveFeedDuringMatch 
                events={duringMatch} 
                hiddenPeriods={hiddenPeriods}
                setHiddenPeriods={setHiddenPeriods}
                game={game} 
                soundOn={soundOn}
                eventsBefore={preMatch.length > 0}
              />}

              {preMatch.length > 0 && <LiveFeedAroundGame 
                events={preMatch}
                live={game.live}
                game={game} 
                title={T('Pep talk')}
                emojiFlagsOn={emojiFlagsOn}
              />}
              
            </div>

          </div>
        </div>
      </div>
      {/*<div className={styles.event_happening}></div>*/}
        
    </div>
  )
}

