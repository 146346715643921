import { faAngleLeft, faAngleRight, faStopwatch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { MatchEvent } from "../../api/schema";
import { formatMinutes } from "../../utils";
import styles from './TimelineMinutes.module.scss';


export default function TimelineMinutes ( {time, side, type} : {time: number, side?: string, type?: MatchEvent['__typename']} ) {

  

  return <div className={classNames(styles.live_minutes, type === 'MatchGoal' && styles.goal, type === 'MatchTimeout' && styles.timeout)}>

    <div className={styles.live_minutes_inner}>

      <FontAwesomeIcon icon={faStopwatch} className={styles.stop_watch}/> {formatMinutes(time)}
      {side && <>
        {side === 'home' && <FontAwesomeIcon icon={faAngleLeft} className={styles.direction_left} />}
        {side === 'away' && <FontAwesomeIcon icon={faAngleRight} className={styles.direction_right} />}
      </>}

    </div>

  </div>

}