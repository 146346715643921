import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { Match } from "../../api/schema"
import LiveFeed from "./LiveFeed"
import styles from './LiveHeaders.module.scss';
import SupporterButton from "./SupporterButton";

type props = {
  game: Match<typeof LiveFeed.fragment>,
  thereIsAFeed: boolean
}

export default function LiveHeaders ({game, thereIsAFeed} : props) {

  const T = useTranslations('cmresults');


  return <>
    <div className={classNames(styles.wrapper)}>
      
      <div className={classNames(styles.team, styles.home)} style={{'--clr-team-primary': game.home.team?.shirt?.color1, '--clr-team-secondary': game.home.team?.shirt?.anti} as any}>

        {/*<div className={styles.team_wrapper}>
          {game.home.team && <Flag code={game.home.team.club.nation.code} alt={T.select(game.home.team.club.nation.code)} />} 
          <span className={styles.team_name}>
            <MiddleEllipsisSpan text={T.select(game.home.name)} />
          </span>
        </div>*/}
        <div className={classNames(styles.btn_wrapper, styles.left, !thereIsAFeed && styles.bottom)}>
          <SupporterButton game={game} side='home' />
        </div>
      </div>

      <div className={classNames(styles.team, styles.away)} style={{'--clr-team-primary': game.away.team?.shirt?.color1, '--clr-team-secondary': game.away.team?.shirt?.anti} as any}>
        {/*<div className={classNames(styles.team_wrapper, styles.away)}>
          {game.away.team && <Flag code={game.away.team.club.nation.code} alt={T.select(game.away.team.club.nation.code)} />} 
          <span className={styles.team_name}>
            <MiddleEllipsisSpan text={T.select(game.away.name)} />
          </span>
        </div>*/}
        <div className={classNames(styles.btn_wrapper, styles.right, !thereIsAFeed && styles.bottom)}>
          <SupporterButton game={game} side='away' />
        </div>
      </div>
      
    </div>
  </>
}