import styles from './TeamAwards.module.scss';
import AwardWithText from './AwardWithText';
import { Team$awards } from '../../api/schema';
import { useTranslations } from 'cupman-utils';
import { useCurrentTournament } from '../../App';
import classNames from 'classnames';

type props = {
  awards?: Team$awards
}

export const sortAwards = (awards: Team$awards | undefined) => {
  if (awards) {
    return awards.sort((a,b) => {
      if (a.type === 'mostSupporters') {
        return 1;
    } else if (b.type === 'mostSupporters') {
        return -1;
    } else if (a.type === 'greencards') {
        return 1;
    } else if (b.type === 'greencards') {
        return -1;
    } else if (a.placement !== b.placement) {
        return a.placement - b.placement;
    } else {
        return 0;
    }
    })
  } else {
    return undefined;
  }
}
export default function TeamAwards ({awards: _awards} : props) {
  
  const _sortedAwards = sortAwards(_awards);

  const awardsLength = () => {
    if (_sortedAwards) {
      if(_sortedAwards.length <= 2) {
        return styles.two;
      }
    }

    return false;
  }

  const T = useTranslations('cmresults');

  return(
    <div className={styles.award_container}>
      <div className="max-75">
        <h3 className="visually_hidden">
          {T('Awards')}
        </h3>

        <div className={classNames(styles.award_grid, awardsLength())}>
          {_sortedAwards && _sortedAwards.map(award => <AwardWithText award={award} key={`${award.type}_${award.placement}`} />)}
        </div>

      </div>
    </div>
  )
}