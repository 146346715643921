import { useTranslations } from "cupman-utils"
import { Team$statistics } from "../../../api/schema"
import ContentGrayContainer from "../../ContentGrayContainer";
import styles from './TeamPageStatistics.module.scss';
import classNames from "classnames";
import PointsLostBar from "./PointsLostBar";
import PointsWonLostBar from "./PointsWonLostBar";
import PointsWonBar from "./PointsWonBar";
import SingleValue from "./SingleValue";
import { getColorString, labContrasts } from "../../../colors";
import OutfieldPlayersTable from "../../TeamMembers/OutFieldPlayersTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-light-svg-icons";

type Props = {
  statistics: Team$statistics,
  teamColors: {
    color1: string,
    color2: string,
    color3: string,
    anti: string
  },
  settings: {
    assists: boolean,
    showResults: boolean
  }
}



export default function BasketBallStatistics ({statistics, teamColors, settings} : Props) {


  const T = useTranslations('cmresults');

  const positiveGreen = {r:165, g:219, b:127};
  const negativeRed = {r:255, g:105, b:105}

  const rgbs = labContrasts(negativeRed)

  return <>
    <GroupHeader label={`${T('Poäng')}`}/>
    <ContentGrayContainer className={styles.gray_container}>
      <PointsWonLostBar statistics={statistics} colors={{
        positive: positiveGreen,
        negative: negativeRed
      }} />
    </ContentGrayContainer>
    <GroupHeader label={`${T('Gjorda / Förlorade baskets')}`}/>
    <ContentGrayContainer className={styles.gray_container}>
      <PointsWonBar statistics={statistics} color={positiveGreen} />
      <PointsLostBar statistics={statistics} color={negativeRed} />
    </ContentGrayContainer>

    <div>
      <GroupHeader label={`${T('Fouls')}`}/>
      <div className={styles.single_values}>
        <SingleValue value={statistics.fouls_personal} backgroundColor="rgb(var(--clr-gray-50))" color={getColorString(rgbs[0])} label={T('Personliga')} />
        <SingleValue value={statistics.fouls_technical} backgroundColor="rgb(var(--clr-gray-50))" color={getColorString(rgbs[1])} label={T('Tekniska')} />
        <SingleValue className={classNames(statistics.fouls_unsportsmanlike === 0 && styles.no_data)} value={statistics.fouls_unsportsmanlike} backgroundColor="rgb(var(--clr-gray-50))" color={getColorString(rgbs[2])} label={T('Osportsliga')} />
        <SingleValue className={classNames(statistics.fouls_disqualifying === 0 && styles.no_data)} value={statistics.fouls_disqualifying} backgroundColor="rgb(var(--clr-gray-50))" color={getColorString(rgbs[3])} label={T('Diskvalificerande')} />
      </div>
    </div>
    {statistics.players.length > 0 && <>
      <h2 className={classNames(styles.player_statistics_header, 'custom-font')}>
        <FontAwesomeIcon icon={faUser}/> {T('Spelarstatistik')}
      </h2>
      <OutfieldPlayersTable 
        players={statistics.players} 
        isHome 
        colors={{
          primary: teamColors.color1,
          secondary: teamColors.anti
        }}
        settings={settings}
      />
    </>}
  </>
}

export const GroupHeader = ({label} : {label:string}) => {
return <h3 className={classNames(styles.statistics_group_header, 'custom-font')}>
  {label}
</h3>
}