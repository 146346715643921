import { useTranslations } from "cupman-utils";
import { useCurrentTournament } from "../../App";
import { Match, MatchFeed$statistics } from "../../api/schema"
import IcehockeyStatistics from "./IcehockeyStatistics";
import BasketballStatistics from "./BasketballStatistics";
import { useState } from "react";
import Button from "../Button/Button";
import styles from './matchStatistics.module.scss';
import FootballStatistics from "./FootballStatistics";
import { useSportSpecificWords } from "../../utils/useSportSpecificWords";
import OtherStatisticsMatch from "./OtherStatisticsMatch";
import { capitalize, makeContrastColor, shortenFirstWord } from "../../utils";
import GameFieldIcon from "../GameFieldIcon/GameFieldIcon";
import ToggleButtons from "../Button/ToggleButtons";
import classNames from "classnames";
MatchStatistics.fragment = Match.fragment({
  result: {},
  home: {
    team: {
      shirt: {}
    }
  },
  away: {
    team: {
      shirt: {}
    }
  },
  division: {
    category: {}
  }
})

type Props = {
  game: Match<typeof MatchStatistics.fragment>,
  statistics: MatchFeed$statistics
}

export default function MatchStatistics ({statistics, game} : Props) {
  
  const shirtPrimaryColors = {
    home: makeContrastColor(game.home.team?.shirt?.color1),
    away: makeContrastColor(game.away.team?.shirt?.color1)
  }

  const t = useCurrentTournament();
  const T = useTranslations('cmresults');
  const sportType = t.subcup.sport.type

  const [periodNumber, setPeriodNumber] = useState<undefined | number>(undefined)

  const statisticsTotalOrPeriod = periodNumber !== undefined
    ? statistics.periods[periodNumber]
    : statistics

  const statisticsOnlyTotal = {
    home: statistics.home, away: 
    statistics.away
  }

  const formatPeriod = useSportSpecificWords(sportType, game.result.ordinaryPeriodCount);

  const padding = 5;
  

  
  return <div className={classNames("max-50", styles.outer_container)}>
    <div className={styles.match_statistics_header}>
      <GameFieldIcon icon={sportType}/> <h2 className="custom-font" style={{fontSize: 'var(--font-size-l)'}}>{T('Matchstatistik')}</h2>
    </div>

    {statistics.periods.length > 1 && <ToggleButtons padding={padding}>
      <Button onClick={() => setPeriodNumber(undefined)} disabled={periodNumber === undefined}>
      {T('Totalt')}
      </Button>
      <div className={styles.periodswrapper}>
        {statistics.periods.map((_, i) => <Button 
          onClick={() => setPeriodNumber(i)} 
          disabled={i === periodNumber} 
          key={i}
        > 
        {sportType === 'basketball' 
          ? <>
            <span className={styles.period_pc}>{capitalize(formatPeriod(game.result.periodTypes[i], i))}</span>
            <span className={styles.period_mobile}>{shortenFirstWord({
              string: capitalize(formatPeriod(game.result.periodTypes[i], i)),
              cutIndex: 1,
              between: ''
            })}</span>
          </>
          : <>{capitalize(formatPeriod(game.result.periodTypes[i], i))}</>
        }
        </Button>)}
      </div>
    </ToggleButtons>}

    {sportType === 'icehockey' && <IcehockeyStatistics
      statisticsOnlyTotal={statisticsOnlyTotal}
      statistics={statisticsTotalOrPeriod} 
      teamColors={shirtPrimaryColors}
      registerShots={game.division.category.registerShots}
    />}
    {sportType === 'basketball' && <BasketballStatistics 
      statisticsOnlyTotal={statisticsOnlyTotal}
      statistics={statisticsTotalOrPeriod} 
      teamColors={shirtPrimaryColors}
    />}
    {sportType === 'football' && <FootballStatistics
      statisticsOnlyTotal={statisticsOnlyTotal} 
      statistics={statisticsTotalOrPeriod} 
      teamColors={shirtPrimaryColors}
      registerYellowCards={game.division.category.registerYellowCards}
      registerRedCards={game.division.category.registerRedCards}
      registerGreenCards={game.division.category.registerGreenCards}
    />}

    {sportType !== 'football' && sportType !== 'icehockey' && sportType !== 'basketball' && <OtherStatisticsMatch
      statisticsTotal={statisticsOnlyTotal} 
      statistics={statisticsTotalOrPeriod} 
      teamColors={shirtPrimaryColors}
    />}
  </div>
}