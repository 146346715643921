import { faPlus, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { Call, useClient } from 'granular';
import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Search, Search$SearchCategory, Search$SearchClub, Search$SearchLocation, SearchLocation$SearchArena } from '../../api/schema';
import { useCurrentTournament } from '../../App';
import GameFieldIcon from '../../components/GameFieldIcon/GameFieldIcon';
import SearchBar from '../../components/Search/SearchBar';
import SearchGroupHeader from '../../components/Search/SearchGroupHeader';
import styles from './SearchPage.module.scss';
import barStyles from '../../components/Search/SearchBar.module.scss';
import acStyles from '../../components/Search/AutocompleteResults.module.scss';
import { faBullseyeArrow, faMars, faVenus } from '@fortawesome/pro-regular-svg-icons';
import { SearchClubResults } from '../../components/Search/SearchTeamsResults';

export default function SearchPage ({}) {
  const {searchQuery} = useParams<{searchQuery:string}>();
  const [searchParams] = useSearchParams();

  return <div>
    <SearchResults query={searchQuery || searchParams.get("q") || undefined} />
  </div>
}



function SearchResults({query} : {query:string | undefined}) {


  const t = useCurrentTournament();
  const T = useTranslations('cmresults');

  return <div>
    {/*<h1 className={classNames("max-50", styles.header)}>
      {!!query && <>
        {T('Search in X', t.fullname)}
      </>}
      {!query && <>
        {T('Search')}
      </>}
      </h1>*/}
    {/*<SearchBar query={query} />*/}
    <SearchPageResult query={query} tournamentId={t.id} />
  </div>
}

function SearchPageResult ({query, tournamentId} : {query?: string, tournamentId: number}) {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const [result, setResult] = useState<any>(undefined);
  const [arenasExpanded, setArenasExpanded] = useState<Record<number, boolean>>({});
  const [catExpanded, setCatExpanded] = useState(false);
  const navigate = useNavigate();

  const searchRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const client = useClient();

  const callLink = query 
    ? Search({tournamentId, query}) 
    : undefined;

  const call = callLink
    ? Call.fromLink(callLink, {
      clubs: [{
        nameClub: {
          nation: {}
        },
        teams: [{
          team: {}
        }]
      }],
      nations: [{
        nation: {}
      }],
      categories: [{
        category: {}
      }],
      locations: [{
        location: {},
        arenas: [{
          arena: {}
        }]
      }]
    })
    : undefined;
  
  useEffect(() => {
    call && client.fetch(call).then(result => {
      const entity = client.readFromCache(call);
      setResult(entity);
    });
  }, [query]);
  

  const render = (array: any) => {
    if( array && array.length > 0 ) {
      return true;
    }
    
    return false;
  }

  const categoryChoosen = result?.categories.filter((cat: Search$SearchCategory) => cat.chosen) || [];
  const categoryOthers = result?.categories.filter((cat: Search$SearchCategory) => !cat.chosen) || [];

  
  const onSubmit = (e:React.FormEvent) => {
    e.preventDefault();
  }

  const onReset = (ref: React.RefObject<HTMLInputElement>) => {
    navigate('/search');
    ref.current && ref.current.focus();
  }

  return <div  className="max-50">

  <div className={classNames(
      styles.search_bar,
      barStyles.search_wrapper, 
      barStyles.typed, 
      barStyles.results
    )}>

      <form 
        role="search" 
        className={barStyles.inner_search_wrapper}
        method="get"
        onKeyUp={(e) => {
          e.preventDefault();
          if(e.key === 'Escape') {
            onReset(searchRef);
          }
        }}
        onSubmit={(e) => onSubmit(e)}
      >

        {/* NOT IN CONTEXT */}
          <label htmlFor="search-input" className="visually_hidden">{T('Search')}</label>
          <FontAwesomeIcon icon={faSearch} role="presentation" className={barStyles.presentation_search} />
        {/* / NOT IN CONTEXT */}

        <input 
          type="text"
          ref={searchRef}
          id="search-input" 
          className={barStyles.search_input} 
          size={10}
          value={query || ''}
          onChange={(e) => navigate('/search/' + e.target.value + '/')}
          autoComplete="off"
          aria-controls="auto_complete"
          autoCapitalize="off"
          spellCheck="false"
        />

        {/* TOOLS */}
        <div className={classNames(barStyles.tools, !!query && barStyles.visible)}>
          <button 
            type='reset'
            aria-label={T('Clear')} 
            className={barStyles.btn_clear} 
            onClick={(e) => {
              e.preventDefault();
              onReset(searchRef);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className={barStyles.separator}></div>
          <button aria-label={T('Search now')} type="submit" className={barStyles.btn_search}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        {/* / TOOLS */}
      </form>
  </div>



    {/*query && result && <h2>
      <FontAwesomeIcon icon={faBullseyeArrow}/> {T('Results using: \'X\'', query)}
          </h2>*/}

    {result && <div className={styles.result}>
      {render(result.clubs) && <div className={acStyles.search_group}>

        
        <div>

          {/* <SearchGroupHeader type="clubs" /> */}

          {result.clubs.map((club: Search$SearchClub<{
          nameClub: {},
          teams: [{
            team: {}
          }]
        }>) => {

          return <>
              <SearchClubResults club={club} /> 
          </>
        })}
        </div>
      </div>}

      {render(result.locations) && <>
        {result.locations.filter((location:Search$SearchLocation<{
          location: {},
          arenas: [{
            arena: {}
          }]
        }>) => location.arenas.length > 0).map((location:Search$SearchLocation<{
          location: {},
          arenas: [{
            arena: {}
          }]
        }>) => {

          const arenasChoosen = location.arenas.filter(arena => arena.chosen);
          const arenasOther = location.arenas.filter(arena => !arena.chosen);

          return <div className={acStyles.search_group} key={location.location.id}>
            <SearchGroupHeader type="locations" title={T.select(location.name)} />
            <div className={acStyles.location_wrapper}>
              <div className={acStyles.content}>
                <ul>
                {arenasChoosen.map((arena: SearchLocation$SearchArena<{
                    arena: {}
                  }>) => {
                    
                    return <li>
                      <Link to={`/places/${arena.arena.id}`} className={acStyles.link} key={arena.arena.id}>
                        <GameFieldIcon icon={t.subcup.sport.type} fontWeight="bold"/> <span>{arena.arena.completeName} </span>
                      </Link>
                    </li>}
                  )}
                  
                </ul>
                {arenasOther.length > 0 && <button className={acStyles.show_more} onClick={() => {   
                  setArenasExpanded({
                    ...arenasExpanded,
                    [location.location.id]: true
                  });
                }}>
                  <span>
                    <FontAwesomeIcon icon={faPlus} /> {T('Show all arenas')}
                  </span>

                </button>}
              </div>
              <div className={acStyles.map}>
                <img src={`https://static.cupmanager.net/uploads/staticMap,${location.location.id},200x120,zoom=7,hash=ENHBBCRB.png`} alt={''} />
              </div>
            </div>
          </div>
        })}
      </>}

      {render(result.categories) && <div className={acStyles.search_group}>
        <SearchGroupHeader type="categories" />
        <div className={acStyles.content}>
          <ul>
            {categoryChoosen.map((category:any) => <li key={category.category.id}>
              <Link to={`/nations/${category.category.id}`} className={acStyles.link} >
                <span>{category.category.gender === 'men' 
                  ? <FontAwesomeIcon icon={faMars} /> 
                  : <FontAwesomeIcon icon={faVenus} />
                } {T.select(category.name)}</span>
              </Link>
            </li>)}
            {catExpanded && <li className={acStyles.separator}></li>}
            {catExpanded && categoryOthers.map((category:Search$SearchCategory<{
                category: {}
            }>) => (
              <li key={category.category.id}>
                <Link to={`/nations/${category.category.id}`} className={acStyles.link}>
                <span>{category.category.gender === 'men' 
                  ? <FontAwesomeIcon icon={faMars} /> 
                  : <FontAwesomeIcon icon={faVenus} />
                } {T.select(category.name)}</span>
                </Link>
              </li>
            ))}
          </ul>

          {categoryOthers.length > 0 && !catExpanded && <button className={acStyles.show_more} onClick={() => {   
            setCatExpanded(true);
          }}>
            <span>
              <FontAwesomeIcon icon={faPlus} /> {T('Show all categories')}
            </span>
          </button>}

        </div>
      </div>}

      {/*render(result.nations) && <div className={acStyles.search_group}>
        <SearchGroupHeader type="nations" />
        <div className={acStyles.content}>
          <ul>
            {result.nations.map((nation:any) => <li>
              <Link to={`/nations/${nation.nation.id}`} className={acStyles.link}>
                <Flag code={nation.nation.code}/> <span>{T.select(nation.nation.name)}</span>
              </Link>
            </li>)}
          </ul>
        </div>
      </div>*/}

    </div>}
  </div>
}