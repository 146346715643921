import ReactDOM from "react-dom";

export default function AriaLivePortal ({children} : {children: string | JSX.Element}) {

  const portal = document.getElementById('async-data-messages-portal');

  if(!portal) return null

  return ReactDOM.createPortal(
    <>{children}</>,
    portal
  )
}