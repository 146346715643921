import { Team$statistics } from "../../api/schema"
import ContentGrayContainer from "../ContentGrayContainer"
import styles from './matchStatistics.module.scss';
import { useTranslations } from "cupman-utils"
import ThreePointsCount from "./ThreePointsCount"
import BasketPoints from "./BasketPoints";
import ScoredFreeThrows from "./ScoredFreeThrows";
import FoulsDiscqualifying from "./FoulsDiscqualifying";
import FoulsUnsportsmanlike from "./FoulsUnsportsmanlike";
import FoulsPersonal from "./FoulsPersonal";
import FoulsTechnical from "./FoulsTechnical";
import FoulsTotal from "./FoulsTotal";
import Timeouts from "./Timeouts";
import WonMatches from "./WonMatches";
import TiedMatches from "./TiedMatches";
import LostMatches from "./LostMatches";
import classNames from "classnames";
import ConcededPoints from "./ConcededPoints";

type Props = {
  statistics: {home: Team$statistics, away: Team$statistics},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  },
  live: boolean
}

export default function BasketballStatisticsTotal ({statistics, teamColors, live} : Props) {

  const showFouls = live || 
    statistics.home.fouls_disqualifying ||
    statistics.home.fouls_personal || 
    statistics.home.fouls_technical ||
    statistics.home.fouls_unsportsmanlike ||
    statistics.away.fouls_disqualifying ||
    statistics.away.fouls_personal  ||
    statistics.away.fouls_technical  ||
    statistics.away.fouls_unsportsmanlike;

  const showTimouts = live ||
    statistics.home.timeouts ||
    statistics.away.timeouts

  const showThreepointers = live ||
    statistics.home.threePointers ||
    statistics.away.threePointers

  const showFreeThrows = live ||
    statistics.home.onePointers ||
    statistics.away.onePointers

  const T = useTranslations('cmresults');
  
  return <>
    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Matcher')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <WonMatches statistics={statistics} teamColors={teamColors} />
      <LostMatches statistics={statistics} teamColors={teamColors} />
    </ContentGrayContainer>

    <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
      {T('Poäng')}
    </h3>
    <ContentGrayContainer className={styles.gray_container}>
      <BasketPoints statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
      {!!showThreepointers && <ThreePointsCount statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />}
      {!!showFreeThrows && <ScoredFreeThrows statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />}
      <ConcededPoints statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
    </ContentGrayContainer>
  
    {!!showFouls && <>
      <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
        {T('Fouls')}
      </h3>
      <ContentGrayContainer className={styles.gray_container}>
        <FoulsTotal statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
        <FoulsPersonal statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
        <FoulsTechnical statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
        {(statistics.away.fouls_disqualifying > 0 || statistics.home.fouls_disqualifying > 0) && <FoulsDiscqualifying statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />}
        {(statistics.away.fouls_unsportsmanlike > 0 || statistics.home.fouls_unsportsmanlike > 0) && <FoulsUnsportsmanlike statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />}
      </ContentGrayContainer>
    </>}
    {!!showTimouts && <>
      <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
        {T('Timeouts')}
      </h3>
      <ContentGrayContainer className={styles.gray_container}>
        <Timeouts statistics={statistics} teamColors={teamColors} statisticsTotal={statistics} />
      </ContentGrayContainer>
    </>}
  </>
}