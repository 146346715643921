import { ReactFragment } from "react";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../utils";
import styles from './Button.module.scss';
var cn = require('classnames');


type props = {
  to: string,
  children: ReactFragment,
  className?: string,
  target?: string
}

export default function ExternalLink ({to, children, className, target} : props) {

  const extUrl =  to.indexOf('http') > -1;

  return extUrl
    ? <a href={to} className={className} target={target || "_blank"} rel="noreferrer">
      {children}
    </a>
    : <Link to={to}  target={target} className={className}>
    {children}
  </Link>
}