import classNames from 'classnames';
import styles from './WinnersIcon.module.scss';

type props = {
  placement: number
}

export default function TopGoalsIcon ({placement} : props) {


    
  return <div className={styles.wrapper} style={{'--clr-icon' : '#fff'} as any}>
  {/* placement IS LARGER THAN 3 - ALWAYS THIS SYMBOL */

  placement > 3 ? 
    <>
      {placement && <div className={styles.placement}>
        <svg viewBox="0 0 22 22">
          <text x="50%" y="17"  style={{
            fill: 'var(--clr-icon)',
            textAnchor: 'middle'

          }}>{placement}</text>
        </svg>
      </div>}

      <svg version="1.1" id="Lager_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 123.7 112.1" xmlSpace="preserve">
        <path style={{fill: 'var(--clr-icon)'}} d="M83.59304,112.09659H40.13297c-6.55612,0-12.66529-3.52698-15.94381-9.20509L2.45889,65.25291c-3.27852-5.67717-3.27852-12.73207,0-18.41018L24.18916,9.20509C27.46675,3.52698,33.57639,0,40.13297,0h43.46007c6.55705,0,12.66575,3.52698,15.94428,9.20509l21.7298,37.63765c3.27759,5.67811,3.27759,12.73301,0,18.41018l-21.7298,37.63858c-3.27852,5.67811-9.38816,9.20509-15.94428,9.20509ZM40.13297,3.82588c-5.19427,0-10.03406,2.79375-12.63073,7.29215L5.77198,48.75568c-2.59667,4.4984-2.59667,10.08683,0,14.5843l21.73027,37.63858c2.59713,4.4984,7.43693,7.29215,12.63073,7.29215h43.46007c5.19333,0,10.03359-2.79375,12.63026-7.29215l21.7298-37.63858c2.5976-4.49746,2.5976-10.0859,0-14.5843L96.2233,11.11803c-2.59667-4.4984-7.43599-7.29215-12.63026-7.29215H40.13297Z"/>
        
      </svg>
    </>
    
  : <>
      {/* FINISHED - SHOW REAL ACHIEVEMNTS */}
      {placement === 1 && <img src="https://static.cupmanager.net/images/achievements/goals-gold.svg" alt="" className={styles.glow_gold} />}

      {placement === 2 && <img src="https://static.cupmanager.net/images/achievements/goals-silver.svg" alt=""  className={styles.glow_silver}/>}

      {placement === 3 && <img src="https://static.cupmanager.net/images/achievements/goals-bronze.svg" alt=""  className={styles.glow_bronze}/>}
    </>}
  </div>
}