import { useTranslations } from "cupman-utils";
import CountdownSection from "./CountdownSection";
import styles from  './Countdown.module.scss'

function pad(n:number, l:number) {
    let str = "" + n;
    while (str.length < l) {
        str = "0" + str;
    }
    return str;
}

export default function Countdown({color, days, hours, mins, secs} : {color?: string, days:number, hours:number, mins:number, secs:number}) {
    const T = useTranslations("cmresults");

    const getTimeFormat = () => {
      if (days > 0) {
          return <>
              <CountdownSection title={T('Days')} value={pad(days, 3)} color={color} />
              <CountdownSection title={T('Hours')} value={pad(hours, 2)} color={color} />
          </>
      } else {
          if (hours > 0) {
              return <>
                  <CountdownSection title={T('Hours')} value={pad(hours, 2)} color={color} />
                  <CountdownSection title={T('Minutes')} value={pad(mins, 2)} color={color} />
              </>
          } else {
              return <>
                  <CountdownSection title={T('Minutes')} value={pad(mins, 2)} color={color} />
                  <CountdownSection title={T('Seconds')} value={pad(secs, 2)} color={color} />
              </>
          }
      }
    }
  
    return <>
      <div className={styles.container}>
        {getTimeFormat()}
      </div>
      <span className={styles.title}>
        {T('until the game starts!')}
      </span>
    </>
}



