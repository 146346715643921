import { NameClub, Team$shirt as Shirt, Sport$SportTypes } from '../../api/schema';
import Dress from './Dress';
import svgMask from '../../img/clip-path-logo.svg';
import styles from './TeamLogo.module.scss';
import classNames from 'classnames';

type Props = {
  logoImg?: string,
  dress?: Omit<Shirt,'__typename'>,
  nationCode: string,
  sport: Sport$SportTypes | undefined,
  scrolled: boolean | undefined
}

export default function TeamLogoDress ({logoImg, dress, nationCode, sport, scrolled} : Props) {

  console.log(dress)

  return <div className={classNames(scrolled && styles.scrolled)}>
    
    <div 
      className={styles.team_icon_container} 
      style={{'--image-mask': `url(${svgMask})`} as any}
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        version="1.1" 
        viewBox="0 0 125.4 113.2"
        className={styles.border}
      >
        <g style={{fill: 'rgb(var(--clr-favorite-dark))'}}>
          <path d="M85.4,0h-45.4c-6.2,0-11.9,3.3-14.9,8.6L2.3,48c-3.1,5.3-3.1,11.9,0,17.2l22.7,39.4c3.1,5.3,8.8,8.6,14.9,8.6h45.4c6.2,0,11.9-3.3,14.9-8.6l22.7-39.4c3.1-5.3,3.1-11.9,0-17.2l-22.7-39.4c-3.1-5.3-8.8-8.6-14.9-8.6Z"/>
        </g>
      </svg>

      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        version="1.1" 
        viewBox="0 0 125.4 113.2"
        className={styles.icon_bg}
      >
        <g style={{fill: 'white'}}>
          <path d="M85.4,0h-45.4c-6.2,0-11.9,3.3-14.9,8.6L2.3,48c-3.1,5.3-3.1,11.9,0,17.2l22.7,39.4c3.1,5.3,8.8,8.6,14.9,8.6h45.4c6.2,0,11.9-3.3,14.9-8.6l22.7-39.4c3.1-5.3,3.1-11.9,0-17.2l-22.7-39.4c-3.1-5.3-8.8-8.6-14.9-8.6Z"/>
        </g>
      </svg>
      <div className={classNames(styles.mask, sport === 'basketball' && styles.basket, sport === 'icehockey' && styles.icehockey)}>
        {logoImg 
          ? <img src={logoImg} alt="cat" />
          : <Dress sport={sport} dress={dress} nationCode={nationCode} />
        }
      </div>
      
    </div>
  </div>

}