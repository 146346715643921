import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { Match, useMe } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import Games from "../Game/Games";
import VideoSlider from "../VideoSlider/VideoSlider";

export default function StartPageMatchesAndStreams({schedulePublished} : {schedulePublished: boolean}) {
  const t = useCurrentTournament();
  
  
  const me = useMe({optionalCupId: t.cup.id},{
    teams: [{
      team: {}
    }]
  });

  if( me.teams.length > 0 ){
    return <FavoriteMatchesAndStreams schedulePublished={schedulePublished}/>
  } else {
    return <CommonMatchesAndStreams schedulePublished={schedulePublished}/>
  }
}

function FavoriteMatchesAndStreams({schedulePublished} : {schedulePublished: boolean}) {
  const t = useCurrentTournament();
  const T = useTranslations('cmresults');
  const me = useMe({optionalCupId: t.cup.id},{
    teams: [{
      team: {
        matches:[Games.GameFragment]
      }
    }]
  });

  const matches = me.teams.reduce((matches, team) => {
    if( team.team ){
      return [...matches, ...team.team?.matches];
    } else {
      return matches;
    }
  },[] as Match<typeof Games.GameFragment>[]).sort((a,b) => {return a.start - b.start});

  const streams = matches.filter(match => match.video);

  return <>
    {streams.length > 0 && schedulePublished && <VideoSlider 
      games={streams}
      title={T('Your streams')}
    />}

    <Games 
      order="asc"
      games={matches}
      published={schedulePublished}
      title={T('Your games')}/>
  </>
}


function CommonMatchesAndStreams({schedulePublished} : {schedulePublished: boolean}) {
  const t = useCurrentTournament();
  const T = useTranslations('cmresults');
  const _t = useLink(t, {
    finishedMatches: [Games.GameFragment],
    comingMatches: [Games.GameFragment],
    liveMatches: [Games.GameFragment]
  });
  
  const {finishedMatches, comingMatches, liveMatches} = _t;
  
  const liveStreams = liveMatches.filter(match => match.video);
  const upcomingStreams = []  as Match<typeof Games.GameFragment>[];//comingMatches.filter(match => match.video);
  const pastStreams = [] as Match<typeof Games.GameFragment>[];//finishedMatches.filter(match => match.video);

  const streamingChapters = [
    {
      games: liveStreams.length > 0
      ? liveStreams
      : [],
      title: T('Streaming now'),
      future: false
    },
    {
      games: upcomingStreams.length > 0
      ? upcomingStreams
      : [],
      title: T('Coming streams'),
      future: true
    },
    {
      games: pastStreams.length > 0
        ? pastStreams
        : [],
      title: T('Replays'),
      future: false
    }
  ]

  return <>
    {schedulePublished && streamingChapters.map((chapter, i) => chapter.games.length > 0 && <VideoSlider 
      games={chapter.games} 
      key={i}
      index={i}
      future={chapter.future}
      title={chapter.title}
    />)}

    {/* NO FAVORITE GAMES ************ */}
    {schedulePublished && liveMatches.length === 0 && finishedMatches.length == 0 && comingMatches.length > 0 && <Games 
      published={schedulePublished}
      games={comingMatches}
      alwaysShow
      title={T('Coming games')}
      order='asc'
    />}
    {schedulePublished && liveMatches.length > 0 && <Games 
      published={schedulePublished} 
      games={liveMatches} 
      title={T('Live games')}
      order='asc' />}
      {schedulePublished && finishedMatches.length > 0 && <Games 
          published={schedulePublished} 
          alwaysShow
          games={finishedMatches} title={T('Last played games')} order='desc' 
    />}
    {/* / NO FAVORITE GAMES ************ */}
  </>
}