import { useTranslations } from "cupman-utils";
import LabeledOpposingHorizontalBars from "./LabeledOpposingHorizontalBars";

type Props = {
  statistics: {home: {lost: number}, away: {lost: number}},
  statisticsTotal: {home: {lost: number, goals: number}, away: {lost: number, goals: number}},
  teamColors: {
    home: string | undefined,
    away: string | undefined
  }
}

export default function ConcededPoints ({statistics, teamColors, statisticsTotal} : Props) {

  const T = useTranslations('cmresults')

  const totalPoints = statisticsTotal.home.goals + statisticsTotal.away.goals;
  const totalConceded = statisticsTotal.home.lost + statisticsTotal.away.lost;
  const scale = Math.max(totalPoints, totalConceded)


  return <LabeledOpposingHorizontalBars 
    label={T('Förlorade')}
    left={{
      value: statistics.home.lost / scale,
      color: teamColors.home,
      label: statistics.home.lost,
    }}
    right={{
      value: statistics.away.lost / scale,
      color: teamColors.away,
      label: statistics.away.lost
    }}
  />
}