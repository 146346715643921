import classNames from 'classnames';
import styles from './TopScrollShadow.module.scss';

export default function TopScrollShadow ({top,topSmall} : {top?: number, topSmall?:number}) {

  const style = {
    '--top': `${top || 0}px`,
    '--topSmall': `${topSmall || ((top||10) - 10) || 0}px`
  }

  return <div style={style as any} className={classNames(styles.shadow, "no-print")}></div>

}