import styles from './ListColumns.module.scss';

type props<T> = {
  list: {header: JSX.Element | string, items:T[]}[],
  render: (p:T) => JSX.Element
}

export default function ListColumns<T>({list, render} : props<T>) {

  return (
    <div className={styles.list_obj_by_character}>
      {list.map((entry,i) => <div key={i}>
        <div className={styles.header}>
          <h3 className={"custom-font"}>{entry.header}</h3>

          {(entry.items.length > 6 
            ? entry.items.slice(0,3)
            : entry.items)
            .map(listObj => render(listObj)
          )}
        </div>
        {entry.items.length > 6 && <>
          {entry.items.slice(3, -3).map((listObj, i) => (
            <div key={i} className={styles.breaker}>
                {render(listObj)}
            </div>
          ))}
          <div className={styles.breaker}>
            {entry.items.slice(-3).map((listObj, i) => (
              <div key={i}>
                {render(listObj)}
              </div>
            ))}
            </div>
        </>}
      </div>)}
    </div>
  )

}